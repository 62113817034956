import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { linkExcelApi } from "../api";

export const excelApi = createApi({
  reducerPath: "excelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: linkExcelApi,
  }),
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: () => ({
        url: "/mid_point_rate",
        method: "GET",
      }),
    }),
    getCompetitors: builder.query({
      query: () => ({
        url: "/competitor",
        method: "GET",
      }),
    }),
    getCrypto: builder.query({
      query: () => ({
        url: "/crypto_rates",
        method: "GET",
      }),
    }),
    getSheet2Rates: builder.query({
      query: () => ({
        url: "/b2b_rates",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useGetCompetitorsQuery,
  useGetCryptoQuery,
  useGetSheet2RatesQuery,
} = excelApi;
