import { Box, Button, Container } from "@mui/material";
import React from "react";
import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
import { useDispatch, useSelector } from "react-redux";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { setName } from "../../../features/transferSlice";
import { setNgn } from "../../../features/transferRouteSlice";

const TransferNgn = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(setNgn({ fullName, bankName, accountNumber }));
    navigate("/review-ngn-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>

          <Container>
            <div className="wn-h1 font-500">Reciever's Bank Information</div>
            <Row className=" justify-content-between align-items-center">
              <Col
                style={{ margin: "10px 0" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="input-h1">Full name of account holder</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req wn-input1"
                    placeholder="Enter account holder name"
                    value={fullName}
                    required
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </Col>
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">Bank name</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter bank name"
                  value={bankName}
                  required
                  onChange={(e) => setBankName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center">
              <Col style={{ margin: "10px 0" }} xs={12} md={12}>
                <div className="input-h1">Account number</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter account number"
                  value={accountNumber}
                  required
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" justify-content-between align-items-center">
              <Col
                style={{ marginTop: "20px" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="text-center">
                  <Button
                    variant="contained"
                    style={{
                      background: "white",
                      color: "black",
                      padding: "10px 15px",
                    }}
                    onClick={handleAddRecipient}
                  >
                    <img src={userAdd} className="me-2" alt="" />
                    Add to recipients
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="text-center">
                  <button className="wn-btn1" type="submit">
                    Continue
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </Box>
      </div>
    </form>
  );
};

export default TransferNgn;
