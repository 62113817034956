import { Box, Container, Grid, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import info from "../../../assets/info.png";
import userAdd from "../../../assets/user-add.png";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import countryList from "../../../components/countryList.js";
import { setName } from "../../../features/transferSlice";
import { setUsd } from "../../../features/transferRouteSlice";

const UsdSwift = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban_AccountNumber, setIban_AccountNumber] = React.useState("");
  const [swift_BicCode, setSwift_BicCode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [country, setCountry] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(
      setUsd({
        fullName,
        bankName,
        iban_AccountNumber,
        swift_BicCode,
        address,
        city,
        postCode,
        country,
        transactionType: "Swift",
      })
    );
    navigate("/review-usd-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     userId,
    //     address,
    //     iban: iban_AccountNumber,
    //     swiftCode: swift_BicCode,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center font-600">
        <div
          style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14 }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          To send USD outside the US, we’ll need to use SWIFT. This will make
          your transfer cost a little more and may take 2-5 days longer. Other
          banks involved may also charge fees.
        </div>
      </div>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Full name of account holder</div>
          <input
            className="input-req w-100 "
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Bank name</div>
          <input
            className="input-req w-100 "
            placeholder="Enter bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">IBAN / Account number</div>
          <input
            className="input-req w-100 "
            placeholder="Enter number"
            value={iban_AccountNumber}
            required
            onChange={(e) => setIban_AccountNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">SWIFT / BIC code</div>
          <input
            className="input-req w-100 "
            placeholder="Enter code"
            value={swift_BicCode}
            required
            onChange={(e) => setSwift_BicCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Recipient country</div>

          <div style={{ marginTop: "10px" }}>
            <select
              style={{
                background: "white",
                borderRadius: "5px",
                border: "none",
                fontSize: "18px",
                outline: "none",
                padding: "16px 18px",
                width: "100%",
              }}
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            >
              {countryList.map(({ name, code }) => (
                <option value={name} key={code}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">City</div>
          <input
            className="input-req w-100 "
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Recipient address</div>
          <input
            className="input-req w-100 "
            placeholder="Enter recipient address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Postal code</div>
          <input
            className="input-req w-100 "
            placeholder="Enter postal code"
            value={postCode}
            required
            onChange={(e) => setPostCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Row className=" justify-content-between align-items-center">
        <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
          <div className="text-center">
            <Button
              variant="contained"
              style={{
                background: "white",
                color: "black",
                padding: "10px 15px",
              }}
              onClick={handleAddRecipient}
            >
              <img src={userAdd} className="me-2" alt="" />
              Add to recipients
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="text-center">
            <button className="wn-btn1" type="submit">
              Continue
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default UsdSwift;
