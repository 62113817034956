/* eslint-disable no-unused-vars */
import "./Tabs.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TabNavItem from "./V2/TabNavItem";
import TabContent from "./V2/TabContent";
import Activity from "./V2/Activity";
import Orders from "./V2/Orders";
import Accounts from "../screens/bankaccounts/Accounts";
import { useGetOrderQuery } from "../services/transferApi";
import { selectCurrentUserId } from "../features/auth/authSlice";

const Tabs = () => {
  const userId = useSelector(selectCurrentUserId);

  const [activeTab, setActiveTab] = useState("tab1");
  const { data } = useGetOrderQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="Tabs">
      <ul className="nav tab-nav tabs-res">
        <TabNavItem
          title="Account"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Activity"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Orders"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <Accounts />
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <Activity />
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
          <Orders />
        </TabContent>
      </div>
    </div>
  );
};

export default Tabs;
