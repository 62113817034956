import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RequestIntlAcc from './screens/account/RequestIntlAcc';
import ReqAccTwo from './screens/ReqAccTwo/ReqAccTwo';
import ReqAccThree from './screens/ReqReceived/ReqReceived';
import ReqNotReceived from './screens/ReqNotReceived/ReqNotReceived';
// import Cards from "./screens/cards/Cards";
import CardCreate from './screens/cards/CardCreate';
import CardWithHistory from './screens/cards/CardWithHistory';
// import ShowCCDetails from "./screens/cards/components/ShowCCDetails";
// import DeleteCard from "./screens/cards/components/DeleteCard";
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Error404 from './screens/404/Error404';
import AccountDashboard from './screens/account/AccountDashboard';
import WithdrawCard from './screens/account/WithdrawCard';
import CompareRates from './screens/compare/CompareRates';
import Dashboard from './screens/dashboard/Dashboard';
import DepositMono from './screens/deposit/Deposit';
import DepositReqNotReceived from './screens/deposit/DepositReqNotReceived';
import DepositReqReceived from './screens/deposit/DepositReqReceived';
import DepositRequest from './screens/deposit/DepositRequest';
import Login from './screens/login/Login';
import Signup from './screens/login/Signup';
import Verify from './screens/login/Verify';
import TrackOrder from './screens/order/TrackOrder';
import ChangePassword from './screens/profile/ChangePassword';
import Profile from './screens/profile/Profile';
import AddRecipient from './screens/recipients/AddRecipient';
import Recipient from './screens/recipients/Recipient';
import PayTransfer from './screens/transaction/PayTransfer';
import ReceiverAddress from './screens/transaction/ReceiverAddress';
import ReviewOrder from './screens/transaction/ReviewOrder';
import Transaction from './screens/transaction/Transaction';
import UsdAccountDetails from './screens/transaction/UsdInAccDetails';
import IDVerification from './screens/verification/IDVerification';
import VerificationInProgress from './screens/verification/VerificationInProgress';
import BankWithdrawEurope from './screens/withdraw/BankWithdrawEurope';
import BankWithdrawGbp from './screens/withdraw/BankWithdrawGbp';
import BankWithdrawNgn from './screens/withdraw/BankWithdrawNgn';
import BankWithdrawPaypal from './screens/withdraw/BankWithdrawPaypal';
import BankWithdrawTry from './screens/withdraw/BankWithdrawTry';
import BankWithdrawUSD from './screens/withdraw/BankWithdrawUSD';
import Withdraw from './screens/withdraw/Withdraw';
import Waitlist from './screens/waitlist/Waitlist';
import WithdrawalNotReceived from './screens/withdraw/WithdrawalNotReceived';
import WithdrawalReqReceived from './screens/withdraw/WithdrawalReqReceived';
// import ChangePinTwo from "./screens/cards/ChangePinTwo";
import Privacy from './screens/404/Privacy';
import Terms from './screens/404/Terms';
// import LeftPanel from "./screens/cards/components/LeftPanel";
// import DashboardNew from "./screens/dashboard/DashboardNew";
import DashboardVerify from './screens/dashboard/DashboardVerify';
// import SideSlider from "./components/SideSlider";
// import MobileNavbarTwo from "./components/MobileNavbarTwo";
// import Transfer from "./screens/transfer/Transfer";
// import CardDetails from "./screens/cards/CardDetails";
import jwtDecode from 'jwt-decode';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentToken, signOut } from './features/auth/authSlice';
import { useGetCurrenciesQuery } from './services/excelApi';
import ProtectedRoutes from './utils/ProtectedRoutes';

// Transfer
import NoAccess from './components/alpha_test/NoAccess';
import ThankYou from './components/alpha_test/ThankYou';
import CardReqNotReceived from './screens/cards/CardReqNotRecieved';
import CardReqReceived from './screens/cards/CardReqRecieved';
import FundCard from './screens/cards/FundCard';
import FundCardVendor from './screens/cards/FundCard_Vendor';
import FundCardExchange from './screens/cards/FundCard_Exchange';
import FundCardReview from './screens/cards/FundCard_Review';
import EmailVerification from './screens/login/EmailVerification';
import ForgotPassword from './screens/login/ForgotPassword';
import PasswordChanged from './screens/login/PasswordChanged';
import ResetPassword from './screens/login/ResetPassword';
import ChfLinkAccountDetails from './screens/transaction/ChfLinkAccountDetails';
import EurInAccDetails from './screens/transaction/EurInAccDetails';
import EurOutAccDetails from './screens/transaction/EurOutAccDetails';
import GbpInAccDetails from './screens/transaction/GbpInAccDetails';
import GbpOutAccDetails from './screens/transaction/GbpOutAccDetails';
import MykoboEurAccountDetails from './screens/transaction/MykoboEurAccountDetails';
import UsdInAccDetails from './screens/transaction/UsdInAccDetails';
import UsdOutAccDetails from './screens/transaction/UsdOutAccDetails';
import AedP2pRequest from './screens/transfer/AED/AedP2pRequest';
import AedReviewOrder from './screens/transfer/AED/AedReviewOrder';
import TransferAed from './screens/transfer/AED/TransferAed';
import CadP2pRequest from './screens/transfer/CAD/CadP2pRequest';
import CadReviewOrder from './screens/transfer/CAD/CadReviewOrder';
import ReverseCadTransfer from './screens/transfer/CAD/ReverseCadTransfer';
import TransferCad from './screens/transfer/CAD/TransferCad';
import ChfP2pRequest from './screens/transfer/CHF/ChfP2pRequest';
import ChfReviewOrder from './screens/transfer/CHF/ChfReviewOrder';
import ReverseChfTransfer from './screens/transfer/CHF/ReverseChfTransfer';
import TransferChf from './screens/transfer/CHF/TransferChf';
import CnhReviewOrder from './screens/transfer/CNH/CnhReviewOrder';
import TransferCnh from './screens/transfer/CNH/TransferCnh';
import EurMykP2pRequest from './screens/transfer/EUR/EurMykP2pRequest';
import EurP2pRequest from './screens/transfer/EUR/EurP2pRequest';
import EurReviewOrder from './screens/transfer/EUR/EurReviewOrder';
import ReverseEurMykoboTransfer from './screens/transfer/EUR/ReverseEurMykoboTransfer';
import ReverseEurTransfer from './screens/transfer/EUR/ReverseEuroTransfer';
import TransferEur from './screens/transfer/EUR/TransferEur';
import GbpP2pRequest from './screens/transfer/GBP/GbpP2pRequest';
import GbpReviewOrder from './screens/transfer/GBP/GbpReviewOrder';
import ReverseGbpTransfer from './screens/transfer/GBP/ReserverGbpTransfer';
import TransferGbp from './screens/transfer/GBP/TransferGbp';
import KesP2pRequest from './screens/transfer/KES/KesP2pRequest';
import KesReviewOrder from './screens/transfer/KES/KesReviewOrder';
import ReverseKesTransfer from './screens/transfer/KES/ReverseKesTransfer';
import TransferKes from './screens/transfer/KES/TransferKes';
import TransferKesBank from './screens/transfer/KES/TransferKesBank';
import TransferKesMobile from './screens/transfer/KES/TransferKesMobile';
import NgnReviewOrder from './screens/transfer/NGN/NgnReviewOrder';
import TransferNgn from './screens/transfer/NGN/TransferNgn';
import ProofOfPayment from './screens/transfer/ProofOfPayment';
import RequestFailed from './screens/transfer/response/RequestFailed';
import RequestRecieved from './screens/transfer/response/RequestRecieved';
import RoutingRequest from './screens/transfer/response/RoutingRequest';
import TransferMethod from './screens/transfer/TransferMethod';
import ReverseTryTransfer from './screens/transfer/TRY/ReverseTryTransfer';
import TransferTry from './screens/transfer/TRY/TransferTry';
import TryP2pRequest from './screens/transfer/TRY/TryP2pRequest';
import TryReviewOrder from './screens/transfer/TRY/TryReviewOrder';
import ReverseUsdTransfer from './screens/transfer/USD/ReverseUsdTransfer';
import TransferUsd from './screens/transfer/USD/TransferUsd';
import UsdP2pRequest from './screens/transfer/USD/UsdP2pRequest';
import UsdReviewOrder from './screens/transfer/USD/UsdReviewOrder';
import CryptoFundReq from './screens/cards/CryptoFundReq';
import WalletFundReq from './screens/cards/WalletFundReq';
import Ramp from './screens/ramp/Ramp';
import AddressVerification from './screens/verification/AddressVerification';
import VerificationUpdate from './screens/verification/VerificationUpdate';
import UpdateKyc from './components/alpha_test/UpdateKyc';

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const accessToken = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  // Fetch currencies on app load
  // eslint-disable-next-line no-unused-vars
  const { data } = useGetCurrenciesQuery('currencies', {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    accessToken && verifyExpiry(accessToken);
  });

  const verifyExpiry = (accessToken) => {
    const { exp } = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (exp < currentTime) {
      dispatch(signOut());
    }
  };

  return (
    <Router>
      <Wrapper>
        <Toaster />
        <Routes>
          {/* Public Routes */}
          <Route path="*" element={<Error404 />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms-and-condition" element={<Terms />} />
          <Route path="/" element={<Login />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify" element={<Verify />} />
          <Route
            path="/waitlist/new-waitlist- user/71guave"
            element={<Waitlist />}
          />
          {/* Ramps Route */}
          <Route path="/ramp" element={<Ramp />} />
          {/* <Route path="/ramp-person-info" element={<PersonlInfo />} />
          <Route path="/ramp-current-address" element={<CurrentAddress />} />
          <Route path="/ramp-identification" element={<Identification />} />
          <Route path="/ramp-send-usdc" element={<SendUSDC />} />
          <Route path="/ramp-upload-payment" element={<UploadPayment />} />
          <Route path="/ramp-complete" element={<Complete />} />
           */}
          <Route
            path="/email-verification/:id/"
            element={<EmailVerification />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-changed" element={<PasswordChanged />} />
          <Route path="/verification-update" element={<VerificationUpdate />} />
          <Route path="/id-verification" element={<IDVerification />} />
          <Route
            path="/address-verification"
            element={<AddressVerification />}
          />
          <Route
            path="/verification-in-progress"
            element={<VerificationInProgress />}
          />
          <Route path="/request-not-received" element={<ReqNotReceived />} />
          <Route path="/dashboard-unverified" element={<DashboardVerify />} />
          <Route path="/profile" element={<Profile />} />

          {/* /////////////////////// Private Routes //////////////////////////////// */}
          <Route element={<ProtectedRoutes />}>
            {/* <Route path="/" element={<DashboardNew />} /> */}
            {/* <Route path="/transfer" element={<Transfer />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Request For virtual Account */}
            <Route
              path="/request-international-account"
              element={<RequestIntlAcc />}
            />
            <Route path="/request-account" element={<ReqAccTwo />} />
            <Route path="/request-received" element={<ReqAccThree />} />
            {/* Request For virtual Account End */}

            {/* Access For virtual Dollar car */}
            <Route path="/card-create" element={<CardCreate />} />
            <Route path="/cards" element={<CardWithHistory />} />
            <Route path="/fund-card" element={<FundCard />} />
            <Route
              path="/fund-card/fiat/vendors"
              element={<FundCardVendor />}
            />
            <Route
              path="/:id-fund-card/fiat/exchange"
              element={<FundCardExchange />}
            />
            <Route
              path="/:id-fund-card/fiat/review-order"
              element={<FundCardReview />}
            />

            <Route path="/card-req-received" element={<CardReqReceived />} />
            <Route
              path="/card-req-not-received"
              element={<CardReqNotReceived />}
            />
            <Route
              path="/wallet-fund-card-success"
              element={<WalletFundReq />}
            />
            <Route
              path="/crypto-fund-card-success"
              element={<CryptoFundReq />}
            />
            {/* Access For virtual Dollar car */}

            <Route path="/recipient" element={<Recipient />} />
            <Route path="/add-recipient" element={<AddRecipient />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/withdraw-card" element={<WithdrawCard />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/receivers-address" element={<ReceiverAddress />} />
            <Route path="/review-order" element={<ReviewOrder />} />
            <Route path="/transfer-mode" element={<PayTransfer />} />
            <Route path="/deposit-request" element={<DepositRequest />} />

            <Route path="/deposit" element={<DepositMono />} />
            <Route
              path="/deposit-req-received"
              element={<DepositReqReceived />}
            />
            <Route
              path="/deposit-req-not-received"
              element={<DepositReqNotReceived />}
            />
            <Route path="/:id-account-details" element={<AccountDashboard />} />
            <Route path="/change-pass" element={<ChangePassword />} />
            <Route path="/compare-rates" element={<CompareRates />} />
            <Route path="/track-order" element={<TrackOrder />} />

            {/* Withdraw */}
            <Route path="/withdraw-usd" element={<BankWithdrawUSD />} />
            <Route path="/withdraw-try" element={<BankWithdrawTry />} />
            <Route path="/withdraw-ngn" element={<BankWithdrawNgn />} />
            <Route path="/withdraw-gbp" element={<BankWithdrawGbp />} />
            <Route path="/withdraw-eur" element={<BankWithdrawEurope />} />
            <Route path="/withdraw-paypal" element={<BankWithdrawPaypal />} />
            {/* Withdraw End*/}

            {/* Withdraw response*/}
            <Route
              path="/withdrawal-completed"
              element={<WithdrawalReqReceived />}
            />
            <Route
              path="/withdrawal-failed"
              element={<WithdrawalNotReceived />}
            />
            {/* Withdraw response End */}

            {/* Transfer */}
            <Route path="/transfer-method" element={<TransferMethod />} />

            {/* ////////////////////////////////USD/////////////////////////////////////// */}
            <Route path="/transfer-usd" element={<TransferUsd />} />
            <Route
              path="/transfer-reverse-usd"
              element={<ReverseUsdTransfer />}
            />
            <Route
              path="/usd-transfer-details"
              element={<UsdAccountDetails />}
            />
            <Route
              path="/usd-in-transfer-details"
              element={<UsdInAccDetails />}
            />
            <Route
              path="/usd-out-transfer-details"
              element={<UsdOutAccDetails />}
            />

            {/* <Route
              path="/transfer-reverse-usd"
              element={<UsdReverseReviewOrder />}
            /> */}
            <Route path="/transfer-p2p-usd" element={<UsdP2pRequest />} />
            {/* //////////////////////////////////GBP/////////////////////////////////////// */}

            {/* //////////////////////////////////GBP/////////////////////////////////////// */}
            <Route path="/transfer-gbp" element={<TransferGbp />} />
            <Route
              path="/transfer-reverse-gbp"
              element={<ReverseGbpTransfer />}
            />
            <Route
              path="/gbp-in-transfer-details"
              element={<GbpInAccDetails />}
            />
            <Route
              path="/gbp-out-transfer-details"
              element={<GbpOutAccDetails />}
            />
            <Route path="/transfer-p2p-gbp" element={<GbpP2pRequest />} />
            {/* /////////////////////////////////////////////////////////////////////////// */}

            {/* ////////////////////////////////////EUR/////////////////////////////////// */}
            <Route path="/transfer-eur" element={<TransferEur />} />
            <Route
              path="/transfer-reverse-eur"
              element={<ReverseEurTransfer />}
            />
            <Route
              path="/transfer-reverse-eur-myk"
              element={<ReverseEurMykoboTransfer />}
            />
            <Route
              path="/eur-in-transfer-details"
              element={<EurInAccDetails />}
            />
            <Route
              path="/eur-out-transfer-details"
              element={<EurOutAccDetails />}
            />
            <Route
              path="/eur-myk-transfer-details"
              element={<MykoboEurAccountDetails />}
            />
            <Route path="/transfer-p2p-eur" element={<EurP2pRequest />} />
            <Route
              path="/transfer-p2p-eur-myk"
              element={<EurMykP2pRequest />}
            />
            {/* /////////////////////////////////////////////////////////////////////////// */}

            {/* ////////////////////////////////////KES/////////////////////////////////// */}
            <Route path="/transfer-kes" element={<TransferKes />} />
            <Route
              path="/transfer-kes/mobile"
              element={<TransferKesMobile />}
            />
            <Route path="/transfer-kes/bank" element={<TransferKesBank />} />
            <Route
              path="/transfer-reverse-kes"
              element={<ReverseKesTransfer />}
            />
            <Route path="/transfer-p2p-kes" element={<KesP2pRequest />} />
            {/* ////////////////////////////////////////////////////////////////////////// /*/}

            {/* ///////////////////////////////////CHF//////////////////////////////////// */}
            <Route path="/transfer-chf" element={<TransferChf />} />
            <Route
              path="/transfer-reverse-chf"
              element={<ReverseChfTransfer />}
            />
            <Route
              path="/chf-transfer-details"
              element={<ChfLinkAccountDetails />}
            />
            <Route path="/transfer-p2p-chf" element={<ChfP2pRequest />} />
            {/* /////////////////////////////////OTHERS/////////////////////////////////// */}

            <Route path="/transfer-cad" element={<TransferCad />} />
            <Route
              path="/transfer-reverse-cad"
              element={<ReverseCadTransfer />}
            />
            <Route path="/transfer-p2p-cad" element={<CadP2pRequest />} />
            {/* /////////////////////////////////////////////////////////// */}

            <Route path="/transfer-try" element={<TransferTry />} />
            <Route
              path="/transfer-reverse-try"
              element={<ReverseTryTransfer />}
            />
            <Route path="/transfer-p2p-try" element={<TryP2pRequest />} />
            {/* /////////////////////////////////////////////////////////// */}

            <Route path="/transfer-aed" element={<TransferAed />} />
            <Route path="/transfer-aed" element={<TransferAed />} />
            <Route path="/transfer-p2p-aed" element={<AedP2pRequest />} />
            {/* /////////////////////////////////////////////////////////// */}

            <Route path="/transfer-cnh" element={<TransferCnh />} />
            <Route path="/transfer-ngn-ngn" element={<TransferNgn />} />

            {/* Transfer routes End */}

            {/* transaction Order Response*/}
            <Route path="/transfer-received" element={<RequestRecieved />} />
            <Route path="/transfer-failed" element={<RequestFailed />} />
            <Route path="/transfer-routing" element={<RoutingRequest />} />
            {/* transaction Order Response End */}

            {/* /////////// review Order //////////////////////// */}
            <Route path="/review-usd-order" element={<UsdReviewOrder />} />
            <Route path="/review-gbp-order" element={<GbpReviewOrder />} />
            <Route path="/review-cad-order" element={<CadReviewOrder />} />
            <Route path="/review-eur-order" element={<EurReviewOrder />} />
            <Route path="/review-try-order" element={<TryReviewOrder />} />
            <Route path="/review-chf-order" element={<ChfReviewOrder />} />
            <Route path="/review-cnh-order" element={<CnhReviewOrder />} />
            <Route path="/review-aed-order" element={<AedReviewOrder />} />
            <Route path="/review-ngn-order" element={<NgnReviewOrder />} />
            <Route path="/review-kes-order" element={<KesReviewOrder />} />
            <Route path="/proof-of-payment" element={<ProofOfPayment />} />
            {/* ////////// review Order End ///////////////////// */}

            {/* Alpha test */}
            <Route path="limited-access" element={<NoAccess />} />
            <Route path="incomplete-verification" element={<UpdateKyc />} />
            <Route path="thank-you" element={<ThankYou />} />
          </Route>
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
