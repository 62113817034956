import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Container, MenuItem, Modal, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { url } from '../../api';
import backArrow from '../../assets/arrow-left.png';
import bank from '../../assets/bank.png';
import close from '../../assets/close.png';
import copyIcon from '../../assets/copy_2.png';
import NGN from '../../assets/nigeria.png';
import recipient from '../../assets/recipient.png';
import save from '../../assets/save.png';
import withdraw from '../../assets/withdraw.png';
import AccountButton from '../../components/AccountButton';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import { selectCurrentUserId } from '../../features/auth/authSlice';
import { useBankMutation } from '../../services/ngnWithdrawAPi';
import '../cards/components/CreateCard.css';
import LeftPanel from '../cards/components/LeftPanel';
import './Acc.css';
import './RequestIntlAcc.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F3F4F6',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  width: 450,
  p: 4,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: window.innerHeight - 200,
  overflowY: 'scroll',
  borderRadius: 3,
  width: 450,
  zIndex: 100,
};

let cur = '';

const AccountDashboard = () => {
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);

  const handleClose3 = () => setOpen3(false);

  const location = useLocation();

  const {
    balance,
    currency,
    img,
    symbol,
    bankName,
    bankAddress,
    accountNumber,
    accountName,
    currencyName,
  } = location.state;

  cur = currency.toLowerCase();

  return (
    <>
      <Row>
        <div>
          <MobileNavbarOne
            activeColor1="#1565DB"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#1565DB"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </div>
        <Col lg={2}>
          <LeftPanel
            activeColor1="#1565DB"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#1565DB"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </Col>
        <Col className="primary-bg">
          <Container maxWidth="xl" className="idv-box1">
            <div className="sm-none">
              <AccountButton />
            </div>
            <Link to="/dashboard" style={{ textDecoration: 'none' }}>
              <div
                className="d-flex align-items-center mt-5"
                style={{ color: '#1565D8' }}
              >
                <KeyboardBackspaceIcon className="me-2" />
                <span className="font-600 fs-5 riac-h1">
                  Go back to the dashboard
                </span>
              </div>
            </Link>
            <Typography className="font-700 wr-h1">
              Your {currency} account details
            </Typography>
            <br />
            <div className="d-flex align-items-center justify-content-between py-2">
              <div>
                <Typography className="font-700 acd-h1">
                  {symbol}
                  {balance}
                </Typography>
                <Typography
                  className="font-600"
                  style={{ fontSize: 20, color: '#696F79' }}
                >
                  {currencyName}
                </Typography>
              </div>
              <div>
                <img src={img} alt="flag" className="flag" />
              </div>
            </div>
            <br />
            <div className="">
              <div className="d-flex align-items-center">
                <Deposit
                  accHolder={accountName}
                  accNumber={accountNumber}
                  bank_name={bankName}
                />
                <button className="withdraw-btn" onClick={handleOpen3}>
                  <img className="me-4" src={withdraw} alt="withdraw" />
                  Withdraw
                </button>
                <Modal
                  open={open3}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style2} style={{ width: 370 }}>
                    <WithdrawBank
                      close={handleClose3}
                      balance={balance}
                      currency={symbol}
                      userAccNum={accountNumber}
                    />
                  </Box>
                </Modal>
              </div>
            </div>
            {/* <div className="md-none">
              <MobileBtnsUSD />
            </div> */}
            <br />
            <br />
            <Box>
              <div className="acc-details-container pt-3 pb-3">
                <div className="acc-details-h1">Account Name:</div>
                <div className="acc-details-h2">{accountName}</div>
              </div>
              <br />
              <div className="acc-details-container pt-3 pb-3">
                <div className="acc-details-h1">Account number:</div>
                <div className="acc-details-h2">{accountNumber}</div>
              </div>
              <br />
              <div className="acc-details-container pt-3 pb-3">
                <div className="acc-details-h1">Bank name:</div>
                <div className="acc-details-h2">{bankName}</div>
              </div>
              <br />
              {bankAddress && (
                <>
                  <div className="acc-details-container pt-3 pb-3">
                    <div className="acc-details-h1">Bank address</div>
                    <div className="acc-details-h2">{bankAddress}</div>
                  </div>
                  <br />
                </>
              )}
              <div className="acc-details-container pt-3 pb-3">
                <div className="acc-details-h1">Account type:</div>
                <div className="acc-details-h2">Savings</div>
              </div>
              <div style={{ height: '5vh' }}></div>
              <br />
            </Box>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default AccountDashboard;

// Deposit In to account
function Deposit({ accHolder, accNumber, bank_name }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button onClick={handleOpen} className="deposit-btn">
        <img className="me-4" src={save} alt="save" />
        Deposit
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 370 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              className="my-2 font-600"
              style={{ fontSize: 22, color: '#1565d8' }}
            >
              Account Details
            </Typography>
            <img
              src={close}
              onClick={handleClose}
              style={{ height: 32, width: 32, cursor: 'pointer' }}
              alt=""
            />
          </div>
          <br />

          {/* <ChildModal /> */}
          <AccDetailsPopup
            accNumber={accNumber}
            bank_name={bank_name}
            accHolder={accHolder}
          />
        </Box>
      </Modal>
    </div>
  );
}

const AccDetailsPopup = ({ accHolder, accNumber, bank_name }) => {
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast('✅ Copied to clipboard');
  };

  return (
    <>
      <Box className="w-100 d-flex align-items-center py-2 justify-content-between">
        <div className="d-flex align-items-center" style={{ width: 250 }}>
          <div>
            <div className="font-600" style={{ fontWeight: 600, fontSize: 20 }}>
              Account holder
            </div>
            <div
              className="font-400 acc-details-h1"
              style={{ fontSize: 19, color: '#A4A5A6' }}
            >
              {accHolder}
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#1565D839',
            padding: 8,
            borderRadius: 50,
            cursor: 'pointer',
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleCopy(accHolder)}
        >
          <img src={copyIcon} style={{ height: 18, width: 18 }} alt="" />
        </div>
      </Box>
      <Box className="w-100 d-flex align-items-center py-2 justify-content-between">
        <div className="d-flex align-items-center" style={{ width: 250 }}>
          <div>
            <div className="font-600" style={{ fontWeight: 600, fontSize: 20 }}>
              Account number
            </div>
            <div
              className="font-400 acc-details-h1"
              style={{ fontSize: 19, color: '#A4A5A6' }}
            >
              {accNumber}
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#1565D839',
            padding: 8,
            borderRadius: 50,
            cursor: 'pointer',
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleCopy(accNumber)}
        >
          <img src={copyIcon} style={{ height: 18, width: 18 }} alt="" />
        </div>
      </Box>
      <Box className="w-100 d-flex align-items-center py-2 justify-content-between">
        <div className="d-flex align-items-center" style={{ width: 250 }}>
          <div>
            <div className="font-600" style={{ fontWeight: 600, fontSize: 20 }}>
              Bank name
            </div>
            <div
              className="font-400 acc-details-h1"
              style={{ fontSize: 19, color: '#A4A5A6' }}
            >
              {bank_name}
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#1565D839',
            padding: 8,
            borderRadius: 50,
            cursor: 'pointer',
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleCopy(bank_name)}
        >
          <img src={copyIcon} style={{ height: 18, width: 18 }} alt="" />
        </div>
      </Box>
    </>
  );
};

// Withdraw from virtual account
const Withdraw = (p) => {
  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <img
          src={close}
          onClick={p.close}
          style={{ height: 42, width: 42, cursor: 'pointer' }}
          alt="close"
        />
      </div>
      <Typography className="font-500 fs-2 my-1 ">Withdraw</Typography>
      <br />

      {/* Withdraw to Bank Account */}
      <Link
        to={`/withdraw-${cur}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Box
          style={{ borderRadius: 8, background: 'white' }}
          className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <img src={bank} style={{ height: 46, width: 46 }} alt="" />
            </div>
            <div>
              <div className="font-600" style={{ fontWeight: 600 }}>
                Bank
              </div>
              <div
                className="font-600"
                style={{ fontSize: 12, color: '#A4A5A6', fontWeight: 500 }}
              >
                Withdraw to a bank account.
              </div>
            </div>
          </div>
          <div>
            <ArrowForwardIosIcon style={{ color: '#A4A5A6', fontSize: 16 }} />
          </div>
        </Box>
      </Link>
      <br />

      {/* Withdraw to Recipient */}
      <Link to="/recipient" style={{ textDecoration: 'none', color: 'black' }}>
        <Box
          style={{ borderRadius: 8, background: 'white' }}
          className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <img src={recipient} style={{ height: 46, width: 46 }} alt="" />
            </div>
            <div>
              <div className="font-600" style={{ fontWeight: 600 }}>
                Recipient
              </div>
              <div
                className="font-600"
                style={{ fontSize: 12, color: '#A4A5A6', fontWeight: 500 }}
              >
                Send to a recipient from your account.
              </div>
            </div>
          </div>
          <div>
            <ArrowForwardIosIcon style={{ color: '#A4A5A6', fontSize: 16 }} />
          </div>
        </Box>
      </Link>
      <br />

      {/* Withdraw to paypal */}
      {/* <Link
        to="/withdraw-paypal"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Box
          style={{ borderRadius: 8, background: "white" }}
          className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <img src={paypal} style={{ height: 46, width: 46 }} alt="" />
            </div>
            <div>
              <div className="font-600" style={{ fontWeight: 600 }}>
                PayPal (United States and Nigeria only)
              </div>
              <div
                className="font-600"
                style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
              >
                Withdraw to a bank account.
              </div>
            </div>
          </div>
          <div>
            <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
          </div>
        </Box>
      </Link> */}
      <br />

      {/* Physical Delivery payout */}
      {/* <Link
        to="/receivers-address"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Box
          style={{ borderRadius: 8, background: "white" }}
          className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <img src={account} style={{ height: 46, width: 46 }} alt="" />
            </div>
            <div>
              <div className="font-600" style={{ fontWeight: 600 }}>
                Physical delivery (North Cyprus only)
              </div>
              <div
                className="font-600"
                style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
              >
                Request for a delivery to your doorstep.
              </div>
            </div>
          </div>
          <div>
            <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
          </div>
        </Box>
      </Link> */}
      <br />
    </>
  );
};

const WithdrawBank = (p) => {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(50);

  const inputCheck = () => {
    if (amount < 0) {
      return setAmount(0);
    }
    if (amount > p.balance) {
      return setAmount(p.balance);
    }
  };

  // useEffect
  useEffect(() => {
    inputCheck();
  }, [amount]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{
        borderRadius: 10,
        position: 'relative',
      }}
      className="px-4 py-3 primary-bg"
    >
      <div style={{ textAlign: 'right' }}>
        <img
          src={close}
          style={{ width: 42, height: 42, cursor: 'pointer' }}
          alt="close"
          onClick={p.close}
        />
      </div>
      <Typography
        className="font-500 fs-3 my-2"
        style={{ textAlign: 'center' }}
      >
        How much do you want to send?
      </Typography>
      <div>
        <div className="py-2 input-transfer-container">
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{
                width: '50%',
              }}
            >
              <input
                className="input-transfer"
                placeholder="Enter amount"
                value={amount}
                required
                type="number"
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <MenuItem
                className="d-flex align-items-center px-2 font-500 mr-2"
                style={{
                  borderLeft: '1px solid grey',
                }}
                value="USDC"
              >
                <img
                  style={{
                    height: 30,
                    width: 45,
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'contain',
                  }}
                  src={NGN}
                  alt="flag"
                />
                NGN
              </MenuItem>
            </div>
          </div>
        </div>
        <Box
          className="my-3 font-500 px-2 py-1 d-flex align-items-center rounded"
          style={{
            fontSize: 14,
            background: '#E7EFFF',
            color: '#1856AF',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>Available balance:</p>
          <p
            style={{
              fontSize: 16,
              marginTop: '0px',
            }}
          >
            {p.currency} {p.balance}
          </p>
        </Box>
        {/* <p
          style={{
            fontSize: 12,
            textAlign: 'center',
            fontWeight: 600,
            marginTop: 10,
          }}
        >
          Fixed fee on withdrawals: ₦50
        </p> */}
        <p
          style={{
            fontSize: 12,
            textAlign: 'center',
            fontWeight: 400,
            marginTop: 20,
          }}
        >
          Click 'Continue' to input the receiver’s Nigerian bank details.
        </p>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ background: 'transparent' }}
        >
          <Box sx={style2} className="left-panel-popup primary-bg">
            <WithdrawDetails
              close={handleClose}
              amount={amount}
              balance={p.balance}
              userAccNum={p.userAccNum}
            />
          </Box>
        </Modal>
        <button
          style={{
            width: '50%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: '#30ae4d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginBottom: '1rem',
            marginTop: '1.5rem',
            marginLeft: '25%',
          }}
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const WithdrawDetails = (p) => {
  const [open, setOpen] = React.useState(false);
  const [fullName, setFullName] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [bankName, setBankName] = React.useState('Select Bank');
  const [accNameCheck, setAccNameCheck] = React.useState('');
  const [bvnError, setBvnError] = React.useState('');

  const checkIDNumber = () => {
    if (accountNumber.length !== 10)
      setAccNameCheck('🚫 provide 10 digits of account number');
    setFullName('');
    if (accountNumber.length === 10) {
      setAccNameCheck('');
      handleCheckAccountNumber();
    }
  };

  const handleCheckAccountNumber = async () => {
    const loading = toast.loading('Fetching account name...');
    try {
      const { data } = await axios.get(
        `${url}/account/verify-account-number?num=${accountNumber}&bank=${bankName}`
      );
      if (data.status === 'success') {
        setFullName(data.name);
        // setAccNameCheck("✅ account name retrieved");
        toast.success('Account name found', { id: loading });
      } else {
        // setAccNameCheck(data.message);
        toast.error('Account name invalid', { id: loading });
      }
    } catch (error) {
      // console.log(error);
      toast.error('Failed to get account name', { id: loading });
    }
  };

  useEffect(() => {
    checkIDNumber();
  }, [accountNumber]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{
        borderRadius: 10,
        position: 'relative',
      }}
      className="px-4 py-1 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <Typography
        className="font-500 fs-3 my-1"
        style={{ textAlign: 'center' }}
      >
        Receiver’s Bank Information
      </Typography>
      <form>
        <Col className="idv-col1 mb-3" xs={12}>
          <div className="input-h1">Bank name</div>
          <div>
            <Select
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              className="font-500"
              label={false}
              fullWidth
              required
              style={{ background: 'white', height: 50 }}
            >
              <MenuItem
                className="d-flex align-items-center px-3 font-500"
                value="Select Bank"
              >
                Select Bank
              </MenuItem>
              <hr className="m-0" />
              {listOfBanks()}
            </Select>
          </div>
        </Col>
        <Col xs={12}>
          <div className="input-h1">Account number</div>
          <input
            className="input-req wn-input1 mb-0"
            placeholder="E.g 4120661825"
            value={accountNumber}
            type="tel"
            required
            readOnly={bankName === 'Select Bank' ? true : false}
            maxLength="10"
            onChange={(e) => setAccountNumber(e.target.value)}
          />
          <p className="mb-4">{accNameCheck}</p>
        </Col>
        <Col xs={12} className="wn-box1">
          <div className="input-h1">Full name of account holder</div>
          <div className="d-flex align-items-center">
            <input
              className="input-req wn-input1"
              placeholder="account name"
              value={fullName}
              type="text"
              // readOnly={true}
              required
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </Col>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ background: 'transparent' }}
        >
          <Box sx={style2} className="left-panel-popup primary-bg">
            <WithdrawReview
              close={handleClose}
              amount={p.amount}
              userAccNum={p.userAccNum}
              balance={p.balance}
              name={fullName}
              bank={bankName}
              accountNumber={accountNumber}
            />
          </Box>
        </Modal>
        <button
          type="button"
          disabled={fullName === '' || accountNumber === ''}
          style={{
            width: '50%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: '#30ae4d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginBottom: '1rem',
            marginTop: '1rem',
            marginLeft: '25%',
          }}
          onClick={handleNext}
        >
          Continue
        </button>
      </form>
    </div>
  );
};

const WithdrawReview = (p) => {
  const location = useLocation();
  const { accountRef } = location.state;

  const ref = Math.random().toString(36).slice(2, 29);
  const navigate = useNavigate();
  const userId = useSelector(selectCurrentUserId);
  const [bankTransfer] = useBankMutation();
  const [debitAmount, setDebitAmount] = React.useState(0);

  const amountCheck = () => {
    if (p.balance - p.amount > 50) {
      return setDebitAmount(p.amount);
    } else {
      const debitBal = p.amount - 50;
      return setDebitAmount(debitBal);
    }
  };

  // useEffect
  useEffect(() => {
    amountCheck();
  }, [debitAmount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading('Processing Withdrawal...');

    try {
      const response = await bankTransfer({
        userId,
        userAccNum: p.userAccNum,
        amount: debitAmount,
        fullName: p.name,
        bank: p.bank,
        accountNumber: p.accountNumber,
        custRef: ref,
        accRef: accountRef,
      }).unwrap();
      toast.success(response.message, { id: toastSubmit });
      navigate('/withdrawal-completed');
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      // navigate('/withdrawal-failed');
    }
  };

  return (
    <div
      style={{
        borderRadius: 10,
        position: 'relative',
      }}
      className="px-4 py-1 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <Typography className="font-500 fs-1" style={{ textAlign: 'center' }}>
        Review transaction
      </Typography>
      <div className="px-2 pt-4">
        <div className="d-flex align-items-center input-contain">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Transaction type
            </label>
            <input
              className="input-create-card"
              id="input-create-card-name"
              disabled
              type="text"
              value={`Withdrawal`}
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-contain">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Reference code
            </label>
            <input
              className="input-create-card"
              id="input-create-card-name"
              disabled
              type="text"
              value={ref}
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-contain">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Amount
            </label>
            <input
              className="input-create-card"
              id="input-create-card-name"
              disabled
              type="text"
              value={p.amount}
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-contain">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Recipient Name
            </label>
            <input
              className="input-create-card"
              id="input-create-card-name"
              disabled
              type="text"
              value={p.name}
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-contain">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Recipient Account Number
            </label>
            <input
              className="input-create-card"
              id="input-create-card-name"
              disabled
              type="text"
              value={p.accountNumber}
            />
          </div>
        </div>
        <button
          style={{
            width: '50%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: '#30ae4d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginBottom: '1rem',
            marginTop: '1rem',
            marginLeft: '25%',
          }}
          onClick={handleSubmit}
        >
          Complete
        </button>
      </div>
    </div>
  );
};

const availableBanks = [
  {
    bank_name: '9Payment Service Bank',
    bank_code: '120001',
  },
  {
    bank_name: 'Access Bank',
    bank_code: '000014',
  },
  {
    bank_name: 'Access Bank (Diamond)',
    bank_code: '000005',
  },
  {
    bank_name: 'Carbon',
    bank_code: '100026',
  },
  {
    bank_name: 'Citibank Nigeria',
    bank_code: '000009',
  },
  {
    bank_name: 'Ecobank',
    bank_code: '000010',
  },
  {
    bank_name: 'Eyowo',
    bank_code: '090328',
  },
  {
    bank_name: 'FairMoney',
    bank_code: '090551',
  },
  {
    bank_name: 'Fidelity Bank',
    bank_code: '000007',
  },
  {
    bank_name: 'First Bank of Nigeria',
    bank_code: '000016',
  },
  {
    bank_name: 'FIRST CITY MONUMENT BANK',
    bank_code: '000003',
  },
  {
    bank_name: 'Globus Bank',
    bank_code: '000027',
  },
  {
    bank_name: 'Guaranty Trust Bank',
    bank_code: '000013',
  },
  {
    bank_name: 'Heritage Bank',
    bank_code: '000020',
  },
  {
    bank_name: 'Jaiz Bank',
    bank_code: '000006',
  },
  {
    bank_name: 'Keystone Bank',
    bank_code: '000002',
  },
  {
    bank_name: 'Kuda',
    bank_code: '090267',
  },
  {
    bank_name: 'Moniepoint Microfinance ank',
    bank_code: '090405',
  },
  {
    bank_name: 'OPAY',
    bank_code: '100004',
  },
  {
    bank_name: 'Paga',
    bank_code: '100002',
  },
  {
    bank_name: 'PalmPay',
    bank_code: '100033',
  },
  {
    bank_name: 'Getripay (PatrickGold Microfinance Bank)',
    bank_code: '090317',
  },
  {
    bank_name: 'Parallex Bank',
    bank_code: '000030',
  },
  {
    bank_name: 'Polaris Bank',
    bank_code: '000008',
  },
  {
    bank_name: 'Providus Bank',
    bank_code: '000023',
  },
  {
    bank_name: 'Stanbic IBTC Bank',
    bank_code: '000012',
  },
  {
    bank_name: 'Standard Chartered Bank',
    bank_code: '000021',
  },
  {
    bank_name: 'Sterling Bank',
    bank_code: '000001',
  },
  {
    bank_name: 'Suntrust Bank',
    bank_code: '000022',
  },
  {
    bank_name: 'TITAN TRUST BANK',
    bank_code: '000025',
  },
  {
    bank_name: 'Union Bank of Nigeria',
    bank_code: '000018',
  },
  {
    bank_name: 'United Bank for Africa',
    bank_code: '000004',
  },
  {
    bank_name: 'Unity Bank',
    bank_code: '000011',
  },
  {
    bank_name: 'VFD Microfinance Bank',
    bank_code: '090110',
  },
  {
    bank_name: 'Wema Bank',
    bank_code: '000017',
  },
  {
    bank_name: 'Zenith Bank',
    bank_code: '000015',
  },
];

export const listOfBanks = () => {
  const bankList = availableBanks.map((items, index) => (
    <MenuItem
      key={index}
      className="d-flex align-items-center px-3 font-500"
      value={items.bank_code}
    >
      {items.bank_name}
    </MenuItem>
  ));
  return bankList;
};
