import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const usdWithdrawApi = createApi({
  reducerPath: "usdWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    localBank: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/usd/local-bank",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    swift: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/usd/swift",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLocalBankMutation, useSwiftMutation } = usdWithdrawApi;
