import React, { useEffect, useState } from "react";
import TabContent from "../../compare/V2/TabContent";
import TabNavItem from "../../compare/V2/TabNavItem";
import CadLocal from "./CadLocal";
import CadInterac from "./CadInterac";

const CadTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [text, setText] = useState("Reciever's Bank Information ");

  useEffect(() => {
    if (activeTab === "tab1") {
      setText("Reciever's Bank Information ");
    } else if (activeTab === "tab2") {
      setText("Reciever's Bank Information");
    } else {
      setText("Reciever's Bank Information");
    }
  }, [activeTab]);

  return (
    <>
      <div className="wn-h1 font-500">{text}</div>
      <div className="Tabs my-3">
        <ul className="nav tab-nav ">
          <TabNavItem
            title="Local bank account"
            id="tab1"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="Interac"
            id="tab2"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>

        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <CadLocal />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <CadInterac />
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default CadTabs;
