import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import ngn from '../../assets/nigeria.png'
import britain from '../../assets/britain.png'
import turkey from '../../assets/turkey.png'
import usa from '../../assets/usa.png'
import ukraine from '../../assets/ukraine.png'

import './RequestIntlAcc.css'
import { Typography } from '@mui/material';


const WithdrawCard = () => {
    return (
        <>
            <Card
                img={ngn}
                countryName="NGN"
                currency="₦"
            />
            <Card
                img={usa}
                countryName="USD"
                currency="$"
            />
            <Card
                img={turkey}
                countryName="TRY"
                currency="₺"
            />
            <Card
                img={britain}
                countryName="GBP"
                currency="£"
            />
            <Card
                img={ukraine}
                countryName="EUR"
                currency="€"
            />

        </>
    )
}

const Card = (p) => {
    return (
        <div className='font-600 my-4'>
            <div style={{ background: "#F3F4F6", maxWidth: 400, borderRadius: 8, padding: "20px 12px" }} className="px-3">
                <div className='d-flex justify-content-between'>
                    <KeyboardBackspaceIcon />
                    <CloseIcon />
                </div>
                <br />
                <Typography className='font-600' variant="h4">Withdraw</Typography>
                <br />
                <div style={{ padding: 12, margin: '10px 0', background: "white", borderRadius: 8 }} className="my-2 d-flex justify-content-between align-items-center monster-font px-3" >
                    <div style={{ fontWeight: 600 }} >
                        <input className='input-withdraw' placeholder='489.77' />
                    </div>
                    <div className='d-flex align-items-center' style={{ fontWeight: 600, color: "black" }}>
                        <img src={p.img} alt="arrowDown" className='mx-3' style={{ height: 42, width: 42 }} />
                        {p.countryName}
                    </div>

                </div>
                <div style={{ padding: 12, margin: '10px 0', background: "white", borderRadius: 8 }} className="my-3 d-flex justify-content-between monster-font px-3" >
                    <div>
                        <div className='py-2' style={{ fontWeight: 600 }} >{p.currency}25</div>
                        <div className='py-2' style={{ fontWeight: 600 }}>in less than 24 hours</div>
                    </div>
                    <div>
                        <div className='py-2' style={{ fontWeight: 500, color: "grey" }}>Fees</div>
                        <div className='py-2' style={{ fontWeight: 500, color: "grey" }}>Estimated arrival</div>
                    </div>
                </div>
                <br />
                <div className='text-center '>
                    <button className="btn btn-success" style={{ background: "#30AE4D", minWidth: 200, fontSize: 24, borderColor: '#30AE4D', borderRadius: 8 }}>
                        Withdraw
                    </button>
                    <div style={{ height: "15vh" }}></div>
                </div>
            </div>
        </div>
    )
}

export default WithdrawCard