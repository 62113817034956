import React from "react";
import logout from "../assets/logout.png";
import qna from "../assets/qna.svg";
import profile from "../assets/profile.png";
import acc from "../assets/acc.png";
import { Link } from "react-router-dom";
import { selectCurrentSurName } from "../features/auth/authSlice";
import { useSelector } from "react-redux";

const MobileNavbarOne = () => {
  const surName = useSelector(selectCurrentSurName);
  return (
    <>
      <div className="mobile-nav-bar">
        <div className="me-4">
          <div className="d-flex justify-content-end py-3">
            <button
              className="dropdown-toggle d-flex align-items-center"
              style={{
                border: "none",
                borderRadius: 8,
                padding: "6px 12px",
                color: "#000",
                background: "white",
                position: "relative",
              }}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={acc} alt="" style={{ height: 36, width: 36 }} />
              <span
                className="mx-3 font-500"
                style={{ textTransform: "capitalize" }}
              >
                {surName}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNavbarOne;
