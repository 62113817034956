import React, { useEffect, useState } from "react";
import TabContent from "../../compare/V2/TabContent";
import TabNavItem from "../../compare/V2/TabNavItem";
import UsdLocal from "./UsdLocal";
import UsdSwift from "./UsdSwift";

const UsdTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [text, setText] = useState("Receiver's Bank Information ");

  useEffect(() => {
    if (activeTab === "tab1") {
      setText("Receiver's Bank Information ");
    } else if (activeTab === "tab2") {
      setText("Receiver's Bank Information");
    } else {
      setText("Receiver's Bank Information");
    }
  }, [activeTab]);

  return (
    <>
      <div className="wn-h1 font-700">{text}</div>
      <div className="Tabs my-3">
        <ul className="nav tab-nav ">
          <TabNavItem
            title="Local bank account"
            id="tab1"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="SWIFT"
            id="tab2"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>

        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <UsdLocal />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <UsdSwift />
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default UsdTabs;
