import React from "react";

const UserIcon = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1626 25.2239C20.1359 25.5272 18.9226 25.6672 17.4993 25.6672H10.4993C9.07594 25.6672 7.8626 25.5272 6.83594 25.2239C7.0926 22.1905 10.2076 19.7988 13.9993 19.7988C17.7909 19.7988 20.9059 22.1905 21.1626 25.2239Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4987 2.33398H10.4987C4.66536 2.33398 2.33203 4.66732 2.33203 10.5007V17.5007C2.33203 21.9107 3.66203 24.3256 6.83537 25.224C7.09203 22.1906 10.207 19.7989 13.9987 19.7989C17.7904 19.7989 20.9054 22.1906 21.162 25.224C24.3354 24.3256 25.6654 21.9107 25.6654 17.5007V10.5007C25.6654 4.66732 23.332 2.33398 17.4987 2.33398ZM13.9987 16.5323C11.6887 16.5323 9.82203 14.654 9.82203 12.344C9.82203 10.034 11.6887 8.16732 13.9987 8.16732C16.3087 8.16732 18.1754 10.034 18.1754 12.344C18.1754 14.654 16.3087 16.5323 13.9987 16.5323Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1776 12.3427C18.1776 14.6527 16.3109 16.531 14.0009 16.531C11.6909 16.531 9.82422 14.6527 9.82422 12.3427C9.82422 10.0327 11.6909 8.16602 14.0009 8.16602C16.3109 8.16602 18.1776 10.0327 18.1776 12.3427Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
