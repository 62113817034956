import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "../services/authApi";
import { accRequestApi } from "../services/accRequestApi";
import { accountCardApi } from "../services/accountCardApi";
import { usdWithdrawApi } from "../services/usdWithdrawApi";
import { gbpWithdrawApi } from "../services/gbpWithdrawApi";
import { tryWithdrawApi } from "../services/tryWithdrawApi";
import { eurWithdrawApi } from "../services/eurWithdrawApi";
import { ngnWithdrawApi } from "../services/ngnWithdrawAPi";
import { paypalWithdrawApi } from "../services/paypalWithdrawApi";
import { recipientApi } from "../services/recipientApi";
import { accVerifyApi } from "../services/accVerifyApi";
import { excelApi } from "../services/excelApi";
import { transferApi } from "../services/transferApi";
import authReducer from "../features/auth/authSlice";
import accRequestReducer from "../features/accRequestSlice";
import physicalDeliveryReducer from "../features/physicalDeliverySlice";
import recipientReducer from "../features/recipientSlice";
import transactionReducer from "../features/transactionSlice";
import converterReducer from "../features/converterSlice";
import competitorReducer from "../features/competitorSlice";
import transferReducer from "../features/transferSlice";
import transferRouteReducer from "../features/transferRouteSlice";
import moneygramReducer from "../features/moneygramSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const authPersistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [accRequestApi.reducerPath]: accRequestApi.reducer,
    [accountCardApi.reducerPath]: accountCardApi.reducer,
    [usdWithdrawApi.reducerPath]: usdWithdrawApi.reducer,
    [gbpWithdrawApi.reducerPath]: gbpWithdrawApi.reducer,
    [tryWithdrawApi.reducerPath]: tryWithdrawApi.reducer,
    [eurWithdrawApi.reducerPath]: eurWithdrawApi.reducer,
    [ngnWithdrawApi.reducerPath]: ngnWithdrawApi.reducer,
    [paypalWithdrawApi.reducerPath]: paypalWithdrawApi.reducer,
    [recipientApi.reducerPath]: recipientApi.reducer,
    [accVerifyApi.reducerPath]: accVerifyApi.reducer,
    [excelApi.reducerPath]: excelApi.reducer,
    [transferApi.reducerPath]: transferApi.reducer,
    transfer: transferReducer,
    moneygram: moneygramReducer,
    transferRoute: transferRouteReducer,
    competitor: competitorReducer,
    converter: converterReducer,
    transaction: transactionReducer,
    recipient: recipientReducer,
    physicalDelivery: physicalDeliveryReducer,
    accRequest: accRequestReducer,
    auth: authPersistedReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      accRequestApi.middleware,
      accountCardApi.middleware,
      usdWithdrawApi.middleware,
      gbpWithdrawApi.middleware,
      tryWithdrawApi.middleware,
      eurWithdrawApi.middleware,
      ngnWithdrawApi.middleware,
      paypalWithdrawApi.middleware,
      recipientApi.middleware,
      accVerifyApi.middleware,
      excelApi.middleware,
      transferApi.middleware
    ),
});

export default store;
