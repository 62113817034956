import React from "react";

const HomeIcon = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 21V17.5"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7469 3.28917L3.66185 9.76417C2.75185 10.4875 2.16852 12.0158 2.36685 13.1592L3.91852 22.4458C4.19852 24.1025 5.78519 25.4442 7.46519 25.4442H20.5319C22.2002 25.4442 23.7985 24.0908 24.0785 22.4458L25.6302 13.1592C25.8169 12.0158 25.2335 10.4875 24.3352 9.76417L16.2502 3.30084C15.0019 2.29751 12.9835 2.29751 11.7469 3.28917Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
