import React from "react";
import Info from "../../assets/info.svg";
import close from "../../assets/close-square.png";
import "./alpha_test.css";
import { Link } from "react-router-dom";

const UpdateKyc = () => {
  return (
    <div className="cont">
      <div>
        <Link to="/dashboard">
          <img src={close} alt="close" className="img" />
        </Link>
      </div>
      <div className="bod">
        <div>
          <img src={Info} alt="info" style={{ width: "80px" }} />
        </div>
        <div className="text">
          <h2 className="heading">Limited Access</h2>
          <p className="info">
            You don't have access to this feature. Please update your
            verification details to get access to this feature.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdateKyc;
