import {
  Box,
  Container,
  Modal,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/arrow-left.png";
import emptySqaure from "../../assets/empty-square.png";
import logo from "../../assets/logo.png";
import tickSquare from "../../assets/tickSquare.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import {
  selectCurrentAccLock,
  selectCurrentEmail,
  selectCurrentFirstName,
  selectCurrentLinkTag,
  selectCurrentSurName,
  selectCurrentToken,
  selectCurrentUserId,
  signOut,
} from "../../features/auth/authSlice";
import {
  useChangePasswordMutation,
  useDeactivateAccountMutation,
} from "../../services/authApi";
import ChangePassword from "./ChangePassword";
import Deactivate from "./Deactivate";

const Profile = () => {
  const [openPwd, setOpenPwd] = React.useState(false);
  const [openDeact, setOpenDeact] = React.useState(false);
  const handleOpenPwd = () => setOpenPwd(true);
  const handleClosePwd = () => setOpenPwd(false);
  const handleOpenDeact = () => setOpenDeact(true);
  const handleCloseDeact = () => setOpenDeact(false);

  const navigate = useNavigate();

  const firstName = useSelector(selectCurrentFirstName || "");
  const surName = useSelector(selectCurrentSurName || "");
  const email = useSelector(selectCurrentEmail || "");
  const linkTag = useSelector(selectCurrentLinkTag || "");
  const verificationStatus = useSelector(selectCurrentAccLock || "");
  const token = useSelector(selectCurrentToken || "");
  const userId = useSelector(selectCurrentUserId || "");

  useEffect(() => {
    if (!token && !userId) return navigate("/signin");
  });

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go back</span>
                </button>
              </Link>
              <div></div>
            </Box>
          </Container>
          <Container>
            <h1 className="wr-h1 font-500">Profile</h1>
            <Typography color="#696F79" className="font-600">
              Update your personal details here.
            </Typography>
            <Box className="my-5 d-flex align-items-center">
              <div
                className="font-600 riac-h4"
                style={{ color: "#696f79", marginRight: 20 }}
              >
                Verification Status :{" "}
              </div>
              {/* Verified */}
              {verificationStatus === "verified" && (
                <div
                  style={{
                    fontSize: 18,
                    background: "#1565D8",
                    minWidth: 180,
                    color: "white",
                  }}
                  className=" px-4 me-3 py-2 font-600 rounded-pill d-flex align-items-center justify-content-center"
                >
                  <img
                    src={tickSquare}
                    style={{ width: 28 }}
                    alt="tickSquare"
                    className="me-2"
                  />
                  Verified
                </div>
              )}
              {/* verifying */}
              {verificationStatus === "verifying" && (
                <div
                  style={{
                    fontSize: 20,
                    background: "#A4A5A6",
                    minWidth: 200,
                    color: "white",
                  }}
                  className=" px-5 me-3 py-2 font-600 rounded-pill d-flex align-items-center justify-content-center"
                >
                  <img src={emptySqaure} alt="emptySqaure" className="me-2" />
                  Verifying
                </div>
              )}
              {/* unverified */}
              {verificationStatus === "unverified" && (
                <div
                  style={{
                    fontSize: 20,
                    background: "#ff0000",
                    minWidth: 200,
                    color: "white",
                  }}
                  className=" px-5 py-2 font-600 rounded-pill d-flex align-items-center justify-content-center"
                >
                  <img src={emptySqaure} alt="emptySqaure" className="me-2" />
                  Unverified
                </div>
              )}
            </Box>
            <Row className="justify-content-between align-items-center ">
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">First name</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req w-100"
                    disabled
                    value={firstName}
                    style={{ textTransform: "capitalize" }}
                  />
                  <div className="dropdown"></div>
                </div>
              </Col>
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Surname</div>
                <input
                  className="input-req w-100"
                  disabled
                  value={surName}
                  style={{ textTransform: "capitalize" }}
                />
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center ">
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">LINK Tag</div>
                <input className="input-req w-100" disabled value={linkTag} />
              </Col>
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Email</div>
                <input className="input-req w-100" disabled value={email} />
              </Col>
            </Row>
            <div className="mt-5" style={{ maxWidth: 1070 }}>
              <div></div>
              <div className="p-box1">
                <div>
                  <div className="fs-2 font-500">Update password</div>
                  <div className="fs-5 font-600" style={{ color: "#696F79" }}>
                    Change your current password to a new one
                  </div>
                </div>
                <div>
                  <div className="sm-none">
                    <button
                      onClick={handleOpenPwd}
                      className="wn-btn1"
                      style={{ width: 300 }}
                    >
                      Change password
                    </button>
                  </div>
                  <div className="md-none">
                    <ChangePass />
                  </div>
                </div>
              </div>
              <Modal open={openPwd}>
                <Box sx={style}>
                  <ChangePassword handleClose={handleClosePwd} />
                </Box>
              </Modal>
            </div>
            <div className="mt-1" style={{ maxWidth: 1070 }}>
              <div></div>
              <div className="p-box1">
                <div>
                  <div className="fs-2 font-500">Deactivate account</div>
                  <div className="fs-5 font-600" style={{ color: "#696F79" }}>
                    Permanently delete your LINK account
                  </div>
                </div>
                <div>
                  <div className="sm-none">
                    <button
                      onClick={handleOpenDeact}
                      className="wn-btn1"
                      style={{ width: 300, backgroundColor: "#000" }}
                    >
                      Deactivate
                    </button>
                  </div>
                  <div className="md-none">
                    <DeactAccount />
                  </div>
                </div>
              </div>
              <Modal open={openDeact}>
                <Box sx={style2}>
                  <Deactivate handleClose={handleCloseDeact} />
                </Box>
              </Modal>
            </div>
          </Container>
        </Box>
        <div style={{ height: "15vh" }}></div>
      </Container>
    </div>
  );
};

export default Profile;

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F3F4F6",
  border: "2px solid #fff",
  boxShadow: 24,
  width: 500,
  height: 530,
  p: 3,
};
const style2 = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F3F4F6",
  border: "2px solid #fff",
  boxShadow: 24,
  width: 500,
  height: 300,
  p: 3,
};

function ChangePass(p) {
  const [currentPwd, setCurrentPwd] = React.useState("");
  const [newPwd, setNewPwd] = React.useState("");
  const [confirmPwd, setConfirmPwd] = React.useState("");
  const [error, setError] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [changePassword] = useChangePasswordMutation();
  const email = useSelector(selectCurrentEmail);

  const handlePasswordCheck = () => {
    if (newPwd === "" || confirmPwd === "") {
      return setError("");
    }
    if (newPwd !== confirmPwd) {
      return setError("❌ Passwords do not match");
    }
    if (newPwd === confirmPwd) {
      return setError("✅ Passwords match");
    }
  };

  useEffect(() => {
    handlePasswordCheck();
  }, [currentPwd, newPwd, confirmPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Changing...");

    try {
      const userData = await changePassword({
        currentPwd,
        newPwd,
        email,
      }).unwrap();
      toast.success("Password Changed", { id: toastSubmit });
      p.handleClose();
    } catch (error) {
      toast.error("Failed to change", { id: toastSubmit });
      console.log(error);
    }
  };

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <form onSubmit={handleSubmit}>
        <Container
          className="primary-bg "
          style={{ height: window.innerHeight }}
        >
          <br />
          <div>
            <img
              src={backArrow}
              alt="tickSquare"
              onClick={toggleDrawer(anchor, false)}
              style={{ width: 35, margin: "10px 0" }}
            />
          </div>
          <div className="wn-h1 font-500">Change Password</div>
          <div>
            <div className="input-h1">Current password</div>
            <input
              className="input-req w-100"
              placeholder="Enter your current password"
              value={currentPwd}
              type="password"
              onChange={(e) => setCurrentPwd(e.target.value)}
            />
          </div>
          <div>
            <div className="input-h1 my-2">New password</div>
            <input
              className="input-req w-100"
              placeholder="Enter a new password"
              value={newPwd}
              type="password"
              onChange={(e) => setNewPwd(e.target.value)}
            />
          </div>
          <div>
            <div className="input-h1 my-2">Confirm New password</div>
            <input
              className="input-req w-100"
              placeholder="Confirm New password"
              value={confirmPwd}
              type="password"
              onChange={(e) => setConfirmPwd(e.target.value)}
            />
            <p>{error}</p>
          </div>
          <div className="text-center">
            <button className="wn-btn1" onClick={toggleDrawer(anchor, false)}>
              Save
            </button>
          </div>
        </Container>
      </form>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <button
            className="wn-btn1"
            onClick={toggleDrawer(anchor, true)}
            style={{ width: 300 }}
          >
            Change password
          </button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
function DeactAccount(p) {
  const dispatch = useDispatch();
  const linkTag = useSelector(selectCurrentLinkTag);
  const userId = useSelector(selectCurrentUserId);
  const email = useSelector(selectCurrentEmail);

  const navigate = useNavigate();
  const [deactivateAccount] = useDeactivateAccountMutation();

  const [state, setState] = React.useState({
    bottom: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Deactivating...");

    try {
      const userData = await deactivateAccount({
        _id: userId,
      }).unwrap();

      dispatch(signOut());
      navigate("/signin");
      p.handleClose();
      toast.success("Your account has been deactivated", { id: toastSubmit });
    } catch (error) {
      toast.error("Could'nt deactivate your account, please try again!", {
        id: toastSubmit,
      });
      // console.log(error);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container className="primary-bg " style={{ height: window.innerHeight }}>
        <br />
        <div>
          <img
            src={backArrow}
            alt="tickSquare"
            onClick={toggleDrawer(anchor, false)}
            style={{ width: 35, margin: "10px 0" }}
          />
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className="fs-2 font-500">Deactivate account</div>
          <div className="fs-5 font-600" style={{ color: "#696F79" }}>
            Permanently delete your LINK account
          </div>
        </div>
        <div className="text-center">
          <button
            className="wn-btn1"
            onClick={toggleDrawer(anchor, false)}
            style={{ backgroundColor: "#696F79" }}
          >
            Cancel
          </button>
          <button
            className="wn-btn1"
            onClick={handleSubmit}
            style={{ backgroundColor: "#000", marginTop: "-5rem" }}
          >
            Deactivate
          </button>
        </div>
      </Container>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <button
            className="wn-btn1"
            onClick={toggleDrawer(anchor, true)}
            style={{ width: 300, backgroundColor: "#000" }}
          >
            Deactivate
          </button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
