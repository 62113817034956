import { Box, Button, Container, MenuItem, Select } from "@mui/material";
import React from "react";
import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import toast from "react-hot-toast";
import { useKesMobileMutation } from "../../../services/transferApi";
import userAdd from "../../../assets/user-add.png";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";

import safaricom from "../../../assets/safaricom.png";
import airtel from "../../../assets/airtel.png";
import equitel from "../../../assets/equitel.png";

import { useDispatch, useSelector } from "react-redux";
import countryList from "../../../components/countryList.js";
import { setName } from "../../../features/transferSlice";
import { setKes } from "../../../features/transferRouteSlice";

const TransferKesMobile = () => {
  const [fullName, setFullName] = React.useState("");
  const [networkProvider, setNetworkProvider] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [country, setCountry] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(
      setKes({
        fullName,
        networkProvider,
        phoneNumber,
        city,
        address,
        postCode,
        country,
        transactionType: "mobile",
      })
    );
    navigate("/review-kes-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     phoneNumber,
    //     address,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/transfer-kes">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>

          <Container>
            <div className="wn-h1 font-500">Reciever's Mobile Information</div>
            <Row className="justify-content-between align-items-center">
              <Col style={{ margin: "10px 0" }} xs={12} md={12}>
                <div className="input-h1">Recipients full name</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter recipient full name"
                  value={fullName}
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" justify-content-between align-items-center">
              <Col
                style={{ margin: "10px 0" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="input-h1">Network provider</div>
                <Select
                  value={networkProvider}
                  onChange={(e) => setNetworkProvider(e.target.value)}
                  className="font-500"
                  label={false}
                  fullWidth
                  style={{ background: "white" }}
                >
                  <MenuItem
                    className="d-flex align-items-center px-3 font-500"
                    value="Equitel"
                  >
                    <img
                      style={{
                        height: 42,
                        width: 72,
                        marginRight: 20,
                        objectFit: "contain",
                      }}
                      src={equitel}
                      alt="flag"
                    />
                  </MenuItem>
                  <hr className="m-0" />
                  <MenuItem
                    className="d-flex align-items-center px-3 font-500"
                    value="Safaricom"
                  >
                    <img
                      style={{
                        height: 42,
                        width: 82,
                        marginRight: 20,
                        objectFit: "contain",
                      }}
                      src={safaricom}
                      alt="flag"
                    />
                  </MenuItem>
                  <hr className="m-0" />
                  <MenuItem
                    className="d-flex align-items-center px-3 font-500"
                    value="Airtel"
                  >
                    <img
                      style={{
                        height: 42,
                        width: 72,
                        marginRight: 20,
                        objectFit: "contain",
                      }}
                      src={airtel}
                      alt="flag"
                    />
                  </MenuItem>
                </Select>
              </Col>
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">Phone number</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" justify-content-between">
              <Col
                style={{ margin: "10px 0" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="input-h1">Country</div>
                <div style={{ marginTop: "10px" }}>
                  <select
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      border: "none",
                      fontSize: "18px",
                      outline: "none",
                      padding: "16px 18px",
                      width: "100%",
                    }}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countryList.map(({ name, code }) => (
                      <option value={name} key={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">City</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter city"
                  value={city}
                  required
                  onChange={(e) => setCity(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" justify-content-between align-items-center">
              <Col
                style={{ margin: "10px 0" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="input-h1">Recipients address</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req wn-input1"
                    placeholder="Enter recipient address"
                    value={address}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </Col>
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">Post code</div>
                <input
                  className="input-req wn-input1"
                  placeholder="Enter post code"
                  value={postCode}
                  required
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" justify-content-between align-items-center">
              <Col
                style={{ marginTop: "20px" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="text-center">
                  <Button
                    variant="contained"
                    style={{
                      background: "white",
                      color: "black",
                      padding: "10px 15px",
                    }}
                    onClick={handleAddRecipient}
                  >
                    <img src={userAdd} className="me-2" alt="" />
                    Add to recipients
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="text-center">
                  <button className="wn-btn1" type="submit">
                    Continue
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </Box>
      </div>
    </form>
  );
};

export default TransferKesMobile;
