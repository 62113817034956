import { Box } from "@mui/system";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import close from "../../assets/close-square.png";
import { selectCurrentEmail } from "../../features/auth/authSlice";
import { useChangePasswordMutation } from "../../services/authApi";
import "./Profile.css";

const ChangePassword = (p) => {
  const [currentPwd, setCurrentPwd] = React.useState("");
  const [newPwd, setNewPwd] = React.useState("");
  const [confirmPwd, setConfirmPwd] = React.useState("");
  const [error, setError] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [changePassword] = useChangePasswordMutation();
  const email = useSelector(selectCurrentEmail);

  const handlePasswordCheck = () => {
    if (newPwd === "" || confirmPwd === "") {
      return setError("");
    }
    if (newPwd !== confirmPwd) {
      return setError("❌ Passwords do not match");
    }
    if (newPwd === confirmPwd) {
      return setError("✅ Passwords match");
    }
  };

  useEffect(() => {
    handlePasswordCheck();
  }, [currentPwd, newPwd, confirmPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Changing...");

    try {
      const userData = await changePassword({
        newPwd,
        currentPwd,
        email,
      }).unwrap();
      toast.success("Password Changed", { id: toastSubmit });
      p.handleClose();
    } catch (error) {
      toast.error("Failed to change", { id: toastSubmit });
      console.log(error);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
      <div className="d-flex justify-content-end">
        <img
          src={close}
          alt="close"
          onClick={p.handleClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="font-500 fs-3 ms-3">Change password</div>
      <Box className="ms-3">
        <div>
          <div className="input-h1 my-1">Current password</div>
          <input
            className="input-req"
            style={{ width: "88%", height: 50 }}
            placeholder="Enter your current password"
            type="password"
            value={currentPwd}
            onChange={(e) => setCurrentPwd(e.target.value)}
          />
        </div>
        <div>
          <div className="input-h1 my-1">New password</div>
          <input
            className="input-req"
            style={{ width: "88%", height: 50 }}
            placeholder="Enter a new password"
            type="password"
            value={newPwd}
            onChange={(e) => setNewPwd(e.target.value)}
          />
        </div>
        <div>
          <div className="input-h1 my-1">Confirm New password</div>
          <input
            className="input-req"
            style={{ width: "88%", height: 50 }}
            placeholder="Confirm New password"
            type="password"
            value={confirmPwd}
            onChange={(e) => setConfirmPwd(e.target.value)}
          />
          <p>{error}</p>
        </div>
      </Box>
      <div className="text-center">
        <button className="wn-btn1" type="submit">
          Save
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
