import React from "react";

import { Steps } from 'rsuite';

const styles = {
    width: '200px',
    display: 'inline-table',
    verticalAlign: 'top'
};


export default class  MySteps extends React.PureComponent {
    render() {
        return (
            <Steps current={1} vertical style={styles}>
                <Steps.Item title="You set up your transfer" />
                <Steps.Item title="We received your NGN" />
                <Steps.Item title="Payment being processed" />
                <Steps.Item title="USD Payout" />
                <Steps.Item title="Delivered" />
            </Steps>
        );
    }
}