import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const ngnWithdrawApi = createApi({
  reducerPath: "ngnWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    bank: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/ngn/bank",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useBankMutation } = ngnWithdrawApi;
