import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  userId: null,
  firstName: null,
  surName: null,
  token: null,
  linkTag: null,
  email: null,
  accLock: null,
  accPlus: null,
  transferAccess: null,
};

export const signin = createAsyncThunk('auth');

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {
        userFirstName,
        userSurName,
        accessToken,
        _id,
        userLinkTag,
        userEmail,
        accLock,
        accPlus,
        transferAccess,
      } = action.payload;
      state.userId = _id;
      state.firstName = userFirstName;
      state.surName = userSurName;
      state.token = accessToken;
      state.linkTag = userLinkTag;
      state.email = userEmail;
      state.accLock = accLock;
      state.accPlus = accPlus;
      state.transferAccess = transferAccess;
    },
    signOut: (state) => {
      state.userId = null;
      state.firstName = null;
      state.surName = null;
      state.token = null;
      state.linkTag = null;
      state.email = null;
      state.accLock = null;
      state.accPlus = null;
      state.transferAccess = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      createEntityAdapter.removeAll(state);
    });
  },
});

export const { setCredentials, signOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUserId = (state) => state.auth.userId;
export const selectCurrentFirstName = (state) => state.auth.firstName;
export const selectCurrentSurName = (state) => state.auth.surName;
export const selectCurrentFullName = (state) =>
  state.auth.firstName + ' ' + state.auth.surName;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentEmail = (state) => state.auth.email;
export const selectCurrentLinkTag = (state) => state.auth.linkTag;
export const selectCurrentAccLock = (state) => state.auth.accLock;
export const selectCurrentAccPlus = (state) => state.auth.accPlus;
export const selectCurrentTransAcc = (state) => state.auth.transferAccess;
