/* eslint-disable no-unused-vars */
import { Box, Container, Radio, responsiveFontSizes } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { setCredentials } from "../../features/auth/authSlice";
import { useSignInMutation } from "../../services/authApi";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  selectCurrentToken,
  selectCurrentUserId,
} from "../../features/auth/authSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && userId) return navigate("/dashboard");
  }, [token, userId]);

  const [signIn] = useSignInMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Signing In");

    try {
      const userData = await signIn({ email, password, rememberMe }).unwrap();
      toast.success("Signed in", { id: toastSubmit });
      dispatch(setCredentials({ ...userData }));
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
    }
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Container maxWidth={"xl"} className="w-100 logo-container">
        <img src={logo} alt="logo" className="logo" />
      </Container>
      <Container style={{ maxWidth: 650 }} className="mt-2">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/signup">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <span className="rco-h2">Cancel</span>
              <img src={closeBtn} alt="closeBtn" className="rco-img2" />
            </button>
          </Link>
        </Box>
      </Container>
      <form onSubmit={handleSubmit}>
        <div className="primary-bg h-100">
          <br />
          <Container style={{ maxWidth: 650 }}>
            <h1 className="monster-font display-4" style={{ fontWeight: 600 }}>
              Welcome back!
            </h1>
            <br />
            <Row className="justify-content-between align-items-center mt-2">
              <Col>
                <div className="input-h1">Email</div>
                <input
                  className="input-req"
                  style={{ width: "100%" }}
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  required
                />
              </Col>
            </Row>
            <br />
            <Row className="justify-content-between align-items-center mt-2">
              <Col>
                <div className="input-h1">Password</div>
                <input
                  className="input-req"
                  style={{ width: "100%" }}
                  placeholder={"Enter your password"}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  required
                />
              </Col>
            </Row>
            <div
              className="d-flex justify-content-between  fs-6 mb-3 font-500 pt-3"
              style={{ color: "#1565D8" }}
            >
              <div
                className="d-flex align-items-center font-600"
                style={{ color: "#888" }}
              >
                <Radio
                  style={{ padding: 0, margin: 0, minWidth: 0, marginRight: 6 }}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                Remember me
              </div>
              <Link style={{ textDecoration: "none" }} to="/forgot-password">
                <div>Forgot password?</div>
              </Link>
            </div>
          </Container>
          <Container className="text-center">
            <button className="wn-btn1" type="submit">
              Login
            </button>
          </Container>
          <div className="text-center">
            New here?
            <Link
              to="https://www.linkio.africa/wavy"
              className="ms-1 font-500"
              style={{ color: "#1565D8", textDecoration: "none" }}
            >
              Signup
            </Link>
          </div>
          <div style={{ height: "15vh" }}></div>
        </div>
      </form>
    </>
  );
};

export default Login;
