import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import info from "../../assets/info.png";
import { Box, Typography } from "@mui/material";
import "./Transaction.css";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const GbpOutAccDetails = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="rco-box1">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/transfer-reverse-gbp">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <div></div>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Typography className=" font-500 wr-h1">
          Our GBP account details (Outside the UK)
        </Typography>
        <br />
        <div
          style={{
            background: "#E7EFFF",
            Gridor: "#1856AF",
            fontSize: 14,
            color: "#1565D8",
          }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          Payments take 4-5 working days to arrive our account.
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Account holder</div>
          <div className="acc-details-right">Finc Arts Studio Ltd</div>
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">SWIFT/BIC</div>
          <div className="acc-details-right" style={{ color: "#1565D8" }}>
            TRWIGB2L
          </div>
        </div>
        <br />

        <div className="acc-details-container">
          <div className="acc-details-left">IBAN</div>
          <div className="acc-details-right" style={{ color: "#1565D8" }}>
            GB07 TRWI 2314 7034 0181 69
          </div>
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Bank Address</div>
          <div className="acc-details-right">
            56 Shoreditch High Stree London E1 6JJ United Kingdom
          </div>
        </div>

        <br />
        <div className="text-center" style={{ maxWidth: 1000 }}>
          <Link to="/proof-of-payment">
            <button className="wn-btn1">Next</button>
          </Link>
        </div>
        <div style={{ height: "20vh" }}></div>
      </Container>
    </div>
  );
};

export default GbpOutAccDetails;
