import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amount: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  secondLastName: undefined,
  birthDate: undefined,
  address: undefined,
  addressLine: undefined,
  city: undefined,
  states: undefined,
  country: undefined,
  postalCode: undefined,
  number: undefined,
  idType: undefined,
  idNumber: undefined,
  countryIssue: undefined,
};

export const moneygramSlice = createSlice({
  name: "moneygram",
  initialState,
  reducers: {
    setAmount: (state, action) => {
      const { amount, email } = action.payload;
      state.amount = amount;
      state.email = email;
    },
    setPersonalInfo: (state, action) => {
      const { firstName, lastName, middleName, secondLastName, birthDate } =
        action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.secondLastName = secondLastName;
      state.middleName = middleName;
      state.birthDate = birthDate;
    },
    setCurrentDetails: (state, action) => {
      const {
        address,
        addressLine,
        city,
        country,
        states,
        postalCode,
        number,
      } = action.payload;
      state.address = address;
      state.addressLine = addressLine;
      state.city = city;
      state.country = country;
      state.postalCode = postalCode;
      state.states = states;
      state.number = number;
    },
    setIdentification: (state, action) => {
      const { idNumber, idType, countryIssue } = action.payload;
      state.idNumber = idNumber;
      state.idType = idType;
      state.countryIssue = countryIssue;
    },

    clear: (state) => {
      state.amount = null;
      state.firstName = null;
      state.lastName = null;
      state.middleName = null;
      state.secondLastName = null;
      state.birthDate = null;
      state.address = null;
      state.addressLine = null;
      state.city = null;
      state.country = null;
      state.states = null;
      state.postalCode = null;
      state.number = null;
      state.idType = null;
      state.idNumber = null;
      state.countryIssue = null;
    },
  },
});

export const {
  setAmount,
  setPersonalInfo,
  setCurrentDetails,
  setIdentification,

  clear,
} = moneygramSlice.actions;
export default moneygramSlice.reducer;

export const selectAllTransferData = (state) => state.moneygram;
