import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  selectCurrentAccLock,
  selectCurrentToken,
} from "../features/auth/authSlice";

const ProtectedRoutes = () => {
  const location = useLocation();
  // const accessToken = true;
  // const verificationStatus = "verified";

  const accessToken = useSelector(selectCurrentToken);
  const verificationStatus = useSelector(selectCurrentAccLock);

  if (accessToken && verificationStatus === "verified") return <Outlet />;
  else if (accessToken && verificationStatus === "unverified") {
    return (
      <Navigate to="/dashboard-unverified" state={{ from: location }} replace />
    );
  } else if (accessToken && verificationStatus === "verifying") {
    return (
      <Navigate to="/dashboard-unverified" state={{ from: location }} replace />
    );
  } else return <Navigate to="/signin" state={{ from: location }} replace />;
};

export default ProtectedRoutes;
