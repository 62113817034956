import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: null,
  bankName: null,
  iban: null,
  amount: null,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransaction: (state, action) => {
      const { fullName, bankName, iban, amount } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban = iban;
      state.amount = amount;
    },
    clear: (state) => {
      state.fullName = null;
      state.bankName = null;
      state.iban = null;
      state.amount = null;
    },
  },
});

export const { setTransaction, clear } = transactionSlice.actions;
export default transactionSlice.reducer;

export const selectCurrentFullName = (state) => state.transaction.fullName;
export const selectCurrentBankName = (state) => state.transaction.bankName;
export const selectCurrentIban = (state) => state.transaction.iban;
export const selectCurrentAmount = (state) => state.transaction.amount;
