import React, { useEffect, useState } from "react";
import TabContent from "../../compare/V2/TabContent";
import TabNavItem from "../../compare/V2/TabNavItem";
import OutEurope from "./OutEurope";
import InEurope from "./InsideEurope";

const EurTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [text, setText] = useState("Withdraw EUR inside Europe ");

  useEffect(() => {
    if (activeTab === "tab1") {
      setText("Reciever's Bank Information ");
    } else if (activeTab === "tab2") {
      setText("Reciever's Bank Information ");
    } else {
      setText("Reciever's Bank Information");
    }
  }, [activeTab]);

  return (
    <>
      <div className="mb-4 font-500 wn-h1">{text}</div>

      <div className="Tabs my-3">
        <ul className="nav tab-nav">
          <TabNavItem
            title="Inside Europe"
            id="tab1"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="Outside Europe"
            id="tab2"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>

        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <InEurope />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <OutEurope />
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default EurTabs;
