/* eslint-disable no-unused-vars */
import { Box, Button, Container, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import info from "../../../assets/info.png";
import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransferReverseMutation } from "../../../services/transferApi";
import {
  selectCurrentUserId,
  selectCurrentFullName,
  selectCurrentEmail,
} from "../../../features/auth/authSlice";
import {
  selectCurrentCurrency,
  selectCurrentYouRecieve,
  selectCurrentYouSend,
} from "../../../features/transferSlice";
import toast from "react-hot-toast";

const CadP2pRequest = () => {
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");

  const amountSend = useSelector(selectCurrentYouSend);
  const amountReceived = useSelector(selectCurrentYouRecieve);
  const currency = useSelector(selectCurrentCurrency);
  const userId = useSelector(selectCurrentUserId);
  const senderName = useSelector(selectCurrentFullName);
  const senderEmail = useSelector(selectCurrentEmail);
  const ref = Math.random().toString(36).slice(2, 21);

  const [transferReverse] = useTransferReverseMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTransfer = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading("Loading...");

    try {
      const response = await transferReverse({
        userId,
        ref,
        transferType: "reverse",
        currency,
        amountSend,
        senderName,
        senderEmail,
        amountReceived,
        recipientName: fullName,
        bankName,
        nuban: number,
      }).unwrap();
      toast.success("Success", { id: loadingToast });
      navigate("/transfer-reverse-cad");
    } catch (error) {
      // console.log(error);
      toast.error("Failed", { id: loadingToast });
    }
  };

  return (
    <form onSubmit={handleTransfer}>
      <div className="primary-bg h-100">
        <Container maxWidth={"xl"} className="w-100 logo-container">
          <img src={logo} alt="logo" className="logo" />
        </Container>
        <Container style={{ maxWidth: 650 }} className="mt-2">
          <Box className="d-flex justify-content-between align-items-center">
            <div></div>
            <Link style={{ textDecoration: "none" }} to="/">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <br />
        <Container style={{ maxWidth: 650 }}>
          <h4
            className="monster-font display-4"
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            P2P Bank Transfer
          </h4>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">Recipient name</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder="Enter recipient full name"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                required
                type="text"
              />
            </Col>
          </Row>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">Recipient Bank</div>
              <select
                className="input-req w-100 px-3"
                value={bankName}
                required
                onChange={(e) => setBankName(e.target.value)}
              >
                <option>Select bank</option>
                <option>GT Bank</option>
                <option>Access Bank</option>
                <option>FCMB Bank</option>
                <option>Zenith Bank</option>
                <option>United Bank For Africa</option>
                <option>Kuda Bank</option>
                <option>Heritage Bank</option>
                <option>First Bank</option>
                <option>Jaiz Bank</option>
                <option>Providus Bank</option>
                <option>Globus Bank</option>
              </select>
            </Col>
          </Row>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">Recipient NUBAN number</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder={"Enter NUBAN number"}
                type="number"
                required
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
            </Col>
          </Row>
          <div
            style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14 }}
            className="d-flex  align-items-center p-2 my-3 rounded "
          >
            <img src={info} className="me-2" alt="" />
            This is a P2P transfer, you will be receiving money directly from an
            individual account do not send any bank deposits to this account or
            your funds will be lost.
          </div>
        </Container>

        <Container className="text-center">
          <button className="wn-btn1" type="submit">
            Next
          </button>
        </Container>

        <div style={{ height: "15vh" }}></div>
      </div>
    </form>
  );
};

export default CadP2pRequest;
