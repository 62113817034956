import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../api';

export const accountCardApi = createApi({
  reducerPath: 'accountCardApi',
  baseQuery,
  endpoints: (builder) => ({
    getCard: builder.query({
      query: (userId) => ({
        url: `/card/get-usd-card?id=${userId}`,
        method: 'GET',
      }),
    }),
    createCard: builder.mutation({
      query: (details) => ({
        url: '/card/create-usd-card',
        method: 'POST',
        body: { ...details },
      }),
    }),
    freezeCard: builder.mutation({
      query: (userId) => ({
        url: `/card/freeze-usd-card?id=${userId}`,
        method: 'PATCH',
      }),
    }),
    unFreezeCard: builder.mutation({
      query: (userId) => ({
        url: `/card/unfreeze-usd-card?id=${userId}`,
        method: 'PATCH',
      }),
    }),
    getCardTransaction: builder.query({
      query: (userId) => ({
        url: `/card/get-card-transactions?id=${userId}`,
        method: 'GET',
      }),
    }),
    fundCardWallet: builder.mutation({
      query: (details) => ({
        url: `/card/fund-card-wallet`,
        method: 'PATCH',
        body: { ...details },
      }),
    }),
    fundCardUsdc: builder.mutation({
      query: (details) => ({
        url: `/card/fund-card-usdc`,
        method: 'PATCH',
        body: { ...details },
      }),
    }),
    fundCardCrypto: builder.mutation({
      query: (details) => ({
        url: `/card/fund-card-crypto`,
        method: 'PATCH',
        body: { ...details },
      }),
    }),
  }),
});

export const {
  useCreateCardMutation,
  useGetCardQuery,
  useFreezeCardMutation,
  useUnFreezeCardMutation,
  useGetCardTransactionQuery,
  useFundCardWalletMutation,
  useFundCardUsdcMutation,
  useFundCardCryptoMutation,
} = accountCardApi;
