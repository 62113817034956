import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const gbpWithdrawApi = createApi({
  reducerPath: "gbpWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    localBank: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/gbp/local-bank",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    iban: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw//gbp/iban",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLocalBankMutation, useIbanMutation } = gbpWithdrawApi;
