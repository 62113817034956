import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TabContent from "../compare/V2/TabContent";
import TabNavItem from "../compare/V2/TabNavItem";
import toast from "react-hot-toast";
import { selectCurrentUserId } from "../../features/auth/authSlice";

import {
  useRecipientBankMutation,
  useRecipientAddressMutation,
  useGetRecipientQuery,
} from "../../services/recipientApi";
import { useSelector } from "react-redux";

const AddRecipientTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div className="Tabs">
      <ul className="nav tab-nav tabs-res">
        <TabNavItem
          title="Recipient bank details"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Recipient address"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>

      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <BankDetails />
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <RecipientAddress />
        </TabContent>
      </div>
    </div>
  );
};

export default AddRecipientTabs;

const BankDetails = () => {
  const [name, setName] = useState("");
  const [bankName, setBankName] = useState("");
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");

  const navigate = useNavigate();
  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);
  const { data } = useGetRecipientQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading("Adding...");

    try {
      const response = await recipientBank({
        type: "Bank account details",
        fullName: name,
        bankName,
        iban,
        swiftCode: swift,
        userId,
      });
      toast.success("Successful", { id: submitToast });
      navigate("/recipient");
    } catch (error) {
      toast.error(error.data.message, { id: submitToast });
      navigate("/recipient");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <br />
      <br />
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-6">Full name of account holder</div>
          <input
            className="input-req w-100"
            placeholder="Enter recipient name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-6">Bank name</div>
          <select
            className="input-req w-100 px-3"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          >
            <option>Select bank</option>
            <option value="AKBANK T.A.S.">AKBANK T.A.S.</option>
            <option value="TURKIYE GARANTI BANKASI A.S.">
              TURKIYE GARANTI BANKASI A.S
            </option>
            <option value="TURKIYE IS BANKASI A.S.">
              TURKIYE IS BANKASI A.S.
            </option>
            <option value="TURKIYE HALK BANKASI A.S.">
              TURKIYE HALK BANKASI A.S.
            </option>
          </select>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-6">IBAN</div>
          <input
            className="input-req w-100"
            placeholder="Enter IBAN"
            value={iban}
            required
            onChange={(e) => setIban(e.target.value)}
          />
        </Grid>
        <Grid item className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-6">SWIFT Code</div>
          <input
            className="input-req w-100"
            value={swift}
            required
            onChange={(e) => setSwift(e.target.value)}
          />
        </Grid>
      </Grid>
      <Container className="text-center">
        <button className="wn-btn1" type="submit">
          Confirm
        </button>
      </Container>
    </form>
  );
};

const RecipientAddress = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const userId = useSelector(selectCurrentUserId);
  const [recipientAddress] = useRecipientAddressMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading("Adding...");

    try {
      const response = await recipientAddress({
        type: "Address details",
        fullName: name,
        address,
        region,
        phoneNumber: phone,
        email,
        userId,
      });
      toast.success("Successful", { id: submitToast });
      navigate("/recipient");
    } catch (error) {
      toast.error(error.data.message, { id: submitToast });
      navigate("/recipient");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <br />
      <br />
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid xs={12} lg={6} item className="my-1">
          <div className="input-h1 fs-6">Recipient name</div>
          <input
            className="input-req w-100"
            placeholder="Enter recipient name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid xs={12} lg={6} item className="my-1">
          <div className="input-h1 fs-6">Region</div>
          <select
            className="input-req w-100 px-3"
            value={region}
            required
            onChange={(e) => setRegion(e.target.value)}
          >
            <option>Select region</option>
            <option value="Famagusta">Famagusta</option>
            <option value="Girne">Girne</option>
            <option value="Guzelyurt">Guzelyurt</option>
            <option value="Iskele">Iskele</option>
            <option value="Lefke">Lefke</option>
            <option value="Nicosia">Nicosia</option>
          </select>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid xs={12} lg={6} item className="my-1">
          <div className="input-h1 fs-6">Recipient address</div>
          <input
            className="input-req w-100"
            placeholder="Enter recipient address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid xs={12} lg={6} item className="my-1">
          <div className="input-h1 fs-6">Recipient phone number</div>
          <input
            className="input-req w-100"
            placeholder="Enter recipient phone number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid spacing={{ xs: 0, lg: 5 }} container>
        <Grid xs={12} lg={6} item className="my-1">
          <div className="input-h1 fs-6">Recipient email address</div>
          <input
            className="input-req w-100"
            placeholder="Enter recipient email address"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          &nbsp;
        </Grid>
      </Grid>
      <Container className="text-center">
        <button className="wn-btn1" type="submit">
          Confirm
        </button>
      </Container>
    </form>
  );
};
