import React from "react";
import logo from "../../assets/logo.png";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const EmailVerification = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="wr-box1">
        <Typography className="text-center font-500 wr-h1">
          Request received
        </Typography>

        <Typography variant="h6" className="text-center my-4 font-600">
          We’ll send a secure link to the email address you signed up with to
          make your payment.
        </Typography>
        <div className="text-center">
          <Link to="/dashboard">
            <button className="wn-btn1">Done</button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default EmailVerification;
