import React, { useState } from "react";
import TabContent from "../../compare/V2/TabContent";
import TabNavItem from "../../compare/V2/TabNavItem";
import GbpAcc from "./GbpAcc";
import GbpIban from "./GbpIban";

const GbpTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <>
      <div className="Tabs my-3">
        <ul className="nav tab-nav">
          <TabNavItem
            title="Local bank account"
            id="tab1"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabNavItem
            title="IBAN"
            id="tab2"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>

        <div className="outlet">
          <TabContent id="tab1" activeTab={activeTab}>
            <GbpAcc />
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <GbpIban />
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default GbpTabs;
