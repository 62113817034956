import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import close from "../../assets/close.png";
import ngn from "../../assets/nigeria.png";
import account from "../../assets/account.png";
import bank from "../../assets/bank.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Container } from "@mui/system";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  selectForeignValue,
  selectAfricanValue,
  setForeign,
  setAfricanValue,
} from "../../features/converterSlice";
import "./converter.css";
import countries from "./countries";
import foreignCurrencies from "./foreignCurrencies";
import africanCurrencies from "./africanCurrencies";
import { setAmount } from "../../features/transferSlice";
import {
  useGetCurrenciesQuery,
  useGetSheet2RatesQuery,
} from "../../services/excelApi";

const Converter = ({ handleClose }) => {
  const [youSend, setYouSend] = React.useState(30000);
  const [youRecieve, setYouRecieve] = React.useState(0);
  const [fees, setFees] = React.useState(0);
  const [saved, setSaved] = React.useState(0);
  const [bmRate, setBmRate] = React.useState(0);
  const [mpRate, setMpRate] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [symbol, setSymbol] = React.useState("$");
  const [isSwaped, setIsSwaped] = React.useState(true);
  const [currencyRates, setCurrencyRates] = React.useState({});
  const [currencyBMRates, setCurrencyBMRates] = React.useState({});
  const [currencyMPRates, setCurrencyMPRates] = React.useState({});
  const [maxAmount, setMaxAmount] = React.useState(0);

  const [selectedValue, setSelectedValue] = React.useState("b");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const currency = useSelector(selectForeignValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isFetching } = useGetCurrenciesQuery("currencies", {
    refetchOnMountOrArgChange: true,
  });

  const { data: sheet2Data, isFetching: isFetchingSheet2 } =
    useGetSheet2RatesQuery("currencies", {
      refetchOnMountOrArgChange: true,
    });

  const inputCheck = () => {
    if (!isSwaped) {
      if (youSend > 110000) {
        return setYouSend(30000);
      }
    } else {
      if (youSend > maxAmount) {
        return setYouSend(maxAmount);
      }
    }
  };

  // useEffect
  useEffect(() => {
    inputCheck();
  }, [youSend, maxAmount]);

  useEffect(() => {
    if (isSwaped === false) return setYouSend(30000);
    return setYouSend(1000);
  }, [isSwaped]);

  useEffect(() => {
    if (!isFetching && !isFetchingSheet2) {
      setCurrencyRates({ ...data?.currencyRates });
      setCurrencyBMRates({ ...sheet2Data });
      setCurrencyMPRates({ ...data?.midPointRates });
    }
  }, [data, isFetching, isFetchingSheet2, sheet2Data]);

  useEffect(() => {
    if (isSwaped) {
      reverseExchangeDetails(currency);
    } else {
      exchangeDetails(currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    youSend,
    youRecieve,
    fees,
    rate,
    symbol,
    currency,
    isSwaped,
    selectedValue,
    maxAmount,
  ]);

  //   Functions
  const numSeparator = (num) => {
    let separator = num.toString();
    // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return separator;
  };

  const beginTransfer = () => {
    if (
      isSwaped &&
      (currency === "CNH" ||
        currency === "AED" ||
        currency === "KES" ||
        currency === "EUR" ||
        currency === "USD" ||
        currency === "GBP" ||
        currency === "CHF")
    ) {
      return navigate("#");
    }
    if (
      !isSwaped &&
      (currency === "CNH" ||
        currency === "AED" ||
        currency === "KES" ||
        currency === "GBP" ||
        currency === "EUR" ||
        currency === "USD" ||
        currency === "CHF")
    ) {
      return navigate("#");
    }
    if (!isSwaped) {
      dispatch(
        setAmount({
          currency,
          youRecieve: youRecieve.toFixed(2),
          youSend,
          routes: `NGN${currency}`,
        })
      );
      return navigate(`/transfer-${currency}`);
    }
    if (isSwaped) {
      dispatch(
        setAmount({
          youRecieve: youRecieve.toFixed(2),
          youSend,
          routes: `${currency}NGN`,
          currency,
        })
      );
      return navigate(
        `/transfer-reverse-${currency}${symbol === "€" ? "-myk" : ""}`
      );
    }
  };

  // Amount save of Transaction
  const calcSaved = (amountSent, amountReceive, bmRate) => {
    const saveAmt = amountReceive - amountSent / bmRate;
    return setSaved(saveAmt);
  };

  // Fee on Sending money out of Nigeria
  const calcFee = (amount, rate) => {
    if (selectedValue === "b") return (amount * 0.055) / rate + 2;
    return (amount * 0.05) / rate;
  };

  // Fee on Sending money out back into Nigeria
  const reverseCalcFee = (amount) => {
    if (currency === "EUR") {
      if (selectedValue === "a") return amount * 0.0;
      else if (selectedValue === "b") return amount * 0.005 + 2;
    }
    return amount * 0.0;
  };

  // Amount receive for forward transaction
  const calcReceiving = (amount, fee, rate, mpRate) => {
    let calc;
    if (selectedValue === "b" && currency === "EUR") {
      calc = (amount - amount * 0.055) / mpRate - 2;
      return setYouRecieve(calc);
    }
    calc = (amount - amount * 0.05) / mpRate;
    return setYouRecieve(calc);
  };

  // Amount receive for reverse transaction
  const reverseCalcReceiving = (amount, fee, rate, mpRate, bmRate) => {
    if (selectedValue === "b" && currency === "EUR") {
      return setYouRecieve((amount - amount * 0.005 - 2) * bmRate);
    }
    return setYouRecieve(amount * bmRate);
  };

  const exFunction = () => {
    const newFee = calcFee(youSend, rate);
    setFees(newFee);
    calcSaved(youSend, youRecieve, bmRate);
    calcReceiving(youSend, fees, rate, mpRate);
  };

  const reverseExFunction = () => {
    const reverseFee = reverseCalcFee(youSend);
    setFees(reverseFee);
    reverseCalcReceiving(youSend, reverseFee, rate, mpRate, bmRate);
  };

  // function swapConversion() {
  //   setIsSwaped(!isSwaped);
  // }

  const exchangeDetails = (data) => {
    switch (data) {
      case "AED":
        setRate(currencyRates.AED);
        setBmRate(currencyBMRates.AED);
        setMpRate(currencyMPRates.AED);
        setSymbol("د.إ");
        exFunction();
        break;
      case "CHF":
        setRate(currencyRates.CHF);
        setBmRate(currencyBMRates.CHF);
        setMpRate(currencyMPRates.CHF);
        setSymbol("CHF");
        exFunction();
        break;
      case "CAD":
        setRate(currencyRates.CAD);
        setBmRate(currencyBMRates.CAD);
        setMpRate(currencyMPRates.CAD);
        setSymbol("C$");
        exFunction();
        break;
      case "CNH":
        setRate(currencyRates.CNY);
        setBmRate(currencyBMRates.CNY);
        setMpRate(currencyMPRates.CNY);
        setSymbol("¥");
        exFunction();
        break;
      case "EUR":
        setRate(currencyRates.EUR);
        setBmRate(currencyBMRates.EUR);
        setMpRate(currencyMPRates.EUR);
        setSymbol("€");
        exFunction();
        break;
      case "GBP":
        setRate(currencyRates.GBP);
        setBmRate(currencyBMRates.GBP);
        setMpRate(currencyMPRates.GBP);
        setSymbol("£");
        exFunction();
        break;
      case "KES":
        setRate(currencyRates.KES);
        setBmRate(currencyBMRates.KES);
        setMpRate(currencyMPRates.KES);
        setSymbol("KES");
        exFunction();
        break;
      case "TRY":
        setRate(currencyRates.TRY);
        setBmRate(currencyBMRates.TRY);
        setMpRate(currencyMPRates.TRY);
        setSymbol("₺");
        exFunction();
        break;
      case "USD":
        setRate(currencyRates.USD);
        setBmRate(currencyBMRates.USD);
        setMpRate(currencyMPRates.USD);
        setSymbol("$");
        exFunction();
        break;
      default:
        return 0;
    }
  };

  const reverseExchangeDetails = (data) => {
    switch (data) {
      case "AED":
        setRate(currencyRates.AED);
        setBmRate(currencyBMRates.AED);
        setMpRate(currencyMPRates.AED);
        setSymbol("د.إ");
        reverseExFunction();
        setMaxAmount(11018.06);
        break;
      case "CHF":
        setRate(currencyRates.CHF);
        setBmRate(currencyBMRates.CHF);
        setMpRate(currencyMPRates.CHF);
        setSymbol("CHF");
        reverseExFunction();
        setMaxAmount(2768.51);
        break;
      case "CAD":
        setRate(currencyRates.CAD);
        setBmRate(currencyBMRates.CAD);
        setMpRate(currencyMPRates.CAD);
        setSymbol("C$");
        reverseExFunction();
        setMaxAmount(4130.85);
        break;
      case "CNH":
        setRate(currencyRates.CNY);
        setBmRate(currencyBMRates.CNY);
        setMpRate(currencyMPRates.CNY);
        setSymbol("¥");
        reverseExFunction();
        setMaxAmount(20661.3);
        break;
      case "EUR":
        setRate(currencyRates.EUR);
        setBmRate(currencyBMRates.EUR);
        setMpRate(currencyMPRates.EUR);
        setSymbol("€");
        reverseExFunction();
        setMaxAmount(2790.15);
        break;
      case "GBP":
        setRate(currencyRates.GBP);
        setBmRate(currencyBMRates.GBP);
        setMpRate(currencyMPRates.GBP);
        setSymbol("£");
        reverseExFunction();
        setMaxAmount(2462.65);
        break;
      case "KES":
        setRate(currencyRates.KES);
        setBmRate(currencyBMRates.KES);
        setMpRate(currencyMPRates.KES);
        setSymbol("KES");
        reverseExFunction();
        setMaxAmount(385583.7);
        break;
      case "TRY":
        setRate(currencyRates.TRY);
        setBmRate(currencyBMRates.TRY);
        setMpRate(currencyMPRates.TRY);
        setSymbol("₺");
        reverseExFunction();
        setMaxAmount(57040.8);
        break;
      case "USD":
        setRate(currencyRates.USD);
        setBmRate(currencyBMRates.USD);
        setMpRate(currencyMPRates.USD);
        setSymbol("$");
        reverseExFunction();
        setMaxAmount(3000);
        break;
      default:
        return 0;
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <img
          src={close}
          alt="close"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <br />
      {/* <p className="text-3">
        Note: For the alpha test there is a ₦30,000 min and ₦110,000 max limit,
        any transaction out of this range will be returned to the sender.
      </p> */}
      <br />
      <div className="input-transfer-container">
        <div className="label-transfer">You send</div>
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={{
              width: "50%",
            }}
          >
            {/* <NumberFormat
              value={youSend}
              thousandSeparator={true}
              className="input-transfer"
              inputMode="numeric"
              onValueChange={(values, sourceInfo) => {
                setYouSend(values.value);
              }}
            /> */}
            <input
              type="number"
              className="input-transfer"
              value={youSend}
              onChange={(e) => setYouSend(e.target.value)}
            />
          </div>
          <div>
            <div>{!isSwaped ? <SelectCurr /> : <SelectReceivingCurr />}</div>
          </div>
          {/* Swap button */}
          <div
            //  onClick={() => swapConversion()}
            className="swapButton"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 17"
              aria-hidden="true"
              className="swapIcon text-blue-800 miscellany___StyledIconSwap-sc-1r08bla-1 fZJuOo "
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11.726 1.273l2.387 2.394H.667V5h13.446l-2.386 2.393.94.94 4-4-4-4-.94.94zM.666 12.333l4 4 .94-.94L3.22 13h13.447v-1.333H3.22l2.386-2.394-.94-.94-4 4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <br />
      <div className="input-transfer-container swap">
        <div className="label-transfer">Receive</div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="input-transfer">
            {numSeparator(youRecieve.toFixed(2))}
          </div>
          <div>
            <div>{!isSwaped ? <SelectReceivingCurr /> : <SelectCurr />}</div>
          </div>
        </div>
        {/* {isSwaped &&
          (currency === "USD" ||
            currency === "CHF" ||
            currency === "KES" ||
            currency === "CNH" ||
            currency === "AED" ||
            currency === "CAD" ||
            currency === "TRY") && (
            <p className="text-4">
              Transfer to the route is currently not available
            </p>
          )} */}
      </div>
      <br />
      {currency === "EUR" && (
        <>
          <div className="bg-white rounded px-3 py-2">
            <p className="text-2">Delivery time options</p>
            <div className="delivery-method">
              <p className="text-1">Time</p>
              <div>
                <p className="text-1">1-2 days</p>
                <Radio
                  size="small"
                  // checked={selectedValue === "a"}
                  disabled
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <div>
                <p className="text-1"> &lt; 24hrs</p>
                <Radio
                  size="small"
                  onChange={handleChange}
                  checked={selectedValue === "b"}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>
            </div>
            <div className="delivery-method">
              <p className="text-1">Price</p>
              <div>
                <p className="text-1 text-ed">{isSwaped ? "0%" : "1%"}</p>
              </div>
              <div>
                <p className="text-1 text-ed">
                  {isSwaped ? "0.5% + 2€" : "5.5%"}
                </p>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      <div className="bg-white rounded px-3 py-2">
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <div className="left-transfer-box">
              <p>
                <span className="text-xs">₦</span>
                {isSwaped ? bmRate : mpRate}{" "}
              </p>
            </div>
            <div className="left-transfer-box">
              <p>
                <span className="text-xs">{symbol} </span>
                <span>{numSeparator(fees.toFixed(2))}</span>{" "}
                {!isSwaped && <span className="text-1">+ M.P rate Diff.</span>}
              </p>
            </div>
            <div className="left-transfer-box">
              {isSwaped ? "In less than 30mins" : "In less than 24 hours"}
            </div>
            {!isSwaped && (
              <div className="left-transfer-box">
                <p>
                  <span className="text-xs">{symbol} </span>
                  <span>{numSeparator(saved.toFixed(2))}</span>
                </p>
              </div>
            )}
          </div>
          <div>
            <div className="right-transfer-box">Our current rate</div>
            <div className="right-transfer-box">Fees</div>
            <div className="right-transfer-box">Estimated arrival</div>
            {!isSwaped && <div className="right-transfer-box">You saved</div>}
          </div>
        </div>
        {currency === "EUR" && (
          <p className="text-3">
            Note: The fees are based on delivery time option chosen.
          </p>
        )}
      </div>
      {/* <br />
      <div className="bg-white rounded px-3 py-2">
        <div className="label-transfer">Destination</div>
        <ChooseDestination isSwaped={isSwaped} />
      </div> */}
      <br />
      <div className="bg-white rounded px-3 py-2">
        <div className="label-transfer">Delivery Method</div>
        <SelectDeliveryMethod isSwaped={isSwaped} />
      </div>
      <div className="text-center my-3 mt-4">
        <button
          className="btn btn-success"
          style={{
            background: "#30AE4D",
            minWidth: 200,
            padding: "12px",
            fontSize: 24,
            borderColor: "#30AE4D",
            borderRadius: 8,
          }}
          onClick={beginTransfer}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Converter;

function SelectDeliveryMethod({ isSwaped }) {
  const currency = useSelector(selectForeignValue);
  const [delivery, setDelivery] = React.useState(10);

  const handleChange = (event) => {
    setDelivery(event.target.value);
  };

  return (
    <div>
      <Select
        value={delivery}
        onChange={handleChange}
        className="font-500 mt-3"
        label={false}
        style={{ width: "100%" }}
      >
        {!isSwaped ? (
          <MenuItem
            className="d-flex align-items-center px-3 font-500"
            value={10}
          >
            <img
              style={{ height: 52, width: 52, marginRight: 10 }}
              src={bank}
              alt="flag"
            />
            Bank Transfer {currency === "KES" && "/ Mobile Money"}
          </MenuItem>
        ) : (
          <MenuItem
            className="d-flex align-items-center px-3 font-500"
            value={10}
          >
            <img
              style={{ height: 52, width: 52, marginRight: 10 }}
              src={account}
              alt="flag"
            />
            <span>P2P Transfer</span>
          </MenuItem>
        )}
      </Select>
    </div>
  );
}

function SelectCurr() {
  const currency = useSelector(selectAfricanValue);
  //   const [filteredCurrencies, setFilteredCurrencies] = React.useState([]);

  const dispatch = useDispatch();

  //   const filterCurrencies = () => {
  //     const filtering = africanCurrencies.filter((data) => {
  //       return data.name === "NGN";
  //     });
  //     setFilteredCurrencies(filtering);
  //   };

  //   useEffect(() => {
  //     filterCurrencies();
  //   }, []);

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setAfricanValue({ value }));
  };

  return (
    <div>
      <Select
        value={currency}
        onChange={handleChange}
        className="font-500"
        label={false}
        style={{ minWidth: 150 }}
      >
        {africanCurrencies.map(({ id, name, image }, index) => (
          <MenuItem
            className="d-flex align-items-center px-3 font-500"
            value={name}
            key={index}
          >
            <img
              style={{ height: 42, width: 42, marginRight: 20 }}
              src={image}
              alt="flag"
            />
            <span>{name}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function SelectReceivingCurr() {
  const currency = useSelector(selectForeignValue);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setForeign({ value }));
  };

  return (
    <div>
      <Select
        value={currency}
        onChange={handleChange}
        className="font-500"
        label={false}
        style={{ minWidth: 150 }}
      >
        {foreignCurrencies.map((curr, index) => (
          <MenuItem
            className="d-flex align-items-center px-3 font-500"
            value={curr.name}
            key={index}
          >
            <img
              style={{ height: 42, width: 42, marginRight: 20 }}
              src={curr.image}
              alt="flag"
            />
            {curr.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function ChooseDestination({ isSwaped }) {
  const currency = useSelector(selectForeignValue);
  const [destination, setDestination] = React.useState(currency);
  const [filteredCurrencies, setFilteredCurrencies] = React.useState([]);
  const [isNGN, setIsNGN] = React.useState(true);

  useEffect(() => {
    filterDestinations();
  }, [currency, destination, isSwaped]);

  const filterDestinations = () => {
    let value = countries.filter((data) => {
      if (!isSwaped) {
        setIsNGN(false);
        return data.code === currency;
      }
      setIsNGN(true);
      return data.code === "NGN";
    });
    setFilteredCurrencies(value);
  };

  const handleChange = (event) => {
    setDestination(event.target.value);
  };
  return (
    <>
      <div>
        {isNGN ? (
          <Select
            value={"NGN"}
            // onChange={handleChange}
            className="font-500"
            label={false}
            style={{ width: "100%" }}
          >
            <MenuItem
              className="d-flex align-items-center px-3 font-500"
              value={"NGN"}
            >
              <img
                style={{ height: 42, width: 42, marginRight: 20 }}
                src={ngn}
                alt="flag"
              />
              <span>Nigeria</span>
            </MenuItem>
          </Select>
        ) : (
          <Select
            value={currency}
            onChange={handleChange}
            className="font-500"
            label={false}
            style={{ width: "100%" }}
          >
            {filteredCurrencies.map(({ id, name, image, code }, index) => (
              <MenuItem
                className="d-flex align-items-center px-3 font-500"
                value={code}
                key={index}
              >
                <img
                  style={{ height: 42, width: 42, marginRight: 20 }}
                  src={image}
                  alt="flag"
                />
                <span>{name}</span>
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
    </>
  );
}
