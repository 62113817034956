import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import tick from "../../assets/progress.png";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import {
  selectCurrentToken,
  selectCurrentUserId,
  selectCurrentAccLock,
  signOut,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const VerificationInProgress = () => {
  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  const accLock = useSelector(selectCurrentAccLock);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(signOut());
    navigate("/signin");
  };

  useEffect(() => {
    if (!token && !userId) return navigate("/signin");
    if (token && userId && accLock === "verified")
      return navigate("/dashboard");
    if (accLock === "verifying") return navigate("/dashboard-unverified");
  });

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>

      <Box>
        <Typography variant="h3" className="text-center font-500 vip-h1">
          Your account is being verified
        </Typography>
        <Box className="d-flex justify-content-center py-2">
          <Box className="d-flex justify-content-center align-items-center">
            <img src={tick} alt="cloud" className="vip-img spin" />
          </Box>
        </Box>

        <Typography variant="h6" className="text-center monster-font vip-h2">
          You'll be notified once your account has been verified.
          <br />
          Please Login again.
        </Typography>
      </Box>
      <Container className="text-center">
        <button onClick={handleLogOut} className="wn-btn1">
          Complete
        </button>
        <div style={{ height: "15vh" }}></div>
      </Container>
    </div>
  );
};

export default VerificationInProgress;
