/* eslint-disable no-unused-vars */
import { Box, Button, Container, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import info from "../../../assets/info.png";
import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setName, setBank, setNuban } from "../../../features/transferSlice";

const UsdP2pRequest = () => {
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(setBank({ recipientBank: bankName }));
    dispatch(setNuban({ recipientNuban: number }));
    navigate("/transfer-reverse-usd");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100">
        <Container maxWidth={"xl"} className="w-100 logo-container">
          <img src={logo} alt="logo" className="logo" />
        </Container>
        <Container style={{ maxWidth: 650 }} className="mt-2">
          <Box className="d-flex justify-content-between align-items-center">
            <div></div>
            <Link style={{ textDecoration: "none" }} to="/">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <br />
        <Container style={{ maxWidth: 650 }}>
          <h4
            className="monster-font display-4"
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            P2P Bank Transfer
          </h4>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">Recipient name</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder="Enter recipient full name"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                required
                type="text"
              />
            </Col>
          </Row>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">Bank</div>
              <select
                className="input-req w-100 px-3"
                value={bankName}
                required
                onChange={(e) => setBankName(e.target.value)}
              >
                <option>Select bank</option>
                <option value="GT Bank">GT Bank</option>
                <option value="Access Bank">Access Bank</option>
                <option value="FCMB Bank">FCMB Bank</option>
                <option value="Zenith Bank">Zenith Bank</option>
                <option value="United Bank For Africa">
                  United Bank For Africa
                </option>
                <option value="Kuda Bank">Kuda Bank</option>
                <option value="Heritage Bank">Heritage Bank</option>
                <option value="First Bank">First Bank</option>
                <option value="Jaiz Bank">Jaiz Bank</option>
                <option value="Providus Bank">Providus Bank</option>
                <option value="Globus Bank">Globus Bank</option>
              </select>
            </Col>
          </Row>
          <br />
          <Row className="justify-content-between align-items-center mt-1">
            <Col>
              <div className="input-h1">NUBAN number</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder={"Enter NUBAN number"}
                type="number"
                required
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
            </Col>
          </Row>
          <div
            style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14 }}
            className="d-flex  align-items-center p-2 my-3 rounded "
          >
            <img src={info} className="me-2" alt="" />
            This is a P2P transfer, you will be receiving money directly from an
            individual account do not send any bank deposits to this account or
            your funds will be lost.
          </div>
        </Container>

        <Container className="text-center">
          <button className="wn-btn1" type="submit">
            Next
          </button>
        </Container>

        <div style={{ height: "15vh" }}></div>
      </div>
    </form>
  );
};

export default UsdP2pRequest;
