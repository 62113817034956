import { Box } from "@mui/material";
import React, { useEffect } from "react";
import incoming from "../../assets/incoming.png";
import outgoing from "../../assets/outgoing.png";
import "./Activity.css";
import { useGetOrderQuery } from "../../services/transferApi";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../features/auth/authSlice";

const Activity = () => {
  const [activity, setActivity] = React.useState([]);
  const userId = useSelector(selectCurrentUserId);

  const { data, isloading } = useGetOrderQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setActivity(data);
  }, [data, isloading]);

  return (
    <>
      <br />
      <Box style={{ maxWidth: 1200 }}>
        <div className="bg-white font-700 rounded" style={{ marginTop: 20 }}>
          {activity ? (
            activity
              .filter((item) => item.status === "delivered")
              .map((item, index) => (
                <div
                  className="a-box1"
                  style={{ borderBottom: "6px solid #f3f4f6" }}
                  key={index}
                >
                  <div className="a-box2 a-size">
                    <img
                      src={
                        item.transferType === "forward" ? outgoing : incoming
                      }
                      alt="activity"
                      className="a-img1"
                    />
                    <div>
                      <div
                        className="a-h1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.fullName || item.recipientName}
                      </div>
                      {/* <div className="md-none a-h2">{item.status}</div> */}
                    </div>
                  </div>
                  <div className="sm-none o-h3">
                    {item.transferType === "forward" ? (
                      <span>Sent</span>
                    ) : (
                      <span>Received</span>
                    )}
                  </div>
                  <div className="font-700 o-h2">
                    <span>
                      {item.transferType === "forward"
                        ? item.amountReceived
                        : item.amountSend}{" "}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {item.currency}
                    </span>
                  </div>
                  <div
                    className="font-700 o-h3"
                    style={{ color: item.color, textTransform: "capitalize" }}
                  >
                    {item.status === "delivered" && (
                      <span style={{ color: "#1565D8" }}>Completed</span>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div
              style={{ maxWidth: 1200 }}
              className="my-3 bg-white rounded p-4 text-center font-700"
            >
              You don't have any activity in your account
            </div>
          )}
        </div>
      </Box>
      <div style={{ height: "10vh" }}></div>
    </>
  );
};

export default Activity;
