import React from "react";
import logout from "../assets/logout.png";
import qna from "../assets/qna.svg";
import profile from "../assets/profile.png";
import acc from "../assets/acc.png";
import { Link } from "react-router-dom";
import vector from "../assets/Vector.png";
import { signOut } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectCurrentSurName,
  selectCurrentFirstName,
} from "../features/auth/authSlice";

const AccountButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const surName = useSelector(selectCurrentSurName);
  const firstName = useSelector(selectCurrentFirstName);

  const handleLogOut = () => {
    dispatch(signOut());
    navigate("/signin");
  };

  const handleSupport = () => {};

  return (
    <div className="d-flex justify-content-end mt-4">
      <div className="dropdown">
        <button
          className="dropdown-toggle d-flex align-items-center"
          style={{
            border: "none",
            borderRadius: 8,
            padding: "6px 12px",
            color: "#000",
            background: "white",
            // marginRight: "20px",
          }}
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={acc} alt="nigeria" style={{ height: 42, width: 42 }} />
          <span
            className="mx-3 font-500"
            style={{ textTransform: "capitalize" }}
          >
            {firstName}
          </span>
          <img src={vector} alt="" />
        </button>
        <ul
          className="dropdown-menu font-300 mt-3"
          aria-labelledby="dropdownMenuLink"
          style={{ color: "#696F79" }}
        >
          <li
            onClick={() => navigate("/profile")}
            style={{ cursor: "pointer" }}
          >
            <p className="dropdown-item">
              <img
                className="me-2"
                src={profile}
                alt="nigeria"
                style={{ height: 20, width: 20 }}
              />
              Profile
            </p>
          </li>
          <li onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <p className="dropdown-item">
              <img
                className="me-2"
                src={qna}
                alt="nigeria"
                style={{ height: 20, width: 20 }}
              />
              Help
            </p>
          </li>
          <li onClick={handleLogOut} style={{ cursor: "pointer" }}>
            <p className="dropdown-item">
              <img
                className="me-2"
                src={logout}
                alt="nigeria"
                style={{ height: 20, width: 20 }}
              />
              Logout
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountButton;
