import React from "react";

const CardIcon = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33203 9.92188H25.6654"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 19.2559H9.33333"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 19.2559H16.9167"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51203 4.08984H20.4737C24.627 4.08984 25.6654 5.11651 25.6654 9.21151V18.7898C25.6654 22.8848 24.627 23.9115 20.4854 23.9115H7.51203C3.37036 23.9232 2.33203 22.8965 2.33203 18.8015V9.21151C2.33203 5.11651 3.37036 4.08984 7.51203 4.08984Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
