import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  youSend: null,
  youRecieve: null,
  ref: null,
  recipientName: null,
  routes: null,
  currency: null,
  recipientNuban: null,
  recipientBank: null,
  receipt: null,
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setAmount: (state, action) => {
      const { youSend, youRecieve, routes, currency } = action.payload;
      state.youSend = youSend;
      state.youRecieve = youRecieve;
      state.routes = routes;
      state.ref = Math.random().toString(36).slice(2, 21);
      state.currency = currency;
    },
    setName: (state, action) => {
      const { recipientName } = action.payload;
      state.recipientName = recipientName;
    },
    setBank: (state, action) => {
      const { recipientBank } = action.payload;
      state.recipientBank = recipientBank;
    },
    setNuban: (state, action) => {
      const { recipientNuban } = action.payload;
      state.recipientNuban = recipientNuban;
    },
    setReceipt: (state, action) => {
      const { receipt } = action.payload;
      state.receipt = receipt;
    },
    clear: (state) => {
      state.youSend = null;
      state.youRecieve = null;
      state.ref = null;
      state.recipientName = null;
      state.routes = null;
      state.currency = null;
    },
  },
});

export const { setAmount, setName, setBank, setNuban, setReceipt, clear } =
  transferSlice.actions;
export default transferSlice.reducer;

export const selectCurrentYouSend = (state) => state.transfer.youSend;
export const selectCurrentYouRecieve = (state) => state.transfer.youRecieve;
export const selectCurrentRef = (state) => state.transfer.ref;
export const selectCurrentRoutes = (state) => state.transfer.routes;
export const selectCurrentCurrency = (state) => state.transfer.currency;
export const selectCurrentReceipt = (state) => state.transfer.receipt;
export const selectCurrentRecipientName = (state) =>
  state.transfer.recipientName;
export const selectCurrentRecipientBank = (state) =>
  state.transfer.recipientBank;
export const selectCurrentRecipientNuban = (state) =>
  state.transfer.recipientNuban;
