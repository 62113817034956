import { Box, Container, Grid, Stack } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import info from "../../../assets/info.png";
import { useSwiftMutation } from "../../../services/usdWithdrawApi";

const UsdSwift = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban_AccountNumber, setIban_AccountNumber] = React.useState("");
  const [swift_BicCode, setSwift_BicCode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [country, setCountry] = React.useState("");

  const navigate = useNavigate();
  const [swift] = useSwiftMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await swift({
        fullName,
        bankName,
        iban_AccountNumber,
        swift_BicCode,
        address,
        city,
        postalCode,
        country,
        transactionType: "swift",
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center font-600">
        <div
          style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14 }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          To send USD outside the US, we’ll need to use SWIFT. This will make
          your transfer cost a little more and may take 2-5 days longer. Other
          banks involved may also charge fees.
        </div>
      </div>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Full name of account holder</div>
          <input
            className="input-req w-100 "
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Bank name</div>
          <input
            className="input-req w-100 "
            placeholder="Enter bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">IBAN / Account number</div>
          <input
            className="input-req w-100 "
            placeholder="Enter number"
            value={iban_AccountNumber}
            required
            onChange={(e) => setIban_AccountNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">SWIFT / BIC code</div>
          <input
            className="input-req w-100 "
            placeholder="Enter code"
            value={swift_BicCode}
            required
            onChange={(e) => setSwift_BicCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Recipient country</div>
          <input
            className="input-req w-100 "
            placeholder="Select country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">City</div>
          <input
            className="input-req w-100 "
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Recipient address</div>
          <input
            className="input-req w-100 "
            placeholder="Enter recipient address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Postal code</div>
          <input
            className="input-req w-100 "
            placeholder="Enter postal code"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Container className="text-center">
        <button className="wn-btn1" type="submit">
          Confirm
        </button>
      </Container>
    </form>
  );
};

export default UsdSwift;
