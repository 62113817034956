import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import USD from '../../assets/usa.png';
import GBP from '../../assets/britain.png';
import TRY from '../../assets/turkey.png';
import NGN from '../../assets/nigeria.png';
import addCircle from '../../assets/add-circle.png';
import { Link } from 'react-router-dom';
import './Accounts.css';
import { useGetAccountsQuery } from '../../services/accRequestApi';
import {
  selectCurrentUserId,
  // selectCurrentAccLock,
  // selectCurrentAccPlus,
  selectCurrentTransAcc,
} from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';

const currencyInfo = [
  {
    id: 1,
    currency: 'USD',
    link: 'usd',
    symbol: '$',
    img: USD,
    name: 'United States Dollar',
  },
  {
    id: 2,
    currency: 'GBP',
    link: 'gbp',
    symbol: '£',
    img: GBP,
    name: 'British Pound Sterling',
  },
  {
    id: 3,
    currency: 'TRY',
    link: 'try',
    symbol: '₺',
    img: TRY,
    name: 'Turkish Lira',
  },
  {
    id: 4,
    currency: 'NGN',
    link: 'ngn',
    symbol: '₦',
    img: NGN,
    name: 'Nigerian Naira',
  },
];

const Accounts = () => {
  const [accounts, setAccounts] = React.useState([]);
  const a = 1;
  const id = useSelector(selectCurrentUserId);
  const { data, isLoading, isFetching } = useGetAccountsQuery(id);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setAccounts(data.accounts);
    }
  }, [data, isLoading, isFetching]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: a > 1 ? 'space-around' : 'flex-start',
        }}
        className="account-box"
      >
        {accounts.map((acc) => (
          <AccountCard currency="NGN" key={acc._id} accountInfo={acc} />
        ))}
        <AccountCreateCard />
      </Box>
    </>
  );
};

export default Accounts;

const AccountCard = ({ currency, accountInfo }) => {
  const accountCurrency = currencyInfo.filter(
    (acc) => acc.currency === currency
  );

  return (
    <>
      <Link
        className="create-card"
        to={`/${accountCurrency[0].link}-account-details`}
        state={{
          currency: accountCurrency[0].currency,
          symbol: accountCurrency[0].symbol,
          img: accountCurrency[0].img,
          currencyName: accountCurrency[0].name,
          balance: accountInfo.balance,
          bankName: accountInfo.bank_name,
          bankAddress: accountInfo.bank_address,
          accountType: accountInfo.type,
          accountNumber: accountInfo.account_number,
          accountName: accountInfo.account_name,
          accountRef: accountInfo.reference,
        }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center font-500">
            <div className="ab-h1">{accountCurrency[0].currency}</div>
            <img
              src={accountCurrency[0].img}
              style={{ height: 50, width: 50 }}
              className="img-flag"
              alt=""
            />
          </div>
          <div className="d-flex justify-content-between align-items-center font-500">
            <div className="card-balance">
              <div className="card-symbol ab-h2 font-800">
                {accountCurrency[0].symbol}
                {accountInfo.balance.toFixed(2)}
              </div>
              <div style={{ color: '#696F79' }} className="card-ava font-500">
                Balance
              </div>
            </div>
            <div className="card-bank">
              <div className="card-symbol ab-h3 font-800">
                {accountInfo.bank_name}
              </div>
              <div style={{ color: '#696F79' }} className="card-ava font-500">
                Bank
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

const AccountCreateCard = () => {
  // const accLock = useSelector(selectCurrentAccLock);
  // const accPlus = useSelector(selectCurrentAccPlus);
  const transAcc = useSelector(selectCurrentTransAcc);
  return (
    <>
      <Box className="create-account">
        {transAcc === true ? (
          <Link
            to="/request-international-account"
            style={{ textDecoration: 'none', color: 'black' }}
            className="p-4 d-flex justify-content-center align-items-center flex-column font-500 h-100 "
          >
            <img src={addCircle} style={{ height: 42, width: 42 }} alt="" />
            Add account
          </Link>
        ) : (
          <Link
            to="/limited-access"
            style={{ textDecoration: 'none', color: 'black' }}
            className="p-4 d-flex justify-content-center align-items-center flex-column font-500 h-100 "
          >
            <img src={addCircle} style={{ height: 42, width: 42 }} alt="" />
            Add account
          </Link>
        )}
      </Box>
    </>
  );
};
