import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../assets/logo.png";
import { Container } from "@mui/system";
import "./Recipient.css";
import { Link } from "react-router-dom";
import AddRecipientTabs from "./AddRecipientTabs";
import MobileNavbarOne from "../../components/MobileNavbarOne";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AddRecipient = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
      <div>
        <MobileNavbarOne />
      </div>
      <div className="pt-5 px-4 logo-vip">
        <img src={logo} alt="logo" />
      </div>
      <Container className="rco-box1">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/recipient">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/recipient">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <span className="rco-h2">Cancel</span>
              <img src={closeBtn} alt="closeBtn" className="rco-img2" />
            </button>
          </Link>
        </Box>
        <div className="my-3 font-600 wn-h1">Add recipient</div>
        <AddRecipientTabs />
      </Container>
    </div>
  );
};

export default AddRecipient;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
