import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Secure from "../../assets/secure.svg";
import {
  selectCurrentToken,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import { useResetPasswordMutation } from "../../services/authApi";
import "./forgotPassword.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = React.useState("");

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  const navigate = useNavigate();
  let location = useLocation();
  const params = new URLSearchParams(location.search);

  const email = params.get("e");

  const handlePasswordCheck = () => {
    if (newPassword === "" || confirmPassword === "") {
      return setError("");
    }
    if (newPassword !== confirmPassword) {
      return setError("❌ Passwords do not match");
    }
    if (newPassword === confirmPassword) {
      return setError("✅ Passwords match");
    }
  };

  useEffect(() => {
    handlePasswordCheck();
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    // if (token && userId) return navigate("/dashboard");
  }, [token, userId]);

  const [resetPassword] = useResetPasswordMutation();

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Resetting...");
    try {
      const userData = await resetPassword({ email, newPassword }).unwrap();
      toast.success(userData.message, { id: toastSubmit });
      console.log(userData);
      // navigate("/signin");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Changing password...");
    try {
      const userData = await resetPassword({ email, newPassword }).unwrap();
      toast.success(userData.message, { id: toastSubmit });
      console.log(userData);
    } catch (error) {
      toast.error(error.data.error, { id: toastSubmit });
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <div className="">
        <form
          onSubmit={handleSubmit}
          className="wrapper"
          // style={{ margin: "0 1rem" }}
        >
          <div style={{ margin: "0 1rem" }}>
            <div className="iconBlock">
              <img src={Secure} alt="info" className="icon" />
            </div>
            <div className="textCenter">
              <h2 className="heading">Reset password</h2>
            </div>
            {/* <div className="primary-bg h-100"> */}
            <div style={{ marginTop: "2rem" }}>
              <div className="input-h1">New password</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder="Enter password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                type="password"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <div className="input-h1">Confirm password</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder="Enter password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                type="password"
              />

              <p>{error}</p>
            </div>

            <div>
              <button
                className="wn-btn1"
                style={{ width: "100%", marginTop: "1rem" }}
              >
                Submit
              </button>
            </div>
            {/* </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
