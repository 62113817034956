import React, { useEffect, useState } from "react";
import acc from "../../assets/acc.png";
import { Col, Row } from "react-bootstrap";
import LeftPanel from "../cards/components/LeftPanel";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import backArrow from "../../assets/arrow-left.png";
import incoming from "../../assets/incoming.png";
import outgoing from "../../assets/outgoing.png";
import Accounts from "../bankaccounts/Accounts";
import AccountButton from "../../components/AccountButton";
import TabCompareRates from "./TabCompareRates";
import MobileNavbarTwo from "../../components/MobileNavbarTwo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompareRates = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box style={{ overflowX: "hidden" }}>
      <div>
        <MobileNavbarTwo
          activeColor1="#696F79"
          activeColor2="#696F79"
          activeColor3="#696F79"
          activeColor4="#696F79"
          activeColor5="#1565DB"
          iconColor1="#696F79"
          iconColor2="#696F79"
          iconColor3="#696F79"
          iconColor4="#696F79"
          iconColor5="#1565DB"
        />
      </div>
      <Row>
        <Col lg={2}>
          <LeftPanel
            activeColor1="#696F79"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#1565DB"
            iconColor1="#696F79"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#1565DB"
          />
        </Col>
        <Col
          className="primary-bg"
          style={{ minHeight: "100vh", overflowX: "hidden" }}
        >
          <div className="sm-none">
            <div className="d-flex justify-content-between align-items-center mx-5 font-700">
              <div></div>
              <AccountButton />
            </div>
          </div>
          <Container maxWidth="xl">
            <Box className="idv-box1">
              <Typography className="wr-h1 font-500">Compare rates</Typography>
              <Typography
                className="font-600"
                style={{ fontSize: 18, color: "#696F79" }}
              >
                Rates when exchanging your
                <span style={{ color: "#1565D8" }}> Naira</span>
              </Typography>
              <br />
              <TabCompareRates />
            </Box>
          </Container>
        </Col>
      </Row>
    </Box>
  );
};

export default CompareRates;
