import React from "react";
import Star from "../../assets/star.svg";
import close from "../../assets/close-square.png";
import "../../components/alpha_test/alpha_test.css";
import { Link } from "react-router-dom";

const PasswordChanged = () => {
  return (
    <div className="container">
      <div>
        <Link to="/dashboard">
          <img
            src={close}
            alt="close"
            className="img"
            style={{ marginTop: "2rem" }}
          />
        </Link>
      </div>
      <div className="box">
        <div>
          <img src={Star} alt="info" />
        </div>
        <div className="text">
          <h1 className="heading">Password changed</h1>
          <p className="info">
            You’ve successfully changed your password. Click the button below to
            login.
          </p>

          <Link to="/signin">
            <button className="wn-btn1" type="submit">
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordChanged;
