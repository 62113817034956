import * as React from 'react';
import Box from '@mui/material/Box';
import close from '../assets/close.png';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ExportIcon from '../assets/icons/ExportIcon';
import MobileConverter from './converter/MobileConverter';
import { Link } from 'react-router-dom';
import {
  selectCurrentAccLock,
  selectCurrentAccPlus,
  selectCurrentTransAcc,
} from '../features/auth/authSlice';
import { useSelector } from 'react-redux';

export default function TransferPop(p) {
  const accLock = useSelector(selectCurrentAccLock);
  const accPlus = useSelector(selectCurrentAccPlus);
  const transAcc = useSelector(selectCurrentTransAcc);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        // height: window.innerHeight - 100,
        height: window.innerHeight - 320,
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>{transAcc === true ? <MobileConverter /> : <TransferOff />}</div>
    </Box>
  );

  return (
    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="px-3" style={{ padding: '12px 0' }}>
            <div
              style={{
                textDecoration: 'none',
                color: '#696F79',
                cursor: 'pointer',
              }}
              className="d-flex align-items-center"
              onClick={toggleDrawer(anchor, true)}
            >
              <ExportIcon
                style={{ fontSize: 24, maxWidth: 20 }}
                iconColor={'#696F79'}
              />
              <span style={{ marginLeft: 20 }}>Transfer</span>
            </div>
          </div>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const TransferOff = ({ handleClose }) => {
  return (
    <div style={{ padding: '10px 0' }}>
      <div style={{ textAlign: 'right' }}>
        <img
          src={close}
          onClick={handleClose}
          style={{ height: 42, width: 42, cursor: 'pointer' }}
          alt="close"
        />
      </div>
      <br />
      <br />
      <h3 style={{ textAlign: 'center' }} className="font-500 fs-1 my-2">
        Sorry, Transfer is temporarily unavailable!
      </h3>
      <br />
      <br />
    </div>
  );
};
