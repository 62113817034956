import MenuIcon from '@mui/icons-material/Menu';
import { Container, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import acc from '../assets/acc.png';
import BitcoinconvertIcon from '../assets/icons/BitcoinconvertIcon';
import CardIcon from '../assets/icons/CardIcon';
import HomeIcon from '../assets/icons/HomeIcon';
import StatsIcon from '../assets/icons/StatsIcon';
import UserIcon from '../assets/icons/UserIcon';
import logo from '../assets/logo.png';
import logout from '../assets/logout.png';
import ngn from '../assets/nigeria.png';
import profile from '../assets/profile.png';
import qna from '../assets/qna.svg';
import {
  selectCurrentSurName,
  signOut,
  selectCurrentAccLock,
  selectCurrentAccPlus,
} from '../features/auth/authSlice';
import './a.css';
import TransferPop from './TransferPop';

const MobileNavbarTwo = (p) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const surName = useSelector(selectCurrentSurName);

  const handleLogOut = () => {
    dispatch(signOut());
    navigate('/signin');
  };
  return (
    <>
      <div className="mobile-nav-bar">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <Drawer
              iconColor1={p.iconColor1}
              iconColor2={p.iconColor2}
              iconColor3={p.iconColor3}
              iconColor4={p.iconColor4}
              iconColor5={p.iconColor5}
              activeColor1={p.activeColor1}
              activeColor2={p.activeColor2}
              activeColor3={p.activeColor3}
              activeColor4={p.activeColor4}
              activeColor5={p.activeColor5}
            />
          </div>
          <button
            className="dropdown-toggle d-flex align-items-center"
            style={{
              border: 'none',
              borderRadius: 8,
              padding: '6px 10px',
              color: '#696F79',
              background: 'white',
            }}
            type="button"
          >
            <img src={acc} alt="" style={{ height: 36, width: 36 }} />
            <span
              className="ms-3 font-500"
              style={{ textTransform: 'capitalize' }}
            >
              {surName}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileNavbarTwo;

const Drawer = (p) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accLock = useSelector(selectCurrentAccLock);
  const accPlus = useSelector(selectCurrentAccPlus);

  const handleLogOut = () => {
    dispatch(signOut());
    navigate('/signin');
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container className="font-600 pt-4">
        <Box style={{ background: 'white', height: '100%' }}>
          <div className="">
            <img
              src={logo}
              alt="logo"
              style={{ width: 85, marginLeft: 20, marginTop: 20 }}
            />
          </div>
          <div className="">
            <SelectCurr />
          </div>
          <div className="my-4" style={{ fontSize: 16 }}>
            <div className="px-3" style={{ padding: '12px 0' }}>
              <Link
                style={{ textDecoration: 'none', color: p.activeColor1 }}
                className="d-flex align-items-center"
                to="/dashboard"
              >
                <HomeIcon
                  style={{ fontSize: 24, maxWidth: 20 }}
                  iconColor={p.iconColor1}
                />
                <span style={{ marginLeft: 20 }}>Dashboard</span>
              </Link>
            </div>
            <div className="px-3" style={{ padding: '12px 0' }}>
              {accLock === 'verified' && accPlus === true ? (
                <Link
                  style={{ textDecoration: 'none', color: p.activeColor2 }}
                  className="d-flex align-items-center"
                  to="/cards"
                >
                  <CardIcon iconColor={p.iconColor2} />
                  <span style={{ marginLeft: 20 }}> Card</span>
                </Link>
              ) : (
                <Link
                  style={{ textDecoration: 'none', color: p.activeColor2 }}
                  className="d-flex align-items-center"
                  to="/incomplete-verification"
                >
                  <CardIcon iconColor={p.iconColor2} />
                  <span style={{ marginLeft: 20 }}> Card</span>
                </Link>
              )}
            </div>
            <TransferPop />
            {/* <div className="px-3" style={{ padding: "12px 0" }}>
              <Link
                style={{ textDecoration: "none", color: p.activeColor4 }}
                className="d-flex align-items-center"
                to="/limited-access"
              >
                <BitcoinconvertIcon iconColor={p.iconColor4} />
                <span style={{ marginLeft: 20 }}>Ramps</span>
              </Link>
            </div> */}
            <div className="px-3" style={{ padding: '12px 0' }}>
              <Link
                style={{ textDecoration: 'none', color: p.activeColor4 }}
                className="d-flex align-items-center"
                to="/limited-access"
              >
                <UserIcon
                  style={{ fontSize: 24, maxWidth: 20 }}
                  iconColor={p.iconColor4}
                />
                <span style={{ marginLeft: 20 }}>Recipients</span>
              </Link>
            </div>
            <div className="px-3" style={{ padding: '12px 0' }}>
              <Link
                style={{ textDecoration: 'none', color: p.activeColor5 }}
                className="d-flex align-items-center"
                to="/compare-rates"
              >
                <StatsIcon
                  style={{ fontSize: 24, maxWidth: 20 }}
                  iconColor={p.iconColor5}
                />
                <span style={{ marginLeft: 20 }}>Compare rates</span>
              </Link>
            </div>
          </div>
        </Box>
        <div className="font-500" style={{ marginTop: 50 }}>
          <div className="px-3" style={{ padding: '10px 0' }}>
            <Link to="/profile" style={{ color: '#696F79' }}>
              <img
                src={profile}
                alt="nigeria"
                style={{ height: 28, width: 28, marginRight: 20 }}
              />
              Profile
            </Link>
          </div>
          <div className="px-3" style={{ padding: '10px 0' }}>
            <Link to="/dashboard" style={{ color: '#696F79' }}>
              <img
                src={qna}
                alt="nigeria"
                style={{ height: 28, width: 28, marginRight: 20 }}
              />
              Help
            </Link>
          </div>
          <div
            className="px-3"
            style={{
              padding: '10px 0',
              color: '#696F79',
              cursor: 'pointer',
            }}
            onClick={handleLogOut}
          >
            <img
              src={logout}
              alt="nigeria"
              style={{ height: 28, width: 28, marginRight: 20 }}
            />
            Logout
          </div>
        </div>
        <br />
        <br />
        <br />
      </Container>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="p-1 px-0"
            variant="contained"
            style={{
              minWidth: 48,
              color: 'black',
              background: 'white',
              boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
              height: 48,
            }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon style={{ fontSize: 32 }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

function SelectCurr() {
  const [curr, setCurr] = React.useState(10);

  const handleChange = (event) => {
    setCurr(event.target.value);
  };

  return (
    <div>
      <Select
        value={curr}
        onChange={handleChange}
        className="font-500"
        displayEmpty={true}
        label={false}
        style={{ minWidth: 125, padding: 0, marginTop: 30, marginLeft: 12 }}
      >
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          value={10}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={ngn}
            alt="flag"
          />
          NGN
        </MenuItem>
        {/* <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={20}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={ghs}
            alt="flag"
          />
          GHS
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={30}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={zar}
            alt="flag"
          />
          ZAR
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={40}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={bwp}
            alt="flag"
          />
          BWP
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={50}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={rwf}
            alt="flag"
          />
          RWF
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={60}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={kes}
            alt="flag"
          />
          KES
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          // value={70}
        >
          <img
            style={{ height: 32, width: 32, marginRight: 10 }}
            src={tzs}
            alt="flag"
          />
          TZS
        </MenuItem> */}
      </Select>
    </div>
  );
}
