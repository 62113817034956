import { Box, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useLocalBankMutation } from "../../../services/gbpWithdrawApi";

const GbpAcc = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban, setIban] = React.useState("");

  const navigate = useNavigate();
  const [localBank] = useLocalBankMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await localBank({
        fullName,
        bankName,
        iban,
        transactionType: "LocalBank",
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className="justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Full name of account holder</div>
          <input
            className="input-req w-100"
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Bank name</div>
          <input
            className="input-req w-100"
            placeholder="Enter bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="justify-content-between align-items-center">
        <Col className="my-1" xs={12} lg={12}>
          <div className="input-h1 fs-5 ">IBAN</div>
          <input
            className="input-req w-100"
            placeholder="Enter IBAN number"
            value={iban}
            required
            onChange={(e) => setIban(e.target.value)}
          />
        </Col>
      </Row>
      <div className="text-center">
        <button className="wn-btn1" type="submit">
          Continue
        </button>
      </div>
    </form>
  );
};

export default GbpAcc;
