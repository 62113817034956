/* eslint-disable no-unused-vars */
import { Box, Container, Radio, responsiveFontSizes } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useWaitlistMutation } from '../../services/authApi';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [refCode, setRefCode] = useState('');

  const navigate = useNavigate();
  const [newWaitlistUser] = useWaitlistMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading('Adding email');
    setEmail(' ');

    try {
      const userData = await newWaitlistUser({ email, refCode }).unwrap();
      console.log(userData);
      toast.success(userData.message, { id: toastSubmit });
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
    }
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Container maxWidth={'xl'} className="w-100 logo-container">
        <img src={logo} alt="logo" className="logo" />
      </Container>
      {/* <Container style={{ maxWidth: 650 }} className="mt-2">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: 'none' }} to="/">
            <button className="rco-btn1 font-600" style={{ color: '#1565D8' }}>
              <span className="rco-h2">Cancel</span>
              <img src={closeBtn} alt="closeBtn" className="rco-img2" />
            </button>
          </Link>
        </Box>
      </Container> */}
      <form onSubmit={handleSubmit}>
        <div className="primary-bg h-100">
          <br />
          <Container style={{ maxWidth: 650 }}>
            <h1 className="monster-font display-4" style={{ fontWeight: 600 }}>
              New Waitlist User
            </h1>
            <br />
            <Row className="justify-content-between align-items-center mt-2">
              <Col>
                <div className="input-h1">Email</div>
                <input
                  className="input-req"
                  style={{ width: '100%' }}
                  required
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  onKeyPress={handleKeypress}
                />
              </Col>
            </Row>
            <br />
            <Row className="justify-content-between align-items-center mt-2">
              <Col>
                <div className="input-h1">Reference Code</div>
                <input
                  className="input-req"
                  style={{ width: '100%' }}
                  required
                  placeholder="Create reference code for user"
                  type="text"
                  onChange={(e) => setRefCode(e.target.value)}
                  value={refCode}
                  onKeyPress={handleKeypress}
                />
              </Col>
            </Row>
          </Container>
          <Container className="text-center">
            <button className="wn-btn1" type="submit">
              Add to waitlist
            </button>
          </Container>
          <div style={{ height: '15vh' }}></div>
        </div>
      </form>
    </>
  );
};

export default Waitlist;
