import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const accVerifyApi = createApi({
  reducerPath: "accVerifyApi",
  baseQuery,
  endpoints: (builder) => ({
    accVerify: builder.mutation({
      query: (credentials) => ({
        url: "/user/account-verification",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateAccVerify: builder.mutation({
      query: (credentials) => ({
        url: "/user/update-verification",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    verifyStatus: builder.mutation({
      query: (credentials) => ({
        url: `/user/verify-status?id=${credentials.userId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    updateVerifyStatus: builder.mutation({
      query: (credentials) => ({
        url: `/user/update-verification-status?id=${credentials.userId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useAccVerifyMutation,
  useVerifyStatusMutation,
  useUpdateAccVerifyMutation,
  useUpdateVerifyStatusMutation,
} = accVerifyApi;

// dateOfBirth,
