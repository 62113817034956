import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LeftPanel from "../cards/components/LeftPanel";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Dashboard.css";
import AccountButton from "../../components/AccountButton";
import TabContent from "../compare/V2/TabContent";
import TabNavItem from "../compare/V2/TabNavItem";
import addCircle from "../../assets/add-circle.png";
import { Link, useNavigate } from "react-router-dom";
import MobileNavbarTwo from "../../components/MobileNavbarTwo";
import Info from "../../assets/info-circle-white.png";
import {
  selectCurrentSurName,
  selectCurrentToken,
  selectCurrentUserId,
  selectCurrentAccLock,
} from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import tick from "../../assets/progress.png";

const DashboardNew = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const surName = useSelector(selectCurrentSurName);
  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);
  const accLock = useSelector(selectCurrentAccLock);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !userId && accLock === "verified") return navigate("/signin");
    if (token && userId && accLock === "verified")
      return navigate("/dashboard");
  });

  return (
    <Box style={{ overflowX: "hidden" }}>
      <div>
        <MobileNavbarTwo
          activeColor1="#1565DB"
          activeColor2="#696F79"
          activeColor3="#696F79"
          activeColor4="#696F79"
          activeColor5="#696F79"
          iconColor1="#1565DB"
          iconColor2="#696F79"
          iconColor3="#696F79"
          iconColor4="#696F79"
          iconColor5="#696F79"
        />
      </div>
      <Row>
        <Col lg={2}>
          <LeftPanel
            activeColor1="#1565DB"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#1565DB"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </Col>
        <Col
          className="db-col1 primary-bg h-100 pb-5"
          style={{ overflowX: "hidden" }}
        >
          <Container maxWidth="xl" className="db-box2">
            <div className="sm-none">
              <div className="db-box1 font-700 d-flex justify-content-between align-items-center">
                <Typography
                  className="font-500 dn-h1"
                  style={{
                    transform: "translateY(4px)",
                    textTransform: "capitalize",
                  }}
                >
                  Hi, {surName}
                </Typography>
                <AccountButton />
              </div>
            </div>
            <div className="md-none" style={{ textTransform: "capitalize" }}>
              <Typography className="font-700 dn-h1">Hi, {surName}</Typography>
            </div>
            <Box>
              <Typography className="font-700 dn-h2">₦0.00</Typography>
              <Typography
                className="font-600  "
                style={{ fontSize: 20, fontWeight: 500, color: "#696F79" }}
              >
                All accounts total in
                <span style={{ color: "#1565D8" }}> NGN</span>
              </Typography>
              <br />

              <VerificationStatus verifyStatus={accLock} />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50px 1fr",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px 0 0 8px",
                    backgroundColor: "#1565D8",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={Info} alt="info" />
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    borderRadius: "0 8px 8px 0",
                  }}
                >
                  <p>
                    We’ve increased our fees, it’ll be reflected in the
                    conversion as M.P rate Diff (Mid Point Rate Difference).
                  </p>
                  <p style={{ color: "#1565D8" }}>
                    <a
                      href="https://buzzlink-io.medium.com/what-the-mid-point-rate-means-4df363cbc705"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to learn more
                    </a>
                  </p>
                </div>
              </div>
              <br />
              <br />
              <Box className=" App">
                <div className="Tabs">
                  <ul className="nav tab-nav tabs-res">
                    <TabNavItem
                      title="Account"
                      id="tab1"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <TabNavItem
                      title="Activity"
                      id="tab2"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <TabNavItem
                      title="Orders"
                      id="tab3"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  </ul>

                  <div className="outlet">
                    <TabContent id="tab1" activeTab={activeTab}>
                      <>
                        <br />
                        <Box className="create-account">
                          <Link
                            to="/limited-access"
                            style={{ textDecoration: "none", color: "black" }}
                            className="p-4 d-flex justify-content-center align-items-center flex-column font-500 h-100 "
                          >
                            <img
                              src={addCircle}
                              style={{ height: 42, width: 42 }}
                              alt=""
                            />
                            Add account
                          </Link>
                        </Box>
                      </>
                    </TabContent>
                    <TabContent id="tab2" activeTab={activeTab}>
                      <br />
                      <Box
                        style={{ maxWidth: 1200 }}
                        className="my-3 bg-white rounded p-4 text-center font-700"
                      >
                        You don't have any activity in your account
                      </Box>
                    </TabContent>
                    <TabContent id="tab3" activeTab={activeTab}>
                      <br />
                      <Box
                        style={{ fontWeight: 500, maxWidth: 1200 }}
                        className="my-3 bg-white rounded p-4 text-center font-700"
                      >
                        You don't have any orders recorded
                      </Box>
                    </TabContent>
                  </div>
                </div>
              </Box>
              <div style={{ height: "10vh" }}></div>
            </Box>
          </Container>
        </Col>
      </Row>
    </Box>
  );
};

export default DashboardNew;

export const VerificationStatus = ({ verifyStatus }) => {
  return verifyStatus === "verifying" ? (
    <div className="w-100 dv-box1 me-5" style={{ padding: "12px" }}>
      <div>
        <div className="fs-5 font-500">Your account is being verified</div>
        <div style={{ color: "#696f79" }} className="font-600">
          You will be notified by email when your account has been verified.
        </div>
      </div>
      <div>
        <img
          src={tick}
          alt="cloud"
          className="spin"
          style={{ height: "40px" }}
        />
      </div>
    </div>
  ) : (
    <div className="w-100 dv-box1 me-5" style={{ padding: "12px" }}>
      <div>
        <div className="fs-5 font-500">Complete your account verification</div>
        <div style={{ color: "#696f79" }} className="font-600">
          To make transfers, verify your account.
        </div>
      </div>
      <div>
        <Link to="/id-verification">
          <button className="dv-btn1">Complete Verification</button>
        </Link>
      </div>
    </div>
  );
};
