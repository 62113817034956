import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import LeftPanel from '../cards/components/LeftPanel';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './Dashboard.css';
import AccountButton from '../../components/AccountButton';
import Tab from '../../components/Tab';
import MobileNavbarTwo from '../../components/MobileNavbarTwo';
import { useGetAccountsQuery } from '../../services/accRequestApi';
import {
  selectCurrentUserId,
  // selectCurrentSurName,
  // selectCurrentFirstName,
  selectCurrentAccLock,
  selectCurrentAccPlus,
} from '../../features/auth/authSlice';
import cd from '../../assets/cd.png';
import grammerly from '../../assets/grammerly.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ud4 from '../../assets/cards/ud4.png';

const Dashboard = () => {
  // const surName = useSelector(selectCurrentSurName);
  // const firstName = useSelector(selectCurrentFirstName);
  const accLock = useSelector(selectCurrentAccLock);
  const accPlus = useSelector(selectCurrentAccPlus);

  const [accounts, setAccounts] = React.useState([]);
  const id = useSelector(selectCurrentUserId);
  const { data, isLoading, isFetching } = useGetAccountsQuery(id);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setAccounts(data.accounts);
    }
  }, [data, isLoading, isFetching]);
  let balance = 0;
  accounts.map((acc) => (balance += acc.balance));

  const [totalBal, setTotalBal] = useState('*****');
  const [img, setImg] = useState(grammerly);

  const handleClick = () => {
    setTotalBal(balance);
    setImg(cd);
  };
  const handleClick2 = () => {
    setTotalBal('*****');
    setImg(grammerly);
  };

  return (
    <Box style={{ overflowX: 'hidden' }}>
      <div>
        <MobileNavbarTwo
          activeColor1="#1565DB"
          activeColor2="#696F79"
          activeColor3="#696F79"
          activeColor4="#696F79"
          activeColor5="#696F79"
          iconColor1="#1565DB"
          iconColor2="#696F79"
          iconColor3="#696F79"
          iconColor4="#696F79"
          iconColor5="#696F79"
        />
      </div>
      <Row>
        <Col lg={2}>
          <LeftPanel
            activeColor1="#1565DB"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#1565DB"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </Col>
        <Col
          className="db-col1 primary-bg h-100 pb-5"
          style={{ overflowX: 'hidden' }}
        >
          <Container maxWidth="xl" className="db-box2">
            <div className="sm-none">
              <div className="db-box1 font-700 d-flex justify-content-end align-items-center">
                {/* <Typography
                  className="font-500 dn-h1 mt-4"
                  style={{
                    transform: "translateY(4px)",
                    textTransform: "capitalize",
                  }}
                >
                  Hi, {firstName}
                </Typography> */}
                <AccountButton />
              </div>
            </div>
            {/* <div className="md-none" style={{ textTransform: "capitalize" }}>
              <Typography
                className="font-700 dn-h1"
                style={{ textTransform: "capitalize" }}
              >
                Hi, {firstName}
              </Typography>
            </div> */}

            <Box className="mt-4 pt-2">
              {/* <section className="ads_card">
                <article className="ads_card_article">
                  <div>
                    <img src={Card_1} alt="ads_card" className="ads_card_img" />
                  </div>
                  <div className="ads_card_head">
                    <h3 className="ads_card_title">
                      USDC Virtual Debit Cards are available
                    </h3>
                    <p className="ads_card_text">
                      Create a virtual debit card and fund it with USDC today
                    </p>
                  </div>
                </article>
                <article className="ads_card_article">
                  <div>
                    <img src={Card_2} alt="ads_card" className="ads_card_img" />
                  </div>
                  <div className="ads_card_head ads_card2_head">
                    <h3 className="ads_card_title">
                      We’ve increased our fees, it’ll be reflected in the
                      conversion as M.P rate Diff (Mid Point Rate Difference).
                    </h3>
                    <p>
                      <a
                        href="https://buzzlink-io.medium.com/what-the-mid-point-rate-means-4df363cbc705"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here to learn more <BsArrowRight size={30} />
                      </a>
                    </p>
                  </div>
                </article>
              </section> */}
              {/* <section className="advert">
                <Link
                  to={
                    accLock === 'verified' && accPlus === true
                      ? '/cards'
                      : '/incomplete-verification'
                  }
                >
                  <article className="advert-img">
                    <img src={ud1} alt="" width={650} />
                  </article>
                </Link>
                <article>
                  <a
                    href="https://buzzlink-io.medium.com/what-the-mid-point-rate-means-4df363cbc705"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ud2} alt="" width={650} />
                  </a>
                </article>
              </section> */}

              <UpdateVerification verifyStat={accLock} updateState={accPlus} />

              <div className="d-flex align-items-center">
                <Typography className="font-700 dn-h2">₦{totalBal}</Typography>
                <div
                // style={{
                //   position: 'absolute',
                //   right: 10,
                //   top: 285,
                //   background: 'white',
                //   borderRadius: 10,
                //   padding: 0,
                // }}
                >
                  <img
                    src={img}
                    onClick={() => {
                      if (img === grammerly) {
                        handleClick();
                      } else {
                        handleClick2();
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                    alt="show"
                  />
                </div>
              </div>
              <Typography
                className="font-600 "
                style={{ fontSize: 20, fontWeight: 500, color: '#696F79' }}
              >
                All accounts total in
                <span style={{ color: '#1565D8' }}> NGN</span>
              </Typography>
              <br />
              <Box className="App">
                <Tab />
              </Box>
            </Box>
          </Container>
        </Col>
      </Row>
    </Box>
  );
};

export default Dashboard;

export const UpdateVerification = ({ verifyStat, updateState }) => {
  const navigate = useNavigate();
  return (verifyStat === 'verified') & (updateState === false) ? (
    <div style={{ marginBottom: '4rem' }}>
      <section className="verification">
        <p>
          Complete your account verification to make transfers and use other
          features on the platform.
        </p>
        <button type="button" onClick={() => navigate('/verification-update')}>
          Complete verification
        </button>
      </section>
      <article
        className="verification-img"
        onClick={() => navigate('/verification-update')}
      >
        <img src={ud4} alt="" width={500} />
      </article>
    </div>
  ) : (
    ' '
  );
};
