import { Button, Container, ListItemButton, ListItemIcon } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../assets/logo.png";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Ngn from "../../assets/nigeria.png";
import britain from "../../assets/britain.png";
import turkey from "../../assets/turkey.png";
import usa from "../../assets/usa.png";
import ukraine from "../../assets/ukraine.png";
import close from "../../assets/close-square.png";
import bank from "../../assets/bank.png";
import account from "../../assets/account.png";
import recipient from "../../assets/recipient.png";
import paypal from "../../assets/paypal.png";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

const Withdraw = () => {
  return (
    <div>
      <br />
      <Box sx={style}>
        <TRY />
      </Box>
      <br />
      <Box sx={style}>
        <USD />
      </Box>
      <br />
      <Box sx={style}>
        <NGN />
      </Box>
      <br />
    </div>
  );
};

export default Withdraw;

const style = {
  width: 450,
  bgcolor: "#F3F4F6",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const NGN = () => {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <img src={close} />
      </div>
      <Typography
        className="my-2 font-600"
        style={{ fontWeight: 600, fontSize: 22 }}
      >
        Withdraw
      </Typography>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={bank} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Bank
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Withdraw to a bank account.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={recipient} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Recipient
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Send to a recipient from your account.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
    </>
  );
};

const USD = () => {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <img src={close} />
      </div>
      <Typography
        className="my-2 font-600"
        style={{ fontWeight: 600, fontSize: 22 }}
      >
        Withdraw
      </Typography>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={bank} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Bank
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Withdraw to a bank account.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={recipient} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Recipient
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Send to a recipient from your account.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={account} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Physical delivery (North Cyprus only)
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Request for a delivery to your doorstep.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
    </>
  );
};

const TRY = () => {
  return (
    <>
      <NGN />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={paypal} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              PayPal (United States and Nigeria only)
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Withdraw to a bank account.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
      <Box
        style={{ borderRadius: 8, background: "white" }}
        className="w-100 d-flex align-items-center py-3 px-3 justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img src={account} style={{ height: 46, width: 46 }} />
          </div>
          <div>
            <div className="font-600" style={{ fontWeight: 600 }}>
              Physical delivery (North Cyprus only)
            </div>
            <div
              className="font-600"
              style={{ fontSize: 12, color: "#A4A5A6", fontWeight: 500 }}
            >
              Request for a delivery to your doorstep.
            </div>
          </div>
        </div>
        <div>
          <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
        </div>
      </Box>
      <br />
    </>
  );
};
