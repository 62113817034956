import React from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import close from "../../assets/close.png";
import {
  selectCurrentEmail,
  selectCurrentLinkTag,
  selectCurrentUserId,
  signOut,
} from "../../features/auth/authSlice";
import { useDeactivateAccountMutation } from "../../services/authApi";
import "./Profile.css";

const Deactivate = (p) => {
  const dispatch = useDispatch();
  const linkTag = useSelector(selectCurrentLinkTag);
  const userId = useSelector(selectCurrentUserId);
  const email = useSelector(selectCurrentEmail);

  const navigate = useNavigate();
  const [deactivateAccount] = useDeactivateAccountMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Deactivating...");

    try {
      const userData = await deactivateAccount({
        _id: userId,
      }).unwrap();

      dispatch(signOut());
      navigate("/signin");
      p.handleClose();
      toast.success("Your account has been deactivated", { id: toastSubmit });
    } catch (error) {
      toast.error("Could'nt deactivate your account!", {
        id: toastSubmit,
      });
      // console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <img
          src={close}
          alt="close"
          onClick={p.handleClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        className="input-h1 mt-3"
        style={{ fontSize: "20px", textAlign: "center", fontWeight: "normal" }}
      >
        Are you sure you want to disable your LINK <br /> account? You can't
        undo this action
      </div>
      <Row className="justify-content-between align-items-center mt-1">
        <Col className="my-1" xs={12} lg={6}>
          <button
            className="wn-btn1"
            type="submit"
            style={{ backgroundColor: "#c1c1c1", color: "#000" }}
            onClick={p.handleClose}
          >
            Cancel
          </button>
        </Col>
        <Col className="my-1" xs={12} lg={6}>
          <button
            className="wn-btn1"
            type="submit"
            style={{ backgroundColor: "#000" }}
            onClick={handleSubmit}
          >
            Deactivate
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Deactivate;
