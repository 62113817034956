import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const transferApi = createApi({
  reducerPath: "transferApi",
  baseQuery,
  endpoints: (builder) => ({
    transfer: builder.mutation({
      query: (credentials) => ({
        url: "/transfer",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    transferReverse: builder.mutation({
      query: (credentials) => ({
        url: "/transfer/reverse",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getOrder: builder.query({
      query: (userId) => ({
        url: `/transfer/orders?id=${userId}`,
        method: "GET",
      }),
    }),
    // usdLocalBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/usd/local-bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // usdSwift: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/usd/swift",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // eurInside: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/eur/inside",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // eurOutside: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/eur/outside",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // gbpLocalBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/gbp/local-bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // gbpIban: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/gbp/iban",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // ngnBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/ngn/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // tryBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/try/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // aedBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/aed/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // cnyBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/cny/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // chfBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/chf/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // kesBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/kes/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // kesMobile: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/kes/mobile",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // cadBank: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/cad/bank",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
    // cadInterac: builder.mutation({
    //   query: (credentials) => ({
    //     url: "/transfer/cad/interac",
    //     method: "POST",
    //     body: { ...credentials },
    //   }),
    // }),
  }),
});

export const {
  // useLocalBankMutation,
  // useSwiftMutation,
  // useAedBankMutation,
  // useCadBankMutation,
  // useCadInteracMutation,
  // useChfBankMutation,
  // useCnyBankMutation,
  // useEurInsideMutation,
  // useEurOutsideMutation,
  // useGbpIbanMutation,
  // useGbpLocalBankMutation,
  // useKesBankMutation,
  // useKesMobileMutation,
  // useNgnBankMutation,
  // useTryBankMutation,
  // useUsdLocalBankMutation,
  // useUsdSwiftMutation,
  useTransferMutation,
  useTransferReverseMutation,
  useGetOrderQuery,
} = transferApi;
