import { Box, Container, MenuItem, Select } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import logo from "../../assets/logo.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import { selectCurrentUserId } from "../../features/auth/authSlice";
import { useBankMutation } from "../../services/ngnWithdrawAPi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BankWithdrawNgn = () => {
  const [amount, setAmount] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("Select Bank");
  const [accountNumber, setAccountNumber] = React.useState("");

  const navigate = useNavigate();
  const userId = useSelector(selectCurrentUserId);
  const [bankTransfer] = useBankMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Processing Withdrawal...");

    try {
      const response = await bankTransfer({
        userId,
        amount,
        fullName,
        bankName,
        accountNumber,
      }).unwrap();
      toast.success(response.message, { id: toastSubmit });

      navigate("/withdrawal-completed");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      // navigate('/withdrawal-failed');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box className="box-set">
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>

          <Container>
            <div className="wn-h1 font-500">Withdraw NGN</div>
            <Row className=" justify-content-between align-items-center">
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">Amount to withdraw</div>
                <input
                  className="input-req wn-input1"
                  placeholder="E.g 10,000"
                  value={amount}
                  type="number"
                  required
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1">Bank name</div>
                <div>
                  <Select
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    className="font-500"
                    label={false}
                    fullWidth
                    required
                    style={{ background: "white", height: 50 }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Select Bank"
                    >
                      Select Bank
                    </MenuItem>
                    <hr className="m-0" />
                    {listOfBanks()}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center">
              <Col
                style={{ margin: "10px 0" }}
                xs={12}
                md={6}
                className="wn-box1"
              >
                <div className="input-h1">Full name of account holder</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req wn-input1"
                    placeholder="Enter account name"
                    value={fullName}
                    type="text"
                    required
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </Col>
              <Col style={{ margin: "10px 0" }} xs={12} md={6}>
                <div className="input-h1">Account number</div>
                <input
                  className="input-req wn-input1"
                  placeholder="E.g 4120661825"
                  value={accountNumber}
                  type="number"
                  required
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Col>
            </Row>
            <div className="text-center">
              <button className="wn-btn1" type="submit">
                Continue
              </button>
            </div>
          </Container>
        </Box>
      </div>
    </form>
  );
};

const availableBanks = [
  {
    id: 59,
    code: "044",
    name: "ACCESS BANK PLC",
  },
  {
    id: 24,
    code: "323",
    name: "AccessMobile",
  },
  {
    id: 70,
    code: "090144",
    name: "CIT MICROFINANCE BANK",
  },
  {
    id: 71,
    code: "023",
    name: "Citibank Nigeria LTD",
  },
  {
    id: 72,
    code: "090130",
    name: "CONSUMER MICROFINANCE BANK",
  },
  {
    id: 56,
    code: "100032",
    name: "CONTEC GLOBAL INFOTECH LIMITED (NOWNOW)",
  },
  {
    id: 41,
    code: "559",
    name: "Coronation Merchant Bank",
  },
  {
    id: 39,
    code: "551",
    name: "Covenant ",
  },
  {
    id: 75,
    code: "063",
    name: "DIAMOND BANK PLC",
  },
  {
    id: 76,
    code: "050",
    name: "ECOBANK NIGERIA PLC",
  },
  {
    id: 11,
    code: "306",
    name: "eTranzact",
  },
  {
    id: 78,
    code: "090179",
    name: "FAST MICROFINANCE BANK",
  },
  {
    id: 34,
    code: "413",
    name: "FBN Mortgages Limited ",
  },
  {
    id: 14,
    code: "309",
    name: "FBNMobile",
  },
  {
    id: 79,
    code: "060002",
    name: "FBNQuest Merchant Bank Limited",
  },
  {
    id: 81,
    code: "070",
    name: "FIDELITY BANK PLC",
  },
  {
    id: 20,
    code: "318",
    name: "Fidelity Mobile",
  },
  {
    id: 83,
    code: "011",
    name: "FIRST BANK OF NIGERIA PLC",
  },
  {
    id: 2,
    code: "214",
    name: "FIRST CITY MONUMENT BANK PLC",
  },
  {
    id: 84,
    code: "090164",
    name: "FIRST ROYAL MICROFINANCE BANK",
  },
  {
    id: 57,
    code: "110002",
    name: "FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED",
  },
  {
    id: 85,
    code: "070009",
    name: "GATEWAY MORTGAGE BANK",
  },
  {
    id: 134,
    code: "000027",
    name: "Globus Bank",
  },
  {
    id: 87,
    code: "090178",
    name: "GREENBANK MICROFINANCE BANK",
  },
  {
    id: 18,
    code: "315",
    name: "GTMobile",
  },
  {
    id: 130,
    code: "058",
    name: "Guaranty Trust Bank",
  },
  {
    id: 90,
    code: "030",
    name: "HERITAGE BANK ",
  },
  {
    id: 91,
    code: "090175",
    name: "HIGHSTREET MICROFINANCE BANK",
  },
  {
    id: 94,
    code: "070016",
    name: "INFINITY TRUST MORTGAGE BANK",
  },
  {
    id: 55,
    code: "100029",
    name: "Innovectives Kesh",
  },
  {
    id: 53,
    code: "100027",
    name: "Intellifin",
  },
  {
    id: 6,
    code: "301",
    name: "JAIZ BANK PLC",
  },
  {
    id: 32,
    code: "402",
    name: "JUBILEE LIFE MORTGAGE BANK",
  },
  {
    id: 95,
    code: "082",
    name: "KEYSTONE BANK",
  },
  {
    id: 51,
    code: "100025",
    name: "KongaPay",
  },
  {
    id: 132,
    code: "090267",
    name: "Kuda",
  },
  {
    id: 97,
    code: "090174",
    name: "MALACHY MICROFINANCE BANK",
  },
  {
    id: 98,
    code: "090136",
    name: "Microcred Microfinance Bank Nigeria Ltd",
  },
  {
    id: 99,
    code: "090113",
    name: "MICROVIS MICROFINANCE&#160;BANK",
  },
  {
    id: 16,
    code: "313",
    name: "Mkudi",
  },
  {
    id: 100,
    code: "090129",
    name: "MONEY TRUST MICROFINANCE BANK",
  },
  {
    id: 26,
    code: "325",
    name: "MoneyBox ",
  },
  {
    id: 101,
    code: "090151",
    name: "MUTUAL TRUST MICROFINANCE BANK",
  },
  {
    id: 102,
    code: "090128",
    name: "NDIORAH MICROFINANCE BANK ",
  },
  {
    id: 43,
    code: "561",
    name: "New Prudential Bank",
  },
  {
    id: 50,
    code: "999",
    name: "NIP Virtual Bank ",
  },
  {
    id: 103,
    code: "060003",
    name: "NOVA MERCHANT BANK LTD",
  },
  {
    id: 40,
    code: "552",
    name: "NPF Microfinance Bank ",
  },
  {
    id: 104,
    code: "090119",
    name: "OHAFIA MFB",
  },
  {
    id: 105,
    code: "090161",
    name: "OKPOGA MICROFINANCE BANK",
  },
  {
    id: 46,
    code: "606",
    name: "Omoluabi Savings and Loans Plc",
  },
  {
    bank_name: "OPAY",
    bank_code: "100004",
  },
  {
    id: 28,
    code: "327",
    name: "PAGA",
  },
  {
    id: 42,
    code: "560",
    name: "Page MFBank",
  },
  {
    id: 131,
    code: "100033",
    name: "PALMPAY",
  },
  {
    id: 675,
    code: "090317",
    name: "Getripay (PatrickGold Microfinance Bank)",
  },
  {
    id: 10,
    code: "305",
    name: "PAYCOM",
  },
  {
    id: 109,
    code: "070013",
    name: "PLATINUM MORTGAGE BANK",
  },
  {
    id: 110,
    code: "076",
    name: "POLARIS BANK LTD (SKYE BANK PLC)",
  },
  {
    id: 1,
    code: "101",
    name: "PROVIDUS BANK",
  },
  {
    id: 4,
    code: "221",
    name: "STANBIC IBTC BANK PLC",
  },
  {
    id: 9,
    code: "304",
    name: "STANBIC MOBILEMONEY",
  },
  {
    id: 116,
    code: "068",
    name: "STANDARD CHARTERED BANK NIGERIA LTD",
  },
  {
    id: 5,
    code: "232",
    name: "STERLING BANK PLC",
  },
  {
    id: 27,
    code: "326",
    name: "Sterling Mobile ",
  },
  {
    id: 119,
    code: "032",
    name: "UNION BANK OF NIGERIA PLC",
  },
  {
    id: 120,
    code: "033",
    name: "UNITED BANK FOR AFRICA PLC",
  },
  {
    id: 3,
    code: "215",
    name: "UNITY BANK PLC",
  },
  {
    id: 122,
    code: "090110",
    name: "VFD MICROFINANCE BANK",
  },
  {
    id: 123,
    code: "090139",
    name: "VISA MICROFINANCE BANK",
  },
  {
    id: 124,
    code: "035",
    name: "WEMA BANK PLC",
  },
  {
    id: 128,
    code: "057",
    name: "ZENITH BANK PLC",
  },
  {
    id: 129,
    code: "322",
    name: "ZenithMobile",
  },
];

export const listOfBanks = () => {
  const bankList = availableBanks.map((items, index) => (
    <MenuItem
      key={index}
      className="d-flex align-items-center px-3 font-500"
      value={items.code}
    >
      {items.name}
    </MenuItem>
  ));
  return bankList;
};

export default BankWithdrawNgn;
