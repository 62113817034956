import { Button, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
// import { useEurInsideMutation } from "../../../services/transferApi";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { setName } from "../../../features/transferSlice";
import { setEur } from "../../../features/transferRouteSlice";

const InEurope = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban, setIban] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [inside] = useEurInsideMutation();
  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(setEur({ fullName, bankName, iban, transactionType: "Inside" }));
    navigate("/review-eur-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className="d-flex justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">
            Full name of account holder
          </div>
          <input
            className="input-add-recipient"
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">Bank name</div>
          <input
            className="input-add-recipient"
            placeholder="Enter Bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col className="my-1">
          <div className="label-input-add-recipient">IBAN</div>
          <input
            className="input-add-recipient"
            placeholder="Enter IBAN"
            value={iban}
            required
            onChange={(e) => setIban(e.target.value)}
          />
        </Col>
      </Row>

      <Container className="text-center">
        <Row className=" justify-content-between align-items-center">
          <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
            <div className="text-center">
              <Button
                variant="contained"
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 15px",
                }}
                onClick={handleAddRecipient}
              >
                <img src={userAdd} className="me-2" alt="" />
                Add to recipients
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center">
              <button className="wn-btn1" type="submit">
                Continue
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default InEurope;
