import React, { useEffect } from "react";
import logo from "../../../assets/logo.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import cloud from "../../../assets/cloud.png";
import tick from "../../../assets/tick.png";
import { Box, Container, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";

const ReverseCadTransfer = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="wr-box1">
        <Typography className="text-center font-500 wr-h1">
          Request received
        </Typography>
        <Box className="d-flex justify-content-center py-2">
          <Box className="wr-img1">
            <img src={tick} alt="cloud" />
          </Box>
        </Box>
        <Typography variant="h6" className="text-center my-4 font-600">
          We’ll send the payment details to the email address you signed up
          with.
        </Typography>
        <div className="text-center">
          <Link to="/dashboard">
            <button className="wn-btn1">Done</button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ReverseCadTransfer;
