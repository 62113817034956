import { Box, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useInsideMutation } from "../../../services/eurWithdrawApi";

const InEurope = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [postCode, setPostCode] = React.useState("");

  const navigate = useNavigate();
  const [inside] = useInsideMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await inside({
        fullName,
        bankName,
        postCode,
        transactionType: "Inside",
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className="d-flex justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">
            Full name of account holder
          </div>
          <input
            className="input-add-recipient"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">Bank name</div>
          <input
            className="input-add-recipient"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-1">
          <div className="label-input-add-recipient">Postal code</div>
          <input
            className="input-add-recipient"
            value={postCode}
            required
            onChange={(e) => setPostCode(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <Container className="text-center">
        <button className="wn-btn1" type="submit">
          Confirm
        </button>
      </Container>
    </form>
  );
};

export default InEurope;
