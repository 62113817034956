import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import outgoing from "../../assets/outgoing.png";
import incoming from "../../assets/incoming.png";
import "./Orders.css";
import { useGetOrderQuery } from "../../services/transferApi";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../features/auth/authSlice";

const Orders = () => {
  const [orders, setOrders] = React.useState([]);
  const userId = useSelector(selectCurrentUserId);

  const { data, isloading } = useGetOrderQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setOrders(data);
  }, [data, isloading]);

  return (
    <>
      <br />
      <div className="rounded" style={{ maxWidth: 1200, marginTop: 20 }}>
        {orders ? (
          orders
            .filter(
              (item) =>
                item.status === "processing" ||
                item.status === "cancelled" ||
                item.status === "delivered"
            )
            .map((item, index) => (
              <Link
                to="#"
                style={{ textDecoration: "none", cursor: "pointer" }}
                key={index}
              >
                <div
                  className="o-box1"
                  style={{
                    color: "black",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.20)",
                    borderRadius: 7,
                  }}
                >
                  <div className="o-box2 o-size">
                    <img
                      src={
                        item.transferType === "forward" ? outgoing : incoming
                      }
                      alt="order"
                      className="o-img1"
                    />
                    <span
                      className="font-700 o-h1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.fullName || item.recipientName}
                    </span>
                  </div>
                  <div className="sm-none font-700 o-h2">
                    {item.transferType === "forward" ? (
                      <span>Forward</span>
                    ) : (
                      <span>Reverse</span>
                    )}
                  </div>
                  <div className="font-700 o-h2">
                    <span>
                      {item.transferType === "forward"
                        ? item.amountReceived
                        : item.amountSend}{" "}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {item.currency}
                    </span>
                  </div>
                  <div
                    className="font-700 o-h3"
                    style={{ color: item.color, textTransform: "capitalize" }}
                  >
                    {item.status === "delivered" && (
                      <span style={{ color: "#04C45C" }}>Delivered</span>
                    )}
                    {item.status === "processing" && (
                      <span style={{ color: "#FF9900" }}>Processing</span>
                    )}
                    {item.status === "cancelled" && (
                      <span style={{ color: "#E30A17" }}>Cancelled</span>
                    )}
                  </div>
                </div>
              </Link>
            ))
        ) : (
          <div
            style={{ fontWeight: 500, maxWidth: 1200 }}
            className="my-3 bg-white rounded p-4 text-center font-700"
          >
            You don't have any orders recorded
          </div>
        )}
      </div>
      <div style={{ height: "10vh" }}></div>
    </>
  );
};

export default Orders;
