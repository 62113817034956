import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import ReactSlider from "react-slider";
import { Col, Row } from "react-bootstrap";
import MySteps from "./Mysteps";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import "./tc.css";

const TrackOrder = () => {
  return (
    <div className="primary-bg h" style={{ minHeight: "100vh" }}>
      <div>
        <MobileNavbarOne />
      </div>
      <div className="sm-none">
        <Container maxWidth="xl" className="w-100 logo-container">
          <img src={logo} alt="logo" className="logo" />
        </Container>
      </div>
      <Container maxWidth="md" className="idv-box1">
        <Box className="d-flex justify-content-between align-items-center tc-i1">
          <Link style={{ textDecoration: "none" }} to="/dashboard">
            <button
              className="d-flex justify-content-between align-items-center font-500 mt-2 fs-5"
              style={{
                color: "#1565D8",
                border: "none",
                background: "transparent",
              }}
            >
              <img
                src={backArrow}
                alt="backArrow"
                className="me-1"
                style={{ width: 26 }}
              />
              <span className="font-600" style={{ fontSize: 16 }}>
                Go back &nbsp;
              </span>
              <span className="sm-none font-600" style={{ fontSize: 16 }}>
                {" "}
                to orders
              </span>
            </button>
          </Link>
        </Box>
        <Typography className=" font-500 to-h1 tc-h1">
          Track your order
        </Typography>

        <Row>
          <Col xs={12} lg={4}>
            <div className="sm-none">
              <div
                className="bg-white rounded p-4"
                style={{
                  height: 445,
                  marginTop: 42,
                  width: 270,
                  marginLeft: "auto",
                }}
              >
                <div className="to-box1">
                  <div style={{ margin: "20px 0 3rem 10px" }}>
                    <div
                      className="font-500"
                      style={{ width: 160, fontSize: 14, color: "#696F79" }}
                    >
                      Recipient
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 160, fontSize: 16 }}
                    >
                      John James Okafor
                    </div>
                  </div>
                  <div style={{ margin: "3rem 10px" }}>
                    <div
                      className="font-500"
                      style={{ width: 130, fontSize: 14, color: "#696F79" }}
                    >
                      Reference code
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 130, fontSize: 16 }}
                    >
                      L879369
                    </div>
                  </div>
                </div>
                <div className="to-box1">
                  <div style={{ margin: "3rem 10px" }}>
                    <div
                      className="font-500"
                      style={{ width: 160, fontSize: 14, color: "#696F79" }}
                    >
                      Amount sent
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 160, fontSize: 16 }}
                    >
                      210,000 NGN
                    </div>
                  </div>
                  <div style={{ margin: "3rem 10px" }}>
                    <div
                      className="font-500"
                      style={{ width: 130, fontSize: 14, color: "#696F79" }}
                    >
                      Amount to receive
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 130, fontSize: 16 }}
                    >
                      468.70 USD
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md-none">
              <div className="bg-white rounded px-4 mx-2">
                <div className="to-box1">
                  <div className="my-2">
                    <div
                      className="font-500"
                      style={{ width: 160, fontSize: 13, color: "#696F79" }}
                    >
                      Recipient
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 160, fontSize: 14.5 }}
                    >
                      John James Okafor
                    </div>
                  </div>
                  <div className="my-2">
                    <div
                      className="font-500"
                      style={{ width: 130, fontSize: 13, color: "#696F79" }}
                    >
                      Reference code
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 130, fontSize: 14.5 }}
                    >
                      L879369
                    </div>
                  </div>
                </div>
                <div className="to-box1">
                  <div className="my-2">
                    <div
                      className="font-500"
                      style={{ width: 160, fontSize: 13, color: "#696F79" }}
                    >
                      Amount sent
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 160, fontSize: 14.5 }}
                    >
                      210,000 NGN
                    </div>
                  </div>
                  <div className="my-2">
                    <div
                      className="font-500"
                      style={{ width: 130, fontSize: 13, color: "#696F79" }}
                    >
                      Amount to receive
                    </div>
                    <div
                      className="font-700"
                      style={{ width: 130, fontSize: 14.5 }}
                    >
                      468.70 USD
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="my-3 p-4">
            <div className="sm-none">
              <MySteps />
            </div>
            <div className="md-none px-5">
              <MySteps />
            </div>
          </Col>
        </Row>
        <div style={{ height: "15vh" }}></div>
      </Container>
    </div>
  );
};

export default TrackOrder;
