
import React from "react";
const TabNavItem = ({ id, title, activeTab, setActiveTab }, p) => {

    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <li onClick={() => {
            handleClick();
    
        }} className={activeTab === id ? "active" : "inactive"}>
            {title}
        </li>
    );
};
export default TabNavItem;