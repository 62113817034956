import { Box } from "@mui/system";
import logo from "../../assets/logo.png";
import React from "react";
import moneyGram from "../../assets/moneygram.png";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import bank from "../../assets/bank.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";

export default function Ramp() {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box className="box-set">
        <Container className="rco-box1">
          <Box className="d-flex justify-content-end align-items-center">
            {/* <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link> */}
            {/* <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link> */}
          </Box>
        </Container>
        <Container>
          <div className="font-500 wn-h1">
            Where do you want to off ramp to?
          </div>
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
              cursor: "pointer",
              "&:hover": {
                background: "#2C2C2C",
                color: "#fff",
              },
            }}
            className="pt-box1"
            // onClick={handleOpenWallet}
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={moneyGram} style={{ height: 46, width: 46 }} alt="" />
              </div>

              <div className="font-600 pt-h1">
                <h5>MoneyGram </h5>
                <p
                  style={{
                    color: "rgb(154, 156, 172)",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  Only available in the Netherland &#127475;&#127473;
                </p>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
              cursor: "pointer",
            }}
            className="pt-box1"
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={bank} style={{ height: 46, width: 46 }} alt="" />
              </div>

              <div className="font-600 pt-h1">
                <h5>To bank account</h5>
                <p
                  style={{
                    color: "rgb(154, 156, 172)",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  Only to Euro and Turkish Lira accounts
                </p>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
        </Container>
      </Box>
    </div>
  );
}
