import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import deposit from "../../assets/deposit.png";
import depo from "../../assets/depo.png";
import bookmark from "../../assets/bookmark.png";
import { Col, Row } from "react-bootstrap";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import "./Deposit.css";

const DepositRequest = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="rco-box1">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <div></div>
        </Box>
      </Container>
      <Container maxWidth="md" className="">
        <Typography className="text-center font-500 wr-h1">
          Account deposit request
        </Typography>

        <div className="d-flex justify-content-center my-4">
          <img src={deposit} alt="logo" className="dr-img1" />
        </div>
        <div>
          <Typography className="mx-2 font-600" style={{ fontSize: 24 }}>
            <Box className="d-flex align-items-center">
              <div xs={1} lg={3}>
                <img src={bookmark} className="dr-img2" alt="logo" />
              </div>
              <div>
                <div className="dr-h1">You’ll get our bank details.</div>
              </div>
            </Box>
          </Typography>
          <br />
          <Typography className="mx-2 font-600" style={{ fontSize: 24 }}>
            <Box className="d-flex align-items-center">
              <div xs={1} lg={3}>
                <img src={depo} className="dr-img2" alt="logo" />
              </div>
              <div>
                <div className="dr-h2">
                  You’ll need to transfer the money to LINK through your bank.
                </div>
              </div>
            </Box>
          </Typography>
        </div>
        <Box className="text-center">
          <Link to="/link-account-details">
            <button className="wn-btn1">Continue</button>
          </Link>
        </Box>
        <div style={{ height: "20vh" }}></div>
      </Container>
    </div>
  );
};

export default DepositRequest;
