import React, { useEffect } from "react";
import logo from "../../../assets/logo.png";
import closeBtn from "../../../assets/close-square.png";
import cross from "../../../assets/cross.png";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import {
  selectCurrentToken,
  selectCurrentUserId,
} from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";

const RequestFailed = () => {
  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !userId) return navigate("/signin");
  });

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <div className="d-flex justify-content-between align-items-center">
          <img src={logo} alt="logo" />
        </div>
      </Container>
      <Container className="wnr-box1">
        <Link to="/dashboard" className="px-3 d-flex justify-content-end">
          <img src={closeBtn} alt="close" style={{ cursor: "pointer" }} />
        </Link>
        <Typography className="text-center font-500 wr-h1 ">
          Request not received
        </Typography>
        <Box className="d-flex justify-content-center py-2">
          <Box className="d-flex justify-content-center align-items-center cross-img">
            <img src={cross} alt="cloud" />
          </Box>
        </Box>
        <Box className="my-4">
          <Typography variant="h6" className="text-center monster-font">
            There seems to be a problem.
          </Typography>
          <Typography variant="h6" className="text-center monster-font">
            Please contact &nbsp;
            <span style={{ color: "#1565D8" }}>support@linkio.africa</span>
            <div style={{ height: "15vh" }}></div>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default RequestFailed;
