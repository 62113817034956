import { Box, Button, Container } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import toast from "react-hot-toast";
import { usePaypalMutation } from "../../services/paypalWithdrawApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BankWithdrawPaypal = () => {
  const [paypalAddress, setPaypalAddress] = React.useState("");

  const navigate = useNavigate();
  const [paypal] = usePaypalMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await paypal({ paypalAddress }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <h1 className="font-500 wn-h1">Withdraw with PayPal</h1>
            <div className="wp-box2">
              <div className="input-h1">
                Registered PayPal email or mobile number
              </div>
              <input
                className="input-req wp-input1"
                placeholder="Enter email or mobile number"
                value={paypalAddress}
                required
                onChange={(e) => setPaypalAddress(e.target.value)}
              />
            </div>
          </Container>
        </Box>
        <Container className="text-center">
          <button className="wp-btn1" type="submit">
            Continue
          </button>
        </Container>
      </div>
    </form>
  );
};

export default BankWithdrawPaypal;
