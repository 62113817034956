import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const paypalWithdrawApi = createApi({
  reducerPath: "paypalWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    paypal: builder.mutation({
      query: (credentials) => ({
        url: `/withdraw/paypal`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { usePaypalMutation } = paypalWithdrawApi;
