import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import ExportIcon from "../../../assets/icons/ExportIcon";
import trash from "../../../assets/trash.png";
import MobileConverter from "../../../components/converter/MobileConverter";
import { useGetRecipientQuery } from "../../../services/recipientApi";
import { useDispatch, useSelector } from "react-redux";
import { setTransaction } from "../../../features/transactionSlice";
import { selectCurrentUserId } from "../../../features/auth/authSlice";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [recipients, setRecipients] = React.useState(null);
  const userId = useSelector(selectCurrentUserId);

  const { data } = useGetRecipientQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    setRecipients(data);
  }, [data, open]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDispatchDetails = (fullName, bankName, iban, anchor) => {
    toggleDrawer(anchor, true);
    handleOpen();
    dispatch(setTransaction({ fullName, iban, bankName }));
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: window.innerHeight - 100,
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="primary-bg">
        <MobileConverter />
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          {recipients?.map((recipient, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <Box className="d-flex align-items-center">
                <Button
                  variant={"contained"}
                  style={{
                    minWidth: 0,
                    padding: 9.5,
                    background: "white",
                    marginRight: 10,
                    height: 55,
                    width: 55,
                  }}
                >
                  <img src={trash} alt="" style={{ height: 24, width: 24 }} />
                </Button>
                <div
                  onClick={
                    () =>
                      handleDispatchDetails(
                        recipient.fullName,
                        recipient.bankName,
                        recipient.iban,
                        anchor
                      )
                    // toggleDrawer(anchor, true)
                  }
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{
                    background: "white",
                    padding: "13px 18px",
                    borderRadius: 4,
                    boxShadow: "1px 2.5px 4px rgba(0,0,0,.25)",
                    cursor: "pointer",
                  }}
                >
                  <div className="font-500" style={{ fontSize: 12 }}>
                    {recipient.fullName}
                  </div>
                  <div
                    className="font-600"
                    style={{ fontSize: 12, color: "#828282" }}
                  >
                    {recipient.type}
                  </div>
                  <ExportIcon iconColor="#000" className="r-ic1" />
                </div>
              </Box>
            </div>
          ))}

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
