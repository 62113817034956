import React from 'react';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import { Link } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import './Transaction.css';
import { useSelector } from 'react-redux';
import {
  selectCurrentSend,
  selectCurrentName,
  selectCurrentRecieve,
  selectCurrentRef,
} from '../../features/physicalDeliverySlice';

const ReviewOrder = () => {
  const send = useSelector(selectCurrentSend);
  const receIve = useSelector(selectCurrentRecieve);
  const ref = useSelector(selectCurrentRef);
  const name = useSelector(selectCurrentName);

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box className="border">
        <Container maxWidth="md" className="rco-box1 border">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container
          className="border"
          // maxWidth="md"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div>
            <div className="font-600 wn-h1 py-1">Review your order</div>
            <Box className="ro-box1">
              <div className="font-500">
                <div className="ro-h2">You send:</div>
                <div className="ro-h2">They get:</div>
              </div>
              <div className="font-700">
                <div className="ro-h1">{send} NGN</div>
                <div className="ro-h1">{receIve} USD</div>
              </div>
            </Box>
            <br />
            <Box className="ro-box2">
              <div className="font-500 ro-h2">Recipient Name:</div>
              <div className="font-500 ro-h1">{name}</div>
            </Box>
            <br />
            <Box className="ro-box1">
              <div className="font-500 ro-h2">Reference code:</div>
              <div className="font-500 ro-h1">{ref}</div>
            </Box>
            <div className="text-center" style={{ maxWidth: 800 }}>
              <Link to="/transfer-method">
                <button className="wn-btn1">Continue</button>
              </Link>
            </div>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default ReviewOrder;
