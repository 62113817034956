import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import "../CompareRates.css";
import grey from "../../../assets/grey.png";
import wyreout from "../../../assets/wyreout.svg";
import sendcash from "../../../assets/sendcash.png";
import klasha from "../../../assets/klasha.png";
import LINK from "../../../assets/LINK.png";
import jumpafrica from "../../../assets/Jumpafrica.png";
import vella from "../../../assets/vella.png";
import payday from "../../../assets/payday.png";
import Afriex from "../../../assets/Afriex.png";
import access from "../../../assets/access.png";
import kaoshi from "../../../assets/kaoshi.jpg";
import wirepay from "../../../assets/wirepay.svg";
import changera from "../../../assets/changera.svg";
import send from "../../../assets/send.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsdValue,
  setUsdCompetitor,
  selectCompetitors,
} from "../../../features/competitorSlice";
import { useGetCompetitorsQuery } from "../../../services/excelApi";

const USD = () => {
  const competitor = useSelector(selectUsdValue);
  const [usdCompetitors, setUsdCompetitors] = React.useState({});

  const [competitorRate, setCompetitorRate] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [competitorFee, setCompetitorFee] = React.useState("");

  const { data, isLoading } = useGetCompetitorsQuery("competitors", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    !isLoading && setUsdCompetitors({ ...data?.usdCompetitors });
  }, [data, isLoading]);

  useEffect(() => {
    handlecompair(competitor);
    setRate(usdCompetitors.link);
  }, [usdCompetitors, competitor]);

  const handlecompair = (competitor) => {
    switch (competitor) {
      case "afriex":
        setCompetitorFee("Free");
        setCompetitorRate(usdCompetitors.afriex);
        break;
      case "wyreout":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.wyreout);
        break;
      case "access":
        setCompetitorFee("ATM: 5-8%+$3.5, SWIFT:$30");
        setCompetitorRate(usdCompetitors.access);
        break;
      case "klasha":
        setCompetitorFee("Free");
        setCompetitorRate(usdCompetitors.klasha);
        break;
      case "jumpafrica":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.jumpafrica);
        break;
      case "payday":
        setCompetitorFee("1.60%");
        setCompetitorRate(usdCompetitors.payday);
        break;
      case "kiakia":
        setCompetitorFee("Free");
        setCompetitorRate(usdCompetitors.kiakia);
        break;
      case "vella":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.vella);
        break;
      case "kaoshi":
        setCompetitorFee("0.01 - 0.98 USD");
        setCompetitorRate(usdCompetitors.kaoshi);
        break;
      case "wirepay":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.wirepay);
        break;
      case "grey":
        setCompetitorFee("1%");
        setCompetitorRate(usdCompetitors.grey);
        break;
      case "changera":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.changera);
        break;
      case "send":
        setCompetitorFee("1 USD");
        setCompetitorRate(usdCompetitors.send);
        break;
      case "sendcash":
        setCompetitorFee("$40");
        setCompetitorRate(usdCompetitors.sendcash);
        break;
      case "localp2p":
        setCompetitorFee("");
        setCompetitorRate(usdCompetitors.localp2p);
        break;
      default:
        return 0;
    }
  };

  return (
    <div>
      <br />
      <div className="link-container">
        <div>
          <img src={LINK} alt="LINK" className="cr-img1" />
        </div>
        <div>
          <div className="cr-h1 font-700">1 USD = {rate} NGN</div>
          {/* <div
            className="cr-h2 font-300"
            style={{ color: "#2DD06E", textAlign: "right" }}
          >
            saves upto 59%
          </div> */}
          <div
            className="cr-h3 font-600"
            style={{ color: "#fff", textAlign: "right" }}
          >
            0-5% Transfer fees
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center bg-white px-3 py-3"
        style={{ borderRadius: "0px 0px 8px 8px" }}
      >
        <div>
          <SelectExchangeMethod />
        </div>
        <div>
          <div className="cr-h4 font-500">1 USD = {competitorRate} NGN</div>
          <div
            className="cr-h5 font-300"
            style={{ color: "#a4a5a6", textAlign: "right" }}
          >
            {competitorFee}
          </div>
        </div>
      </div>
      <div style={{ height: "20vh" }}></div>
    </div>
  );
};

export default USD;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: window.innerWidth < 992 ? 150 : 200,
    },
  },
};

function SelectExchangeMethod() {
  const competitor = useSelector(selectUsdValue);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setUsdCompetitor({ value }));
  };

  return (
    <div>
      <Select
        value={competitor}
        onChange={handleChange}
        className="font-500 "
        label={false}
        MenuProps={MenuProps}
        style={{ width: window.innerWidth < 992 ? "130px" : "250px" }}
        // style={{ width: "150px" }}
      >
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="grey"
        >
          <img
            src={grey}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "cover" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="wyreout"
        >
          <img
            src={wyreout}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="sendcash"
        >
          <img
            src={sendcash}
            alt=""
            style={{
              objectFit: "cover",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="klasha"
        >
          <img
            src={klasha}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="access"
        >
          <img
            src={access}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "fill" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="afriex"
        >
          <img
            src={Afriex}
            className=""
            alt=""
            style={{
              objectFit: "contain",
              height: "45px",
              width: "200px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="jumpafrica"
        >
          <img
            src={jumpafrica}
            alt=""
            style={{
              objectFit: "contain",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="payday"
        >
          <img
            src={payday}
            alt=""
            style={{
              objectFit: "cover",
              width: "140px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="vella"
        >
          <img
            src={vella}
            alt=""
            style={{
              objectFit: "cover",
              width: "150px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="kaoshi"
        >
          <img
            src={kaoshi}
            alt=""
            style={{
              objectFit: "contain",
              width: "200px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="wirepay"
        >
          <img
            src={wirepay}
            alt=""
            style={{ objectFit: "contain", width: "120px", height: "45px" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="changera"
        >
          <img
            src={changera}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="send"
        >
          <img
            src={send}
            alt=""
            style={{ objectFit: "cover", width: "70px", height: "45px" }}
          />
        </MenuItem>
      </Select>
    </div>
  );
}
