import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Box, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const privacy = () => {
  return (
    <>
      <Container maxWidth={"xl"} className="w-100 logo-container">
        <img src={logo} alt="logo" className="logo" />
      </Container>
      <Container style={{ maxWidth: 650 }} className="mt-2">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/signup">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
        </Box>
      </Container>
      <div>
        <Container style={{ maxWidth: 650 }}>
          <h4
            className="monster-font display-4"
            style={{ fontWeight: 600, fontSize: "50px" }}
          >
            PRIVACY CONDITIONS
          </h4>
          <p>
            This privacy policy describes how we collect, use, store, share and
            protect your personal information. It applies to our website and all
            related sites, applications, services and tools regardless of how
            you access or use them. This Privacy Policy forms part of our
            Customer Agreement. Therefore, whenever you access or use our
            services, website, software, applications, products and content, you
            agree to this Privacy Policy and our Customer Agreement.
          </p>
          <br />

          <p>
            We value the privacy of our Users and as such, we are dedicated to
            safeguarding the information given to us in the ways we know best.
            We carry out our services in strict compliance with the Nigeria Data
            Protection Regulation (NDPR) and the General Data Protection
            Regulation (GDPR).
          </p>
          <br />

          <p>
            This Privacy Policy applies to services that are owned and
            controlled by LINK. We do not have control over other third-party
            sites displayed or linked from within our various services. These
            other sites may place their own cookies, plug-ins or other files on
            your computer, collect data or solicit personal information from
            you. Link does not control these third-party websites and we are not
            responsible for their privacy statements. Please consult such third
            parties’ privacy statements and set appropriate controls from your
            setting when using any third-party services.
          </p>
          <br />

          <dl>
            <dt style={{ fontSize: "20px" }}>1. Data we collect about you</dt>
            <p>We will collect and process the following data about you:</p>
            <ul>
              <li>
                Identity Data: Information such as, your full name(s), your
                government-issued identity number, social security number, and
                your date of birth. This data is to enable us to verify your
                identity in order to offer our services to you;
              </li>
              <li>
                Contact Data: This is data that is needed to reach out to you,
                such as your contact address, email address, telephone number,
                details of the device you use and billing details;
              </li>
              <li>
                Identification documents :(such as your passport or any
                Government-issued identity card), a photograph (if applicable)
                and any other registration information you may provide to prove
                you are eligible to use our services and in compliance with
                regulatory requirements on Know Your Customer (KYC). We may also
                need additional commercial and/or identification information
                from you, for instance, if you execute certain high-value or
                high-volume transactions or when we need to comply with our
                anti-money laundering obligations under applicable law;
              </li>
              <li>
                Log/Technical information: When you access Link services, our
                servers automatically record information that your browser sends
                whenever you visit a website, such as Internet protocol (IP)
                address, browser type and version, links you have clicked on,
                length of visit on certain pages, unique device identifier,
                log-in information, location and other device details.
              </li>
              <li>
                Financial Data: Information, such as Bank Verification Number
                (BVN), personal account number, name and location, the date and
                the total amount of transaction, and other information provided
                by financial institutions or merchants when we act on their
                behalf;
              </li>
              <li>
                Transactional Data: These are information relating to a payment
                when you are using our products or services;
              </li>
              <li>
                Marketing and Communications Data: This includes both a record
                of your decision to subscribe or to withdraw from receiving
                marketing materials from us or from our third parties.
              </li>
              <li>
                Information from Other Sources: We may receive information about
                you if you use any of the other websites we operate or the other
                services we provide. We may also receive information about you
                from third-parties we work with.
              </li>
              <li>
                Information from Social Networks: If you log in to our Services
                through your social media account, we will receive relevant
                information necessary to enable our Services and authenticate
                you. The social media network will provide us with access to
                certain information that you have provided to them, including
                your name, profile image and e-mail address. The information
                gotten from the social network is used together with any other
                information you provided us (when registering or using our
                services) to create your account and to communicate with you
                about the products and services we offer.
              </li>
              <li>
                Records of your discussions with us, if we contact you and if
                you contact us.
              </li>
            </ul>

            <dt style={{ fontSize: "20px" }}>
              2. Purpose of Processing Personal Information
            </dt>
            <p>We process your personal data for the following reasons:</p>
            <ul>
              <li>
                To create and manage any accounts you may have with us, verify
                your identity, provide our services, and respond to your
                inquiries;
              </li>
              <li>
                To enable us carry out our obligations relating to your contract
                with us and to take steps at your request prior to entering into
                such contract;
              </li>
              <li>
                To comply with any applicable legal and/or regulatory
                requirements;  constantly improving our Services and to ensure
                that they are presented in the most effective manner;
              </li>
              <li>
                Measuring or understanding the effectiveness of advertising we
                serve and delivering relevant advertising to you;
              </li>
              <li>
                Protect against and prevent fraud, unauthorized transactions,
                claims and other liabilities;
              </li>
              <li>
                providing you, or permitting selected third parties to provide
                you, with information about goods or services we feel may
                interest you;
              </li>
              <li>
                to perform a task carried out in the public interest (such as
                the prevention of money laundering and terrorism financing).
              </li>
            </ul>

            <dt style={{ fontSize: "20px" }}>3. Cookies</dt>
            <p>
              We use cookies to distinguish you from other Users, provide you
              with a better experience and also to improve our services. For
              more information on the cookies we use and how we use them, see
              our Cookie Policy.
            </p>
            <br />
            <dt style={{ fontSize: "20px" }}>
              4. Disclosure of your information
            </dt>
            <p>We may share your information with third-parties such as:</p>
            <ul>
              <li>
                affiliates, business partners, suppliers and sub-contractors for
                the performance and execution of any contract we enter into with
                them or you;
              </li>
              <li>
                advertisers and advertising networks solely to select and serve
                relevant adverts to you and others;
              </li>
              <li>
                analytics and search engine providers that assist us in the
                improvement and optimisation of our site;
              </li>
              <li>our group entities or subsidiaries.</li>
              <p>
                We may also disclose your information under the following
                circumstances:
              </p>
              <li>
                in the event that we sell or buy any business or assets, we may
                disclose your personal data to the prospective seller or buyer
                of such business or assets;
              </li>
              <li>
                if we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our Customer Agreement and other applicable
                agreements; or to protect the rights, property, or safety of
                LINK, our customers, or others. This includes exchanging
                information with other companies and organisations for the
                purposes of fraud protection and credit risk reduction;
              </li>
              <li>
                to assist us in conducting or co-operating in investigations of
                fraud or other illegal activity where we believe it is
                reasonable and appropriate to do so;
              </li>
              <li>to prevent and detect fraud or crime;</li>
              <li>
                in response to a subpoena, warrant, court order, or as otherwise
                required by law;
              </li>
              <li>to assess financial and insurance risks;</li>
              <li>to recover debt or in relation to your insolvency; and</li>
              <li>to develop customer relationships, services and systems.</li>
            </ul>

            <dt style={{ fontSize: "20px" }}>
              5. International Data Transfers
            </dt>
            <p>
              Where Personal Information is to be transferred to a country
              outside Nigeria and the European Economic Area, we ensure that
              both ourselves and our partners take adequate and appropriate
              technical, physical and organisational security measures to
              protect your data. We ensure that the level of data protection is
              similar to that of the Nigeria Data Protection Regulation (NDPR)
              and the General Data Protection Regulation (GDPR) by having the
              necessary contractual protections in place with these third
              parties.
            </p>
            <br />
            <dt style={{ fontSize: "20px" }}>
              6. How We Protect your Information
            </dt>
            <p>
              LINK has set up security measures to ensure that the integrity and
              confidentiality of Personal Information is not accidentally or
              deliberately compromised. We maintain administrative, technical
              and physical controls designed to protect the Personal Information
              you provide, or we collect against loss or theft, as well as
              against any unauthorized access, risk of loss, disclosure,
              copying, misuse or modification.
            </p>
            <p>
              Other security measures include but not limited to, secure
              servers, firewall, data encryption and granting access only to
              employees in order to fulfil their job responsibilities.
            </p>
            <p>
              It is necessary to point out that no transmission or storage via
              the Internet is completely secure. Therefore, we cannot guarantee
              absolute security of your personal information, you are expected
              to play a role in protecting Personal Information by safeguarding
              your password and login in details. Endeavor to notify LINK of any
              unauthorized use of your account or any breach of security.
            </p>
            <br />

            <dt style={{ fontSize: "20px" }}>7. Data Retention</dt>
            <p>
              We will only retain your information for as long as your account
              is active or as needed to provide our services to you. We might
              also be statutorily obligated to store some of your personal and
              transactional data beyond the closure of your account with us.
              This is done for a certain period of time for the purpose of fraud
              monitoring, detection and prevention. Where the data is no longer
              required by relevant law or the time for retention has elapsed, it
              will be deleted.
            </p>
            <br />
            <dt style={{ fontSize: "20px" }}>
              8. Customer Data Protection Rights
            </dt>
            <ul>
              <li>Right to be informed.</li>
              <li>
                Right to request for and access Personal Information collected
                and stored.
              </li>
              <li>
                Right to correct any Personal Information we hold on you that is
                inaccurate, incorrect, or out of date.
              </li>
              <li>
                Right to request for deletion of Personal Information when it is
                no longer necessary, or no longer subject to a legal obligation
                to which LINK is subject to.
              </li>
              <li>
                Right to ask us not to contact you for marketing purposes by
                adjusting your notification preference on the settings page or
                by opting out via the unsubscribe link in marketing emails we
                send you.
              </li>
              <li>
                Right to request for restriction; this means refrain us from
                doing certain things with your data or restrict the extent of
                our collection or processing of your data. Please note that
                where you object to us processing your Personal Information, we
                might be unable to provide the services to you.
              </li>
              <li>
                Right to transfer your personal data between data controllers,
                for example, to move your account details from one online
                platform to another.
              </li>
            </ul>
            <dt style={{ fontSize: "20px" }}>
              9. Changes to Our Privacy Policy
            </dt>
            <p>
              Any changes we may make to our privacy policy will be posted on
              this page and, where appropriate, notified to you by e-mail.
              Please check back frequently to see any updates or changes to our
              privacy policy.
            </p>
            <br />
            <dt style={{ fontSize: "20px" }}>10. Contact Us</dt>
            <p>
              All questions, comments and requests regarding this privacy policy
              should be addressed to support@linkio.africa.
            </p>
            <br />
          </dl>
        </Container>
      </div>
    </>
  );
};

export default privacy;
