import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const tryWithdrawApi = createApi({
  reducerPath: "tryWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    localBank: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/try/bank",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLocalBankMutation } = tryWithdrawApi;
