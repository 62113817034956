import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/system';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import profile1 from '../../assets/profile1.png';
import profile2 from '../../assets/profile2.png';
import profile3 from '../../assets/profile3.png';
import profile4 from '../../assets/profile4.png';
import { Link, useNavigate } from 'react-router-dom';
import { MenuItem, Select, Typography } from '@mui/material';
import NGN from '../../assets/nigeria.png';
import { useGetVendorsQuery } from '../../services/accRequestApi';

const FundCardVendor = () => {
  const vendors = [
    {
      id: 1,
      account_name: 'victor onyezia',
      account_number: '8180789672',
      bank_name: 'wema bank',
      hashtag: 'Veeqtour',
      image: 'profile1',
      limit: '₦30K - ₦1.2M',
      rate: '759.5',
    },
    {
      id: 2,
      account_name: 'Okemdinachi Nwaejie',
      account_number: '8180789670',
      bank_name: 'wema bank',
      hashtag: 'Retuski',
      image: 'profile2',
      limit: '₦50K - ₦1.4M',
      rate: '759.1',
    },
    {
      id: 3,
      account_name: 'Marvel Ekpo',
      account_number: '8180789248',
      bank_name: 'wema bank',
      hashtag: 'Mjstiles',
      image: 'profile3',
      limit: '₦45K - ₦1.2M',
      rate: '759.3',
    },
    {
      id: 4,
      account_name: 'Christopher okoye',
      account_number: '8180789246',
      bank_name: 'wema bank',
      hashtag: 'Chris_top',
      image: 'profile4',
      limit: '₦45K - ₦1.2M',
      rate: '759.7',
    },
  ];

  // const [vendors, setVendors] = React.useState([]);
  // const { data, isLoading, isFetching } = useGetVendorsQuery();
  // useEffect(() => {
  //   if (!isLoading && !isFetching) {
  //     setVendors(data.vendors);
  //   }
  // }, [data, isLoading, isFetching]);

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container maxWidth="md" className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: 'none' }} to="/fund-card">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container style={{ display: 'flex', maxWidth: 900 }}>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              className="font-700  py-4 mb-2"
              style={{ textAlign: 'left', fontSize: '30px' }}
            >
              Available Vendors
            </div>
            {vendors.map((item) => (
              <div className="vendor-container" key={item.id}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <img src={profile1} alt="activity" className="profile-img" />
                  <div className="py-2">
                    <p className="top-text">@{item.hashtag}</p>
                    <p className="bottom-text">{item.limit}</p>
                  </div>
                </div>
                <div className="py-2">
                  <p className="top-text">$1=₦{item.rate}</p>
                  <p className="bottom-text">Exchange rate</p>
                </div>
                <div className="my-2 font-400 px-3 pt-2 funding">
                  <p>Instant Funding</p>
                </div>
                <Link
                  style={{ textDecoration: 'none' }}
                  className="my-2 font-400 px-4 py-1 d-flex align-items-center fund-btn"
                  to={`/${item.hashtag}-fund-card/fiat/exchange`}
                  state={{
                    rate: item.rate,
                    hashtag: item.hashtag,
                    bankName: item.bank_name,
                    accountNumber: item.account_number,
                    accountName: item.account_name,
                  }}
                >
                  Buy
                </Link>
                {/* <button
                  className="my-2 font-400 px-5 py-1 d-flex align-items-center fund-btn"
                  onClick={handleNext}
                >
                  Buy
                </button> */}
              </div>
            ))}
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default FundCardVendor;
