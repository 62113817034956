import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    waitlist: builder.mutation({
      query: (credentials) => ({
        url: '/user/waitlist',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    signIn: builder.mutation({
      query: (credentials) => ({
        url: '/user/signin',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: '/user/signup',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: `/user/forgot-password`,
        method: 'POST',
        headers: Headers,
        body: { ...credentials },
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: `/user/change-password`,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: `/user/reset-password`,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deactivateAccount: builder.mutation({
      query: (credentials) => ({
        url: `/user/deactivate-account`,
        method: 'DELETE',
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useWaitlistMutation,
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useDeactivateAccountMutation,
} = authApi;
