import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import "../CompareRates.css";
import grey from "../../../assets/grey.png";
import LINK from "../../../assets/LINK.png";
import sendcash from "../../../assets/sendcash.png";
import lemonade from "../../../assets/lemonade.png";
import kiakia from "../../../assets/kiakia.png";
import kyshi from "../../../assets/kyshi.png";
import send from "../../../assets/send.png";
import plumter from "../../../assets/plumter.png";
import chipper from "../../../assets/chipper.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGbpValue,
  setGbpCompetitor,
} from "../../../features/competitorSlice";
import { useGetCompetitorsQuery } from "../../../services/excelApi";

const GBP = () => {
  const competitor = useSelector(selectGbpValue);
  const [gbpCompetitors, setGbpCompetitors] = React.useState({});

  const [competitorRate, setCompetitorRate] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [competitorFee, setCompetitorFee] = React.useState("");

  const { data, isLoading } = useGetCompetitorsQuery("competitors", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    !isLoading && setGbpCompetitors({ ...data?.gbpCompetitors });
  }, [data, isLoading]);

  useEffect(() => {
    handlecompair(competitor);
    setRate(gbpCompetitors.link);
  }, [gbpCompetitors, competitor]);

  const handlecompair = (competitor) => {
    switch (competitor) {
      case "fcmb":
        setCompetitorFee("ATM: 5-8%+£3.5, SWIFT:£30");
        setCompetitorRate(gbpCompetitors.fcmb);
        break;
      case "grey":
        setCompetitorFee("1%");
        setCompetitorRate(gbpCompetitors.grey);
        break;
      case "chipper":
        setCompetitorFee("");
        setCompetitorRate(gbpCompetitors.chipper);
        break;
      case "lemonade":
        setCompetitorFee("Free");
        setCompetitorRate(gbpCompetitors.lemonade);
        break;
      case "localp2p":
        setCompetitorFee("");
        setCompetitorRate(gbpCompetitors.localp2p);
        break;
      case "jumpafrica":
        setCompetitorFee("");
        setCompetitorRate(gbpCompetitors.jumpafrica);
        break;
      case "klasha":
        setCompetitorFee("Free");
        setCompetitorRate(gbpCompetitors.klasha);
        break;
      case "payday":
        setCompetitorFee("1.60%");
        setCompetitorRate(gbpCompetitors.payday);
        break;
      case "kaoshi":
        setCompetitorFee("0.01 - 0.98 GBP");
        setCompetitorRate(gbpCompetitors.kaoshi);
        break;
      case "kyshi":
        setCompetitorFee("1%");
        setCompetitorRate(gbpCompetitors.kyshi);
        break;
      case "kiakia":
        setCompetitorFee("Free");
        setCompetitorRate(gbpCompetitors.kiakia);
        break;
      case "plumter":
        setCompetitorFee("Free");
        setCompetitorRate(gbpCompetitors.plumter);
        break;
      case "send":
        setCompetitorFee("1 GBP");
        setCompetitorRate(gbpCompetitors.send);
        break;
      case "sendcash":
        setCompetitorFee("");
        setCompetitorRate(gbpCompetitors.sendcash);
        break;

      default:
        return 0;
    }
  };
  return (
    <div>
      <br />
      <div className="link-container">
        <div>
          <img src={LINK} alt="LINK" className="cr-img1" />
        </div>
        <div>
          <div className="cr-h1 font-700">1 GBP = {rate} NGN</div>
          {/* <div
            className="cr-h2 font-300"
            style={{ color: "#2DD06E", textAlign: "right" }}
          >
            saves upto 59%
          </div> */}
          <div
            className="cr-h3 font-600"
            style={{ color: "#fff", textAlign: "right" }}
          >
            0-5% Transfer fees
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center bg-white px-3 py-3"
        style={{ borderRadius: "0px 0px 8px 8px" }}
      >
        <div>
          <SelectExchangeMethod />
        </div>
        <div>
          <div className="cr-h4 font-500">1 GBP = {competitorRate} NGN</div>
          <div
            className="cr-h5 font-300"
            style={{ color: "#a4a5a6", textAlign: "right" }}
          >
            {competitorFee}
          </div>
        </div>
      </div>
      <div style={{ height: "20vh" }}></div>
    </div>
  );
};

export default GBP;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: window.innerWidth < 992 ? "130px" : "250px",
    },
  },
};

function SelectExchangeMethod() {
  const competitor = useSelector(selectGbpValue);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setGbpCompetitor({ value }));
  };

  return (
    <div>
      <Select
        value={competitor}
        onChange={handleChange}
        className="font-500  "
        label={false}
        MenuProps={MenuProps}
        style={{ width: window.innerWidth < 992 ? 130 : 250 }}
      >
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="grey"
        >
          <img
            src={grey}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "cover" }}
          />
        </MenuItem>
        <hr className="m-0" />

        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="send"
        >
          <img
            src={send}
            alt=""
            style={{ objectFit: "cover", width: "70px", height: "45px" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="sendcash"
        >
          <img
            src={sendcash}
            alt=""
            style={{
              objectFit: "cover",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="lemonade"
        >
          <img
            src={lemonade}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "fill" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="kiakia"
        >
          <img
            src={kiakia}
            alt=""
            style={{
              objectFit: "contain",
              width: "180px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-700"
          value="localp2p"
          style={{ height: 50 }}
        >
          Local Peer-To-Peer
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="kyshi"
        >
          <img
            src={kyshi}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="plumter"
        >
          <img
            src={plumter}
            alt=""
            style={{
              objectFit: "cover",
              width: "150px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="chipper"
        >
          <img
            src={chipper}
            alt=""
            style={{
              objectFit: "contain",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
      </Select>
    </div>
  );
}
