import { Container, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import ImageGallery from "react-image-gallery";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/arrow-left.png";
import blackCard from "../../assets/blackcard.png";
import blueCard2 from "../../assets/bluecard2.png";
import greenCard from "../../assets/greencard.png";
import USD from "../../assets/usa.png";
import AccountButton from "../../components/AccountButton";
import MobileNavbarTwo from "../../components/MobileNavbarTwo";
import { selectCurrentUserId } from "../../features/auth/authSlice";
import { useCreateCardMutation } from "../../services/accountCardApi";
import "./Cards.css";
import LeftPanel from "./components/LeftPanel";

const images = [
  {
    original:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851350/LINK/Virtual%20Cards/bluecard2_x5cnau.png",
    thumbnail:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851350/LINK/Virtual%20Cards/bluecard2_x5cnau.png",
  },
  {
    original:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851344/LINK/Virtual%20Cards/greencard_vburwi.png",
    thumbnail:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851344/LINK/Virtual%20Cards/greencard_vburwi.png",
  },
  // {
  //   original:
  //     "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851343/LINK/Virtual%20Cards/blackcard_rosssv.png",
  //   thumbnail:
  //     "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851343/LINK/Virtual%20Cards/blackcard_rosssv.png",
  // },
];

const CardCreate = () => {
  const navigate = useNavigate();
  const [cardImg, setCardImg] = React.useState(
    "https://res.cloudinary.com/dqw0lwkil/image/upload/v1669851350/LINK/Virtual%20Cards/bluecard2_x5cnau.png"
  );
  const [currency, setCurrency] = React.useState("USD");

  const Id = useSelector(selectCurrentUserId);
  const [createCard] = useCreateCardMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading("Creating Card...");
    try {
      await createCard({
        Id,
        currency,
        cardDesign: cardImg,
      }).unwrap();
      toast.success("Card Created!", {
        id: submitToast,
      });
      navigate("/card-req-received");
    } catch (error) {
      toast.error(error.data.message, {
        id: submitToast,
      });
      console.log(error);
      // navigate("/card-req-not-received");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <MobileNavbarTwo
            activeColor1="#696F79"
            activeColor2="#1565DB"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#696F79"
            iconColor2="#1565DB"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </div>
        <Row>
          <Col lg={2}>
            <LeftPanel
              activeColor1="#696F79"
              activeColor2="#1565DB"
              activeColor3="#696F79"
              activeColor4="#696F79"
              activeColor5="#696F79"
              iconColor1="#696F79"
              iconColor2="#1565DB"
              iconColor3="#696F79"
              iconColor4="#696F79"
              iconColor5="#696F79"
            />
          </Col>
          <Col lg={10} className="primary-bg">
            <div className="sm-none">
              <AccountButton />
            </div>
            <Container maxWidth="xl" className="idv-box1">
              <div style={{ maxWidth: 700 }}>
                <div className="md-none">
                  <div
                    className="font-600"
                    style={{ fontSize: 18, color: "#1565db" }}
                    onClick={() => navigate("/")}
                  >
                    <img
                      src={backArrow}
                      style={{ width: 24, marginRight: 4 }}
                      alt=""
                    />
                    Go back
                  </div>
                </div>
                <Typography className="font-500 fs-1 my-2 ">
                  Create Card
                </Typography>

                <Box className="sm-none">
                  <ImageGallery
                    items={images}
                    thumbnailPosition="right"
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    disableThumbnailScroll={true}
                    useTranslate3D={false}
                    showNav={false}
                    originalHeight={324}
                    original
                    onThumbnailClick={(e) => {
                      setCardImg(e.target.src);
                    }}
                  />
                </Box>
                <Box className="md-none">
                  <ImageGallery
                    items={images}
                    thumbnailPosition="bottom"
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    disableThumbnailScroll={true}
                    useTranslate3D={false}
                    showNav={false}
                    originalHeight={324}
                    original
                    onThumbnailClick={(e) => {
                      setCardImg(e.target.src);
                    }}
                  />
                </Box>
                <br />
                <div className="my-2">
                  <div className="input-h1 fs-6">Select Currency</div>
                  <Select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="font-500"
                    label={false}
                    fullWidth
                    required
                    style={{ background: "white" }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="USD"
                    >
                      <img
                        style={{
                          height: 42,
                          width: 72,
                          marginRight: 20,
                          objectFit: "contain",
                        }}
                        src={USD}
                        alt="flag"
                      />
                      USD
                    </MenuItem>
                    <hr className="m-0" />
                  </Select>
                </div>
                <div>
                  <div className="text-center">
                    <button className="wn-btn1" type="submit">
                      {/* <img
                        src={tickSquare}
                        alt="nigeria"
                        style={{ height: 24, width: 24, marginRight: 20 }}
                      /> */}
                      <span>Request card</span>
                    </button>
                  </div>
                </div>
              </div>
            </Container>
            <br />
            <div style={{ height: "5vh" }}></div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default CardCreate;

// class MyGallery extends React.Component {
//   render() {
//     return (
//       <ImageGallery
//         items={images}
//         thumbnailPosition="right"
//         showPlayButton={false}
//         showFullscreenButton={false}
//         useBrowserFullscreen={false}
//         disableThumbnailScroll={true}
//         useTranslate3D={false}
//         showNav={false}
//         originalHeight={324}
//         onThumbnailClick={(e) => {
//           console.log(e.target.src);
//         }}

//       />
//     );
//   }
// }

// class MyGalleryTwo extends React.Component {
//   render() {
//     return (
//       <ImageGallery
//         items={images}
//         showPlayButton={false}
//         showFullscreenButton={false}
//         useBrowserFullscreen={false}
//         disableThumbnailScroll={true}
//         useTranslate3D={false}
//         showNav={false}
//       />
//     );
//   }
// }
