import React from 'react';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import danger from '../../assets/danger.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import '../../screens/transaction/Transaction.css';
import { selectCurrentUserId } from '../../features/auth/authSlice';
import { useFundCardWalletMutation } from '../../services/accountCardApi';

const FundCardReview = () => {
  const location = useLocation();
  const { fund, sent, bankName, accountNumber, accountName } = location.state;

  const navigate = useNavigate();
  const userId = useSelector(selectCurrentUserId);
  const [fundCard] = useFundCardWalletMutation();

  const ref = Math.random().toString(36).slice(2, 29);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading('Processing funding request');
    try {
      await fundCard({
        userId,
        amountFund: fund,
        amountSent: sent,
        custRef: ref,
        vendorName: accountName,
        vendorNumber: accountNumber,
      }).unwrap();
      toast.success('Card Fund Request successful!', { id: toastSubmit });
      navigate('/wallet-fund-card-success');
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      // navigate('/withdrawal-failed');
    }
  };

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container maxWidth="md" className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link
              style={{ textDecoration: 'none' }}
              to="/fund-card/fiat/vendors"
            >
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <div
              className="font-700 ro-h1 py-4"
              style={{ textAlign: 'center' }}
            >
              Send the exact NGN amount to the account below
            </div>
            <Box className="ro-box1 mb-3">
              <div className="font-500 ro-h2">Amount:</div>
              <div className="font-500 ro-h1 bold">₦{sent}</div>
            </Box>
            <Box className="ro-box1 mb-3">
              <div className="font-500 ro-h2">Account Name: </div>
              <div className="font-500 ro-h1">{accountName}</div>
            </Box>
            <Box className="ro-box1 mb-3">
              <div className="font-500">
                <div className="ro-h2">Account Number:</div>
                <div className="ro-h2">Bank Name:</div>
              </div>
              <div className="font-700">
                <div className="ro-h1">{accountNumber}</div>
                <div className="ro-h1">{bankName} </div>
              </div>
            </Box>
            <Box className="ro-box1 mb-3">
              <div className="font-500 ro-h2">Reference code:</div>
              <div className="font-500 ro-h1">{ref}</div>
            </Box>
            <Box
              className="ro-box1 mb-3"
              style={{
                fontSize: 14,
                background: '#E7EFFF',
                color: '#1856AF',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div className="me-4">
                <img src={danger} style={{ height: 30, width: 36 }} alt="" />
              </div>
              <p
                style={{
                  fontWeight: 'bold',
                }}
              >
                {/* Please don’t add any crypto phrase or your transfer will not be
                be processed. <br /> */}
                Please ensure the transfer details (account name) match the name
                on your LINK account or your request won’t be processed.
              </p>
            </Box>
            <button
              style={{
                width: '50%',
                padding: '10px 9px',
                fontSize: '20px',
                backgroundColor: '#30ae4d',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                marginBottom: '1rem',
                marginTop: '1rem',
                marginLeft: '25%',
              }}
              onClick={handleSubmit}
            >
              Complete
            </button>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default FundCardReview;
