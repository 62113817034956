import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import cloud from "../../assets/cloud.png";
import cross from "../../assets/cross.png";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const ReqReceived = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <div className="d-flex justify-content-between align-items-center">
          <img src={logo} alt="logo" />
          <Link to="/dashboard">
            <img src={closeBtn} alt="close" style={{ cursor: "pointer" }} />
          </Link>
        </div>
      </Container>
      <Box className="wnr-box1">
        <div className="text-center font-500 wn-h1 ">
          Withdrawal request not received
        </div>
        <Box className="d-flex justify-content-center py-2">
          <Box
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "#FF4B55",
              height: 300,
              width: 300,
              marginTop: 40,
              borderRadius: "100%",
            }}
          >
            <img src={cross} alt="cloud" />
          </Box>
        </Box>
        <Box className="my-5">
          <Typography variant="h6" className="text-center monster-font">
            There seems to be a problem.
          </Typography>
          <Typography variant="h6" className="text-center monster-font">
            Please contact &nbsp;
            <span style={{ color: "#1565D8" }}>support@linkup.io</span>
            <div style={{ height: "15vh" }}></div>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ReqReceived;
