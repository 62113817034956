import React from "react";
import logo from "../../../assets/logo.png";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import info from "../../../assets/info.png";
import { Box, Container } from "@mui/system";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import "../../transaction/Transaction.css";
import { useSelector } from "react-redux";

import {
  selectCurrentRecipientName,
  selectCurrentYouRecieve,
  selectCurrentYouSend,
  selectCurrentRef,
} from "../../../features/transferSlice";

const NgnReviewOrder = () => {
  const send = useSelector(selectCurrentYouSend);
  const recieve = useSelector(selectCurrentYouRecieve);
  const ref = useSelector(selectCurrentRef);
  const name = useSelector(selectCurrentRecipientName);

  const numSeparator = (num) => {
    let separator = num.toString();
    // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return separator;
  };

  const navigate = useNavigate();

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container maxWidth="md" className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: "none" }} to="/transfer-ngn">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container
          maxWidth="md"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div>
            <div className="font-500 wn-h1">Review your order</div>
            <Box className="ro-box1">
              <div className="font-500">
                <div className="ro-h2">You send</div>
                <div className="ro-h2">They get</div>
              </div>
              <div className="font-700">
                <div className="ro-h1">{Number(send)} NGN</div>
                <div className="ro-h1">{recieve} NGN</div>
              </div>
            </Box>
            <br />
            <Box className="ro-box2">
              <div className="font-500 ro-h2">Recipient Name</div>
              <div className="font-500 ro-h1">{name}</div>
            </Box>
            <br />
            <Box className="ro-box1">
              <div className="font-500 ro-h2">Reference code</div>
              <div
                className="font-500 ro-h1"
                style={{ textTransform: "uppercase" }}
              >
                {ref}
              </div>
            </Box>
            <div className="text-center" style={{ maxWidth: 800 }}>
              <Link to="/transfer-method">
                <button className="wn-btn1">Continue</button>
              </Link>
            </div>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default NgnReviewOrder;
