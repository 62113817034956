import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import cloud from "../../assets/cloud.png";
import tick from "../../assets/tick.png";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const CryptoFundReq = () => {
  return (
    <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box className="wr-box1">
        <Typography className="text-center font-500 wr-h1">
          Card fund request received
        </Typography>
        <Box className="d-flex justify-content-center py-2">
          <Box className="wr-img1">
            <img src={tick} alt="cloud" />
          </Box>
        </Box>
        <p className="text-center text-2">
          Once we receive your money we’ll notify you and fund your card
        </p>
      </Box>
      <Container className="text-center">
        <Link to="/cards">
          <button className="wr-btn1">Done</button>
        </Link>
        <div style={{ height: "15vh" }}></div>
      </Container>
    </div>
  );
};

export default CryptoFundReq;
