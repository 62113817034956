/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import info from "../../assets/info.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, Redirect } from "react-router-dom";
import ngn from "../../assets/nigeria.png";
import ghs from "../../assets/ghs.png";
import zar from "../../assets/zar.svg";
import bwp from "../../assets/bwp.svg";
import rwf from "../../assets/rwf.png";
import kes from "../../assets/kes.svg";
import tzs from "../../assets/tzs.svg";
import { useSelector } from "react-redux";
import { useSignUpMutation } from "../../services/authApi";
import toast from "react-hot-toast";
import {
  selectCurrentToken,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import axios from "axios";
import { url, url3 } from "../../api";

const Signup = () => {
  useEffect(() => {
    window.location.href = "https://www.linkio.africa/wavy";
    return null;
  });

  const [type, setType] = React.useState("password");
  const [show, setShow] = React.useState("Show");
  const [firstName, setFirstName] = React.useState("");
  const [surName, setSurName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [refCode, setRefCode] = React.useState("");
  const [linkTag, setLinkTag] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [curr, setCurr] = React.useState("NGN");
  const [linkTagCheck, setLinkTagCheck] = React.useState("");
  const [error, setError] = React.useState("");

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleCheckLinkTag();
  }, [linkTag]);

  useEffect(() => {
    if (token && userId) return navigate("/dashboard");
  }, [token, userId]);

  const [signup] = useSignUpMutation();

  const handleCheckLinkTag = async () => {
    try {
      const { data } = await axios.get(
        `${url}/user/verify-linktag?tag=${linkTag}`
      );
      setLinkTagCheck(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const handlePasswordCheck = () => {
    if (password === "" || confirmPassword === "") {
      return setError("");
    }
    if (password !== confirmPassword) {
      return setError("❌ Passwords do not match");
    }
    if (password === confirmPassword) {
      return setError("✅ Passwords match");
    }
  };

  useEffect(() => {
    handlePasswordCheck();
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Signing up...");

    try {
      const userData = await signup({
        firstName,
        surName,
        email,
        refCode,
        linkTag,
        password,
        account_currency: curr,
      }).unwrap();
      // console.log(userData);
      toast.success(userData.message, { id: toastSubmit });
      // handleOpen();
      navigate("/signin");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      // console.log(error);
    }
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="sm-none">
        <Container maxWidth={"xl"} className="w-100 logo-container ">
          <img src={logo} alt="logo" className="logo" />
        </Container>
      </div>
      <div className="md-none ">
        <Container maxWidth={"xl"} className="w-100 shadow">
          <div>
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Container>
      </div>
      <Container>
        <Box className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <img src={backArrow} alt="backArrow" className="backArrow" />
          </div>
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <span className="rco-h2">Cancel</span>
              <img src={closeBtn} alt="closeBtn" className="rco-img2" />
            </button>
          </Link>
        </Box>
      </Container>
      <form onSubmit={handleSubmit}>
        <div className="primary-bg h-100">
          <Box>
            <Container>
              <Typography className="wr-h1 font-500">Hey there!</Typography>
              <Typography
                className="wr-h1 font-500"
                style={{ color: "#696F79" }}
              >
                Let's get to know you!
              </Typography>
              <br />
              <Box
                className="mb-3 py-2 px-2"
                style={{
                  background: "#E7EFFF",
                  color: "#1856AF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={info}
                  style={{ height: 30, width: 36, marginRight: 10 }}
                  alt=""
                />
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Ensure your name is the same as it is on your BVN, we’ll need
                  it for your verification
                </p>
              </Box>

              <Row className="justify-content-between align-items-center mt-3">
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Your firstname</div>
                  <input
                    className="input-req w-100"
                    placeholder="Enter firstname"
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </Col>
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Your surname</div>
                  <input
                    className="input-req w-100"
                    placeholder="Enter surname"
                    type="text"
                    onChange={(e) => setSurName(e.target.value)}
                    value={surName}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between align-items-center mt-3">
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Email address</div>
                  <input
                    className="input-req w-100"
                    placeholder="Enter email address"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Col>
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Create LINK Tag</div>
                  <input
                    className="input-req w-100"
                    placeholder="Create a LINK Tag"
                    type="text"
                    onChange={(e) => setLinkTag(e.target.value)}
                    value={linkTag}
                  />
                  <p
                    style={{
                      fontSize: "15px",
                      color: "#878b91",
                    }}
                  >
                    {linkTagCheck}
                  </p>
                </Col>
              </Row>
              {/* <Row className="justify-content-between align-items-center mt-3">
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Referral Code</div>
                  <input
                    className="input-req w-100"
                    placeholder="Enter referral code"
                    type="text"
                    onChange={(e) => setRefCode(e.target.value)}
                    value={refCode}
                  />
                </Col>
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Account currency</div>
                  <div>
                    <Select
                      value={curr}
                      onChange={(e) => setCurr(e.target.value)}
                      className="font-500 border"
                      label={false}
                      fullWidth
                      style={{ background: 'white', height: 50 }}
                    >
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        value="NGN"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={ngn}
                          alt="flag"
                        />
                        NGN
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="GHS"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={ghs}
                          alt="flag"
                        />
                        GHS
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="ZAR"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={zar}
                          alt="flag"
                        />
                        ZAR
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="BWP"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={bwp}
                          alt="flag"
                        />
                        BWP
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="RWF"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={rwf}
                          alt="flag"
                        />
                        RWF
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="KES"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={kes}
                          alt="flag"
                        />
                        KES
                      </MenuItem>
                      <hr className="m-0" />
                      <MenuItem
                        className="d-flex align-items-center px-3 font-500"
                        // value="TZS"
                      >
                        <img
                          style={{ height: 42, width: 42, marginRight: 20 }}
                          src={tzs}
                          alt="flag"
                        />
                        TZS
                      </MenuItem>
                    </Select>
                  </div>
                </Col>
              </Row> */}
              <Row className="justify-content-between align-items-center mt-3">
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Create password</div>
                  <div style={{ position: "relative" }}>
                    <input
                      className="input-req w-100"
                      placeholder="Enter a secure password"
                      onChange={(e) => setPassword(e.target.value)}
                      type={type}
                      value={password}
                    />
                    <div
                      className="font-600"
                      style={{
                        fontSize: 12,
                        position: "absolute",
                        right: 16,
                        top: 30,
                        display: password.length > 0 ? "block" : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (type === "password") {
                          setType("text");
                          setShow("Hide");
                        } else {
                          setType("password");
                          setShow("Show");
                        }
                      }}
                    >
                      {show}
                    </div>
                  </div>
                </Col>
                <Col className="my-1" xs={12} lg={6}>
                  <div className="input-h1">Confirm password</div>
                  <input
                    className="input-req w-100"
                    placeholder="Confirm password"
                    type={"password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    onKeyPress={handleKeypress}
                  />
                  <p>{error}</p>
                </Col>
              </Row>
              <br />
            </Container>
            <Container className="text-center ">
              <div>
                <div className="font-600">
                  <div className="topping mx-2">
                    <input
                      type="checkbox"
                      id="topping"
                      name="topping"
                      value="Paneer"
                      className="mx-2"
                    />
                    <span>I agree to the </span>
                    <span
                      onClick={() => navigate("/terms-and-condition")}
                      style={{ color: "#1565D8", cursor: "pointer" }}
                      className="mx-1"
                    >
                      Terms
                    </span>
                    <span>and</span>
                    <span
                      onClick={() => navigate("/privacy")}
                      style={{ color: "#1565D8", cursor: "pointer" }}
                      className="mx-1"
                    >
                      Privacy Policy
                    </span>
                  </div>
                </div>
                {/* <div className="md-none">
                  <div style={{ height: "20vh" }}></div>
                </div> */}
              </div>
            </Container>
          </Box>
          <Container className="text-center ">
            {/* <div className="sm-none"> */}
            <button className="wn-btn1" type="submit">
              Sign up
            </button>
            {/* </div> */}

            {/* <div className="md-none">
            <SignPop />
          </div> */}
            {/* <button className="wn-btn1">Sign up</button> */}
            <div>
              <div className="font-600">
                <span>Already have an account?</span>
                <span
                  onClick={() => navigate("/signin")}
                  style={{ color: "#1565D8", cursor: "pointer" }}
                  className="mx-1"
                >
                  Login
                </span>
              </div>
              <div className="md-none">
                <div style={{ height: "20vh" }}></div>
              </div>
            </div>

            {/* <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Verify close={handleClose} />
            </Box>
          </Modal> */}
            <div style={{ height: "15vh" }}></div>
          </Container>
        </div>
      </form>
    </>
  );
};

export default Signup;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F3F4F6",
  border: "none",
  boxShadow: 24,
  p: 4,
  height: window.innerHeight < 700 ? window.innerHeight - 100 : 685,
  overflowY: "scroll",
};
