import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ExportIcon from "../../assets/icons/ExportIcon";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import RecipientPop from "./components/RecipientPop";
import LeftPanel from "../cards/components/LeftPanel";
import AccountButton from "../../components/AccountButton";
import trash from "../../assets/trash.png";
import MobileNavbarTwo from "../../components/MobileNavbarTwo";
import addcircle from "../../assets/addcircle.png";
import {
  useGetRecipientQuery,
  useDeleteRecipientMutation,
} from "../../services/recipientApi";
import { useDispatch, useSelector } from "react-redux";
import { setTransaction } from "../../features/transactionSlice";
import Converter from "../../components/converter/Converter";
import { selectCurrentUserId } from "../../features/auth/authSlice";

const Recipient = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [recipients, setRecipients] = React.useState(null);
  const userId = useSelector(selectCurrentUserId);

  const { data } = useGetRecipientQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setRecipients(data);
  }, [data, open]);

  const handleDispatchDetails = (fullName, bankName, iban) => {
    handleOpen();
    dispatch(setTransaction({ fullName, iban, bankName }));
  };

  const [deleteRecipient] = useDeleteRecipientMutation();

  const handleDelete = async (id, userid) => {
    try {
      const recipient = await deleteRecipient({ id, userId }).unwrap();
      console.log(recipient);
    } catch (error) {}
  };

  return (
    <Box>
      <div>
        <MobileNavbarTwo
          activeColor1="#696F79"
          activeColor2="#696F79"
          activeColor3="#696F79"
          activeColor4="#1565DB"
          activeColor5="#696F79"
          iconColor1="#696F79"
          iconColor2="#696F79"
          iconColor3="#696F79"
          iconColor4="#1565DB"
          iconColor5="#696F79"
        />
      </div>
      <Row style={{ overflowX: "hidden" }}>
        <Col lg={2}>
          <LeftPanel
            activeColor1="#696F79"
            activeColor2="#696F79"
            activeColor3="#696F79"
            activeColor4="#1565DB"
            activeColor5="#696F79"
            iconColor1="#696F79"
            iconColor2="#696F79"
            iconColor3="#696F79"
            iconColor4="#1565DB"
            iconColor5="#696F79"
          />
        </Col>
        <Col style={{ minHeight: "120vh" }} className="primary-bg">
          <div className="sm-none">
            <AccountButton />
          </div>
          <Container maxWidth="xl" className="idv-box1">
            <Typography className="fs-1 font-500">Recipients</Typography>
            <br />
            {recipients?.map((recipient, index) => (
              <div
                className="sm-none"
                key={index}
                style={{ paddingTop: "10px" }}
              >
                <Box
                  className="d-flex align-items-center"
                  style={{ maxWidth: 1200 }}
                >
                  <Button
                    variant={"contained"}
                    style={{
                      minWidth: 0,
                      padding: 9.5,
                      background: "white",
                      marginRight: 10,
                      height: 55,
                      width: 55,
                    }}
                    onClick={() => handleDelete(recipient.id, recipient.userId)}
                  >
                    <img src={trash} alt="" style={{ height: 24, width: 24 }} />
                  </Button>
                  <div
                    onClick={() =>
                      handleDispatchDetails(
                        recipient.fullName,
                        recipient.bankName,
                        recipient.iban
                      )
                    }
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{
                      background: "white",
                      padding: "13px 18px",
                      borderRadius: 4,
                      boxShadow: "1px 2.5px 4px rgba(0,0,0,.25)",
                      cursor: "pointer",
                    }}
                  >
                    <div className="font-500 r-h1">{recipient.fullName}</div>
                    <div className="font-600 r-h2">{recipient.type}</div>
                    <ExportIcon iconColor="#000" className="r-ic1" />
                  </div>
                  <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="left-panel-popup">
                      {/* <Transfer handleClose={handleClose} /> */}
                      <Converter handleClose={handleClose} />
                    </Box>
                  </Modal>
                </Box>
              </div>
            ))}
            <div className="md-none">
              <RecipientPop />
            </div>
            <Link to="/add-recipient" style={{ textDecoration: "none" }}>
              <Box
                style={{
                  color: "#696F79",
                  border: "2px dashed #C4C4C4",
                  borderRadius: 8,
                  maxWidth: 1200,
                }}
                className="my-4 font-600 fs-6 py-3 d-flex justify-content-center align-items-center"
              >
                <img src={addcircle} className="me-2" alt="" />
                Add Recipient
              </Box>
            </Link>
          </Container>
        </Col>
      </Row>
    </Box>
  );
};

export default Recipient;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#F3F4F6",
  border: "none",
  boxShadow: 24,
  p: 5,
  height: 675,
  overflowY: "scroll",
  borderRadius: 3,
};
