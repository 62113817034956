import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: null,
};

export const accRequestSlice = createSlice({
  name: "accRequest",
  initialState,
  reducers: {
    setPhaseOne: (state, action) => {
      const { intAccount } = action.payload;
      state.currency = intAccount;
    },
    clearData: (state) => {
      state.idImage = null;
    },
  },
});

export const { setPhaseOne, clearData } = accRequestSlice.actions;
export default accRequestSlice.reducer;

export const selectCurrentCurrency = (state) => state.accRequest.currency;
export const selectCurrentUserImage = (state) => state.accRequest.idImage;
export const selectCurrentAllData = (state) => state.accRequest;
