import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const recipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.value = action.payload;
    },
    clear: (state) => {
      state.value = null;
    },
  },
});

export const { setData, clear } = recipientSlice.actions;
export default recipientSlice.reducer;

export const selectCurrentValue = (state) => state.recipient.value;
