import React from "react";

const ExportIcon = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.168 12.8342L24.7346 3.26758"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6664 7.93398V2.33398H20.0664"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.832 2.33398H10.4987C4.66536 2.33398 2.33203 4.66732 2.33203 10.5007V17.5007C2.33203 23.334 4.66536 25.6673 10.4987 25.6673H17.4987C23.332 25.6673 25.6654 23.334 25.6654 17.5007V15.1673"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;
