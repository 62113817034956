import React from "react";

const StatsIcon = (p) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02734 21.1748V18.7598"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14 21.1757V16.3457"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.9727 21.1746V13.918"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.974 6.82422L19.4373 7.45422C16.4623 10.9309 12.4723 13.3926 8.02734 14.5009"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5547 6.82422H19.973V10.2309"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4987 25.6673H17.4987C23.332 25.6673 25.6654 23.334 25.6654 17.5007V10.5007C25.6654 4.66732 23.332 2.33398 17.4987 2.33398H10.4987C4.66536 2.33398 2.33203 4.66732 2.33203 10.5007V17.5007C2.33203 23.334 4.66536 25.6673 10.4987 25.6673Z"
        stroke={p.iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StatsIcon;
