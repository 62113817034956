import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import cloud from "../../assets/cloud.png";
import cross from "../../assets/cross.png";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const DepositReqNotReceived = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <div className="mx-5 mt-5 sm-none">
        <img src={logo} alt="logo" />
      </div>

      <Container className="idv-box1">
        <div className="d-flex justify-content-between align-items-center">
          <div></div>
          <Link to="/dashboard">
            <img src={closeBtn} alt="close" style={{ cursor: "pointer" }} />
          </Link>
        </div>
        <Typography className="text-center font-700 wr-h1 mt-3">
          Account deposit request not received
        </Typography>
        <Box className="d-flex justify-content-center py-2">
          <Box
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "#FF4B55",
              height: 185,
              width: 185,
              marginTop: 20,
              borderRadius: "100%",
            }}
          >
            <img src={cross} alt="cloud" />
          </Box>
        </Box>
        <Box className="my-5">
          <Typography variant="h6" className="text-center font-600 ">
            There seems to be a problem.
          </Typography>
          <Typography variant="h6" className="text-center font-600 ">
            Please contact &nbsp;
            <span style={{ color: "#1565D8" }}>support@linkup.io</span>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default DepositReqNotReceived;
