import { Box, Button, Container } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import info from "../../assets/info.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import userAdd from "../../assets/user-add.png";
import { setData } from "../../features/physicalDeliverySlice";
import { useDispatch } from "react-redux";

const ReceiverAdress = () => {
  const [fullName, setFullName] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [address, setaddress] = React.useState("");
  const [region, setRegion] = React.useState("");

  const navigate = useNavigate();
  const dispach = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispach(setData({ name: fullName, email, phone, address, region }));
    navigate("/review-order");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100">
        <div>
          <MobileNavbarOne />
        </div>
        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <h1 className="font-500 wr-h1">Receiver's address</h1>
            <br />
            <Row className="justify-content-between align-items-center">
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Recipient name</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req w-100"
                    placeholder={"Recipient name"}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </Col>
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Region</div>
                <select
                  className="input-req w-100"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option> select a region</option>
                  <option value={1}>Famagusta</option>
                  <option value={2}>Girne</option>
                  <option value={3}>Güzelyurt</option>
                  <option value={4}>Iskele</option>
                  <option value={5}>Lefke</option>
                  <option value={6}>Nicosia</option>
                </select>
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center">
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Recipient address</div>
                <input
                  className="input-req w-100"
                  placeholder="Enter recipient address"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                />
                <div className="sm-none font-600" style={{ opacity: 0 }}>
                  <div
                    style={{
                      maxWidth: 500,
                      background: "#E7EFFF",
                      color: "#1856AF",
                    }}
                    className="d-flex justify-content-between  py-2 px-4 rounded"
                  >
                    <img src={info} className="me-1" />
                    The phone number provided should be an active one
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6} className="my-3">
                <div className="input-h1 fs-6">Recipient phone number</div>
                <input
                  className="input-req w-100"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
                <div className=" font-600">
                  <div
                    style={{
                      maxWidth: 500,
                      background: "#E7EFFF",
                      color: "#1856AF",
                    }}
                    className="d-flex justify-content-between align-items-center py-2 px-4 rounded"
                  >
                    <img src={info} className="me-1" />
                    The phone number provided should be an active one
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center">
              <Col xs={12} lg={12} className="my-3">
                <div className="input-h1 fs-6">Recipient email address</div>
                <input
                  className="input-req w-100"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Col>
            </Row>
            <div className="text-center md-none">
              <Button
                variant="contained"
                style={{ background: "white", color: "black" }}
              >
                <img src={userAdd} className="me-2" />
                Add to recipients
              </Button>
            </div>
            <div className="text-center">
              <button className="wn-btn1">Continue</button>
            </div>
          </Container>
        </Box>
      </div>
    </form>
  );
};

export default ReceiverAdress;
