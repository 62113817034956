import { Box, Button, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import info from "../../../assets/info.png";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { setName } from "../../../features/transferSlice";
import { setEur } from "../../../features/transferRouteSlice";

const OutEurope = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban_AccountNumber, setIban_AccountNumber] = React.useState("");
  const [swift_BicCode, setSwift_BicCode] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(
      setEur({
        fullName,
        bankName,
        iban_AccountNumber,
        swift_BicCode,
        transactionType: "Outside",
      })
    );
    navigate("/review-eur-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     iban: iban_AccountNumber,
    //     swiftCode: swift_BicCode,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        className="my-5 font-500 d-flex align-items-center p-2 rounded"
        style={{
          fontSize: 13,
          background: "#E7EFFF",
          color: "#1856AF",
        }}
      >
        <img src={info} alt="info" className="me-2" />
        To send EUR outside Europe, we’ll need to use the SWIFT network. The
        transfer may take 2-5 days longer. Other banks involved may also charge
        fees.
      </Box>
      <Row className="d-flex justify-content-between align-items-center">
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">
            Full name of account holder
          </div>
          <input
            className="input-add-recipient"
            value={fullName}
            placeholder="Enter account holder name"
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">Bank name</div>
          <input
            className="input-add-recipient"
            value={bankName}
            placeholder="Enter Bank name"
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-between align-items-center">
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">IBAN / AccountNumber</div>
          <input
            className="input-add-recipient"
            value={iban_AccountNumber}
            placeholder="Enter IBAN"
            required
            onChange={(e) => setIban_AccountNumber(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} md={6}>
          <div className="label-input-add-recipient">SWIFT / BIC code</div>
          <input
            className="input-add-recipient"
            value={swift_BicCode}
            placeholder="Enter Swift / BIC code"
            required
            onChange={(e) => setSwift_BicCode(e.target.value)}
          />
        </Col>
      </Row>

      <Container className="text-center">
        <Row className=" justify-content-between align-items-center">
          <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
            <div className="text-center">
              <Button
                variant="contained"
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 15px",
                }}
                onClick={handleAddRecipient}
              >
                <img src={userAdd} className="me-2" alt="" />
                Add to recipients
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center">
              <button className="wn-btn1" type="submit">
                Continue
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default OutEurope;
