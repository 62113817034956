import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: undefined,
  routes: undefined,
  bankName: undefined,
  iban: undefined,
  nationality: undefined,
  country: undefined,
  city: undefined,
  address: undefined,
  postCode: undefined,
  institutionNumber: undefined,
  transitNumber: undefined,
  accountType: undefined,
  accountNumber: undefined,
  email: undefined,
  transactionType: undefined,
  iban_AccountNumber: undefined,
  swift_BicCode: undefined,
  sortCode: undefined,
  networkProvider: undefined,
  phoneNumber: undefined,
  swiftCode: undefined,
  routingNumber: undefined,
  cardNumber: undefined,
};

export const transferRouteSlice = createSlice({
  name: "transferRoute",
  initialState,
  reducers: {
    setUsd: (state, action) => {
      const {
        fullName,
        bankName,
        accountNumber,
        postCode,
        address,
        accountType,
        city,
        routingNumber,
        country,
        iban_AccountNumber,
        swift_BicCode,
        iban,
      } = action.payload;
      state.fullName = fullName;
      state.accountNumber = accountNumber;
      state.accountType = accountType;
      state.routingNumber = routingNumber;
      state.country = country;
      state.city = city;
      state.postCode = postCode;
      state.address = address;
      state.bankName = bankName;
      state.iban_AccountNumber = iban_AccountNumber;
      state.swift_BicCode = swift_BicCode;
      state.iban = iban;
    },
    setEur: (state, action) => {
      const {
        fullName,
        bankName,
        iban,
        iban_AccountNumber,
        swift_BicCode,
        transactionType,
      } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban_AccountNumber = iban_AccountNumber;
      state.swift_BicCode = swift_BicCode;
      state.iban = iban;
      state.transactionType = transactionType;
    },
    setCad: (state, action) => {
      const {
        fullName,
        transitNumber,
        bankName,
        accountNumber,
        accountType,
        routingNumber,
        email,
        transactionType,
      } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.accountNumber = accountNumber;
      state.accountType = accountType;
      state.routingNumber = routingNumber;
      state.transitNumber = transitNumber;
      state.email = email;
      state.transactionType = transactionType;
    },
    setGbp: (state, action) => {
      const {
        fullName,
        transactionType,
        bankName,
        iban,
        accountNumber,
        sortCode,
      } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban = iban;
      state.accountNumber = accountNumber;
      state.sortCode = sortCode;
      state.transactionType = transactionType;
    },
    setAed: (state, action) => {
      const {
        fullName,
        bankName,
        iban,
        country,
        city,
        address,
        postCode,
        nationality,
      } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban = iban;
      state.country = country;
      state.city = city;
      state.address = address;
      state.postCode = postCode;
      state.nationality = nationality;
    },
    setChf: (state, action) => {
      const { fullName, bankName, iban } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban = iban;
    },
    setNgn: (state, action) => {
      const { fullName, bankName, accountNumber } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.accountNumber = accountNumber;
    },
    setCnh: (state, action) => {
      const { fullName, cardNumber, iban, country, city, postCode } =
        action.payload;
      state.fullName = fullName;
      state.cardNumber = cardNumber;
      state.iban = iban;
      state.country = country;
      state.city = city;
      state.postCode = postCode;
    },
    setTry: (state, action) => {
      const { fullName, bankName, address, iban, country, city, postCode } =
        action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.iban = iban;
      state.country = country;
      state.city = city;
      state.postCode = postCode;
      state.address = address;
    },
    setKes: (state, action) => {
      const {
        fullName,
        bankName,
        address,
        accountNumber,
        country,
        city,
        postCode,
        phoneNumber,
        networkProvider,
        transactionType,
      } = action.payload;
      state.fullName = fullName;
      state.bankName = bankName;
      state.accountNumber = accountNumber;
      state.country = country;
      state.city = city;
      state.postCode = postCode;
      state.address = address;
      state.phoneNumber = phoneNumber;
      state.networkProvider = networkProvider;
      state.transactionType = transactionType;
    },
    clear: (state) => {
      state.fullName = null;
      state.routes = null;
      state.bankName = null;
      state.iban = null;
      state.nationality = null;
      state.country = null;
      state.city = null;
      state.address = null;
      state.postCode = null;
      state.institutionNumber = null;
      state.transitNumber = null;
      state.accountType = null;
      state.accountNumber = null;
      state.email = null;
      state.transactionType = null;
      state.iban_AccountNumber = null;
      state.swift_BicCode = null;
      state.sortCode = null;
      state.networkProvider = null;
      state.phoneNumber = null;
      state.swiftCode = null;
      state.routingNumber = null;
      state.cardNumber = null;
    },
  },
});

export const {
  setAed,
  setCad,
  setChf,
  setCnh,
  setEur,
  setGbp,
  setKes,
  setNgn,
  setTry,
  setUsd,
  clear,
} = transferRouteSlice.actions;
export default transferRouteSlice.reducer;

export const selectAllTransferData = (state) => state.transferRoute;
