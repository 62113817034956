import { Container, fontSize } from "@mui/system";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import close from "../../assets/close-square.png";
import email from "../../assets/email.png";
import logo from "../../assets/logo.png";

const Error404 = (p) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "100px",
      }}
    >
      <div
        className="text-center"
        style={{ fontSize: "4rem", fontWeight: "bolder" }}
      >
        404
      </div>
      <br />
      <br />
      <div
        className="text-center pt-1"
        style={{ fontSize: "2.2rem", fontWeight: "bolder" }}
      >
        Page Not Found
      </div>
      <div
        className="text-center font-600 py-4"
        style={{ color: "#A4A5A6", fontSize: "1.3rem" }}
      >
        You probably clicked a link that was <br />
        outdated or broken.
      </div>
      <Container className="text-center">
        <button className="wn-btn1" onClick={() => navigate("/dashboard")}>
          Go to home
        </button>
      </Container>
    </div>
  );
};

export default Error404;
