import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // send: null,
  recieve: null,
  ref: null,
  name: null,
};

export const physicalDeliverySlice = createSlice({
  name: "physicalDelivery",
  initialState,
  reducers: {
    setData: (state, action) => {
      const { send, recieve, ref, name } = action.payload;
      // state.send = send;
      state.recieve = recieve;
      state.ref = ref;
      state.name = name;
    },
    clear: (state) => {
      // state.send = null;
      state.recieve = null;
      state.ref = null;
      state.name = null;
    },
  },
});

export const { setData, clear } = physicalDeliverySlice.actions;
export default physicalDeliverySlice.reducer;

export const selectCurrentSend = (state) => state.physicalDelivery.send;
export const selectCurrentRecieve = (state) => state.physicalDelivery.recieve;
export const selectCurrentRef = (state) => state.physicalDelivery.ref;
export const selectCurrentName = (state) => state.physicalDelivery.name;
