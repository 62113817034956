import React, { useEffect } from 'react';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import { Col, Row } from 'react-bootstrap';
import cloud from '../../assets/cloud.png';
import NIN from '../../assets/NIN.png';
import NINSLIP from '../../assets/NINSLIP.png';
// import BVN from "../../assets/BVN.png";
import DL from '../../assets/DL.png';
import shieldTick from '../../assets/shield-tick.png';
import ngn from '../../assets/nigeria.png';
import ghs from '../../assets/ghs.png';
import zar from '../../assets/zar.svg';
import bwp from '../../assets/bwp.svg';
import rwf from '../../assets/rwf.png';
import kes from '../../assets/kes.svg';
import tzs from '../../assets/tzs.svg';
import { Box, Container, MenuItem, Select } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import calendar from '../../assets/calendar.png';
import ReactFileReader from 'react-file-reader';
import {
  useAccVerifyMutation,
  useVerifyStatusMutation,
} from '../../services//accVerifyApi';
import {
  selectCurrentAccLock,
  selectCurrentToken,
  selectCurrentUserId,
} from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { url2 } from '../../api';
import '../ReqAccOne/ReqAccOne.css';

const IDVerification = () => {
  const [issueDate, setIssueDate] = React.useState('2020-01-01');
  const [idNumber, setIDNumber] = React.useState('');
  const [issueCountry, setIssueCountry] = React.useState(
    'Select Country of Issue'
  );
  const [idType, setIdType] = React.useState('Select ID Type');
  const [idDocument, setIdDocument] = React.useState('');
  const [bvn, setBvn] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [occupation, setOccupation] = React.useState('');
  const [dateOfBirth, setDateOfBirth] = React.useState('2020-01-01');

  const [bvnError, setBvnError] = React.useState('');
  const [docsError, setDocsError] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);
  const accLock = useSelector(selectCurrentAccLock);

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !userId) return navigate('/signin');
    if (token && userId && accLock === 'verified')
      return navigate('/dashboard');
    if (accLock === 'verifying') return navigate('/dashboard-unverified');
  });

  const [accVerify] = useAccVerifyMutation();
  const [verifyStatus] = useVerifyStatusMutation();

  const handleFiles = (files) => {
    setIdDocument(files.base64);
  };

  useEffect(() => {
    checkIdType();
    checkIDNumber();
    checkPhoneNumber();
  }, [idType, bvn, phoneNumber]);

  const checkIDNumber = () => {
    if (bvn.length !== 11) return setBvnError('🚫 BVN must be 11 digits');
    if (bvn.length === 11) return setBvnError('✅ BVN has 11 digits');
  };

  const checkPhoneNumber = () => {
    if (phoneNumber.length !== 13)
      return setPhoneError('🚫 Phone Number must be 11 digits');
    if (phoneNumber.length === 13)
      return setPhoneError('✅ Phone Number has 11 digits');
  };

  const checkIdType = () => {
    if (idType === 'NIGERIAN_NIN') {
      return setDocsError('🚫 Input only virtual NIN number.');
    } else {
      return setDocsError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    checkIDNumber();
    navigate('/address-verification', {
      state: {
        idType,
        idNumber,
        issueDate,
        issueCountry,
        idDocument,
        phoneNumber,
        dateOfBirth,
        occupation,
        bvn,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100">
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link
                style={{ textDecoration: 'none' }}
                to="/dashboard-unverified"
              >
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go back to dashboard</span>
                </button>
              </Link>
              <Link
                style={{ textDecoration: 'none' }}
                to="/dashboard-unverified"
              >
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <div className="font-500 wn-h1">Identity verification</div>
            <div className="d-flex align-items-center">
              <img src={shieldTick} className="idv-img1" alt="" />
              <span className="idv-p1">
                This information is for identity verification only, and is kept
                private and confidential by LINK IO.
              </span>
            </div>
            <Row className="justify-content-between align-items-center mt-4">
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1">BVN</div>
                <input
                  className="input-req idv-input1"
                  placeholder={'BVN must be 11 digits'}
                  required
                  value={bvn}
                  type="number"
                  min="0"
                  onChange={(e) => setBvn(e.target.value)}
                />
                <p>{bvnError}</p>
              </Col>

              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1" style={{ marginTop: '0.5rem' }}>
                  Date of birth
                </div>
                <div
                  className="d-flex mb-4 align-items-center"
                  style={{ position: 'relative' }}
                >
                  <input
                    className="input-req me-3 w-100"
                    placeholder={'Select Date of Birth'}
                    value={dateOfBirth}
                    required
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    style={{ height: 50 }}
                  />
                  <input
                    type="date"
                    className="date-input"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    style={{
                      border: 'none',
                      borderRadius: 4,
                      filter: 'drop-shadow(0px 3px 1px rgba(0,0,0,0.25))',
                      background: 'white',
                    }}
                  />
                  {/* <div>
                    <img
                      src={calendar}
                      alt="arrowDown"
                      style={{ position: "absolute", top: 26, right: 9 }}
                    />
                  </div> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-between align-items-center">
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1" style={{ marginTop: '0.5rem' }}>
                  ID Type
                </div>
                <div>
                  <Select
                    value={idType}
                    onChange={(e) => setIdType(e.target.value)}
                    className="font-500"
                    required
                    label={false}
                    style={{
                      width: '100%',
                      background: 'white',
                      border: 'none',
                    }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Select ID Type"
                    >
                      Select ID Type
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="NIGERIAN_INTERNATIONAL_PASSPORT"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 10 }}
                        src={NINSLIP}
                        alt="flag"
                      />
                      NIGERIAN_INTERNATIONAL_PASSPORT
                    </MenuItem>
                    <hr className="m-0" />
                    {/* <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="NIGERIAN_PVC"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 10 }}
                        src={DL}
                        alt="flag"
                      />
                      NIGERIAN_PVC
                    </MenuItem>
                    <hr className="m-0" /> */}
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="NIGERIAN_DRIVERS_LICENSE"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 10 }}
                        src={DL}
                        alt="flag"
                      />
                      NIGERIAN_DRIVERS_LICENSE
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="NIGERIAN_NIN"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 10 }}
                        src={NIN}
                        alt="flag"
                      />
                      NIGERIAN_NIN
                    </MenuItem>
                  </Select>
                </div>
              </Col>

              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1" style={{ marginTop: '0.5rem' }}>
                  Country of Issue
                </div>
                <div>
                  <Select
                    value={issueCountry}
                    onChange={(e) => setIssueCountry(e.target.value)}
                    className="font-500"
                    required
                    label={false}
                    fullWidth
                    style={{ background: 'white' }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Select Country of Issue"
                    >
                      Select Country of Issue
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Nigeria"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={ngn}
                        alt="flag"
                      />
                      Nigeria
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Ghana"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={ghs}
                        alt="flag"
                      />
                      Ghana
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="South africa"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={zar}
                        alt="flag"
                      />
                      South Africa
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Botswana"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={bwp}
                        alt="flag"
                      />
                      Botswana
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Rwanda"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={rwf}
                        alt="flag"
                      />
                      Rwanda
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Kenya"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={kes}
                        alt="flag"
                      />
                      Kenya
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Tanzania"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={tzs}
                        alt="flag"
                      />
                      Tanzania
                    </MenuItem>
                  </Select>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-between align-items-center">
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1" style={{ marginTop: '0.5rem' }}>
                  ID Number
                </div>
                <input
                  className="input-req idv-input1"
                  placeholder={'Enter ID number'}
                  required
                  value={idNumber}
                  onChange={(e) => setIDNumber(e.target.value)}
                />
                <p style={{ color: 'blue' }}>{docsError}</p>
              </Col>

              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1" style={{ marginTop: '0.5rem' }}>
                  Date of Issue
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ position: 'relative' }}
                >
                  <input
                    className="input-req me-3 w-100"
                    placeholder={'Select Date of Issue'}
                    required
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                    style={{ height: 50 }}
                  />
                  <input
                    type="date"
                    className="date-input"
                    onChange={(e) => setIssueDate(e.target.value)}
                    style={{
                      border: 'none',
                      borderRadius: 4,
                      filter: 'drop-shadow(0px 3px 1px rgba(0,0,0,0.25))',
                      background: 'white',
                    }}
                  />
                  {/* <div>
                    <img
                      src={calendar}
                      alt="arrowDown"
                      style={{ position: "absolute", top: 26, right: 9 }}
                    />
                  </div> */}
                </div>
              </Col>
            </Row>

            <Row className="mb-3 justify-content-between align-items-center">
              <Col>
                <div className="input-h1 idv-h1">
                  Upload a good photo of your ID. Make sure we can see all the
                  four edges. <b>Note: PDF's are not allowed</b>
                </div>
                <Box className="idv-upload">
                  <div className="d-flex align-items-center">
                    <img
                      src={cloud}
                      alt="cloud"
                      className="me-3"
                      style={{ height: 36 }}
                    />
                    <div>
                      <div className="font-600">
                        Upload verification file here
                      </div>
                      <div
                        className="font-600"
                        style={{ color: '#888', fontSize: 14 }}
                      >
                        Max. file size: 10MB
                      </div>
                    </div>
                  </div>
                  <div>
                    <ReactFileReader
                      fileTypes={['.png', '.jpg']}
                      base64={true}
                      handleFiles={handleFiles}
                    >
                      <label
                        htmlFor="file"
                        style={{
                          fontWeight: 600,
                          color: 'black',
                          boxShadow: '0px 4px 4px rgba(0,0,0,.25)',
                          cursor: 'pointer',
                          minWidth: 200,
                        }}
                        className={`ms-4  ${
                          idDocument ? 'bg-primary text-white' : 'bg-white'
                        } font-700  border rounded p-2 d-flex justify-content-center align-items-center`}
                      >
                        {idDocument ? 'Uploaded' : 'Browse files'}
                      </label>
                    </ReactFileReader>
                  </div>
                </Box>
              </Col>
            </Row>

            <Row className="justify-content-between align-items-center">
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1">Occupation</div>
                <input
                  className="input-req idv-input1"
                  placeholder={'Politician'}
                  required
                  value={occupation}
                  type="text"
                  onChange={(e) => setOccupation(e.target.value)}
                />
              </Col>

              <Col className="idv-col1" xs={12} md={6}>
                <div className="input-h1">Phone number</div>
                <input
                  className="input-req idv-input1"
                  placeholder={'234 809 769 2506'}
                  required
                  value={phoneNumber}
                  type="number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <p>{phoneError}</p>
              </Col>
            </Row>
          </Container>
        </Box>
        <Container className="text-center">
          <button className="idv-btn1" type="submit">
            Next
          </button>
        </Container>
      </div>
    </form>
  );
};

export default IDVerification;
