import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import "./Transaction.css";
import { Link, useNavigate } from "react-router-dom";
import info from "../../assets/info.png";
import Modal from "@mui/material/Modal";
import close from "../../assets/close.png";
import additem from "../../assets/additem.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import userAdd from "../../assets/user-add.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import { useDispatch, useSelector } from "react-redux";
// import { setTransaction } from "../../features/transactionSlice";
import {
  selectCurrentFullName,
  selectCurrentBankName,
  selectCurrentIban,
} from "../../features/transactionSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F3F4F6",
  border: "2px solid #F3F4F6",
  boxShadow: 24,
  p: 4,
};

const Transaction = () => {
  const recipientFullName = useSelector(selectCurrentFullName);
  const recipientBankName = useSelector(selectCurrentBankName);
  const recipientIban = useSelector(selectCurrentIban);

  const [name, setName] = React.useState(recipientFullName || "");
  const [iban, setIban] = React.useState(recipientIban || "");
  const [swift, setSwift] = React.useState("");
  const [bankName, setBankName] = React.useState(recipientBankName || "");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [state, setState] = React.useState({
    top: false,
  });
  const navigate = useNavigate();
  const dispach = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // dispach(setData({ name, iban, swift, bankName }));
    navigate("/review-order");
  };
  return (
    <form className="primary-bg h-100" onSubmit={handleSubmit}>
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: "none" }} to="/recipient">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container>
          <div className="font-600 wn-h1">Receiver's bank information</div>
          <Row className="justify-content-between align-items-center">
            <Col className="my-3" xs={12} lg={6}>
              <div className="input-h1">Full Name of account holder</div>
              <div className="d-flex align-items-center">
                <input
                  className="input-req w-100"
                  value={name}
                  required
                  style={{ textTransform: "capitalize" }}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </Col>
            <Col className="my-3" xs={12} lg={6}>
              <div className="input-h1">Bank name</div>
              <select
                className="input-req w-100"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              >
                <option>Select Bank</option>
                <option value="AKBANK T.A.S.">AKBANK T.A.S.</option>
                <option value="TURKIYE GARANTI BANKASI A.S.">
                  TURKIYE GARANTI BANKASI A.S.
                </option>
                <option value="TURKIYE IS BANKASI A.S.">
                  TURKIYE IS BANKASI A.S.
                </option>
                <option value="TURKIYE HALK BANKASI A.S.">
                  TURKIYE HALK BANKASI A.S.
                </option>
              </select>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col className="my-3" xs={12} lg={6}>
              <div className="input-h1">IBAN</div>
              <input
                className="input-req w-100"
                value={iban}
                required
                onChange={(e) => setIban(e.target.value)}
              />
            </Col>
            <Col className="my-3" xs={12} lg={6}>
              <div className="d-flex align-items-center">
                <div className="input-h1 me-3">SWIFT Code</div>
                <button
                  className="sm-none font-500"
                  variant="contained"
                  style={{
                    background: "#fff",
                    color: "#1565db",
                    fontSize: 12,
                    padding: 4,
                    filter: "drop-shadow(0px 3px 1px rgba(0,0,0,0.25))",
                  }}
                  onClick={handleOpen}
                >
                  CHECK
                </button>
                <div className="md-none">
                  <SwiftCode />
                </div>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <SwiftCodes handleClose={handleClose} />
                  </Box>
                </Modal>
              </div>
              <input
                className="input-req w-100"
                value={swift}
                onChange={(e) => setSwift(e.target.value)}
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-center font-600">
            <div
              style={{ maxWidth: 800, background: "#E7EFFF", color: "#1856AF" }}
              className="d-flex justify-content-between align-items-center p-2 px-4 my-3 rounded"
            >
              <img src={info} className="me-4" alt="" />
              The recipient bank account should be the currency of requested
              foreign currency
            </div>
          </div>
          <div className="text-center md-none">
            <Button
              variant="contained"
              style={{ background: "white", color: "black" }}
            >
              <img src={userAdd} className="me-2" alt="" />
              Add to recipients
            </Button>
          </div>
          <div className="text-center">
            <button className="wn-btn1" type="submit">
              Continue
            </button>

            <div style={{ height: "10vh" }}></div>
          </div>
        </Container>
      </Box>
    </form>
  );
};

export default Transaction;

const SwiftCodes = (p) => {
  return (
    <>
      <div className="d-flex justify-content-end">
        <img
          src={close}
          alt="close"
          onClick={p.handleClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Typography className="font-700 fs-4">SWIFT CODES</Typography>
      </div>
      <Box className="my-4">
        <div className="d-flex justify-content-between align-items-center bg-white p-3 rounded">
          <div>
            <div className="py-1 font-700">AKBANK T.A.S.</div>
            <div className="py-1 font-700" style={{ color: "#1976D2" }}>
              AKBKTRISXXX
            </div>
          </div>
          <div
            style={{ background: "#DBE7FF", borderRadius: "100%", padding: 12 }}
          >
            <img
              src={additem}
              alt="additem"
              style={{ height: 28, width: 28 }}
            />
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-between align-items-center bg-white p-3 rounded">
          <div>
            <div className="py-1 font-700">AKBANK T.A.S.</div>
            <div className="py-1 font-700" style={{ color: "#1976D2" }}>
              AKBKTRISXXX
            </div>
          </div>
          <div
            style={{ background: "#DBE7FF", borderRadius: "100%", padding: 12 }}
          >
            <img
              src={additem}
              alt="additem"
              style={{ height: 28, width: 28 }}
            />
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-between align-items-center bg-white p-3 rounded">
          <div>
            <div className="py-1 font-700">AKBANK T.A.S.</div>
            <div className="py-1 font-700" style={{ color: "#1976D2" }}>
              AKBKTRISXXX
            </div>
          </div>
          <div
            style={{ background: "#DBE7FF", borderRadius: "100%", padding: 12 }}
          >
            <img
              src={additem}
              alt="additem"
              style={{ height: 28, width: 28 }}
            />
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-between align-items-center bg-white p-3 rounded">
          <div>
            <div className="py-1 font-700">AKBANK T.A.S.</div>
            <div className="py-1 font-700" style={{ color: "#1976D2" }}>
              AKBKTRISXXX
            </div>
          </div>
          <div
            style={{ background: "#DBE7FF", borderRadius: "100%", padding: 12 }}
          >
            <img
              src={additem}
              alt="additem"
              style={{ height: 28, width: 28 }}
            />
          </div>
        </div>
        <br />
      </Box>
    </>
  );
};

function SwiftCode() {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="primary-bg">
        <div className="d-flex justify-content-center">
          <Typography className="font-500 fs-5 mt-3">SWIFT CODES</Typography>
        </div>
        <br />
        <Box>
          <div className="d-flex justify-content-between align-items-center bg-white mx-4 px-2 py-2 rounded">
            <div>
              <div className=" font-500">AKBANK T.A.S.</div>
              <div className=" font-500" style={{ color: "#1976D2" }}>
                AKBKTRISXXX
              </div>
            </div>
            <div
              style={{
                background: "#DBE7FF",
                borderRadius: "100%",
                padding: 12,
              }}
            >
              <img
                src={additem}
                alt="additem"
                style={{ height: 18, width: 18 }}
              />
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center bg-white mx-4 px-2 py-2 rounded">
            <div>
              <div className=" font-500">AKBANK T.A.S.</div>
              <div className=" font-500" style={{ color: "#1976D2" }}>
                AKBKTRISXXX
              </div>
            </div>
            <div
              style={{
                background: "#DBE7FF",
                borderRadius: "100%",
                padding: 12,
              }}
            >
              <img
                src={additem}
                alt="additem"
                style={{ height: 18, width: 18 }}
              />
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center bg-white mx-4 px-2 py-2 rounded">
            <div>
              <div className=" font-500">AKBANK T.A.S.</div>
              <div className=" font-500" style={{ color: "#1976D2" }}>
                AKBKTRISXXX
              </div>
            </div>
            <div
              style={{
                background: "#DBE7FF",
                borderRadius: "100%",
                padding: 12,
              }}
            >
              <img
                src={additem}
                alt="additem"
                style={{ height: 18, width: 18 }}
              />
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center bg-white mx-4 px-2 py-2 rounded">
            <div>
              <div className="font-500">AKBANK T.A.S.</div>
              <div className="font-500" style={{ color: "#1976D2" }}>
                AKBKTRISXXX
              </div>
            </div>
            <div
              style={{
                background: "#DBE7FF",
                borderRadius: "100%",
                padding: 12,
              }}
            >
              <img
                src={additem}
                alt="additem"
                style={{ height: 18, width: 18 }}
              />
            </div>
          </div>
          <br />
        </Box>
      </List>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              background: "#fff",
              color: "#1565db",
              boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
            }}
            onClick={toggleDrawer(anchor, true)}
          >
            Check
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
