import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Secure from "../../assets/secure-ques.svg";
import {
  selectCurrentToken,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import { useForgotPasswordMutation } from "../../services/authApi";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);

  const navigate = useNavigate();

  useEffect(() => {
    // if (token && userId) return navigate("/dashboard");
  }, [token, userId]);

  const [forgotPassword] = useForgotPasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Submitting...");

    try {
      const userData = await forgotPassword({ email }).unwrap();
      toast.success("A reset link has been sent to your email", {
        id: toastSubmit,
      });

      navigate("/signin");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <div className="">
        <form
          onSubmit={handleSubmit}
          className="box"
          style={{ margin: "0 1rem" }}
        >
          <div className="primary-bg">
            <div className="iconBlock">
              <img src={Secure} alt="info" className="icon" />
            </div>
            <div className="textCenter">
              <h2 className="heading">Forgot password</h2>
              <p className="details">
                Enter your email and we’ll send you an email to reset your
                password.
              </p>
            </div>
            <div style={{ marginTop: "2rem" }}>
              <div className="input-h1">Email</div>
              <input
                className="input-req"
                style={{ width: "100%" }}
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              />
            </div>
            <div>
              <button
                className="wn-btn1"
                type="submit"
                style={{ width: "100%", marginTop: "1rem" }}
              >
                Submit
              </button>
            </div>
            <div className="text-center">
              <Link
                to="/signin"
                className="ms-1 font-500"
                style={{ color: "#1565D8", textDecoration: "none" }}
              >
                Go back to login
              </Link>
            </div>
            <div style={{ height: "15vh" }}></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
