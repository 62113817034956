/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import logo from "../../assets/logo.png";
import React from "react";
import "../transaction/Transaction.css";
import bank from "../../assets/bank.png";
import wallet from "../../assets/wallet.svg";
import { Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import card from "../../assets/card.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import toast from "react-hot-toast";
import { useTransferMutation } from "../../services/transferApi";
import { selectAllTransferData } from "../../features/transferRouteSlice";
import { useSelector } from "react-redux";
import {
  selectCurrentYouSend,
  selectCurrentYouRecieve,
  selectCurrentRef,
  selectCurrentRoutes,
  selectCurrentCurrency,
} from "../../features/transferSlice";
import {
  selectCurrentUserId,
  selectCurrentFirstName,
  selectCurrentFullName,
  selectCurrentEmail,
} from "../../features/auth/authSlice";
import Connect from "@mono.co/connect.js";

const TransferMethod = () => {
  const navigate = useNavigate();
  const transferData = useSelector(selectAllTransferData);
  const userId = useSelector(selectCurrentUserId);
  const senderName = useSelector(selectCurrentFullName);
  const senderEmail = useSelector(selectCurrentEmail);
  const amountSend = useSelector(selectCurrentYouSend);
  const amountReceived = useSelector(selectCurrentYouRecieve);
  const currency = useSelector(selectCurrentCurrency);
  const routes = useSelector(selectCurrentRoutes);
  const ref = useSelector(selectCurrentRef);
  const [transfer] = useTransferMutation();

  let response;
  let sent = amountSend * 100;
  sent = sent.toString();

  const deposit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "mono-sec-key": "live_sk_w0hC1N6stAF0h3xxzL20",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      amount: sent,
      type: "onetime-debit",
      description: "Transfer Deposit",
      reference: ref,
      redirect_url: "app.linkio.africa/dashboard",
      meta: {
        reference: ref,
      },
    }),
  };

  const handleMonoTransfer = async () => {
    // eslint-disable-next-line no-unused-vars
    try {
      response = await fetch(
        "https://api.withmono.com/v1/payments/initiate",

        deposit
      ).then((response) => response.json());

      const monoWidget = new Connect({
        key: "live_pk_YE21jmzgAm5CvcTNZPAb",
        scope: "payments",
        data: {
          payment_id: response?.id,
        },
        onSuccess: (chargeObject) => {
          // console.log(chargeObject);
          // console.log(chargeObject.data.status);
          handleTransfer(chargeObject);
          // toast.success("Transfer Successful");
        },
      });

      monoWidget.setup();
      monoWidget.open();
    } catch (err) {
      // console.log(err.message, err.data, "Not going through");
      // toast.error("Not going through", { id: loadingToast }),
    }
  };

  const handleTransfer = async (chargeObject) => {
    const loadingToast = toast.loading("Completing Transfer...");
    // console.log(chargeObject.data.status);
    try {
      if ((chargeObject.data.status = "successful")) {
        const response = await transfer({
          ...transferData,
          ref,
          routes,
          userId,
          currency,
          amountSend,
          senderName,
          senderEmail,
          amountReceived,
          transferType: "forward",
          status: "processing",
        }).unwrap();
        toast.success("Transfer Completed", { id: loadingToast });
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      toast.error("Transfer Not completed. Please Try Again", {
        id: loadingToast,
      });
      navigate("/dashboard");
    }
  };

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container>
          <div className="font-700 wn-h1" style={{ margin: "rem 0 " }}>
            How do you want to pay for your Transfer?
          </div>
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
              cursor: "pointer",
            }}
            className="pt-box1"
            onClick={handleMonoTransfer}
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={bank} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div style={{ marginTop: "0.7rem" }}>
                <div className="font-600 pt-h1">
                  <h5>Direct debit (Bank transfer)</h5>
                  <p
                    style={{
                      color: "rgb(154, 156, 172)",
                      fontSize: "16px",
                      fontWeight: "lighter",
                    }}
                  >
                    Transfer the money to LINK from your bank
                  </p>
                </div>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
              cursor: "not-allowed",
            }}
            className="pt-box1"
            // onClick={() => navigate("mobile")}
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={card} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div>
                <div style={{ marginTop: "0.7rem" }}>
                  <div className="font-600 pt-h1">
                    <h5>Debit card</h5>
                    <p
                      style={{
                        color: "rgb(154, 156, 172)",
                        fontSize: "16px",
                        fontWeight: "lighter",
                      }}
                    >
                      Pay for your transfer online with a debit card
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
              cursor: "not-allowed",
            }}
            className="pt-box1"
            // onClick={() => navigate("mobile")}
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={wallet} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div>
                <div style={{ marginTop: "0.7rem" }}>
                  <div className="font-600 pt-h1">
                    <h5>LINK NGN Wallet</h5>
                    <p
                      style={{
                        color: "rgb(154, 156, 172)",
                        fontSize: "16px",
                        fontWeight: "lighter",
                      }}
                    >
                      Pay for your transfer directly with your Naira wallet
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
        </Container>
      </Box>
    </div>
  );
};

export default TransferMethod;
