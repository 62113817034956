import { Button, Container, Grid } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
import info from "../../../assets/info.png";
import { useCadBankMutation } from "../../../services/transferApi";
import { useDispatch, useSelector } from "react-redux";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { setCad } from "../../../features/transferRouteSlice";

const CadLocal = () => {
  const [fullName, setFullName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [institutionNumber, setInstitutionNumber] = React.useState("");
  const [transitNumber, setTransitNumber] = React.useState("");
  const [bankName, setBankName] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setCad({
        fullName,
        accountNumber,
        accountType,
        institutionNumber,
        transitNumber,
        bankName,
        transactionType: "localBank",
      })
    );
    navigate("/review-ngn-cad-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <div className="d-flex  font-600">
          {/* <div
            style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14,  marginBottom: "20px", }}
            className="d-flex  align-items-center p-2 rounded "
          >
            <img src={info} className="me-2" alt="" />
            Currently we can apply Canadian dollars into bank accounts in
            Canada, and not other currencies such as US dollars.
          </div> */}
          <div
            style={{
              background: "#E7EFFF",
              Gridor: "#1856AF",
              fontSize: 14,
              marginBottom: "20px",
            }}
            className="d-flex  align-items-center p-2 rounded "
          >
            <img src={info} className="me-2" alt="" />
            Transfering to Canada via local bank is currenly not available!
            please use the interac method to send money to Canada.
          </div>
        </div>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Full name of account holder</div>
            <input
              className="input-req w-100"
              placeholder="Enter account holder name"
              value={fullName}
              required
              disabled
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className="my-1"
            style={{ marginLeft: "auto" }}
          >
            <div className="input-h1">Bank name</div>
            <input
              className="input-req w-100"
              placeholder="Enter bank name"
              value={bankName}
              required
              disabled
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Institution number</div>
            <input
              className="input-req w-100"
              placeholder="Enter institution number"
              value={institutionNumber}
              required
              disabled
              onChange={(e) => setInstitutionNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Transit number</div>
            <input
              className="input-req w-100"
              placeholder="Enter transit number"
              value={transitNumber}
              required
              disabled
              onChange={(e) => setTransitNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account type</div>
            <input
              className="input-req w-100"
              placeholder="Select account type"
              value={accountType}
              required
              disabled
              onChange={(e) => setAccountType(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account number</div>
            <input
              className="input-req w-100"
              placeholder="Enter account number"
              value={accountNumber}
              required
              disabled
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </Grid>
        </Grid>

        <Row className=" justify-content-between align-items-center">
          <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
            <div className="text-center">
              <Button
                variant="contained"
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 15px",
                }}
                onClick={handleAddRecipient}
              >
                <img src={userAdd} className="me-2" alt="" />
                Add to recipients
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center">
              <button className="wn-btn1" type="submit" disabled>
                Continue
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default CadLocal;
