import { Button, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
import { useDispatch, useSelector } from "react-redux";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { setName } from "../../../features/transferSlice";
import { setGbp } from "../../../features/transferRouteSlice";

const GbpAcc = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [sortCode, setSortCode] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(
      setGbp({
        fullName,
        bankName,
        accountNumber,
        sortCode,
        transactionType: "Local Bank",
      })
    );
    navigate("/review-gbp-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     sortCode,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className="justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Full name of account holder</div>
          <input
            className="input-req w-100"
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Bank name</div>
          <input
            className="input-req w-100"
            placeholder="Enter bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Account number</div>
          <input
            className="input-req w-100"
            placeholder="Enter account number"
            value={accountNumber}
            required
            type="number"
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5 ">Sort Code</div>
          <input
            className="input-req w-100"
            placeholder="Enter sort code"
            value={sortCode}
            required
            onChange={(e) => setSortCode(e.target.value)}
          />
        </Col>
      </Row>

      <Container className="text-center">
        <Row className=" justify-content-between align-items-center">
          <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
            <div className="text-center">
              <Button
                variant="contained"
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 15px",
                }}
                onClick={handleAddRecipient}
              >
                <img src={userAdd} className="me-2" alt="" />
                Add to recipients
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center">
              <button className="wn-btn1" type="submit">
                Continue
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default GbpAcc;
