import { Box } from "@mui/system";
import logo from "../../assets/logo.png";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./Transaction.css";
import bank from "../../assets/bank.png";
import {
  Button,
  Container,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import card from "../../assets/card.png";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const ReviewOrder = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: "#1565D8" }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container>
          <div className="font-600 wn-h1">
            How do you want to pay for your transfer?
          </div>
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
            }}
            className="pt-box1"
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={bank} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div>
                <div className="font-600 pt-h1">
                  Direct debit (Bank Transfer)
                </div>
                <div className="font-600 pt-h2">
                  Manually transfer the money to LINK from your bank.
                </div>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: "white",
              padding: "36px 24px",
            }}
            className="pt-box1"
          >
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img src={card} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div>
                <div className="font-600 pt-h1">Debit card</div>
                <div className="font-600 pt-h2">
                  Pay for your transfer online with a debit card.
                </div>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: "#A4A5A6", fontSize: 16 }} />
            </div>
          </Box>
          <br />
        </Container>
      </Box>
    </div>
  );
};

export default ReviewOrder;
