import React from "react";
import logo from "../../../assets/logo.png";
import closeBtn from "../../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import cloud from "../../../assets/cloud.png";
import tick from "../../../assets/tick.png";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";

const RoutingRequest = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container
        className="wr-box1"
        style={{ maxWidth: "800px", margin: "5rem auto 0" }}
      >
        <Typography className="text-center font-500" variant="h4">
          We’ve partnered with a cross data service provider to securely receive
          CHF deposits
        </Typography>

        <Typography variant="h6" className="text-center my-4 font-600">
          Please send the exact amount of Francs.
        </Typography>
        <div className="text-center">
          <Link to="/dashboard">
            <button className="wn-btn1">Contnue</button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default RoutingRequest;
