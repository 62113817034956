import { Button, Container, Grid } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import userAdd from "../../../assets/user-add.png";
import { useRecipientBankMutation } from "../../../services/recipientApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import countryList from "../../../components/countryList.js";
import { setName } from "../../../features/transferSlice";
import { setUsd } from "../../../features/transferRouteSlice";

const UsdLocal = () => {
  const [fullName, setFullName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [routingNumber, setRoutingNumber] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [bankName, setBankName] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recipientBank] = useRecipientBankMutation();
  const userId = useSelector(selectCurrentUserId);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(
      setUsd({
        fullName,
        accountNumber,
        accountType,
        routingNumber,
        country,
        city,
        postCode,
        address,
        bankName,
        transactionType: "Bank",
      })
    );
    navigate("/review-usd-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();
    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientBank({
    //     type: "Bank account details",
    //     fullName,
    //     bankName,
    //     userId,
    //     address,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Full name of account holder</div>
            <input
              className="input-req w-100"
              placeholder="Enter account holder name"
              value={fullName}
              required
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className="my-1"
            style={{ marginLeft: "auto" }}
          >
            <div className="input-h1">Bank name</div>
            <input
              className="input-req w-100"
              placeholder="Enter bank name"
              value={bankName}
              required
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">ACH Routing number</div>
            <input
              className="input-req w-100"
              placeholder="Enter routing number"
              value={routingNumber}
              required
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account number</div>
            <input
              className="input-req w-100"
              placeholder="Enter account number"
              value={accountNumber}
              required
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account type</div>
            <input
              className="input-req w-100"
              placeholder="Select account type"
              value={accountType}
              required
              onChange={(e) => setAccountType(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Recipient country</div>

            <div style={{ marginTop: "10px" }}>
              <select
                style={{
                  background: "white",
                  borderRadius: "5px",
                  border: "none",
                  fontSize: "18px",
                  outline: "none",
                  padding: "16px 18px",
                  width: "100%",
                }}
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryList.map(({ name, code }) => (
                  <option value={name} key={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">City</div>
            <input
              className="input-req w-100"
              placeholder="Enter city"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Recipient address</div>
            <input
              className="input-req w-100"
              placeholder="Enter recipient address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} lg={12} className="my-2">
            <div className="input-h1">Postal code</div>
            <input
              className="input-req w-100 w-100"
              placeholder="Enter postal code"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </Grid>
        </Grid>
        <Row className=" justify-content-between align-items-center">
          <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
            <div className="text-center">
              <Button
                variant="contained"
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 15px",
                }}
                onClick={handleAddRecipient}
              >
                <img src={userAdd} className="me-2" alt="" />
                Add to recipients
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center">
              <button className="wn-btn1" type="submit">
                Continue
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default UsdLocal;
