import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useLocalBankMutation } from "../../../services/usdWithdrawApi";

const UsdLocal = () => {
  const [fullName, setFullName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [routingNumber, setRoutingNumber] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [bankName, setBankName] = React.useState("");

  const navigate = useNavigate();
  const [localBank] = useLocalBankMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await localBank({
        fullName,
        accountNumber,
        accountType,
        routingNumber,
        country,
        city,
        postalCode,
        address,
        bankName,
        transactionType: "localBank",
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Full name of account holder</div>
            <input
              className="input-req w-100"
              placeholder="Enter account holder name"
              value={fullName}
              required
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className="my-1"
            style={{ marginLeft: "auto" }}
          >
            <div className="input-h1">Bank name</div>
            <input
              className="input-req w-100"
              placeholder="Enter bank name"
              value={bankName}
              required
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">ACH Routing number</div>
            <input
              className="input-req w-100"
              placeholder="Enter routing number"
              value={routingNumber}
              required
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account number</div>
            <input
              className="input-req w-100"
              placeholder="Enter account number"
              value={accountNumber}
              required
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Account type</div>
            <input
              className="input-req w-100"
              placeholder="Select account type"
              value={accountType}
              required
              onChange={(e) => setAccountType(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Recipient country</div>
            <input
              className="input-req w-100"
              placeholder="Select country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, lg: 5 }}>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">City</div>
            <input
              className="input-req w-100"
              placeholder="Enter city"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={6} className="my-1">
            <div className="input-h1">Recipient address</div>
            <input
              className="input-req w-100"
              placeholder="Enter recipient address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} lg={12} className="my-2">
            <div className="input-h1">Postal code</div>
            <input
              className="input-req w-100 w-100"
              placeholder="Enter postal code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Grid>
        </Grid>
        <Container className="text-center">
          <button className="wn-btn1" type="submit">
            Confirm
          </button>
        </Container>
      </form>
    </>
  );
};

export default UsdLocal;
