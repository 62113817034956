import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import "../CompareRates.css";
import LINK from "../../../assets/LINK.png";
import vella from "../../../assets/vella.png";
import plumter from "../../../assets/plumter.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCnhValue,
  setCnhCompetitor,
} from "../../../features/competitorSlice";
import { useGetCompetitorsQuery } from "../../../services/excelApi";

const CNY = () => {
  const competitor = useSelector(selectCnhValue);
  const [cnhCompetitors, setEurCompetitors] = React.useState({});

  const [competitorRate, setCompetitorRate] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [competitorFee, setCompetitorFee] = React.useState("");

  const { data, isLoading } = useGetCompetitorsQuery("competitors", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    !isLoading && setEurCompetitors({ ...data?.cnhCompetitors });
  }, [data, isLoading]);

  useEffect(() => {
    handlecompair(competitor);
    setRate(cnhCompetitors.link);
  }, [cnhCompetitors, competitor]);

  const handlecompair = (competitor) => {
    switch (competitor) {
      case "vella":
        setCompetitorFee("");
        setCompetitorRate(cnhCompetitors.vella);
        break;
      case "plumter":
        setCompetitorFee("Free");
        setCompetitorRate(cnhCompetitors.plumter);
        break;

      default:
        return 0;
    }
  };
  return (
    <div>
      <br />
      <div className="link-container">
        <div>
          <img src={LINK} alt="LINK" className="cr-img1" />
        </div>
        <div>
          <div className="cr-h1 font-700">1 CNH = {rate} NGN</div>
          {/* <div
            className="cr-h2 font-300"
            style={{ color: "#2DD06E", textAlign: "right" }}
          >
            saves upto 59%
          </div> */}
          <div
            className="cr-h3 font-600"
            style={{ color: "#fff", textAlign: "right" }}
          >
            0-5% Transfer fees
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center bg-white px-3 py-3"
        style={{ borderRadius: "0px 0px 8px 8px" }}
      >
        <div>
          <SelectExchangeMethod />
        </div>
        <div>
          <div className="cr-h4 font-500">1 CNH = {competitorRate} NGN</div>
          <div
            className="cr-h5 font-300"
            style={{ color: "#a4a5a6", textAlign: "right" }}
          >
            {competitorFee}
          </div>
        </div>
      </div>
      <div style={{ height: "20vh" }}></div>
    </div>
  );
};

export default CNY;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: window.innerWidth < 992 ? "130px" : "250px",
    },
  },
};

function SelectExchangeMethod() {
  const competitor = useSelector(selectCnhValue);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setCnhCompetitor({ value }));
  };

  return (
    <div>
      <Select
        value={competitor}
        onChange={handleChange}
        className="font-500  "
        label={false}
        MenuProps={MenuProps}
        style={{
          width: window.innerWidth < 992 ? 130 : 250,
        }}
      >
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="plumter"
        >
          <img
            src={plumter}
            alt=""
            style={{
              objectFit: "cover",
              width: "150px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="vella"
        >
          <img
            src={vella}
            alt=""
            style={{
              objectFit: "cover",
              width: "150px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
      </Select>
    </div>
  );
}
