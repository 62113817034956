import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import cloud from "../../assets/cloud.png";
import { Box, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import ReactFileReader from "react-file-reader";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  selectCurrentCurrency,
  selectCurrentYouRecieve,
  selectCurrentYouSend,
  selectCurrentRecipientBank,
  selectCurrentRecipientNuban,
  selectCurrentRecipientName,
  selectCurrentReceipt,
} from "../../features/transferSlice";
import {
  selectCurrentUserId,
  selectCurrentFullName,
  selectCurrentEmail,
} from "../../features/auth/authSlice";
import {
  useTransferMutation,
  useTransferReverseMutation,
} from "../../services/transferApi";

const ProofOfPayment = () => {
  const amountSend = useSelector(selectCurrentYouSend);
  const amountReceived = useSelector(selectCurrentYouRecieve);
  const currency = useSelector(selectCurrentCurrency);
  const userId = useSelector(selectCurrentUserId);
  const recipientBank = useSelector(selectCurrentRecipientBank);
  const recipientNuban = useSelector(selectCurrentRecipientNuban);
  const recipientName = useSelector(selectCurrentRecipientName);
  const receiptImage = useSelector(selectCurrentReceipt);
  const senderName = useSelector(selectCurrentFullName);
  const senderEmail = useSelector(selectCurrentEmail);
  const ref = Math.random().toString(36).slice(2, 21);

  const [receipt, setReceipt] = React.useState("");
  console.log(recipientBank, recipientNuban, recipientName);

  const navigate = useNavigate();

  const handleFiles = (files) => {
    setReceipt(files.base64);
    // dispatch(setReceipt(files.base64));
  };

  // const [transfer] = useTransferMutation();
  const [transferReverse] = useTransferReverseMutation();
  console.log(currency);

  const handleTransfer = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading("Loading...");

    try {
      const response = await transferReverse({
        userId,
        ref,
        transferType: "reverse",
        currency,
        amountSend,
        amountReceived,
        recipientName,
        senderName,
        senderEmail,
        bankName: recipientBank,
        nuban: recipientNuban,
        receipt,
      }).unwrap();
      toast.success("Success", { id: loadingToast });
      navigate("/transfer-received");
    } catch (error) {
      // console.log(error);
      toast.error("Failed", { id: loadingToast });
    }
  };

  return (
    <form onSubmit={handleTransfer}>
      <div className="primary-bg h-100">
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <div></div>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <div className="font-600 wn-h1">Upload a proof of payment</div>

            <Row className="justify-content-between align-items-center">
              <Col>
                <div
                  className="input-h1 idv-h1"
                  style={{ marginTop: "0.5rem" }}
                >
                  Upload a good photo of your receipt. Make sure it’s clear.
                  <b>Note: PDF's are not allowed</b>
                </div>
                <Box className="idv-upload">
                  <div className="d-flex align-items-center">
                    <img
                      src={cloud}
                      alt="cloud"
                      className="me-3"
                      style={{ height: 36 }}
                    />
                    <div>
                      <div className="font-600">Upload receipt here</div>
                      <div
                        className="font-600"
                        style={{ color: "#888", fontSize: 14 }}
                      >
                        Max. file size: 10MB
                      </div>
                    </div>
                  </div>
                  <div>
                    <ReactFileReader
                      fileTypes={[".png", ".jpg"]}
                      base64={true}
                      handleFiles={handleFiles}
                    >
                      <label
                        htmlFor="file"
                        style={{
                          fontWeight: 600,
                          color: "black",
                          boxShadow: "0px 4px 4px rgba(0,0,0,.25)",
                          cursor: "pointer",
                          minWidth: 200,
                        }}
                        className={`ms-4  ${
                          receipt ? "bg-primary text-white" : "bg-white"
                        } font-700  border rounded p-2 d-flex justify-content-center align-items-center`}
                      >
                        {receipt ? "Uploaded" : "Browse files"}
                      </label>
                    </ReactFileReader>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <Container className="text-center">
          <button
            className="idv-btn1"
            type="submit"
            disabled={receipt ? false : true}
          >
            Next
          </button>
        </Container>
      </div>
    </form>
  );
};

export default ProofOfPayment;
