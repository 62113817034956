import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  foreignValue: "USD",
  africanValue: "NGN",
  isSwaped: false,
};

export const converterSlice = createSlice({
  name: "converter",
  initialState,
  reducers: {
    setForeign: (state, action) => {
      const { value } = action.payload;
      state.foreignValue = value;
    },
    setAfricanValue: (state) => {
      state.africanValue = null;
    },
    setIsSwaped: (state, action) => {
      const { value } = action.payload;
      state.isSwaped = value;
    },
  },
});

export const { setForeign, setAfricanValue, setIsSwaped } =
  converterSlice.actions;
export default converterSlice.reducer;

export const selectForeignValue = (state) => state.converter.foreignValue;
export const selectAfricanValue = (state) => state.converter.africanValue;
export const selectIsSwaped = (state) => state.isSwaped.isSwaped;
