/* eslint-disable no-unused-vars */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Container, SwipeableDrawer } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AccountButton from '../../components/AccountButton';
import MobileNavbarTwo from '../../components/MobileNavbarTwo';
import './Cards.css';
import CardDetails from './components/CardDetails';
import LeftPanel from './components/LeftPanel';
import { useSelector } from 'react-redux';
import incoming from '../../assets/incoming.png';
import outgoing from '../../assets/outgoing.png';
import {
  selectCurrentAccLock,
  selectCurrentAccPlus,
  selectCurrentUserId,
} from '../../features/auth/authSlice';
import {
  useGetCardQuery,
  useGetCardTransactionQuery,
} from '../../services/accountCardApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  height: window.innerHeight - 100,
  overflowY: 'scroll',
  borderRadius: 3,
  width: 450,
};

const CardWithHistory = () => {
  const id = useSelector(selectCurrentUserId);
  const { data, isLoading, isFetching } = useGetCardTransactionQuery(id);
  const [transactions, setTransactions] = useState([]);

  const accLock = useSelector(selectCurrentAccLock);
  const accPlus = useSelector(selectCurrentAccPlus);
  const navigate = useNavigate();

  useEffect(() => {
    if (accLock === 'verified' && accPlus === false) {
      navigate('/dashboard');
    }
  });

  useEffect(() => {
    if (!isFetching) {
      setTransactions(data.cards);
    }
  }, [data, isFetching, isLoading]);

  // console.log(transactions);
  return (
    <div>
      <Box className="cards-body">
        <div>
          <MobileNavbarTwo
            activeColor1="#696F79"
            activeColor2="#1565DB"
            activeColor3="#696F79"
            activeColor4="#696F79"
            activeColor5="#696F79"
            iconColor1="#696F79"
            iconColor2="#1565DB"
            iconColor3="#696F79"
            iconColor4="#696F79"
            iconColor5="#696F79"
          />
        </div>

        <Row>
          <Col lg={2}>
            <LeftPanel
              activeColor1="#696F79"
              activeColor2="#1565DB"
              activeColor3="#696F79"
              activeColor4="#696F79"
              activeColor5="#696F79"
              iconColor1="#696F79"
              iconColor2="#1565DB"
              iconColor3="#696F79"
              iconColor4="#696F79"
              iconColor5="#696F79"
            />
          </Col>
          <Col
            className="bd-col1 primary-bg h-100 pb-5"
            style={{ overflowX: 'hidden' }}
          >
            <Container maxWidth="xl" className="bd-box2">
              <div className="sm-none">
                <AccountButton />
              </div>
              <Box className="px-3 bd-box1">
                <Typography className="font-500 fs-1">Card</Typography>
                <div className="sm-none">
                  <CardMd />
                </div>
                <div className="md-none">
                  <CardMd />
                </div>
              </Box>
              <br />
              <br />

              <Typography className="px-3 font-500 fs-3 mt-2">
                History
              </Typography>
              <Box
                className="m-4 text-center bg-white rounded font-600 py-1"
                style={{ fontWeight: 600, maxWidth: 1200 }}
              >
                {transactions === null
                  ? "You don't have any transactions recorded"
                  : transactions.map((item) => (
                      <div
                        className="d-flex justify-content-between align-items-center pe-3"
                        style={{
                          borderBottom: item.border
                            ? 'none'
                            : '2px solid #F3F4F6',
                        }}
                        key={item.bridgecard_transaction_reference}
                      >
                        <div className="d-box">
                          <img
                            src={
                              item.card_transaction_type === 'DEBIT'
                                ? outgoing
                                : incoming
                            }
                            alt="activity"
                            className="d-img"
                          />
                          <div>
                            <div className="d-h1">${item.amount / 100}</div>
                          </div>
                        </div>
                        <div className="level font-700 d-h1">
                          {item.card_transaction_type === 'DEBIT' ? (
                            <span style={{ color: '#DD0101' }}>DEBIT</span>
                          ) : (
                            <span style={{ color: '#27AE60' }}>CREDIT</span>
                          )}
                        </div>
                        <div className="date2 font-700 d-h2">
                          {item.transaction_date.split(' ')[0]}
                        </div>
                        <div className="desc font-700 d-h2">
                          {item.description}
                        </div>
                      </div>
                    ))}
              </Box>
            </Container>
          </Col>
        </Row>
      </Box>
    </div>
  );
};

export default CardWithHistory;

const CardMd = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const id = useSelector(selectCurrentUserId);
  const { data, isLoading, isFetching } = useGetCardQuery(id);
  const [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      setCardDetails(data.cards);
    }
  }, [data, isFetching, isLoading]);

  // console.log(cardDetails);
  return (
    <>
      <Box className="d-flex align-items-end">
        {cardDetails.length === 0 || cardDetails[0].card_number === null
          ? ' '
          : cardDetails.map((detail) => (
              <div key={detail._id}>
                <div
                  className="card text-white me-3 cc-header"
                  onClick={handleOpen}
                >
                  <img src={detail.cardDesign} className="card-img" alt="..." />
                  <div className="card-img-overlay">
                    <div className="cc-overlay">
                      <div className="font-600 cwh-h3">Available balance</div>
                      <div className="font-600 cwh-h4">
                        {detail.balance}
                        <span style={{ fontSize: '25px' }}> USD</span>
                      </div>
                      <div
                        style={{
                          fontSize: '15px',
                          marginTop: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={handleOpen}
                      >
                        Click to view details
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="left-panel-popup primary-bg">
                    <CardDetails
                      close={handleClose}
                      card_name={detail.card_name}
                      card_number={detail.card_number}
                      expiry={detail.expiry}
                      cvv={detail.cvv}
                      balance={detail.balance}
                      cardImg={detail.cardDesign}
                      is_active={detail.is_active}
                    />
                  </Box>
                </Modal>
              </div>
            ))}

        {cardDetails.length === 0 || cardDetails[0].card_number === null ? (
          <Link to="/card-create" style={{ textDecoration: 'none' }}>
            <div
              className="monster-font text-muted fs-5"
              style={{
                border: '2px dashed #ccc',
                width: 200,
                height: 150,
                borderRadius: 8,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <AddCircleOutlineIcon style={{ fontSize: 42, fontWeight: 400 }} />
              Add Card
            </div>
          </Link>
        ) : (
          ' '
        )}
      </Box>
    </>
  );
};

const CardSm = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const id = useSelector(selectCurrentUserId);
  const { data, isLoading, isFetching } = useGetCardQuery(id);
  const [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      setCardDetails(data.cards);
    }
  }, [data, isFetching, isLoading]);

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor, detail) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container
        className="primary-bg "
        style={{
          height: window.innerHeight - 100,
          overflowY: 'scroll',
          padding: '0',
        }}
      >
        <CardDetails
          close={toggleDrawer(anchor, false)}
          card_name={detail.card_name}
          card_number={detail.card_number}
          expiry={detail.expiry}
          cvv={detail.cvv}
          balance={detail.balance}
          cardImg={detail.cardDesign}
          is_active={detail.is_active}
        />
      </Container>
    </Box>
  );

  return (
    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div onClick={toggleDrawer(anchor, true)}>
            <div
              className="d-flex"
              style={{ overflowX: 'scroll', marginTop: '4rem' }}
            >
              {!cardDetails
                ? ' '
                : cardDetails.map((detail) => (
                    <div key={detail._id}>
                      <div
                        className="card text-white me-2"
                        style={{ minWidth: 275, border: 'none' }}
                      >
                        <img
                          src={detail.cardDesign}
                          className="card-img"
                          alt="..."
                        />
                        <div className="card-img-overlay">
                          <div className="cc-overlay">
                            <div className="font-600 fs-6">
                              Available balance
                            </div>
                            <div className="font-600 fs-1">
                              {detail.balance}
                              <span style={{ fontSize: '18px' }}> USD</span>
                            </div>
                            <div
                              style={{
                                fontSize: '10px',
                                marginTop: '10px',
                                cursor: 'pointer',
                              }}
                            >
                              Click to view details
                            </div>
                          </div>
                        </div>
                      </div>
                      <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor, detail)}
                      </SwipeableDrawer>
                    </div>
                  ))}
            </div>

            {cardDetails ? (
              ' '
            ) : (
              <Link to="/card-create" style={{ textDecoration: 'none' }}>
                <div
                  className="my-4 font-600"
                  style={{
                    color: '#696F79',
                    border: '2px dashed #ccc',
                    height: 60,
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{ fontSize: 26, marginRight: 8 }}
                  />
                  Add Card
                </div>
              </Link>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  // return (
  //     <>
  // <div className='d-flex' style={{ overflowX: 'scroll' }} >
  //     {
  //         cards.map(item => {
  //             return (
  //                 <div className="card text-white me-2" style={{ minWidth: 275, border: 'none' }}>
  //                     <img src={item.color} className="card-img" alt="..." />
  //                     <div className="card-img-overlay" >
  //                         <div className='cc-overlay'  >
  //                             <div className='font-600 fs-6'>Debit Card</div>
  //                             <div className='font-600 fs-1'>
  //                                 $468.60
  //                             </div>
  //                             <OpenCard />
  //                         </div>
  //                     </div>
  //                 </div>
  //             )
  //         })
  //     }
  // </div>
  //         <Link to="/card-create-rgb" style={{ textDecoration: 'none' }}>
  //             <div className='my-4 font-600' style={{ color: '#696F79', border: "2px dashed #ccc", height: 60, borderRadius: 8, display: 'flex', justifyContent: "center", alignItems: 'center' }} >
  //                 <AddCircleOutlineIcon style={{ fontSize: 26, marginRight: 8 }} />
  //                 Add Card
  //             </div>
  //         </Link>

  //     </>
  // )
};
