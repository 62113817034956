import { Box } from '@mui/system';
import logo from '../../assets/logo.png';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import ReactFileReader from 'react-file-reader';
import Modal from '@mui/material/Modal';
import wallet from '../../assets/wallet.png';
import cryptoBtc from '../../assets/crypto.png';
import Fonbnk from '../../assets/funbnk.png';
import { Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import backArrow from '../../assets/arrow-left.png';
import close from '../../assets/close.png';
import closeBtn from '../../assets/close-square.png';
import crypto from '../../assets/crypto.svg';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import USD from '../../assets/usa.png';
import NGN from '../../assets/nigeria.png';
import USDC from '../../assets/usdc.png';
import USDT from '../../assets/tether.png';
import BTC from '../../assets/bitcoin.png';
import ETH from '../../assets/ethereum.png';
import XLM from '../../assets/XLM.png';
import Copy from '../../assets/copy.png';
import Help from '../../assets/help.png';
import shot from '../../assets/shot.png';
import Temp_qr from '../../assets/temp-qr.png';
import Crypto_qr from '../../assets/Crypto_qr.png';
import { useSelector } from 'react-redux';
import {
  selectCurrentUserId,
  selectCurrentTransAcc,
} from '../../features/auth/authSlice';
import { useGetCryptoQuery } from '../../services/excelApi';
import {
  useFundCardUsdcMutation,
  useFundCardCryptoMutation,
} from '../../services/accountCardApi';
import '../ReqAccOne/ReqAccOne.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: window.innerHeight - 200,
  overflowY: 'scroll',
  borderRadius: 3,
  width: 450,
  zIndex: 100,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: window.innerHeight - 200,
  overflowY: 'scroll',
  borderRadius: 3,
  width: 450,
  zIndex: 100,
};

const FundCard = () => {
  const transAcc = useSelector(selectCurrentTransAcc);

  const [cryptoModal, setCryptoModal] = useState(false);
  const [usdcModal, setUsdcModal] = useState(false);

  const handleOpenCrypto = () => setCryptoModal(true);
  const handleCloseCrypto = () => setCryptoModal(false);

  const handleOpenUsdc = () => setUsdcModal(true);
  const handleCloseUsdc = () => setUsdcModal(false);

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box className="box-set">
        <Container className="rco-box1">
          <Box className="d-flex justify-content-end align-items-center">
            {/* <Link style={{ textDecoration: "none" }} to="/dashboard">
              <button
                className="rco-btn1 font-600
                style={{ color: "#1565D8" }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link> */}
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container className="pb-4">
          <div className="font-500 wn-h1">
            How do you want to fund your Virtual debit card?
          </div>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: 'white',
              padding: '15px 15px',
              cursor: 'pointer',
            }}
            className="pt-box1 border"
            onClick={handleOpenUsdc}
          >
            <div className="d-flex align-items-center">
              <div className="me-4">
                <img src={crypto} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div className="font-600 pt-h1">
                <h5>USDC</h5>
                <p
                  style={{
                    color: 'rgb(154, 156, 172)',
                    fontSize: '14px',
                    fontWeight: 'lighter',
                  }}
                >
                  Fund your virtual card with (USDC)
                </p>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: '#A4A5A6', fontSize: 16 }} />
            </div>
          </Box>
          <Modal
            open={usdcModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ background: 'transparent' }}
          >
            <Box sx={style} className="left-panel-popup primary-bg">
              <FundWithUsdc close={handleCloseUsdc} />
            </Box>
          </Modal>
          <br />
          <Box
            style={{
              borderRadius: 8,
              background: 'white',
              padding: '15px 15px',
              cursor: 'pointer',
            }}
            className="pt-box1 border"
            onClick={handleOpenCrypto}
          >
            <div className="d-flex align-items-center">
              <div className="me-4">
                <img src={cryptoBtc} style={{ height: 46, width: 46 }} alt="" />
              </div>
              <div className="font-600 pt-h1">
                <h5>
                  Crypto
                  {/* <span
                    style={{
                      color: '#1565D8',
                      fontSize: '20spx',
                      marginLeft: '5px',
                    }}
                  >
                    (Coming Soon)
                  </span> */}
                </h5>
                <p
                  style={{
                    color: 'rgb(154, 156, 172)',
                    fontSize: '14px',
                    fontWeight: 'lighter',
                  }}
                >
                  Convert other Crypto assets to USDC and fund card
                </p>
              </div>
            </div>
            <div>
              <ArrowForwardIosIcon style={{ color: '#A4A5A6', fontSize: 16 }} />
            </div>
          </Box>
          <Modal
            open={cryptoModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ background: 'transparent' }}
          >
            <Box sx={style} className="left-panel-popup primary-bg">
              <FundWithCrypto close={handleCloseCrypto} />
            </Box>
          </Modal>
          <br />
          {/* <Box
            style={{
              borderRadius: 8,
              background: 'white',
              padding: '15px 15px',
              cursor: 'pointer',
            }}
            className="pt-box1 border"
          >
            <Link
              style={{
                textDecoration: 'none',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
              }}
              to={'#'}
            >
              <div className="d-flex align-items-center">
                <div className="me-4">
                  <img src={Fonbnk} style={{ height: 46, width: 46 }} alt="" />
                </div>
                <div className="font-600 pt-h1">
                  <h5>
                    Fund with airtime{' '}
                    <span
                      style={{
                        color: '#1565D8',
                        fontSize: '20spx',
                        marginLeft: '5px',
                      }}
                    >
                      (Coming Soon)
                    </span>
                  </h5>
                  <p
                    style={{
                      color: 'rgb(154, 156, 172)',
                      fontSize: '14px',
                      fontWeight: 'lighter',
                    }}
                  >
                    Fonbnk processing fee & LINK card loading fees included.
                  </p>
                </div>
              </div>
              <div>
                <ArrowForwardIosIcon
                  style={{ color: '#A4A5A6', fontSize: 16 }}
                />
              </div>
            </Link>
          </Box> */}
          <br />
        </Container>
      </Box>
    </div>
  );
};

export default FundCard;

// Funding With Usdc Flow Functions

const FundWithUsdc = (p) => {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(50);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [XLMRate, setXLMRate] = useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState('USDC');
  const [cryptoAddress, setCryptoAddress] = useState('');
  const [cryptoTitle, setCryptoTitle] = useState('');
  let [fees, setFees] = useState(0);

  const { data, isFetching } = useGetCryptoQuery({
    refetchOnMountOrArgChange: true,
  });

  const inputCheck = () => {
    if (amount < 0) {
      return setAmount(0);
    }
    if (amount > 3000) {
      return setAmount(3000);
    }
  };

  // useEffect
  useEffect(() => {
    inputCheck();
  }, [amount]);

  const handleAmountTotal = () => {
    setFees = amount - 2.5;
    return setTotalAmount(setFees - setFees * 0.01);
  };

  const handleCryptoDetails = () => {
    if (paymentMethod === 'USDC') {
      setCryptoAddress(
        `GBKY2V7DAPJ66LUGFRT6RHVQZ6HPWG42YBJSQATEABQCUC2IWXH3XEJ7`
      );
      setCryptoTitle('USDC');
    }
  };

  useEffect(() => {
    handleAmountTotal();
    handleCryptoDetails();
  }, [amount, paymentMethod]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="px-4 py-3 primary-bg"
    >
      <div style={{ textAlign: 'right' }}>
        <img
          src={close}
          style={{ width: 42, height: 42, cursor: 'pointer' }}
          alt="close"
          onClick={p.close}
        />
      </div>
      <Typography className="font-500 fs-1 my-2 ">Fund with USDC</Typography>
      <div>
        <Box
          className="font-400 px-2 py-1 d-flex align-items-center rounded"
          style={{
            fontSize: 16,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>
            Amount{' '}
            <span style={{ color: '#1856AF', fontSize: 16 }}>
              (min $15, max $3000)
            </span>
          </p>
          <p
            style={{
              fontSize: 18,
              marginTop: '0px',
            }}
          >
            Currency:
          </p>
        </Box>
        <div className="py-2 input-transfer-container">
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{
                width: '50%',
              }}
            >
              <input
                className="input-transfer"
                placeholder="Enter amount"
                value={amount}
                required
                type="number"
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <MenuItem
                className="d-flex align-items-center px-2 font-500 mr-2"
                style={{
                  borderLeft: '1px solid grey',
                }}
                value="USDC"
              >
                <img
                  style={{
                    height: 30,
                    width: 45,
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'contain',
                  }}
                  src={USDC}
                  alt="flag"
                />
                USDC
              </MenuItem>
            </div>
          </div>
        </div>
        <Box
          className="my-3 font-500 px-2 py-1 d-flex align-items-center rounded"
          style={{
            fontSize: 16,
            background: '#E7EFFF',
            color: '#1856AF',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>Amount to be funded (fees included):</p>
          <p
            style={{
              fontSize: 18,
              marginTop: '0px',
            }}
          >
            ${totalAmount.toFixed(2)}
          </p>
        </Box>
        <p
          style={{
            fontSize: 12,
            textAlign: 'center',
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          Click 'Continue' to see a wallet address where you can send your USDC.
        </p>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ background: 'transparent' }}
        >
          <Box sx={style2} className="left-panel-popup primary-bg">
            <UsdcDetails
              close={handleClose}
              amount={amount}
              amountFund={totalAmount}
              address={cryptoAddress}
              asset={paymentMethod}
              title={cryptoTitle}
            />
          </Box>
        </Modal>
        <button
          style={{
            width: '50%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: '#30ae4d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginBottom: '1rem',
            marginTop: '1.5rem',
            marginLeft: '25%',
          }}
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const UsdcDetails = (p) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="p-3 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <h3
        className="font-500 fs-1 text-center"
        style={{ marginTop: '-1.5rem' }}
      >
        Send {p.title}
      </h3>
      <p className="text-center">Send to this address below.</p>
      <div className="d-flex align-items-center justify-content-between bg-white rounded-2 p-3 w-100 mt-3">
        <textarea
          cols={48}
          rows={2}
          style={{
            fontSize: '14px',
            border: 'none',
            outline: 'none',
            resize: 'none',
            backgroundColor: 'transparent',
          }}
          disabled
          defaultValue={p.address}
        ></textarea>
        <button
          style={{ background: 'transparent' }}
          onClick={() => {
            navigator.clipboard.writeText(p.address);
            toast.success('Address Copied!');
          }}
        >
          <img src={Copy} alt="copy" />
        </button>
      </div>
      <div className="bg-white rounded-2 p-3 w-100 my-3">
        <p
          style={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#1856AF',
            marginBottom: 8,
          }}
        >
          Network: Stellar Network
        </p>
        <div className="d-flex">
          <p style={{ fontSize: '16px', fontWeight: 500, color: '#1856AF' }}>
            Add your LINK Tag as Memo
          </p>
          <button style={{ background: 'transparent' }}>
            <img src={Help} alt="help" style={{ width: 24, marginLeft: 5 }} />
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Temp_qr} alt="" style={{ width: 130 }} />
      </div>
      <Box
        className="my-3 font-400 d-flex align-items-center p-2 rounded"
        style={{
          fontSize: 16,
          background: '#E7EFFF',
          color: '#1856AF',
          display: 'flex',

          justifyContent: 'space-between',
        }}
      >
        <p>Amount to send:</p>
        <p
          style={{
            fontWeight: 600,
          }}
        >
          {p.amount} {p.title}
        </p>
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ background: 'transparent' }}
      >
        <Box sx={style2} className="left-panel-popup primary-bg">
          <UsdcProof
            close={handleClose}
            amount={p.amount}
            amountFund={p.amountFund}
            asset={p.asset}
          />
        </Box>
      </Modal>
      <button
        style={{
          width: '100%',
          padding: '10px 9px',
          fontSize: '20px',
          backgroundColor: '#30ae4d',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginBottom: '2rem',
          marginTop: '0.2rem',
        }}
        onClick={handleNext}
      >
        Continue
      </button>
    </div>
  );
};

const UsdcProof = (p) => {
  const [idImage, setIDImage] = React.useState('');
  const [senderAddress, setSenderAddress] = useState('');

  const Id = useSelector(selectCurrentUserId);
  const [fundCard] = useFundCardUsdcMutation();

  const navigate = useNavigate();

  const handleFiles = (files) => {
    setIDImage(files.base64);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading('Processing Usdc Fund Request...');
    try {
      await fundCard({
        userId: Id,
        asset: p.asset,
        amountSent: p.amount,
        amountFund: p.amountFund,
        proof: idImage,
        walletAddress: senderAddress,
      }).unwrap();
      toast.success('Card Fund Request successful!', {
        id: submitToast,
      });
      navigate('/crypto-fund-card-success');
    } catch (error) {
      toast.error(error.data.message, {
        id: submitToast,
      });
      // console.log(error);
      // navigate("/card-req-not-received");
    }
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="p-3 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <h3
        className="font-500 fs-1 text-center"
        style={{ marginTop: '-1.5rem' }}
      >
        Choose proof of payment
      </h3>
      <div className="align-items-center justify-content-between w-100 mt-2">
        <Col className="my-2">
          <div className="input-h1 idv-h1" style={{ color: 'black' }}>
            You can choose either of the two
          </div>
          <Box
            style={{
              border: '1px dashed #c4c4c4',
              borderRadius: 10,
              width: 600,
              padding: '18px 40px',
              marginTop: 5,
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <img
                src={shot}
                alt="cloud"
                className="me-3"
                style={{ height: 36 }}
              />
              <div>
                <div className="font-600 mb-1">Screenshot</div>
                <div
                  className="font-600 mb-2"
                  style={{ color: '#888', fontSize: 12 }}
                >
                  Upload a screenshot of your transfer receipt here
                </div>
              </div>
            </div>
            <div>
              <ReactFileReader
                fileTypes={['.png', '.jpg']}
                base64={true}
                handleFiles={handleFiles}
              >
                <label
                  htmlFor="file"
                  style={{
                    fontSize: 14,
                    color: 'black',
                    boxShadow: '0px 4px 4px rgba(0,0,0,.25)',
                    cursor: 'pointer',
                    maxWidth: 300,
                    marginLeft: '25%',
                    borderRadius: 10,
                    padding: '12px 0',
                  }}
                  className={`ms-4  ${
                    idImage ? 'bg-primary text-white' : 'bg-white'
                  } font-700  border rounded p-2 d-flex justify-content-center align-items-center`}
                >
                  {idImage ? 'Uploaded' : 'Upload'}
                </label>
              </ReactFileReader>
            </div>
          </Box>
        </Col>
        <Col></Col>
      </div>
      <br />
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey' }} />

        <div>
          <p style={{ width: '70px', textAlign: 'center' }}>OR</p>
        </div>

        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey' }} />
      </div>
      <br />
      <div className="align-items-center justify-content-between w-100 mt-2">
        <Col>
          <div className="input-h1" style={{ color: 'black' }}>
            Enter wallet address used to make the transfer
          </div>
          <input
            className="input-req"
            style={{ width: '100%' }}
            placeholder={'Enter wallet address'}
            type="text"
            onChange={(e) => setSenderAddress(e.target.value)}
            value={senderAddress}
          />
        </Col>
      </div>
      <br />
      <Container className="text-center mb-4">
        <div>
          <div className="font-600">
            <div className="topping mx-2">
              <input
                type="checkbox"
                id="topping"
                name="topping"
                value="Paneer"
                className="mx-2"
              />
              <span style={{ fontSize: 12 }}>
                I acknowledge the information I uploaded is correct
              </span>
            </div>
          </div>
        </div>
      </Container>
      <button
        style={{
          width: '50%',
          marginLeft: '25%',
          padding: '10px 9px',
          fontSize: '20px',
          backgroundColor: '#30ae4d',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginBottom: '2rem',
          marginTop: '0.2rem',
        }}
        onClick={handleSubmit}
      >
        Complete
      </button>
    </div>
  );
};

// Funding With Crypto Flow Functions

const FundWithCrypto = (p) => {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(50);
  const [youSend, setYouSend] = React.useState(0);
  const [paymentMethod, setPaymentMethod] = React.useState('BTC');
  const [rate, setRate] = useState(0);
  const [bTCRate, setBtcRate] = useState(20770);
  const [usdtRate, setUsdtRate] = useState(1);
  const [ethRate, setEthRate] = useState(1540);
  const [cryptoAddress, setCryptoAddress] = useState(
    '0xbf77a6649dd0d17f2a4b57cda3401af47646bf4a'
  );
  const [cryptoTitle, setCryptoTitle] = useState('');
  const navigate = useNavigate();

  const { data, isFetching } = useGetCryptoQuery({
    refetchOnMountOrArgChange: true,
  });

  const inputCheck = () => {
    if (amount < 0 || amount === ' ') {
      return setAmount(0);
    }
    if (amount > 3000) {
      return setAmount(3000);
    }
  };

  // useEffect
  useEffect(() => {
    inputCheck();
  }, [amount]);

  useEffect(() => {
    if (data) {
      setBtcRate(data.cryptoRates.BTC);
      setUsdtRate(data.cryptoRates.USDT);
      setEthRate(data.cryptoRates.ETH);
    }
  }, [bTCRate, data, ethRate, isFetching, usdtRate]);

  const handleAmountTotal = () => {
    if (paymentMethod === 'BTC') {
      setRate(bTCRate);
      return setYouSend(amount / bTCRate);
    } else if (paymentMethod === 'ETH') {
      setRate(ethRate);
      return setYouSend(amount / ethRate);
    } else if (paymentMethod === 'USDT') {
      setRate(usdtRate);
      return setYouSend(amount / usdtRate);
    }
  };

  useEffect(() => {
    handleAmountTotal();
    setCryptoTitle(paymentMethod);
  }, [amount, paymentMethod]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="px-4 py-2 primary-bg"
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img
          src={close}
          onClick={p.close}
          style={{ width: 42, height: 42, cursor: 'pointer' }}
          alt="close"
        />
      </div>
      <Typography className="font-500 fs-1 my-2 ">Fund with Crypto</Typography>
      <div>
        <div className="py-2 input-transfer-container">
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{
                width: '50%',
              }}
            >
              <input
                type="number"
                className="input-transfer"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <MenuItem
                className="d-flex align-items-center px-2 font-500 mr-2"
                style={{
                  borderLeft: '1px solid grey',
                  marginRight: 6,
                }}
                value="USDC"
              >
                <img
                  style={{
                    height: 30,
                    width: 45,
                    marginRight: 10,
                    marginLeft: 10,
                    objectFit: 'contain',
                  }}
                  src={USDC}
                  alt="flag"
                />
                USDC
              </MenuItem>
            </div>
          </div>
        </div>
        <Box
          className="my-2 font-500 px-2 py-1 d-flex align-items-center rounded"
          style={{
            fontSize: 16,
            background: '#E7EFFF',
            color: '#1856AF',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>
            1 {paymentMethod} = {rate} USDC
          </p>
          <p>(fees included)</p>
        </Box>
        <div className="py-2 input-transfer-container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="input-transfer">{youSend.toFixed(4)}</div>
            <div>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="font-500"
                required
                label={false}
              >
                <MenuItem
                  className="d-flex align-items-center px-2 font-500 mr-2"
                  style={{
                    borderLeft: '1px solid grey',
                  }}
                  value="BTC"
                >
                  <img
                    style={{
                      height: 30,
                      width: 45,
                      marginRight: 12,
                      marginLeft: 10,
                      objectFit: 'contain',
                    }}
                    src={BTC}
                    alt="flag"
                  />
                  BTC
                </MenuItem>
                <MenuItem
                  className="d-flex align-items-center px-2 font-500 mr-2"
                  style={{
                    borderLeft: '1px solid grey',
                  }}
                  value="USDT"
                >
                  <img
                    style={{
                      height: 30,
                      width: 45,
                      marginRight: 12,
                      marginLeft: 10,
                      objectFit: 'contain',
                    }}
                    src={USDT}
                    alt="flag"
                  />
                  USDT
                </MenuItem>
                <MenuItem
                  className="d-flex align-items-center px-2 font-500 mr-2"
                  style={{
                    borderLeft: '1px solid grey',
                  }}
                  value="ETH"
                >
                  <img
                    style={{
                      height: 30,
                      width: 45,
                      marginRight: 12,
                      marginLeft: 10,
                      objectFit: 'contain',
                    }}
                    src={ETH}
                    alt="flag"
                  />
                  ETH
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <p
          style={{
            fontSize: 12,
            textAlign: 'center',
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          <span style={{ color: '#1856AF', fontSize: 14 }}>Note: </span>All
          these assets will be converted to USDC which will be used to fund your
          card.
        </p>
        {/* <div className="my-2">
          <div className="input-h1 fs-6">Payment method</div>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="font-500"
            label={false}
            fullWidth
            required
            style={{ background: 'white' }}
          >
            <MenuItem
              className="d-flex align-items-center px-3 font-500"
              value="NGN_Wallet"
            >
              <img
                style={{
                  height: 30,
                  width: 50,
                  marginRight: 10,
                  objectFit: 'contain',
                }}
                src={NGN}
                alt="flag"
              />
              LINK NGN wallet
            </MenuItem>
            <hr className="m-0" />
            <MenuItem
              className="d-flex align-items-center px-3 font-500"
              value="USD_Wallet"
            >
              <img
                style={{
                  height: 32,
                  width: 52,
                  marginRight: 10,
                  objectFit: 'contain',
                }}
                src={USD}
                alt="flag"
              />
              LINK USD wallet
            </MenuItem>
            <hr className="m-0" />
          </Select>
        </div> */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ background: 'transparent' }}
        >
          <Box sx={style2} className="left-panel-popup primary-bg">
            <CryptoDetails
              close={handleClose}
              amount={youSend}
              amountFund={amount}
              address={cryptoAddress}
              asset={paymentMethod}
              title={cryptoTitle}
            />
          </Box>
        </Modal>
        <button
          style={{
            width: '50%',
            marginLeft: '25%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: '#30ae4d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginTop: '1rem',
          }}
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const CryptoDetails = (p) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    handleOpen();
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="p-3 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <h3
        className="font-500 fs-1 text-center"
        style={{ marginTop: '-1.5rem' }}
      >
        Send {p.title}
      </h3>
      <p className="text-center">Send to this address below.</p>
      <div className="d-flex align-items-center justify-content-between bg-white rounded-2 p-3 w-100 mt-3">
        <textarea
          cols={48}
          rows={1}
          style={{
            fontSize: '14px',
            border: 'none',
            outline: 'none',
            resize: 'none',
            backgroundColor: 'transparent',
          }}
          disabled
          defaultValue={p.address}
        ></textarea>
        <button
          style={{ background: 'transparent' }}
          onClick={() => {
            navigator.clipboard.writeText(p.address);
            toast.success('Address Copied!');
          }}
        >
          <img src={Copy} alt="copy" />
        </button>
      </div>
      <div className="bg-white rounded-2 p-3 w-100 my-3">
        <p
          style={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#1856AF',
            marginBottom: 8,
          }}
        >
          Network: BNB Smart Chain (BEP20)
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Crypto_qr} alt="" style={{ width: 130 }} />
      </div>
      <Box
        className="my-3 font-400 d-flex align-items-center p-2 rounded"
        style={{
          fontSize: 14,
          background: '#E7EFFF',
          color: '#1856AF',
          display: 'flex',

          justifyContent: 'space-between',
        }}
      >
        <p>Amount to send:</p>
        <p
          style={{
            fontWeight: 600,
          }}
        >
          {p.amount.toFixed(4)} {p.title}
        </p>
      </Box>
      <Box
        className="my-3 font-400 d-flex align-items-center p-2 rounded"
        style={{
          fontSize: 12,
          background: '#E7EFFF',
          color: '#1856AF',
          display: 'flex',
        }}
      >
        <p>
          The accepted amount is between $15 to $3000 equivalent in {p.asset},
          any amount below or above will be ignored by the system.
        </p>
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ background: 'transparent' }}
      >
        <Box sx={style2} className="left-panel-popup primary-bg">
          <CryptoProof
            close={handleClose}
            amount={p.amount}
            amountFund={p.amountFund}
            asset={p.asset}
          />
        </Box>
      </Modal>
      <button
        style={{
          width: '50%',
          marginLeft: '25%',
          padding: '10px 9px',
          fontSize: '20px',
          backgroundColor: '#30ae4d',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginBottom: '2rem',
          marginTop: '0.2rem',
        }}
        onClick={handleNext}
      >
        Continue
      </button>
    </div>
  );
};

const CryptoProof = (p) => {
  const [idImage, setIDImage] = React.useState('');
  const [senderAddress, setSenderAddress] = useState('');

  const Id = useSelector(selectCurrentUserId);
  const [fundCrypto] = useFundCardCryptoMutation();

  const navigate = useNavigate();

  const handleFiles = (files) => {
    setIDImage(files.base64);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading('Processing Crypto Fund Request...');
    try {
      await fundCrypto({
        userId: Id,
        asset: p.asset,
        amountSent: p.amount,
        amountFund: p.amountFund,
        proof: idImage,
        walletAddress: senderAddress,
      }).unwrap();
      toast.success('Card Fund Request successful!', {
        id: submitToast,
      });
      navigate('/crypto-fund-card-success');
    } catch (error) {
      toast.error(error.data.message, {
        id: submitToast,
      });
      // console.log(error);
      // navigate("/card-req-not-received");
    }
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative' }}
      className="p-3 primary-bg"
    >
      <div style={{ textAlign: 'left' }}>
        <img
          src={backArrow}
          className="rco-img1"
          style={{ width: 42, height: 30, cursor: 'pointer' }}
          alt="backArrow"
          onClick={p.close}
        />
      </div>
      <h3
        className="font-500 fs-1 text-center"
        style={{ marginTop: '-1.5rem' }}
      >
        Choose proof of payment
      </h3>
      <div className="align-items-center justify-content-between w-100 mt-2">
        <Col className="my-2">
          <div className="input-h1 idv-h1" style={{ color: 'black' }}>
            You can choose either of the two
          </div>
          <Box
            style={{
              border: '1px dashed #c4c4c4',
              borderRadius: 10,
              width: 600,
              padding: '18px 40px',
              marginTop: 5,
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <img
                src={shot}
                alt="cloud"
                className="me-3"
                style={{ height: 36 }}
              />
              <div>
                <div className="font-600 mb-1">Screenshot</div>
                <div
                  className="font-600 mb-2"
                  style={{ color: '#888', fontSize: 12 }}
                >
                  Upload a screenshot of your transfer receipt here
                </div>
              </div>
            </div>
            <div>
              <ReactFileReader
                fileTypes={['.png', '.jpg']}
                base64={true}
                handleFiles={handleFiles}
              >
                <label
                  htmlFor="file"
                  style={{
                    fontSize: 14,
                    color: 'black',
                    boxShadow: '0px 4px 4px rgba(0,0,0,.25)',
                    cursor: 'pointer',
                    maxWidth: 300,
                    marginLeft: '25%',
                    borderRadius: 10,
                    padding: '12px 0',
                  }}
                  className={`ms-4  ${
                    idImage ? 'bg-primary text-white' : 'bg-white'
                  } font-700  border rounded p-2 d-flex justify-content-center align-items-center`}
                >
                  {idImage ? 'Uploaded' : 'Upload'}
                </label>
              </ReactFileReader>
            </div>
          </Box>
        </Col>
        <Col></Col>
      </div>
      <br />
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey' }} />

        <div>
          <p style={{ width: '70px', textAlign: 'center' }}>OR</p>
        </div>

        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey' }} />
      </div>
      <br />
      <div className="align-items-center justify-content-between w-100 mt-2">
        <Col>
          <div className="input-h1" style={{ color: 'black' }}>
            Enter wallet address used to make the transfer
          </div>
          <input
            className="input-req"
            style={{ width: '100%' }}
            placeholder={'Enter wallet address'}
            type="text"
            onChange={(e) => setSenderAddress(e.target.value)}
            value={senderAddress}
          />
        </Col>
      </div>
      <br />
      <Container className="text-center mb-4">
        <div>
          <div className="font-600">
            <div className="topping mx-2">
              <input
                type="checkbox"
                id="topping"
                name="topping"
                value="Paneer"
                className="mx-2"
              />
              <span style={{ fontSize: 12 }}>
                I acknowledge the information I uploaded is correct
              </span>
            </div>
          </div>
        </div>
      </Container>
      <button
        style={{
          width: '50%',
          marginLeft: '25%',
          padding: '10px 9px',
          fontSize: '20px',
          backgroundColor: '#30ae4d',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginBottom: '2rem',
          marginTop: '0.2rem',
        }}
        onClick={handleSubmit}
      >
        Complete
      </button>
    </div>
  );
};
