import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../api';

export const accRequestApi = createApi({
  reducerPath: 'accRequestApi',
  baseQuery,
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (userId) => ({
        url: `/account/virtual-account?id=${userId}`,
        method: 'GET',
      }),
    }),
    accRequest: builder.mutation({
      query: (credentials) => ({
        url: '/account/virtual-account-v2',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    createAccounts: builder.mutation({
      query: (credentials) => ({
        url: '/account/create-virtual-account',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getVendors: builder.query({
      query: () => ({
        url: `/account/vendor`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAccRequestMutation,
  useCreateAccountsMutation,
  useGetAccountsQuery,
  useGetVendorsQuery,
} = accRequestApi;
