/* eslint-disable no-unused-vars */
import { Box, Container } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { Col, Row } from 'react-bootstrap';
import ReactFileReader from 'react-file-reader';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import cloud from '../../assets/cloud.png';
import logo from '../../assets/logo.png';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import {
  clearData,
  selectCurrentCurrency,
} from '../../features/accRequestSlice';
import { selectCurrentUserId } from '../../features/auth/authSlice';
import {
  useCreateAccountsMutation,
  useAccRequestMutation,
} from '../../services/accRequestApi';
import './ReqAccTwo.css';

import { useDispatch, useSelector } from 'react-redux';

const ReqAccOne = () => {
  const [idImage, setIDImage] = React.useState('');
  const currency = useSelector(selectCurrentCurrency);
  const Id = useSelector(selectCurrentUserId);
  const handleFiles = (files) => {
    setIDImage(files.base64);
  };

  const [accCreate] = useAccRequestMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProceed = async (e) => {
    e.preventDefault();
    const submitToast = toast.loading('Requesting...');
    try {
      const accountData = await accCreate({
        Id,
        idImage,
      }).unwrap();
      dispatch(clearData());
      toast.success(accountData.message, {
        id: submitToast,
      });
      navigate('/request-received');
    } catch (error) {
      toast.error(error.data.message, {
        id: submitToast,
      });
      setIDImage(null);
      // console.log(error);
    }
  };

  return (
    <form onSubmit={handleProceed}>
      <div className="primary-bg h-100">
        <div>
          <MobileNavbarOne />
        </div>
        <Container maxWidth={'xl'} className="w-100 pt-4 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box style={{ paddingTop: '40px' }}>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link
                style={{ textDecoration: 'none' }}
                to="/request-international-account"
              >
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: 'none' }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <div className="font-500 wn-h1">
              We’ll need some details from you
            </div>

            <Row className="justify-content-between align-items-center">
              <Col className="my-1">
                <div className="input-h1 idv-h1">
                  Upload a picture of an official document with the same address
                  provided.
                </div>
                <Box className="idv-upload" style={{ padding: '18px 60px' }}>
                  <div className="d-flex align-items-center">
                    <img
                      src={cloud}
                      alt="cloud"
                      className="me-3"
                      style={{ height: 36 }}
                    />
                    <div>
                      <div className="font-600">Drop files here</div>
                      <div
                        className="font-600"
                        style={{ color: '#888', fontSize: 14 }}
                      >
                        Max. file size: 10MB
                      </div>
                    </div>
                  </div>

                  <div>
                    <ReactFileReader
                      fileTypes={['.png', '.jpg']}
                      base64={true}
                      handleFiles={handleFiles}
                    >
                      <label
                        htmlFor="file"
                        style={{
                          fontSize: 18,
                          color: 'black',
                          boxShadow: '0px 4px 4px rgba(0,0,0,.25)',
                          cursor: 'pointer',
                          minWidth: 200,
                          borderRadius: 10,
                          padding: '12px 0',
                        }}
                        className={`ms-4  ${
                          idImage ? 'bg-primary text-white' : 'bg-white'
                        } font-700  border rounded p-2 d-flex justify-content-center align-items-center`}
                      >
                        {idImage ? 'Uploaded' : 'Browse files'}
                      </label>
                    </ReactFileReader>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <Container className="text-center">
          <button className="wn-btn1">Complete</button>
          <div style={{ height: '15vh' }}></div>
        </Container>
      </div>
    </form>
  );
};

export default ReqAccOne;
