import GHS from "../../assets/ghs.png";
import ZAR from "../../assets/zar.svg";
import BWP from "../../assets/bwp.svg";
import RWF from "../../assets/rwf.png";
import KES from "../../assets/kes.svg";
import TZS from "../../assets/tzs.svg";
import NGN from "../../assets/nigeria.png";

const africanCurrenies = [
  {
    id: 10,
    name: "NGN",
    image: NGN,
  },
  // {
  //   id: 20,
  //   name: "GHS",
  //   image: GHS,
  // },
  // {
  //   id: 30,
  //   name: "ZAR",
  //   image: ZAR,
  // },
  // {
  //   id: 40,
  //   name: "BWP",
  //   image: BWP,
  // },
  // {
  //   id: 50,
  //   name: "RWF",
  //   image: RWF,
  // },
  // {
  //   id: 60,
  //   name: "TZS",
  //   image: TZS,
  // },
  // {
  //   id: 70,
  //   name: "KES",
  //   image: KES,
  // },
];

export default africanCurrenies;
