import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   usdlink: null,
  //   usdafriex: null,
  //   usdwyreout: null,
  //   usdaccess: null,
  //   klasha: null,
  //   usdjumpafrica: null,
  //   usdpayday: null,
  //   usdkiakia: null,
  //   usdvella: null,
  //   usdkaoshi: null,
  //   usdgrey: null,
  //   usdwirepay: null,
  //   usdchangera: null,
  //   usdlocalp2p: null,
  //   usdsend: null,
  //   usdsendcash: null,

  //   eurlink: null,
  //   eurfirstbank: null,
  //   eurlocalp2p: null,
  //   eurjumpafrica: null,
  //   eurkaoshi: null,
  //   eurgrey: null,
  //   eurkiakia: null,
  //   eursend: null,
  //   eurplumter: null,

  //   gbplink: null,
  //   gbpfcmb: null,
  //   gbpgrey: null,
  //   gbpchipper: null,
  //   gbplemonade: null,
  //   gbplocalp2p: null,
  //   gbpjumpafrica: null,
  //   gbpkaoshi: null,
  //   gbpkyshi: null,
  //   gbpkiakia: null,
  //   gbpplumter: null,
  //   gbpsend: null,
  //   gbpsendcash: null,

  //   trylink: null,
  //   trylocalp2p: null,
  //   trygtbank: null,

  //   cadlink: null,
  //   cadpesapeer: null,
  //   cadlemonade: null,
  //   cadlocalp2p: null,
  //   cadsendcash: null,

  //   keslink: null,
  //   kessendcash: null,
  //   kesjumpafrica: null,
  //   keslocalp2p: null,
  //   kesgrey: null,
  //   keswirepay: null,

  //   cnhlink: null,
  //   cnhplumter: null,
  //   cnhvella: null,

  usdValue: "access",
  eurValue: "send",
  gbpValue: "grey",
  tryValue: "localp2p",
  cadValue: "lemonade",
  kesValue: "jumpafrica",
  cnhValue: "vella",

  competitors: {},
};

export const competitorSlice = createSlice({
  name: "competitor",
  initialState,
  reducers: {
    setCompetitors: (state, action) => {
      const { value } = action.payload;
      state.competitors = action.payload;
    },
    setUsdCompetitor: (state, action) => {
      const { value } = action.payload;
      state.usdValue = value;
    },
    setEurCompetitor: (state, action) => {
      const { value } = action.payload;
      state.eurValue = value;
    },
    setGbpCompetitor: (state, action) => {
      const { value } = action.payload;
      state.gbpValue = value;
    },
    setTryCompetitor: (state, action) => {
      const { value } = action.payload;
      state.tryValue = value;
    },
    setCadCompetitor: (state, action) => {
      const { value } = action.payload;
      state.cadValue = value;
    },
    setKesCompetitor: (state, action) => {
      const { value } = action.payload;
      state.kesValue = value;
    },
    setCnhCompetitor: (state, action) => {
      const { value } = action.payload;
      state.cnhValue = value;
    },
  },
});

export const {
  setUsdCompetitor,
  setEurCompetitor,
  setGbpCompetitor,
  setTryCompetitor,
  setCadCompetitor,
  setKesCompetitor,
  setCnhCompetitor,
  setCompetitors,
} = competitorSlice.actions;
export default competitorSlice.reducer;

export const selectUsdValue = (state) => state.competitor.usdValue;
export const selectEurValue = (state) => state.competitor.eurValue;
export const selectGbpValue = (state) => state.competitor.gbpValue;
export const selectTryValue = (state) => state.competitor.tryValue;
export const selectCadValue = (state) => state.competitor.cadValue;
export const selectKesValue = (state) => state.competitor.kesValue;
export const selectCnhValue = (state) => state.competitor.cnhValue;
export const selectCompetitors = (state) => state.competitor.competitors;
