import React from "react";
import backArrow from "../../../assets/arrow-left.png";
import closeBtn from "../../../assets/close-square.png";

import Box from "@mui/material/Box";
import logo from "../../../assets/logo.png";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import CadTabs from "./CadTabs";

const TransferCad = () => {
  return (
    <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="rco-box1">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/dashboard">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/dashboard">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <span className="rco-h2">Cancel</span>
              <img src={closeBtn} alt="closeBtn" className="rco-img2" />
            </button>
          </Link>
        </Box>
      </Container>
      <Container>
        <CadTabs />
        <div style={{ minHeight: "20vh" }}></div>
      </Container>
    </div>
  );
};

export default TransferCad;
