import USD from "../../assets/usa.png";
import EUR from "../../assets/ukraine.png";
import GBP from "../../assets/britain.png";
import TRY from "../../assets/turkey.png";
import CNY from "../../assets/CNY.png";
import CHF from "../../assets/CHF.svg";
import CAD from "../../assets/CAD.svg";
import AED from "../../assets/AED.svg";
import KES from "../../assets/kes.svg";

const foreignCurrencies = [
  {
    id: 10,
    name: "USD",
    image: USD,
  },
  {
    id: 20,
    name: "GBP",
    image: GBP,
  },
  {
    id: 30,
    name: "EUR",
    image: EUR,
  },
  {
    id: 40,
    name: "TRY",
    image: TRY,
  },
  {
    id: 50,
    name: "CNH",
    image: CNY,
  },
  {
    id: 60,
    name: "CHF",
    image: CHF,
  },
  {
    id: 70,
    name: "CAD",
    image: CAD,
  },
  {
    id: 80,
    name: "AED",
    image: AED,
  },
  {
    id: 90,
    name: "KES",
    image: KES,
  },
];

export default foreignCurrencies;
