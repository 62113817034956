import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/system';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuItem, Select, Typography } from '@mui/material';
import NGN from '../../assets/nigeria.png';
import USDC from '../../assets/usdc.png';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const FundCardExchange = () => {
  const [youGet, setYouGet] = React.useState(50);
  const [youSend, setYouSend] = React.useState(50);
  const [paymentMethod, setPaymentMethod] = React.useState('NGN_Wallet');
  let [fees, setFees] = useState(0);
  const navigate = useNavigate();

  const inputCheck = () => {
    if (youGet < 0) {
      return setYouGet(0);
    }
    if (youGet > 2000) {
      return setYouGet(2000);
    }
  };

  const handleAmountTotal = () => {
    // setFees = youGet * 0.01;
    return setYouSend(youGet * rate);
  };

  const location = useLocation();
  const { rate, hashtag, bankName, accountNumber, accountName } =
    location.state;

  // useEffect
  useEffect(() => {
    inputCheck();
  }, [youGet]);

  useEffect(() => {
    handleAmountTotal();
  }, [youGet, youSend]);

  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Box>
        <Container maxWidth="md" className="rco-box1">
          <Box className="d-flex justify-content-between align-items-center">
            <Link
              style={{ textDecoration: 'none' }}
              to="/fund-card/fiat/vendors"
            >
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <img src={backArrow} alt="backArrow" className="rco-img1" />
                <span className="rco-h2">Go Back</span>
              </button>
            </Link>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <button
                className="rco-btn1 font-600"
                style={{ color: '#1565D8' }}
              >
                <span className="rco-h2">Cancel</span>
                <img src={closeBtn} alt="closeBtn" className="rco-img2" />
              </button>
            </Link>
          </Box>
        </Container>
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <div
              className="font-700 ro-h1 py-4 mb-2"
              style={{ textAlign: 'center' }}
            >
              How much do you want to Fund?
            </div>
            <form>
              <Box
                className="font-400 px-2 py-1 d-flex align-items-center rounded"
                style={{
                  fontSize: 16,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>
                  Amount{' '}
                  <span style={{ color: '#1856AF', fontSize: 16 }}>
                    (minimum $15)
                  </span>
                </p>
                <p
                  style={{
                    fontSize: 18,
                    marginTop: '0px',
                  }}
                >
                  Currency:
                </p>
              </Box>
              <div className="py-2 input-transfer-container">
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    style={{
                      width: '50%',
                    }}
                  >
                    <input
                      type="number"
                      min="15"
                      className="input-transfer"
                      value={youGet}
                      onChange={(e) => setYouGet(e.target.value)}
                    />
                  </div>
                  <div>
                    <MenuItem
                      className="d-flex align-items-center px-2 font-500 mr-2"
                      style={{
                        borderLeft: '1px solid grey',
                      }}
                      value="USDC"
                    >
                      <img
                        style={{
                          height: 30,
                          width: 45,
                          marginRight: 10,
                          marginLeft: 10,
                          objectFit: 'contain',
                        }}
                        src={USDC}
                        alt="flag"
                      />
                      USDC
                    </MenuItem>
                  </div>
                </div>
              </div>
              <Box
                className="my-2 font-500 px-2 py-1 d-flex align-items-center rounded"
                style={{
                  fontSize: 16,
                  background: '#E7EFFF',
                  color: '#1856AF',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>1 USD = {rate} NGN</p>
                <p>(fees included)</p>
              </Box>
              <div className="py-2 input-transfer-container">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="input-transfer">{youSend}</div>
                  <div>
                    <MenuItem
                      className="d-flex align-items-center px-2 font-500 mr-2"
                      style={{
                        borderLeft: '1px solid grey',
                      }}
                      value="NGN."
                    >
                      <img
                        style={{
                          height: 30,
                          width: 45,
                          marginRight: 12,
                          marginLeft: 10,
                          objectFit: 'contain',
                        }}
                        src={NGN}
                        alt="flag"
                      />
                      NGN
                    </MenuItem>
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontSize: 12,
                  textAlign: 'center',
                  fontWeight: 600,
                  marginTop: 20,
                  marginBottom: 40,
                  color: '#1856AF',
                }}
              >
                Please input a minimum 15 USDC or the order will not be
                processed
              </p>
              {/* <div className="my-2">
                <div className="input-h1 fs-6">Payment method</div>
                <Select
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="font-500"
                  label={false}
                  fullWidth
                  required
                  style={{ background: 'white' }}
                >
                  <MenuItem
                    className="d-flex align-items-center px-3 font-500"
                    value="NGN_Wallet"
                  >
                    <img
                      style={{
                        height: 30,
                        width: 50,
                        marginRight: 10,
                        objectFit: 'contain',
                      }}
                      src={NGN}
                      alt="flag"
                    />
                    LINK NGN wallet
                  </MenuItem>
                  <hr className="m-0" />
                </Select>
              </div> */}
              <Link
                style={{
                  textDecoration: 'none',
                  width: '100%',
                  padding: '10px 30px',
                  fontSize: '20px',
                  backgroundColor: '#30ae4d',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  marginTop: '1rem',
                  marginLeft: '30%',
                }}
                to={`/${hashtag}-fund-card/fiat/review-order`}
                state={{
                  bankName: bankName,
                  accountNumber: accountNumber,
                  accountName: accountName,
                  fund: youGet,
                  sent: youSend,
                }}
              >
                Continue
              </Link>
            </form>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default FundCardExchange;
