import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const eurWithdrawApi = createApi({
  reducerPath: "eurWithdrawApi",
  baseQuery,
  endpoints: (builder) => ({
    inside: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/eur/inside",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    outside: builder.mutation({
      query: (credentials) => ({
        url: "/withdraw/eur/outside",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useInsideMutation, useOutsideMutation } = eurWithdrawApi;
