import { Container } from "@mui/system";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import close from "../../assets/close-square.png";
import email from "../../assets/email.png";
import logo from "../../assets/logo.png";

const Verify = (p) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="text-center font-500 fs-3">Verify your email</div>
      <br />
      <div className="d-flex justify-content-center w-100">
        <img src={email} alt="email" />
      </div>
      <br />
      <div className="fs-4 text-center font-500 pt-4">Check your email</div>
      <div
        className="text-center font-600 py-4 fs-5"
        style={{ color: "#A4A5A6" }}
      >
        <div>
          Open your email app to confirm your <br /> email address, tap the
          button in the <br /> email we sent you.
        </div>
      </div>
      <Container className="text-center">
        <Link
          to="/"
          className="ms-1 font-500"
          style={{ color: "#1565D8", textDecoration: "none" }}
        >
          <button className="wn-btn1">Continue</button>
        </Link>
      </Container>
    </>
  );
};

export default Verify;
