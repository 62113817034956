import { Box, Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import info from "../../../assets/info.png";
import toast from "react-hot-toast";
import { useIbanMutation } from "../../../services/gbpWithdrawApi";

const GbpAcc = () => {
  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban_AccountNumber, setIban_AccountNumber] = React.useState("");
  const [swift_BicCode, setSwift_BicCode] = React.useState("");

  const navigate = useNavigate();
  const [iban] = useIbanMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await iban({
        fullName,
        bankName,
        iban_AccountNumber,
        swift_BicCode,
        transactionType: "Iban",
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        className="my-5 font-500 d-flex align-items-center p-2 rounded"
        style={{
          fontSize: 13,
          background: "#E7EFFF",
          color: "#1856AF",
        }}
      >
        <img src={info} alt="info" className="me-2" />
        To send EUR outside Europe, we’ll need to use the SWIFT network. The
        transfer may take 2-5 days longer. Other banks involved may also charge
        fees.
      </Box>
      <Row className="justify-content-between align-items-center mt-4">
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5">Full name of account holder</div>
          <input
            className="input-req w-100"
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Col>
        <Col className="my-1" xs={12} lg={6}>
          <div className="input-h1 fs-5">Bank name</div>
          <input
            className="input-req w-100"
            placeholder="Enter bank name"
            value={bankName}
            required
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className="my-1">
          <div className="input-h1 fs-5">IBAN / Account number</div>
          <input
            className="input-req w-100"
            placeholder="Enter number"
            value={iban_AccountNumber}
            required
            onChange={(e) => setIban_AccountNumber(e.target.value)}
          />
        </Col>
        <Col xs={12} lg={6} className="my-1">
          <div className="input-h1 fs-5">SWIFT / BIC Code</div>
          <input
            className="input-req w-100"
            placeholder="Enter code"
            value={swift_BicCode}
            required
            onChange={(e) => setSwift_BicCode(e.target.value)}
          />
        </Col>
      </Row>
      <div className="text-center">
        <button className="wn-btn1" type="submit">
          Continue
        </button>
      </div>
    </form>
  );
};

export default GbpAcc;
