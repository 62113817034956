import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import copyIcon from '../../../assets/copy_2.png';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import cd from '../../../assets/cd.png';
import close from '../../../assets/close.png';
import grammerly from '../../../assets/grammerly.png';
import { selectCurrentUserId } from '../../../features/auth/authSlice';
import {
  useFreezeCardMutation,
  useUnFreezeCardMutation,
} from '../../../services/accountCardApi';
import FundCardOld from '../FundCard_Old';
import './CreateCard.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: window.innerHeight - 100,
  overflowY: 'scroll',
  borderRadius: 3,
  width: 450,
  zIndex: 100,
};

const CardDetails = (p) => {
  const [cc, setCc] = useState('**************');
  const [cvv, setCvv] = useState('***');
  const [exp, setExp] = useState('****');
  const [img, setImg] = useState(grammerly);
  const [is_active, setIs_active] = useState(p.is_active);
  const [open2, setOpen2] = useState(false);

  const userId = useSelector(selectCurrentUserId);
  const [freezeCard] = useFreezeCardMutation();
  const [unFreezeCard] = useUnFreezeCardMutation();
  const navigate = useNavigate();

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClick = () => {
    setCc(p.card_number);
    setCvv(p.cvv);
    setExp(p.expiry);
    setImg(cd);
  };
  const handleClick2 = () => {
    setCc('***************');
    setCvv('***');
    setExp('****');
    setImg(grammerly);
  };

  const handleFreezeUnFreezeAccount = async () => {
    const toastSubmit = toast.loading(
      `${is_active ? 'Freezing...' : 'UnFreezing...'}`
    );

    try {
      if (is_active) {
        await freezeCard(userId).unwrap();
        toast.success('This card has been frozen successfully', {
          id: toastSubmit,
        });
        setIs_active(false);
      }
      if (!is_active) {
        await unFreezeCard(userId).unwrap();
        toast.success('This card has been unfrozen successfully', {
          id: toastSubmit,
        });
        setIs_active(true);
      }
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      console.log(error);
    }
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast('✅ Copied to clipboard');
  };

  return (
    <div
      style={{ borderRadius: 10, position: 'relative', border: 'none' }}
      className="primary-bg"
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img
          src={close}
          onClick={p.close || p.onClose}
          style={{ width: 40, height: 40, cursor: 'pointer' }}
          alt="close"
        />
      </div>
      <div className="px-5">
        <div
          className="mt-0 ml-2 card text-white"
          style={{ width: '90%', borderRadius: 5 }}
        >
          <img
            src={p.cardImg}
            className="card-img"
            alt="..."
            style={{ width: '100%', height: '40%' }}
          />
          <div className="card-img-overlay">
            <br />
            <br />
            <br />
            <div className="h-20 w-100">
              <div className="fs-4">Available balance</div>
              <div className="fs-1">
                {p.balance}
                <span style={{ fontSize: '16px' }}> USD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pt-4">
        {is_active && (
          <button
            style={{
              width: '90%',
              padding: '10px 9px',
              fontSize: '20px',
              backgroundColor: '#30ae4d',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              margin: '0rem  0 1.3rem',
            }}
            onClick={() => navigate('/fund-card')}
          >
            Fund Card
          </button>
        )}
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Name on card:
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              value={p.card_name}
              type="text"
              style={{ textTransform: 'capitalize' }}
            />
          </div>
          <div onClick={() => handleCopy(p.card_name)}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Card number
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value={cc}
              style={{ letterSpacing: '1px' }}
            />
          </div>
          <div onClick={() => handleCopy(cc)}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Card CVV{}
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value={cvv}
            />
          </div>
          <div onClick={() => handleCopy(cvv)}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Card expiry date
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value={exp}
            />
          </div>
          <div onClick={() => handleCopy(exp)}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        {/* <div
          className="d-flex align-items-center my-4"
          style={{ maxWidth: 350 }}
        >
          <div className="input-container-2 me-3">
            <label htmlFor="input-create-card-name" id="label-name">
              Expiry date
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value={exp}
              style={{ background: 'transparent' }}
            />
          </div>
          <div className="input-container-3 me-4">
            <label htmlFor="input-create-card-name" id="label-name">
              CVC{}
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value={cvv}
              style={{ background: 'transparent' }}
            />
          </div>
        </div> */}
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Card address
            </label>
            <input
              className="input-create-card billing"
              id="input-create-card-name"
              disabled
              type="text"
              value={`256 Chapman Road STE 105-4`}
            />
          </div>
          <div onClick={() => handleCopy('256 Chapman Road STE 105-4, NW')}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        {/* <div className="d-flex mb-3">
          <div className="input-container-3 me-4" style={{ width: '43%' }}>
            <label htmlFor="input-create-card-name" id="label-name">
              City
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="Newark"
            />
          </div>
          <div className="input-container-2 me-3" style={{ width: '42%' }}>
            <label htmlFor="input-create-card-name" id="label-name">
              State
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="Delaware"
            />
          </div>
        </div> */}
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              address
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="Newark, United States"
            />
          </div>
          <div onClick={() => handleCopy('Newark, United States')}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex align-items-center input-container">
          <div className="input-field">
            <label htmlFor="input-create-card-name" id="label-name">
              Zip code
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="19702"
            />
          </div>
          <div onClick={() => handleCopy('19702')}>
            <img
              src={copyIcon}
              style={{ height: 18, width: 18, cursor: 'pointer' }}
              alt=""
            />
          </div>
        </div>
        {/* <div
          className="d-flex align-items-center my-4"
          style={{ maxWidth: 350 }}
        >
          <div className="input-container-2 me-3">
            <label htmlFor="input-create-card-name" id="label-name">
              State
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="Delaware"
              style={{ background: 'transparent' }}
            />
          </div>
          <div className="input-container-3 me-4">
            <label htmlFor="input-create-card-name" id="label-name">
              Zip code
            </label>
            <input
              className="input-create-card fs-5"
              id="input-create-card-name"
              disabled
              type="text"
              value="19702"
              style={{ background: 'transparent' }}
            />
          </div>
        </div> */}
        <button
          style={{
            width: '90%',
            padding: '10px 9px',
            fontSize: '20px',
            backgroundColor: `${is_active ? 'black' : 'red'}`,
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginBottom: '2rem',
          }}
          onClick={handleFreezeUnFreezeAccount}
        >
          {is_active ? 'Freeze Card' : 'Unfreeze Card'}
        </button>
      </div>

      <div
        style={{
          position: 'absolute',
          right: 10,
          top: 290,
          width: 40,
          height: 40,
          background: 'white',
          borderRadius: 10,
          padding: 0,
        }}
      >
        <img
          src={img}
          onClick={() => {
            if (img === grammerly) {
              handleClick();
            } else {
              handleClick2();
            }
          }}
          style={{ cursor: 'pointer' }}
          alt="show"
        />
      </div>
    </div>
  );
};

export default CardDetails;
