import React, { useEffect } from 'react';
import logo from '../../assets/logo.png';
import backArrow from '../../assets/arrow-left.png';
import closeBtn from '../../assets/close-square.png';
import { Col, Row } from 'react-bootstrap';
import cloud from '../../assets/cloud.png';
import NIN from '../../assets/NIN.png';
import NINSLIP from '../../assets/NINSLIP.png';
// import BVN from "../../assets/BVN.png";
import DL from '../../assets/DL.png';
import shieldTick from '../../assets/shield-tick.png';
import ngn from '../../assets/nigeria.png';
import ghs from '../../assets/ghs.png';
import zar from '../../assets/zar.svg';
import bwp from '../../assets/bwp.svg';
import rwf from '../../assets/rwf.png';
import kes from '../../assets/kes.svg';
import tzs from '../../assets/tzs.svg';
import { Box, Container, MenuItem, Select } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import calendar from '../../assets/calendar.png';
import ReactFileReader from 'react-file-reader';
import {
  useAccVerifyMutation,
  useVerifyStatusMutation,
} from '../../services//accVerifyApi';
import toast from 'react-hot-toast';
import {
  selectCurrentToken,
  selectCurrentUserId,
  selectCurrentFirstName,
  selectCurrentSurName,
  setCredentials,
  selectCurrentAccLock,
  selectCurrentEmail,
} from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { url2 } from '../../api';

const AddressVerification = () => {
  const [country, setCountry] = React.useState('Select Country');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('Select state');
  const [zipCode, setZipCode] = React.useState('');
  const [street, setStreet] = React.useState('');

  const token = useSelector(selectCurrentToken);
  const userId = useSelector(selectCurrentUserId);
  const firstName = useSelector(selectCurrentFirstName);
  const surName = useSelector(selectCurrentSurName);
  const accLock = useSelector(selectCurrentAccLock);
  const email = useSelector(selectCurrentEmail);

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token && !userId) return navigate('/signin');
    if (token && userId && accLock === 'verified')
      return navigate('/dashboard');
    if (accLock === 'verifying') return navigate('/dashboard-unverified');
  });

  const [accVerify] = useAccVerifyMutation();
  const [verifyStatus] = useVerifyStatusMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading('Sending Details...');
    try {
      const userData = await accVerify({
        ...location.state,
        street,
        city,
        country,
        state,
        zipCode,
        firstName,
        surName,
        userId,
        email,
      }).unwrap();

      if (userData.status === 'success') {
        const status = await verifyStatus({
          status: 'verifying',
          plus: true,
          userId,
        }).unwrap();
        toast.success(status.message, { id: toastSubmit });
        navigate('/verification-in-progress');
      }
    } catch (error) {
      navigate('/request-not-received');
      toast.error(error.data.message, { id: toastSubmit });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100">
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={'xl'} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: 'none' }} to="/id-verification">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link
                style={{ textDecoration: 'none' }}
                to="/dashboard-unverified"
              >
                <button
                  className="rco-btn1 font-600"
                  style={{ color: '#1565D8' }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <div className="font-600 wn-h1">Address</div>

            <Row className="justify-content-between align-items-center mt-3">
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1">Country</div>
                <div>
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="font-500"
                    label={false}
                    fullWidth
                    required
                    style={{ background: 'white' }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Select Country"
                    >
                      Select Country
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Nigeria"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={ngn}
                        alt="flag"
                      />
                      Nigeria
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Ghana"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={ghs}
                        alt="flag"
                      />
                      Ghana
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="South Africa"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={zar}
                        alt="flag"
                      />
                      South Africa
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Botswana"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={bwp}
                        alt="flag"
                      />
                      Botswana
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Rwanda"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={rwf}
                        alt="flag"
                      />
                      Rwanda
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Kenya"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={kes}
                        alt="flag"
                      />
                      Kenya
                    </MenuItem>
                    <hr className="m-0" />
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      // value="Tanzania"
                    >
                      <img
                        style={{ height: 42, width: 42, marginRight: 20 }}
                        src={tzs}
                        alt="flag"
                      />
                      Tanzania
                    </MenuItem>
                  </Select>
                </div>
              </Col>
              <Col className="idv-col1 mb-3" xs={12} md={6}>
                <div className="input-h1">state</div>
                <div>
                  <Select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="font-500"
                    label={false}
                    fullWidth
                    required
                    style={{ background: 'white' }}
                  >
                    <MenuItem
                      className="d-flex align-items-center px-3 font-500"
                      value="Select state"
                    >
                      Select state
                    </MenuItem>
                    <hr className="m-0" />
                    {listOfStates()}
                  </Select>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-between align-items-center mt-3">
              <Col className="idv-col1" xs={12} md={6}>
                <div className="input-h1">ZIP code / Postal code</div>
                <input
                  className="input-req idv-input1"
                  placeholder={'Enter zip code / postal code'}
                  required
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </Col>

              <Col className="idv-col1" xs={12} md={6}>
                <div className="input-h1">City</div>
                <input
                  className="input-req me-3 w-100"
                  placeholder={'Mainland'}
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ height: 50 }}
                />
              </Col>
            </Row>

            <Col className="idv-col1 mt-3">
              <div className="input-h1">Street</div>
              <input
                className="input-req idv-input1"
                placeholder={'4th Maryland road, simpson way'}
                required
                value={street}
                type="text"
                onChange={(e) => setStreet(e.target.value)}
              />
            </Col>
          </Container>
        </Box>
        <Container className="text-center">
          <button className="idv-btn1" type="submit">
            Complete Verification
          </button>
        </Container>
      </div>
    </form>
  );
};

const statesInNigeria = [
  'Abuja',
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
];

export const listOfStates = () => {
  const stateList = statesInNigeria.map((items, index) => (
    <MenuItem
      key={index}
      className="d-flex align-items-center px-3 font-500"
      value={items}
    >
      {items}
    </MenuItem>
  ));
  return stateList;
};
export default AddressVerification;
