import React, { useState } from "react";
import TabNavItem from "./V2/TabNavItem";
import TabContent from "./V2/TabContent";
import "./Tabs.css";
import USD from "./components/USD";
import GBP from "./components/GBP";
import TRY from "./components/TRY";
import EUR from "./components/EUR";
import CAD from "./components/CAD";
import KES from "./components/KES";
import CNY from "./components/CNY";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";

const Tabss = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: {
          md: "80%",
          sm: 980,
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        <Tab label="USD" />
        <Tab label="EUR" />
        <Tab label="GBP" />
        <Tab label="TRY" />
        <Tab label="CAD" />
        {/* <Tab label="CHF" /> */}
        <Tab label="CNH" />
        {/* <Tab label="AED" /> */}
        <Tab label="KES" />
      </Tabs>

      <TabPanel id="tab1" value={value} index={0}>
        <USD />
      </TabPanel>
      <TabPanel id="tab2" value={value} index={1}>
        <EUR />
      </TabPanel>
      <TabPanel id="tab3" value={value} index={2}>
        <GBP />
      </TabPanel>
      <TabPanel id="tab4" value={value} index={3}>
        <TRY />
      </TabPanel>
      <TabPanel id="tab5" value={value} index={4}>
        <CAD />
      </TabPanel>
      {/* <TabPanel id="tab6" value={value} index={5}> */}
      {/* <CHF /> */}
      {/* </TabPanel> */}
      <TabPanel id="tab7" value={value} index={5}>
        <CNY />
      </TabPanel>
      {/* <TabPanel id="tab8" value={value} index={7}> */}
      {/* <AED /> */}
      {/* </TabPanel> */}
      <TabPanel id="tab9" value={value} index={6}>
        <KES />
      </TabPanel>
    </Box>
  );
};

export default Tabss;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
