/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import addCircle from '../../assets/add-circle.png';
import arrowLeft from '../../assets/arrow-left.png';
import britain from '../../assets/britain.png';
import usa from '../../assets/usa.png';
import logo from '../../assets/logo.png';
import Ngn from '../../assets/nigeria.png';
import Multicurrency from '../../assets/multicurrency.png';
import MobileNavbarOne from '../../components/MobileNavbarOne';
import { setPhaseOne } from '../../features/accRequestSlice';
import './RequestIntlAcc.css';
import { useGetAccountsQuery } from '../../services/accRequestApi';
import { selectCurrentUserId } from '../../features/auth/authSlice';

const RequestIntlAcc = () => {
  const [intAccount, setIntAccount] = useState('');
  const [hasNgnAccount, setHasNgnAccount] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector(selectCurrentUserId);
  const { data, error, isLoading } = useGetAccountsQuery(id);

  useEffect(() => {
    if (data) {
      const activeAccounts = data.accounts.filter(
        (item) => item.currency === 'NGN'
      );
      setHasNgnAccount(activeAccounts.length === 0 ? false : true);
      console.log(activeAccounts);
    }
  }, [data, isLoading]);

  console.log(hasNgnAccount);

  useEffect(() => {
    intAccount !== '' && selectAccount();
  }, [intAccount]);

  const selectAccount = () => {
    try {
      dispatch(setPhaseOne({ intAccount }));
      navigate('/request-account');
    } catch (error) {}
  };

  return (
    <>
      <Box className="primary-bg">
        <div>
          <MobileNavbarOne />
        </div>
        <Container maxWidth={'xl'} className="w-100 pt-4 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <br />
        <Container className="riac-box3">
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <div
              className="d-flex align-items-center my-3"
              style={{ color: '#1565D8' }}
            >
              <img
                src={arrowLeft}
                style={{ width: 28, marginRight: 10 }}
                alt=""
              />
              <span className="font-600 fs-5 riac-h1">
                Go back to the dashboard
              </span>
            </div>
          </Link>
          <div className="font-500 wn-h1">Choose an international account</div>

          <Box className="my-3 d-flex align-items-center">
            <div
              className="riac-box2 d-flex align-items-center bg-white"
              style={{ width: '100%', borderRadius: 7, cursor: 'pointer' }}
            >
              <div className="riac-cont">
                <img className="riac-img1" src={Ngn} alt="Nigeria" />
              </div>
              <div>
                <div className="font-700 riac-h4">NGN</div>
                <div className="font-600 riac-h3">With an account number</div>
              </div>
            </div>
            <div style={{ textDecoration: 'none' }}>
              <button className="riac-btn1" onClick={setIntAccount}>
                <img src={addCircle} alt="" />
                <span className="sm-none"> Request account</span>
              </button>
            </div>
          </Box>

          {/* <Box className="my-3 d-flex align-items-center">
            <div
              className="riac-box2 d-flex align-items-center bg-white"
              style={{ width: '100%', borderRadius: 7, cursor: 'pointer' }}
            >
              <div className="riac-cont">
                <img className="" src={Multicurrency} alt="Nigeria" />
              </div>
              <div>
                <div className="font-700 riac-h4">Multi-currency</div>
                <div className="font-600 riac-h3">
                  With an IBAN for multiple currency
                </div>
              </div>
            </div>
            <div style={{ textDecoration: 'none' }}>
              <button className="riac-btn1" onClick={() => {}}>
                <img src={addCircle} alt="" />
                <span className="sm-none"> Request account</span>
              </button>
            </div>
          </Box> */}
          <div style={{ height: '10vh' }}></div>
        </Container>
      </Box>
    </>
  );
};

export default RequestIntlAcc;
