import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api";

export const recipientApi = createApi({
  reducerPath: "recipientApi",
  baseQuery,
  endpoints: (builder) => ({
    getRecipient: builder.query({
      query: (userId) => ({
        url: `/recipient?id=${userId}`,
        method: "GET",
      }),
    }),
    recipientBank: builder.mutation({
      query: (credentials) => ({
        url: "/recipient/bank",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    recipientAddress: builder.mutation({
      query: (credentials) => ({
        url: "/recipient/address",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    deleteRecipient: builder.mutation({
      query: (credentials) => ({
        url: `/recipient/?userId=${credentials.userId}?id=${credentials.id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useRecipientBankMutation,
  useRecipientAddressMutation,
  useGetRecipientQuery,
  useDeleteRecipientMutation,
} = recipientApi;
