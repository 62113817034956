import React from "react";
import logo from "../../../assets/logo.png";
import { Col, Row } from "react-bootstrap";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MobileNavbarOne from "../../../components/MobileNavbarOne";
import backArrow from "../../../assets/close-square.png";

const ReverseEurTransfer = () => {
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>

      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
        <Box className="d-flex justify-content-between align-items-center">
          <div></div>
          <Link style={{ textDecoration: "none" }} to="/dashboard">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
            </button>
          </Link>
        </Box>
      </Container>
      <Container
        className="wr-box1"
        style={{ maxWidth: "800px", margin: "5rem auto 0" }}
      >
        <Typography className="text-center font-500" variant="h4">
          You'll be initiating a P2P Transfer to a LINK Partner.
        </Typography>

        <Typography variant="h6" className="text-center my-4 font-500">
          Please send the exact amount of Euros.
        </Typography>
        <Row className="justify-content-between align-items-center mt-4">
          <Col className="my-1" xs={12} lg={6}>
            <div className="text-center">
              <Link to="/eur-in-transfer-details">
                <button className="wn-btn1">Inside EU and SEPA</button>
              </Link>
            </div>
          </Col>
          <Col className="my-1" xs={12} lg={6}>
            <div className="text-center">
              <Link to="/eur-out-transfer-details">
                <button className="wn-btn1">Outside EU</button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReverseEurTransfer;
