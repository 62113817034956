import React from "react";
import Info from "../../assets/info.svg";
import close from "../../assets/close-square.png";
import "./alpha_test.css";
import { Link } from "react-router-dom";

const NoAccess = () => {
  return (
    <div className="cont">
      <div>
        <Link to="/dashboard">
          <img src={close} alt="close" className="img" />
        </Link>
      </div>
      <div className="bod">
        <div>
          <img src={Info} alt="info" style={{ width: "80px" }} />
        </div>
        <div className="text">
          <h2 className="heading">Service is currently unavailable.</h2>
          <p className="info">
            This feature is currently being developed. Once completed, you will
            get full access to it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
