import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import "../CompareRates.css";
import LINK from "../../../assets/LINK.png";
import wirepay from "../../../assets/wirepay.svg";
import send from "../../../assets/send.png";
import grey from "../../../assets/grey.png";
import jumpafrica from "../../../assets/Jumpafrica.png";
import {
  selectKesValue,
  setKesCompetitor,
  selectTryValue,
} from "../../../features/competitorSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompetitorsQuery } from "../../../services/excelApi";

const KES = () => {
  const competitor = useSelector(selectKesValue);
  const [kesCompetitors, setKesCompetitors] = React.useState({});

  const [competitorRate, setCompetitorRate] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [competitorFee, setCompetitorFee] = React.useState("");

  const { data, isLoading } = useGetCompetitorsQuery("competitors", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    !isLoading && setKesCompetitors({ ...data?.kesCompetitors });
  }, [data, isLoading]);

  useEffect(() => {
    handlecompair(competitor);
    setRate(kesCompetitors.link);
  }, [kesCompetitors, competitor]);

  const handlecompair = (competitor) => {
    switch (competitor) {
      case "send":
        setCompetitorFee("1 KES");
        setCompetitorRate(kesCompetitors.send);
        break;
      case "jumpafrica":
        setCompetitorFee("");
        setCompetitorRate(kesCompetitors.jumpafrica);
        break;
      case "localp2p":
        setCompetitorFee("");
        setCompetitorRate(kesCompetitors.localp2p);
        break;
      case "grey":
        setCompetitorFee("1%");
        setCompetitorRate(kesCompetitors.grey);
        break;
      case "wirepay":
        setCompetitorFee("");
        setCompetitorRate(kesCompetitors.wirepay);
        break;
      default:
        return 0;
    }
  };
  return (
    <div>
      <br />
      <div className="link-container">
        <div>
          <img src={LINK} alt="LINK" className="cr-img1" />
        </div>
        <div>
          <div className="cr-h1 font-700">1 KES = {rate} NGN</div>
          {/* <div
            className="cr-h2 font-300"
            style={{ color: "#2DD06E", textAlign: "right" }}
          >
            saves upto 59%
          </div> */}
          <div
            className="cr-h3 font-600"
            style={{ color: "#fff", textAlign: "right" }}
          >
            0-5% Transfer fees
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center bg-white px-3 py-3"
        style={{ borderRadius: "0px 0px 8px 8px" }}
      >
        <div>
          <SelectExchangeMethod />
        </div>
        <div>
          <div className="cr-h4 font-500">1 KES ={competitorRate} NGN</div>
          <div
            className="cr-h5 font-300"
            style={{ color: "#a4a5a6", textAlign: "right" }}
          >
            {competitorFee}
          </div>
        </div>
      </div>
      <div style={{ height: "20vh" }}></div>
    </div>
  );
};

export default KES;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: window.innerWidth < 992 ? "130px" : "250px",
    },
  },
};

function SelectExchangeMethod() {
  const competitor = useSelector(selectKesValue);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(setKesCompetitor({ value }));
  };

  return (
    <div>
      <Select
        value={competitor}
        onChange={handleChange}
        className="font-500  "
        label={false}
        MenuProps={MenuProps}
        style={{
          width: window.innerWidth < 992 ? 130 : 250,
        }}
      >
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-700"
          value="localp2p"
          style={{ height: 50 }}
        >
          Local Peer-To-Peer
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="jumpafrica"
        >
          <img
            src={jumpafrica}
            alt=""
            style={{
              objectFit: "contain",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="send"
        >
          <img
            src={send}
            alt=""
            style={{ objectFit: "cover", width: "70px", height: "45px" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="grey"
        >
          <img
            src={grey}
            className="menu-item-cp"
            alt=""
            style={{ objectFit: "cover" }}
          />
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="d-flex justify-content-center align-items-center   font-500"
          value="wirepay"
        >
          <img
            src={wirepay}
            alt=""
            style={{
              objectFit: "contain",
              width: "120px",
              height: "45px",
              objectPosition: "left",
            }}
          />
        </MenuItem>
      </Select>
    </div>
  );
}
