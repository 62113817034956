import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Box, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Container maxWidth={"xl"} className="w-100 logo-container">
        <img src={logo} alt="logo" className="logo" />
      </Container>
      <Container style={{ maxWidth: 650 }} className="mt-2">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/signup">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
        </Box>
      </Container>
      <div>
        <Container style={{ maxWidth: 650 }}>
          <h4
            className="monster-font display-4"
            style={{ fontWeight: 600, fontSize: "40px" }}
          >
            TERMS AND CONDITIONS
          </h4>

          <p>
            These Terms and Conditions is a Legal Agreement between you, as a
            prospective customer of LINKIO. Global Services Limited a company
            incorporated in Nigeria (RC No: 1820023) with office at Plot B30
            Federal Housing Estate Idoro, Uyo, Akwa Ibom, (“LINK”, “We”, “Us” or
            “Our”) and shall govern your access to and use of LINK’s services
            which include all pages within the LINK’s website, mobile
            applications and other products and services (collectively referred
            to as the “Services”).
          </p>
          <p>
            These Terms apply in full force and effect to your use of the
            Services and by using any of the Services, you expressly accept all
            terms and conditions contained herein in full and without limitation
            or qualification, including our Privacy Policy. You must not use any
            of the Services, if you have any objection to any of these Terms.
          </p>
          <p>
            NOTE: WE ADVISE THAT YOU READ AND UNDERSTAND THE TERMS OF THIS
            AGREEMENT CAREFULLY BEFORE ACCEPTING TO BE BOUND BY IT’S TERMS
          </p>
          <br />

          <h4>Who May Use Our Services</h4>
          <p>
            You may use the Services only if you agree to LINK’s terms and
            conditions, and are not a person barred from receiving services
            under the laws of the applicable jurisdiction. If you are an
            individual, you must be 18 years or older to use our Services and by
            opening an account with LINK, you declare that you are 18 years or
            older. If you are accepting these Terms and using the Services on
            behalf of a company, business, or organization, you represent and
            warrant that you are authorized to do so.
          </p>
          <br />

          <h4>Age Restriction</h4>
          <p>
            Our website and services are directed to people from the ages of 18
            and above. You are only permitted to use the Services if you are
            aged 18 or older. We do not knowingly engage people younger than the
            age of 18.
          </p>
          <br />

          <h4>Software License</h4>
          <p>
            We grant you a non-assignable, non-exclusive and revocable license
            to use the software provided as part of our services in the manner
            permitted by these Terms. This license grant includes all updates,
            upgrades, new versions and replacement software for you to use in
            connection with our services. Our services are protected by
            copyright, trademark, and other laws of both Nigeria and foreign
            countries. You have no right to use our name trademarks, logos,
            domain names, and other distinctive brand features. If you do not
            comply with all the provisions, then you will be liable for all
            resulting damages arising from your non-compliance.
          </p>
          <p>
            Unless otherwise provided by applicable law, you agree not to alter,
            re-design, reproduce, adapt, display, distribute, translate,
            disassemble, reverse engineer, or otherwise attempt to create any
            source code that is derived from the software.
          </p>
          <br />

          <h4>Intellectual Property</h4>
          <p>
            Unless otherwise stated, LINK and/or its licensors own the
            intellectual property rights and materials on the website subject to
            the license above. All text, formatting including without limitation
            the arrangement of materials on the LINK website and the graphics,
            animation, tools, commercials, music, video, articles, sound, copy,
            trade names, logos and other materials and information on the
            website are subject to the intellectual property rights of LINK and
            its affiliates and their licensors and licensees (collectively the
            “Content”). We do not grant you any right, license, title or
            interest to any of our Intellectual Property rights which you may or
            may not have access to. These Content may not be copied, reverse
            engineered, decompiled, disassembled, modified or reposted to other
            websites. Nothing on the site should be construed as granting by
            implication or otherwise, any license or right to use any Trademark
            displayed on the website without the written permission of LINK or
            such third party that may own the Trademark. You agree to take such
            actions including any legal or official document or other documents
            that may be needed to further access our intellectual property
            rights.
          </p>
          <br />

          <h4>Warranties and Representations</h4>
          <p>You represent to Us that:</p>
          <ul>
            <li>
              You have the full power, authority, regulatory approval or
              corporate authorisation to enter into, execute, deliver and
              perform this Agreement.
            </li>
            <li>
              You are duly organised, authorised and in good standing under the
              laws of the Federal Republic of Nigeria or any state, region or
              country of your organisation and are duly authorised to do
              business in all other states, regions or countries in which your
              business operates.
            </li>
          </ul>
          <br />

          <h4>Charges and Fees</h4>
          <p>
            You must pay the fees in connection with the use of our Services. We
            will not process your transaction until we have received the fees
            from you. We will let you know the exact amount payable by you when
            you set up your order.
          </p>
          <br />

          <h4>Disclaimer</h4>
          <p>
            We will make efforts to ensure that our services are available to
            you always. But we cannot guarantee that our Services will always be
            available, bug free or uninterrupted. You are responsible for
            protecting your computer, software and other devices you use to
            access our service.
          </p>
          <br />

          <h4>Limitation of Liability</h4>
          <p>
            We shall be responsible for any damage or foreseeable loss that is
            caused by us. If any loss or damage suffered by you is a direct
            result of us not living up to our commitment, breaking our contract
            with you or failing to carry out reasonable care and skill, then we
            shall be responsible.
          </p>
          <p>
            However, we shall not be responsible for any damage or loss that is
            not foreseeable. A loss or damage is considered foreseeable if it
            was anticipated at the time the contract was made. We are not liable
            for business losses. We are not liable for any loss or damage that
            may be caused by a virus or any other harmful material that may
            affect your computer, software, data or any other proprietary
            material during your of use our service. We do have control over
            websites linked to our website, there for we are not liable for any
            loss or damage that may arise from your use of them. If we make an
            unauthorized payment, we shall at your request refund the amount
            immediately including all fees deducted. We will not be liable for
            things which are outside our control. If you happen to be indebted
            to Us, we reserve the right to remove such amounts from your
            balance.
          </p>
          <br />

          <h4>Indemnity</h4>
          <p>
            You hereby indemnify us and undertake to keep LINK, its staff and
            affiliates indemnified against any losses, damages, costs,
            liabilities and expenses (including without limitation reasonable
            legal fees and expenses) arising out of any breach by you of any
            provision of these Terms, or arising out of any claim that you have
            breached any provision of these Terms. You will indemnify and hold
            LINK harmless from and against any claim, suit or proceedings
            brought against LINK arising from or in connection with violations
            of Intellectual Property or other rights of third parties in
            relation to your use of the Services.
          </p>
          <br />

          <h4>Breaches of these Terms</h4>
          <p>
            Without prejudice to LINK’s other rights under these Terms, if you
            breach these Terms in any way, we may take such action as we see
            fit, including suspending your access to the website, prohibiting
            you from accessing the website, blocking computers using your IP
            address from accessing the website, contacting your internet service
            provider to request that they block your access to the website
            and/or bringing court proceedings against you.
          </p>
          <br />

          <h4>Data Protection</h4>
          <p>
            We value the privacy of those who visit our site and use our
            services. Therefore, we are compliant with all Data Protection Laws
            in the territories in which we operate, including the Nigeria Data
            Protection Regulation 2019 (NDPR) and the General Data Protection
            Regulation 2018 (GDPR). LINK maintains a Privacy Policy which
            provides an overview of the Personal Information we collect about
            you or that you provide to us and how we store and use the
            information provided by you in line with applicable Data protection
            legislations. By using our Services, you consent to such processing
            and you commit to provide accurate information.
          </p>
          <br />

          <h4>Cookie Policy</h4>
          <p>
            Like many other websites, we make use of cookies. To know more about
            this, check out our cookie policy.
          </p>
          <br />

          <h4>Governing Law</h4>
          <p>
            The formation, existence, construction, performance, validity and
            all aspects whatsoever of the terms in this Agreement will be
            governed by the Laws of the Federal Republic of Nigeria.
          </p>
          <br />

          <h4>Assignment</h4>
          <p>
            You hereby agree that we may assign, transfer, sub-contract or
            otherwise deal with our rights and/or obligations for proper
            execution of our obligations to you under these terms and
            conditions.
          </p>
          <p>
            Without our prior written consent, you may not assign, transfer,
            sub-contract, or otherwise deal with any of your rights and/or
            obligations under these terms and conditions.
          </p>
          <br />

          <h4>Termination</h4>
          <p>You may terminate this Agreement by closing your LINK Account.</p>
          <p>
            We may suspend your LINK Account and your access to LINK services
            and any funds, or terminate this Agreement, if;
          </p>
          <ul>
            <li>
              you do not comply with any of the provisions of this Agreement;
            </li>
            <li>we are required to do so by a Law;</li>
            <li>
              we are directed by a Card Network or issuing financial
              institution; or
            </li>
            <li>where a suspicious or fraudulent transaction occurs.</li>
          </ul>
          <br />

          <h4>Updates, Modifications &amp; Amendments</h4>
          <p>
            LINK reserves the right to change, revise or modify these Terms from
            time to time by updating this page. We will also try to notify you
            of any material changes which could be done via email associated
            with your account or service notification. By continuing to use our
            services after the changes become effective, you agree to be bound
            by the revised Terms.
          </p>
          <br />

          <h4>Contact Us</h4>
          <p>
            All access requests, questions, comments, complaints and other
            requests should be sent to support@linkio.africa.
          </p>
        </Container>
      </div>
    </>
  );
};

export default Terms;
