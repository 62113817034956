import React from 'react';
import logo from '../../../assets/logo.png';
import { Link } from 'react-router-dom';
import HomeIcon from '../../../assets/icons/HomeIcon';
import CardIcon from '../../../assets/icons/CardIcon';
import BitcoinconvertIcon from '../../../assets/icons/BitcoinconvertIcon';
import ExportIcon from '../../../assets/icons/ExportIcon';
import UserIcon from '../../../assets/icons/UserIcon';
import StatsIcon from '../../../assets/icons/StatsIcon';
import { Box, Modal } from '@mui/material';
import close from '../../../assets/close.png';
import ngn from '../../../assets/nigeria.png';
import ghs from '../../../assets/ghs.png';
import zar from '../../../assets/zar.svg';
import bwp from '../../../assets/bwp.svg';
import rwf from '../../../assets/rwf.png';
import kes from '../../../assets/kes.svg';
import tzs from '../../../assets/tzs.svg';
import Select from '@mui/material/Select';
import './CreateCard.css';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Converter from '../../../components/converter/Converter';
import {
  selectCurrentAccLock,
  selectCurrentAccPlus,
  selectCurrentTransAcc,
} from '../../../features/auth/authSlice';

const LeftPanel = (p) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const accLock = useSelector(selectCurrentAccLock);
  const accPlus = useSelector(selectCurrentAccPlus);
  const transAcc = useSelector(selectCurrentTransAcc);

  return (
    <Box
      style={{ background: 'white', height: '100%', minHeight: '100vh' }}
      className="left-panel pt-2 px-4"
    >
      <div className="py-3 px-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="mt-4 curr">
        <SelectCurr />
      </div>
      <div className="my-4 font-600" style={{ fontSize: 16 }}>
        <div className="py-4 px-3">
          <Link
            style={{ textDecoration: 'none', color: p.activeColor1 }}
            className="d-flex align-items-center"
            to="/dashboard"
          >
            <HomeIcon iconColor={p.iconColor1} />
            <span className="ms-2"> Dashboard</span>
          </Link>
        </div>
        <div className="py-4 px-3">
          {accLock === 'verified' && accPlus === true ? (
            <Link
              style={{ textDecoration: 'none', color: p.activeColor2 }}
              className="d-flex align-items-center"
              to="/cards"
            >
              <CardIcon iconColor={p.iconColor2} />
              <span className="ms-2"> Card</span>
            </Link>
          ) : (
            <Link
              style={{ textDecoration: 'none', color: p.activeColor2 }}
              className="d-flex align-items-center"
              to="/incomplete-verification"
            >
              <CardIcon iconColor={p.iconColor2} />
              <span className="ms-2"> Card</span>
            </Link>
          )}
        </div>
        <div className="py-4 px-3">
          <div
            style={{
              textDecoration: 'none',
              color: p.activeColor3,
              cursor: 'pointer',
            }}
            className="d-flex align-items-center"
            onClick={handleOpen}
          >
            <ExportIcon iconColor={p.iconColor3} />
            <span className="ms-2">Transfer</span>
          </div>
          {transAcc === true ? (
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="left-panel-popup_1">
                <Converter handleClose={handleClose} />
              </Box>
            </Modal>
          ) : (
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="left-panel-popup">
                <TransferOff handleClose={handleClose} />
              </Box>
            </Modal>
          )}
        </div>
        <div className="py-4 px-3">
          <Link
            style={{ textDecoration: 'none', color: p.activeColor4 }}
            className="d-flex align-items-center"
            to="/limited-access"
          >
            <UserIcon iconColor={p.iconColor4} />
            <span className="ms-2"> Recipients</span>
          </Link>
        </div>
        <div className="py-4 px-3">
          <Link
            style={{ textDecoration: 'none', color: p.activeColor5 }}
            className="d-flex align-items-center"
            to="/compare-rates"
          >
            <StatsIcon iconColor={p.iconColor5} />
            <span className="ms-2"> Compare rates</span>
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default LeftPanel;

const TransferOff = ({ handleClose }) => {
  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <img
          src={close}
          onClick={handleClose}
          style={{ height: 42, width: 42, cursor: 'pointer' }}
          alt="close"
        />
      </div>
      <br />
      <br />
      <h3 style={{ textAlign: 'center' }} className="font-500 fs-1 my-2 ">
        Sorry, Transfer is temporarily unavailable!
      </h3>
      <br />
      <br />
    </>
  );
};

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   bgcolor: "#F3F4F6",
//   border: "none",
//   boxShadow: 24,
//   p: 5,
//   height: 675,
//   overflowY: "scroll",
//   borderRadius: 3,
// };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F3F4F6',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  width: 450,
  p: 4,
};

function SelectCurr() {
  const [curr, setCurr] = React.useState(10);

  const handleChange = (event) => {
    setCurr(event.target.value);
  };

  return (
    <div>
      <Select
        value={curr}
        onChange={handleChange}
        className="font-500"
        displayEmpty={true}
        label="curr"
        style={{ minWidth: 100, padding: 0 }}
      >
        <MenuItem
          className="py-1 d-flex align-items-center px-3 font-500"
          value={10}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={ngn}
            alt="flag"
          />
          NGN
        </MenuItem>
        {/* <hr className="m-0" />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={20}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={ghs}
            alt="flag"
          />
          GHS
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={30}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={zar}
            alt="flag"
          />
          ZAR
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={40}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={bwp}
            alt="flag"
          />
          BWP
        </MenuItem>
        <hr className="m-0 " style={{ backgroundColor: "black" }} />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={50}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={rwf}
            alt="flag"
          />
          RWF
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={60}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={kes}
            alt="flag"
          />
          KES
        </MenuItem>
        <hr className="m-0" />
        <MenuItem
          className="py-2 d-flex align-items-center px-3 font-500"
          // value={70}
        >
          <img
            style={{ height: 42, width: 42, marginRight: 20 }}
            src={tzs}
            alt="flag"
          />
          TZS
        </MenuItem> */}
      </Select>
    </div>
  );
}
