import { Box, Button, Container } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import closeBtn from "../../assets/close-square.png";
import { Col, Row } from "react-bootstrap";
import arrowDown from "../../assets/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import MobileNavbarOne from "../../components/MobileNavbarOne";
import toast from "react-hot-toast";
import { useLocalBankMutation } from "../../services/tryWithdrawApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BankWithdrawTry = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fullName, setFullName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [iban, setIban] = React.useState("");
  const [swift, setSwift] = React.useState("");

  const navigate = useNavigate();
  const [localBank] = useLocalBankMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastSubmit = toast.loading("Withdrawing...");

    try {
      const response = await localBank({
        fullName,
        swiftCode: swift,
        bankName,
        iban,
      }).unwrap();
      toast.success("Successful", { id: toastSubmit });

      navigate("/withdrawal-received");
    } catch (error) {
      toast.error(error.data.message, { id: toastSubmit });
      navigate("/withdrawal-not-received");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="primary-bg h-100" style={{ minHeight: "100vh" }}>
        <div>
          <MobileNavbarOne />
        </div>

        <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
          <img src={logo} alt="logo" />
        </Container>
        <Box>
          <Container className="rco-box1">
            <Box className="d-flex justify-content-between align-items-center">
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <img src={backArrow} alt="backArrow" className="rco-img1" />
                  <span className="rco-h2">Go Back</span>
                </button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <button
                  className="rco-btn1 font-600"
                  style={{ color: "#1565D8" }}
                >
                  <span className="rco-h2">Cancel</span>
                  <img src={closeBtn} alt="closeBtn" className="rco-img2" />
                </button>
              </Link>
            </Box>
          </Container>
          <Container>
            <h1 className="font-500 wn-h1">Withdraw TRY</h1>
            <Row className="justify-content-between align-items-center">
              <Col className="my-1" xs={12} md={6}>
                <div className="input-h1">Full name of account holder</div>
                <div className="d-flex align-items-center">
                  <input
                    className="input-req wt-input1"
                    placeholder="Enter account holder name"
                    value={fullName}
                    required
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </Col>
              <Col className="my-1" xs={12} md={6}>
                <div className="input-h1">Bank name</div>
                <input
                  className="input-req wt-input1"
                  placeholder="Enter bank name"
                  value={bankName}
                  required
                  onChange={(e) => setBankName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="justify-content-between align-items-center">
              <Col className="my-1" xs={12} md={6}>
                <div className="input-h1">IBAN</div>
                <input
                  className="input-req wt-input1"
                  placeholder="Enter IBAN number"
                  value={iban}
                  required
                  onChange={(e) => setIban(e.target.value)}
                />
              </Col>
              <Col className="my-1" xs={12} md={6}>
                <div className="d-flex align-items-center">
                  <div className="input-h1 me-3">SWIFT Code</div>
                  <Button
                    variant="contained"
                    className="font-500"
                    style={{ background: "#fff", color: "#1565DB" }}
                  >
                    CHECK
                  </Button>
                </div>
                <input
                  className="input-req wt-input1"
                  placeholder="Enter SWIFT code"
                  value={swift}
                  required
                  onChange={(e) => setSwift(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Box>
        <Container className="text-center">
          <button className="wt-btn1" type="submit">
            Continue
          </button>
        </Container>
      </div>
    </form>
  );
};

export default BankWithdrawTry;
