import { Box, Container, Grid, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import info from "../../../assets/info.png";
import userAdd from "../../../assets/user-add.png";
import { useCadInteracMutation } from "../../../services/transferApi";
import { selectCurrentUserId } from "../../../features/auth/authSlice";
import { useRecipientAddressMutation } from "../../../services/recipientApi";
import { useDispatch, useSelector } from "react-redux";
import { setName } from "../../../features/transferSlice";
import { setCad } from "../../../features/transferRouteSlice";

const CadInteract = () => {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector(selectCurrentUserId);
  const [recipientAddress] = useRecipientAddressMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setName({ recipientName: fullName }));
    dispatch(setCad({ transactionType: "interac", fullName, email }));
    navigate("/review-cad-order");
  };

  const handleAddRecipient = async (e) => {
    e.preventDefault();

    // const submitToast = toast.loading("Adding...");

    // try {
    //   const response = await recipientAddress({
    //     type: "Address details",
    //     fullName,
    //     email: interacEmail,
    //     userId,
    //   });
    //   toast.success("Recipient Added", { id: submitToast });
    //   navigate("/recipient");
    // } catch (error) {
    //   toast.error(error.data.message, { id: submitToast });
    //   navigate("/recipient");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center font-600">
        <div
          style={{ background: "#E7EFFF", Gridor: "#1856AF", fontSize: 14 }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          Interac is faster, but it’s also more expensive. The money will reach
          your recipient in a few hours at most, but we might need to charge you
          a little more. (A traditional bank transfer might take 1-2 days)
        </div>
      </div>
      <Grid container spacing={{ xs: 0, lg: 5 }}>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Full name of account holder</div>
          <input
            className="input-req w-100 "
            placeholder="Enter account holder name"
            value={fullName}
            required
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="my-1">
          <div className="input-h1">Interac registered email</div>
          <input
            className="input-req w-100 "
            placeholder="Enter email address"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>

      <Row className=" justify-content-between align-items-center">
        <Col style={{ marginTop: "20px" }} xs={12} md={6} className="wn-box1">
          <div className="text-center">
            <Button
              variant="contained"
              style={{
                background: "white",
                color: "black",
                padding: "10px 15px",
              }}
              onClick={handleAddRecipient}
            >
              <img src={userAdd} className="me-2" alt="" />
              Add to recipients
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="text-center">
            <button className="wn-btn1" type="submit">
              Continue
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default CadInteract;
