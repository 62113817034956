import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import backArrow from "../../assets/arrow-left.png";
import info from "../../assets/info.png";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";

import "./Transaction.css";
import MobileNavbarOne from "../../components/MobileNavbarOne";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 1,
  borderRadius: 3,
  p: 4,
};

const UsdOutAccDetails = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="primary-bg h-100">
      <div>
        <MobileNavbarOne />
      </div>
      <Container maxWidth={"xl"} className="w-100 pt-5 px-5 logo-vip">
        <img src={logo} alt="logo" />
      </Container>
      <Container className="rco-box1">
        <Box className="d-flex justify-content-between align-items-center">
          <Link style={{ textDecoration: "none" }} to="/transfer-reverse-usd">
            <button className="rco-btn1 font-600" style={{ color: "#1565D8" }}>
              <img src={backArrow} alt="backArrow" className="rco-img1" />
              <span className="rco-h2">Go Back</span>
            </button>
          </Link>
          <div></div>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Typography className=" font-500 wr-h1">
          Our USD account details (Outside the US)
        </Typography>
        <br />
        <div
          style={{
            background: "#E7EFFF",
            Gridor: "#1856AF",
            fontSize: 14,
            color: "#1565D8",
          }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          Payments take 4-5 working days to arrive our account.
        </div>

        <div
          style={{
            background: "#E7EFFF",
            Gridor: "#1856AF",
            fontSize: 14,
            color: "#1565D8",
          }}
          className="d-flex  align-items-center p-2 my-3 rounded "
        >
          <img src={info} className="me-2" alt="" />
          There’s a 4.14 USD fee to receive wire transfers.
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Account holder</div>
          <div className="acc-details-right">Finc Arts Studio Ltd</div>
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">SWIFT/BIC</div>
          <div
            style={{
              color: "#1565D8",
              backgroundColor: "#E7EFFF",
              padding: "8px 20px",
              fontSize: "18px",
              fontWeight: "semibold",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            Click to reveal
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          <TransferDetails handleClose={handleClose} />
        </Modal>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Account number </div>
          <div className="acc-details-right" style={{ color: "#1565D8" }}>
            8311211940
          </div>
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Account type</div>
          <div className="acc-details-right"> Checking</div>
        </div>
        <br />
        <div className="acc-details-container">
          <div className="acc-details-left">Bank Address</div>
          <div className="acc-details-right">
            30 W. 26th Street, Sixth Floor New York NY 10010 United States
          </div>
        </div>

        <br />
        <div className="text-center" style={{ maxWidth: 1000 }}>
          <Link to="/proof-of-payment">
            <button className="wn-btn1">Next</button>
          </Link>
        </div>
        <div style={{ height: "20vh" }}></div>
      </Container>
    </div>
  );
};

export default UsdOutAccDetails;

export function TransferDetails({ handleClose }) {
  return (
    <Box sx={style} width={{ xs: 400, sm: 500 }} className="primary-bg">
      <button
        className="rco-btn1 font-600"
        style={{ color: "#1565D8" }}
        onClick={handleClose}
      >
        <img src={backArrow} alt="backArrow" className="rco-img1" />
        <span className="rco-h2">Go Back</span>
      </button>

      <Typography id="modal-modal-title" variant="h6" component="h2">
        SWIFT/BIC: <span style={{ color: "#1565D8" }}>CMFGUS33</span>
      </Typography>
      <div
        style={{
          background: "#E7EFFF",
          Gridor: "#1856AF",
          fontSize: 14,
          color: "#1565D8",
        }}
        className="d-flex  align-items-center p-2 my-3 rounded "
      >
        <img src={info} className="me-2" alt="" />
        We’ll refund payments from countries not on this list to the sender.
        This can take 3-10 working days.
      </div>
      <div>
        <h4 style={{ marginBottom: "10px" }}>America</h4>
        <p>United States, Canada</p>
      </div>
      <br />
      <div>
        <h4 style={{ marginBottom: "10px" }}>Asia</h4>
        <p>
          China, Hong Kong, India, Japan, Philippines, Singapore, Taiwan, United
          Arab Emirates.
        </p>
      </div>
      <br />
      <div>
        <h4 style={{ marginBottom: "10px" }}>Oceania</h4>
        <p>Australia, New Zealand</p>
      </div>
      <br />
      <div>
        <h4 style={{ marginBottom: "10px" }}>Europe</h4>
        <p>
          Austria, Belgium, Bulgaria, Croatia, Czech Republic,Denmark, Estonia,
          Finland, France, Germany, Gibraltar, Guernsey, Greece, Hungary,
          Iceland, Ireland, Isle of Man, Italy, Jersey, Latvia, Liechtenstein,
          Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania,
          Slovakia, Slovenia, Spain, Sweden, Switzerland, United Kingdom.
        </p>
      </div>
    </Box>
  );
}
